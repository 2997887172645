import {Typography, Grid , IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import './Etherbait/MarketEtherbait.css';
import './Marketplace.css';
import React, { Component } from 'react';


class MarketTransactionInfoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            infoModalOpen: true
        }
    }

    render() {
        return (
            <div>
                <Typography >
                    Please sign the created trade request in Enjin app within 5 minutes or the transaction will fail. 
                    {<br></br>}{<br></br>}
                    Your purchase will show up within 2 minutes of signing the trade request. 
                    {<br></br>}{<br></br>}
                    You may notice that the trade request says Cost: 0JENJ. This is refering to 
                    the gas cost of the transaction.
                    {<br></br>}{<br></br>}
                    <Grid container>
                        <Grid className="market-grid-item" item xs={9}>
                            If you get a warning in the transaction status page please report the issue 
                            by email to etherfishing@gmail.com or the fishcord #report-bugs 
                            channel and we will resolve the issue promptly.  
                        </Grid>
                        <Grid className="market-grid-item" item xs={3}>
                            <IconButton className="about-page-social-icons-email" href="mailto: Etherfishing@gmail.com" target="_">
                                <EmailIcon fontSize="large"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Typography>
            </div>
        )
    }
}

export default (MarketTransactionInfoModal)