import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserInfo } from '../../store/actions/firestoreActions';
import './UserAgreement.css';

class UserAgreement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userOver21: false
        }

        this.handleAcceptAgreement = this.handleAcceptAgreement.bind(this)
        this.handleOver21Checked = this.handleOver21Checked.bind(this)
    }

    handleOver21Checked(event) {
        this.setState({ userOver21: event.target.checked })
    }

    handleAcceptAgreement() {
        this.props.updateUserInfo({over21: true})
        this.setState({userOver21: true})
    }

    render() {
        return (
            <Dialog
                open={this.props.userInfo && !this.props.userInfo?.over21 ? true : false}
            >
                <DialogTitle>User Agreement</DialogTitle>
                <DialogContent className="user-agreement-content">
                    <FormControl required error={this.state.userOver21Error}>
                        <FormControlLabel
                            checked={this.state.userOver21}
                            onChange={this.handleOver21Checked}
                            color="primary"
                            control={<Checkbox name="userOver21" />}
                            label="I am 21+ years old"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleAcceptAgreement}
                        disabled={!this.state.userOver21}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAgreement)