import { Grid, Grow, Paper, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Fish.css';

class Fishdex extends Component {

    render() {
        return (
            <div className="fishdex-page">
                <Grow in={true} style={{transformOrigin: 'top'}}>
                    <Paper className="fishdex-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Typography variant="h1">
                                    Fishdex
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </div>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    icon: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconActive: {
        color: "#6690cb",
    },
    iconCompleted: {
        color: "#3465b0",
    },
    imageIcon: {
        height: '100%',
        width: '100%',
    },
    iconRoot: {
        textAlign: 'center'
    }
});

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Fishdex))