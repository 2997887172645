export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    /*{
        title: 'Go Fish!',
        url: '/game',
        cName: 'nav-links'
    },*/
    {
        title: 'Fish Tank',
        url: '/bowl',
        cName: 'nav-links'
    },
    /*{
        title: 'Marketplace',
        url: '/marketplace',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links'
    },*/
    {
        title: 'Profile',
        url: '/user/profile',
        cName: 'nav-links'
    }
]