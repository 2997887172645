const initState = {
    species: null,
    speciesError: null,
    traits: null,
    traitsError: null,
}

const speciesAndTraitsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_SPECIES_SUCCESS':
            return {
                ...state,
                species: action.species,
                speciesError: null
            }
        case 'GET_SPECIES_ERROR':
            return {
                ...state,
                speciesError: action.error.message
            }
        case 'GET_TRAITS_SUCCESS':
            return {
                ...state,
                traits: action.traits,
                traitsError: null
            }
        case 'GET_TRAITS_ERROR':
            return {
                ...state,
                traitsError: action.error.message
            }
        default:
            return {
                ...state
            }
    }
}

export default speciesAndTraitsReducer
