import { Button, Dialog, DialogContent, Divider, Grid, Grow, Icon, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import willImage from '../static/images/BioPics/will-image.gif';
import collinImage from '../static/images/BioPics/collin-image.gif';
import jackImage from '../static/images/BioPics/jack-image.gif';
import triImage from '../static/images/BioPics/tri-image.gif';
import ethanImage from '../static/images/BioPics/ethan-image.png';
import gabrieleImage from '../static/images/BioPics/sleeptalker-image.png';
import romanPunksImage from '../static/images/BioPics/romanPunks.png';
import cryptoClockyImage from '../static/images/BioPics/cryptoClocky.gif';
import mintopolyImage from '../static/images/BioPics/1Etherfish0.gif';
import LSDxGummyBearImage from '../static/images/BioPics/LSDNarwhal.gif';
import CoinHuntWorldImage from '../static/images/BioPics/cubie.gif';
import logo from '../static/images/logo.png';
import openseaLogo from '../static/images/opensea-logo.svg';
import jackpot from '../static/images/500.png';
import bait from '../static/images/Bait/baitS.png';
import fish from '../static/images/fish.png';
import collect from '../static/images/Collectibles/starfish.png';
import './About.css';
import Footer from '../components/Footer/Footer';
import HelpIcon from '@material-ui/icons/Help';
import DialogTitle from '../components/DialogTitle';
import { updateFirstTimePopup } from '../store/actions/firestoreActions';
import { connect } from 'react-redux';
import discordLogo from '../static/images/discord-logo-white.svg';


class About extends Component {

    constructor(props) {
        super(props);

        this.handleCloseFirstTimePopup = this.handleCloseFirstTimePopup.bind(this)
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    render() {
        return (
            <div className="about-page">
                <Grow in={true} style={{transformOrigin: 'top'}}>
                    <Paper className="about-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                {this.renderAboutSummary()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderCatches()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderCombine()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderCollect()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderEarn()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderPremierFish()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderTheTeam()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderContributors()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderCollaborations()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderWorkWithUs()}
                                <Footer/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
                {this.renderFirstTimePopup()}
            </div>
        )
    }

    renderAboutSummary() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={()=> window.open("https://docs.google.com/document/d/1PZRXbNa7TYX8zVDdF5dYqd1pstdW6XWkUiZ7OWB4x74/edit?usp=sharing")}
                    size="large"
                >
                    <Typography variant="h5">
                        White Paper
                    </Typography>
                </Button>
                <Button 
                        className="home-page-help-button"
                        color="primary" 
                        variant="contained"
                        onClick={() => this.props.history.push('/help')}
                        startIcon={<HelpIcon fontSize="large"/>}
                    >
                        <Typography variant="h5">Beginners Guide</Typography>
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => this.props.history.push('/species-and-traits')}
                    size="large"
                >
                    <Typography variant="h5">
                        View Traits And Species Page
                    </Typography>
                </Button>
                <br/><br/>
                <Typography variant="h3">
                    About Etherfishing&nbsp;
                    <IconButton className="home-page-social-icons-discord" href="https://discord.gg/Q8SZutwRCR" target="_">
                        <Icon classes={{root: classes.iconRoot}}>
                            <img className={classes.imageIcon} src={discordLogo} alt=""/>
                        </Icon>
                    </IconButton>
                </Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Grid container className="about-page-summary-list-grid" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <div className="about-page-summary-list-text">
                            <Typography variant="h4">
                                <b>SEASON</b><br/>
                            </Typography>
                            <Typography variant="h5">
                                -LARGE Pool of NFTs <br/>
                                -Golden Etherfish <br/>
                                -Limited Artwork <br/>
                                -Special Events <br/>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="about-page-summary-list-text">
                        <Typography variant="h4">
                            <b>OFF-SEASON</b><br/>
                        </Typography>
                        <Typography variant="h5">
                            -SMALL Pool of NFTs <br/>
                            -Themed Catches <br/>
                            -Discord Events <br/>
                            -Giveaways <br/>
                        </Typography></div>                        </Grid>
                    </Grid>
                    <Typography variant="h5">
                        EVERYTHING will be caught in each pool!
                    </Typography>
            </div>
        )
    }

    renderCatches() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Catch</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Typography variant="h5">
                    <Grid container className="about-page-summary-list-grid" spacing={2}>
                        <Grid item xs={12} md={3}>
                            <img className="about-page-sq-img" src={fish} alt=""/>
                            <Grid item>
                                <div className="about-page-summary-list-text"><b>Etherfish</b><Typography>One of our Etherfish NFTs backed by ENJ</Typography></div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className="about-page-sq-img" src={jackpot} alt=""/>
                            <Grid item>
                                <div className="about-page-summary-list-text"><b>Golden Etherfish</b><Typography>Big Money Fish redeemable for full value</Typography></div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <img className="about-page-sq-img" src={bait} alt=""/>
                            <Grid item>
                                <div className="about-page-summary-list-text"><b>Etherbait</b><Typography>Single-use items to increase the chance at catching an Etherfish!</Typography></div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className="about-page-sq-img" src={collect} alt=""/>
                            <Grid item>
                                <div className="about-page-summary-list-text"><b>Collectibles</b><Typography>Collectible items that give your bonuses over time in your Profile!</Typography></div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Typography>
            </div>
        )
    }

    renderCombine() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Combine</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Typography variant="h5">
                    <Grid container className="about-page-summary-list-grid" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div className="about-page-summary-list-text">
                                <b>Eating</b>
                                <div className="about-page-summary-list-container">
                                    <Typography><b>&#8226;&nbsp;</b>Have one of your Etherfish Eat another to gain all unique traits and an additional property "Eaten: X" that boosts Golden Etherfish catch percentages!</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>If you Eat a premier Etherfish, the remaining Etherfish will gain "Premier Eaten: X" to retain all profits as it transfers accounts.</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Only a TOTAL of 5 Fish can be Eaten by one fish!</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Legendary Traits will be passed on!</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Trophy Catch will count for 2 Fish Eaten!</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Eating another fish with “Eaten: X” will only increase by one!!</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="about-page-summary-list-text">
                                <b>Breeding</b>
                                <div className="about-page-summary-list-container">
                                    <Typography><b>&#8226;&nbsp;</b>Breed 2 Etherfish of the same species (see Etherfish Data Below) to create a new Etherfish!</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Bred Etherfish have a 20% Chance to gain each trait from both Etherfish for a Max of 6 Traits</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Legendary Traits, Trophy Catches, and Eaten Traits are excluded from breeding but guarantee 1 random unique Trait.</Typography>
                                    {<div/>}
                                    <Typography><b>&#8226;&nbsp;</b>Special Etherfish CAN NOT produce more Special Etherfish (ie. Coin Sharks, Etherfish #420...). Breeding Special Etherfish of the same Species gives you a Random Etherfish!</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Typography>
            </div>
        )
    }

    renderSpeciesBreakdown() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Species and Traits Breakdown</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => this.props.history.push('/species-and-traits')}
                    size="large"
                >
                    <Typography variant="h5">
                        View Page
                    </Typography>
                </Button>
            </div>
        )
    }

    renderPremierFish() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3" className="home-page-content-title">Premier Etherfish: Membership Benefits</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h5" className="home-page-content-title">
                    ★ Possible Airdrop at the End of Each Season
                    <br/> ★ Rare Fish Tank Backgrounds Depending on the Premier Etherfish #
                    <br/> ★ Voting Rights for Future Updates & Plans
                    <br/> ★ Exclusive Discord Events & Giveaways 
                    <br/> ★ Bonus Referrals in Profile
                    <br/><br/>
                    There are 5 background sets and each background set will offer the Etherfisher a unique, swappable fish tank 
                    which all their fish will be displayed in! Owning 1 Etherfish from each background set unlocks the fish tank 
                    for that set. 
                    {<div></div>}
                    0-199(V1), 200-399(V2), 400-599(V3), 600-799(V4), 800-999(V5)
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="large" 
                    href="https://opensea.io/collection/etherfishing" 
                    target="_" 
                    className="about-page-opensea-button"
                    startIcon={
                        <Icon classes={{ root: classes.iconRoot }} fontSize="large" style={{maxWidth: '45px', height: 'auto'}}>
                            <img className={classes.imageIcon} src={openseaLogo} alt="" />
                        </Icon>
                    }
                >
                    <Typography variant="h5">
                        OpenSea
                    </Typography>
                </Button>
            </div>
        )
    }

    renderCollect() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3" className="home-page-content-title">Collect</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h5" className="home-page-content-title">
                    View all Etherfish NFTs swimming in your Fish Tank! {<div></div>}
                    All Traits increase the Etherfish Size and Boost Stats!{<div></div>}{<div></div>}
                    <br/>
                    <Grid container className="about-page-summary-list-grid" spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Grid>
                                <div className="about-page-summary-list-text"><b>Positive Traits</b><Typography> Increase Bait Effectiveness (0.08%)</Typography></div>
                            </Grid>
                            <Grid>
                                Common
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid>
                                <div className="about-page-summary-list-text"><b>Neutral Traits</b><Typography>Increase Etherfish Catch Percentage (+0.016%)</Typography></div>
                            </Grid>
                            <Grid>
                                Common
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid>
                                <div className="about-page-summary-list-text"><b>Negative Traits:</b><Typography>Cast Time Reduction (+0.07%) <br/>Note: Not including F2P pole</Typography></div>
                            </Grid>
                            <Grid>
                                Common
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid>
                                <div className="about-page-summary-list-text"><b>Legendary Traits:</b><Typography> Unique Swimming Animations<br/> COMBINABLE Through EATING</Typography></div>
                            </Grid>
                            <Grid>
                                Legendary
                            </Grid>
                        </Grid>
                    </Grid>
                </Typography>
            </div>
        )
    }

    renderEarn() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3" className="home-page-content-title">Earn</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h5" className="home-page-content-title">
                    Earn using your NFTs in 6 different ways!
                    <br/> 1. Melt directly into JENJ
                    <br/> 2. Sell the NFTs on the market
                    <br/> 3. Eat other Etherfish to Upgrade NFTs
                    <br/> 4. Breed to Create new NFTs
                    <br/> 5. Set Your Price and let Players Breed with your Etherfish
                    <br/> 6. Set Your Price and let Players Fish from your Tank!
                </Typography>
            </div>
        )
    }

    renderTheTeam() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">The Team</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Grid container className="about-page-summary-list-grid" spacing={2}>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <img className="about-page-team-image" src={willImage} alt={logo}/>
                            </Grid>
                            <Grid className="about-page-team-text" item xs={12} md={8}>
                                <Typography variant="h5" className="about-page-team-text-title">
                                    Will Suchy -&nbsp;<Typography className="about-page-team-text-title-role">Creator/Product Manager/Artist</Typography>
                                </Typography>
                                <Typography>
                                    Crypto Enthusiast, Fisherman, and growing NFT Collector with a passion to create! I also share an equal passion for video games and see such a bright future for NFTs in the video game world. The level of utility behind games we will see in the next decade and beyond will be incredible and I want to be a part of it! This is my first project ever and I can’t wait to see where it goes. I have very much enjoyed the journey because the NFT community is fantastic! NFTs bring together the next generation of talent by helping each other succeed and grow to create beautiful things. Many projects, this one included, are also helping give back and save the Planet!! Thank you all very much for helping us succeed in developing this project, I hope you enjoy what Etherfishing has to offer.                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} md={4}>
                        <img className="about-page-team-image" src={jackImage} alt={logo}/>
                        <Typography variant="h5" className="about-page-team-text-title-devs">Jack Melvin</Typography>
                        <Typography className="about-page-team-text-title-role">Lead Software Developer</Typography>
                        <Typography>
                            You can program anything, its just a matter of how. I love making games and working with my 3D printer. I think NFT gaming is going to be the way of the future!
                        </Typography>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item xs={12} md={4}>
                        <img className="about-page-team-image" src={collinImage} alt={logo}/>
                        <Typography variant="h5" className="about-page-team-text-title-devs">Collin Wernsman</Typography>
                        <Typography className="about-page-team-text-title-role">Software Developer</Typography>
                        <Typography>
                            Recent college graduate from Colorado State University with a Bachelor's in Computer Science. I'm passionate about web design and have professional experience working in the industry. I’m excited to be working on this amazing product with such a great team.
                        </Typography>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
            </div>
        )
    }

    renderWorkWithUs() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Work With Us</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Typography variant="h5">
                    Want to work with us? Want to promote yourself or your business? Want to do some collaboration with Etherfishing?
                    {<div><br/></div>}
                    We have many different options available and are very interested and excited to work with other projects!
                </Typography>
            </div>
        )
    }

    renderContributors() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Contributors</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Grid container className="about-page-contributors-grid" spacing={2}>
                    <Grid item>
                        <Typography variant="h5"><b>Animation Artist</b></Typography>
                        <img className="about-page-animation-artist-image" src={ethanImage} alt={logo}/>
                        <Typography>Ethan Bryant</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>Music Artist</b></Typography>
                        <img className="about-page-animation-artist-image" src={gabrieleImage} alt={logo}/>
                        <Typography>Gabriele "Sleeptalker" Toffanin</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>Software Developer</b></Typography>
                        <img className="about-page-animation-artist-image" src={triImage} alt={logo}/>
                        <Typography>Tri Nguyen</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderCollaborations() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Collaborations</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Grid container className="about-page-contributors-grid" spacing={2}>
                <Grid item>
                        <Typography variant="h5"><b>Roman Punks</b></Typography>
                        <img className="about-page-animation-artist-image" src={romanPunksImage} alt={logo}/>
                        <Typography><a className="help-page-link" href={"https://romanpunks.io/"} target="_">Website</a></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>CryptoClocky</b></Typography>
                        <img className="about-page-animation-artist-image" src={cryptoClockyImage} alt={logo}/>
                        <Typography><a className="help-page-link" href={"https://www.cryptoclocky.com/"} target="_">Website</a></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>Mintopoly</b></Typography>
                        <img className="about-page-animation-artist-image" src={mintopolyImage} alt={logo}/>
                        <Typography><a className="help-page-link" href={"https://www.mintopoly.io/"} target="_">Website</a></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>LSD Gummy Bear</b></Typography>
                        <img className="about-page-animation-artist-image" src={LSDxGummyBearImage} alt={logo}/>
                        <Typography><a className="help-page-link" href={"https://knownorigin.io/lsd-gummy-bears"} target="_">Website</a></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><b>Coin Hunt World</b></Typography>
                        <img className="about-page-animation-artist-image" src={CoinHuntWorldImage} alt={logo}/>
                        <Typography><a className="help-page-link" href={"https://coinhunt.world/"} target="_">Website</a></Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitle onClose={this.handleCloseFirstTimePopup}>WANT TO WORK WITH US?</DialogTitle>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        Etherfishing is open to any Collaboration between NFT projects!<br />
                        -Sponsor a Top Catch/Private Pool<br />
                        -Exclusive NFT sales<br />
                        -GET RECOGNITION!<br />
                        <div><br /></div>
                        Contact us if you want to advertise on our website or promote for us!
                        <Footer/>
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }

}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    icon: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconActive: {
        color: "#6690cb",
    },
    iconCompleted: {
        color: "#3465b0",
    },
    imageIcon: {
        height: '100%',
        width: '100%',
    },
    iconRoot: {
        textAlign: 'center'
    }
});

const mapStateToProps = (state) => {
    return {
        showFirstTimePopup: state.firestore.aboutFirstTimePopup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('about', newVal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(About))