import { Component } from "react";
import { connect } from "react-redux";
import { getUserInfo } from "../../store/actions/firestoreActions";
import { getUserOpenSeaFish } from "../../store/actions/openseaActions";
import { getEnjinTokens } from "../../store/actions/enjinActions";

class UserInfoWatcher extends Component {
    constructor(props) {
        super(props)

        this.state = {
            watchingCurrentUser: false,
            unsubscribe: null,
        }
    }

    async componentDidMount() {
        if (this.props.user?.uid) {
            const unsubscribe = await this.props.getUserInfo()
            this.setState({unsubscribe, watchingCurrentUser: true})
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.user?.uid !== this.props.user?.uid) {
            if (!this.props.user?.uid) {
                // user logged out
                if (this.state.unsubscribe) {
                    this.state.unsubscribe()
                }
                this.setState({watchingCurrentUser: false, unsubscribe: null})
            } else if (!this.state.watchingCurrentUser) {
                const unsubscribe = await this.props.getUserInfo()
                this.setState({watchingCurrentUser: true, unsubscribe})
            }
        }
        if (prevProps.userInfo?.openSeaWalletAddress !== this.props.userInfo?.openSeaWalletAddress) {
            // grab user opensea fish if changes to wallet address (or reload)
            this.props.getUserOpenSeaFish()
        }
        if (prevProps.userInfo?.enjinWalletAddress !== this.props.userInfo?.enjinWalletAddress && (this.props.userInfo?.mintPastTokens ?? false) === true) {
            //this.props.getEnjinTokens() DO NOT UNCOMMENT UNLESS TOKENS ARE DONE
        }
    }

    render() {
        return false
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(getUserInfo()),
        getUserOpenSeaFish: () => dispatch(getUserOpenSeaFish()),
        getEnjinTokens: () => dispatch(getEnjinTokens()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoWatcher)