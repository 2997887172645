import { Avatar, Button, CircularProgress, Fade, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadProfilePhoto } from '../../../store/actions/storageActions';
import logo from '../../../static/images/logo.png';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './ProfilePic.css'

class ProfilePic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileAvatarHover: false,
            profileAvatarModalOpen: false,
            profileNewAvatarImage: null,
            profileNewAvatarImageUrl: null,
            profileSavingNewAvatar: false,
            profileAvatarUploadError: null,
        }

        this.handleSaveNewAvatar = this.handleSaveNewAvatar.bind(this)
        this.handleAvatarModalClose = this.handleAvatarModalClose.bind(this)
        this.handleUploadImage = this.handleUploadImage.bind(this)
    }

    handleSaveNewAvatar() {
        // Send call to upload image to firebase
        
        this.setState({profileSavingNewAvatar: true}, async () => {
            await this.props.uploadProfilePhoto({uid: this.props.user.uid, image: this.state.profileNewAvatarImage})

            if (this.props.storage.storageError) {
                this.setState({profileAvatarUploadError: this.props.storage.storageError, profileSavingNewAvatar: false})
            }
            else {
                this.setState({
                    profileAvatarModalOpen: false, 
                    profileNewAvatarImage: null, 
                    profileNewAvatarImageUrl: null,
                    profileSavingNewAvatar: false
                })
            }
        })
    }

    handleAvatarModalClose() {
        this.setState({profileAvatarModalOpen: false, profileNewAvatarImage: null, profileNewAvatarImageUrl: null, profileAvatarUploadError: null})
    }

    handleUploadImage(event) {
        if (event.target.files[0].size < 1048576) { // Less than 1 MB
            this.setState({profileNewAvatarImage: event.target.files[0], profileNewAvatarImageUrl: URL.createObjectURL(event.target.files[0]), profileAvatarUploadError: null})
        } else {
            this.setState({profileAvatarUploadError: 'File size too large, must be less than 1MB', profileNewAvatarImage: null, profileNewAvatarImageUrl: null})
        }
    }

    render() {
        return (
            <div>
                <Button
                    className="profile-avatar-button" 
                    onClick={() => this.setState({profileAvatarModalOpen: true})}
                    onMouseEnter={() => this.setState({profileAvatarHover: true})}
                    onMouseLeave={() => this.setState({profileAvatarHover: false})}
                >
                    <Avatar 
                        className="profile-avatar"
                        src={this.props.userInfo?.photoUrl ?? logo}
                    />
                    <Fade in={this.state.profileAvatarHover}>
                        <EditIcon className="profile-avatar-edit"/>
                    </Fade>
                </Button>
                {this.renderAvatarModal()}
            </div>
        )
    }

    renderAvatarModal() {
        return (
            <Dialog
                open={this.state.profileAvatarModalOpen}
                onClose={this.handleAvatarModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Edit profile picture</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-avatar-modal-content">
                    {this.renderAvatarUploadError()}
                    <Avatar 
                        className="profile-avatar" 
                        src={this.state.profileNewAvatarImageUrl ??
                            this.props.userInfo?.photoUrl ?? 
                            logo
                        }
                    />
                    <input
                        accept="image/jpeg"
                        id="contained-button-file"
                        type="file"
                        style={{display: 'none'}}
                        onChange={this.handleUploadImage}
                    />
                    <label htmlFor="contained-button-file">
                        <Button 
                            className="profile-avatar-modal-content-upload" 
                            variant="contained" 
                            color="primary" 
                            component="span"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload
                        </Button>
                    </label>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={this.handleAvatarModalClose}
                    >
                        Cancel
                    </Button>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={this.handleSaveNewAvatar}
                        disabled={!this.state.profileNewAvatarImageUrl}
                    >
                        {this.state.profileSavingNewAvatar ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderAvatarUploadError() {
        return (
            <div className='profile-avatar-upload-error'>
                {this.state.profileAvatarUploadError ? 
                <Alert severity='error'>
                    <AlertTitle>Upload error</AlertTitle>
                    {this.state.profileAvatarUploadError }
                </Alert> :
                null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        storage: state.storage,
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProfilePhoto: (info) => dispatch(uploadProfilePhoto(info)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePic)