import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { isLoaded, ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { Provider, useSelector } from 'react-redux';
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import rootReducer from './store/reducers/rootReducer';
import './index.css';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore"
import "firebase/functions"

const fbConfig = {
  apiKey: "AIzaSyC4Nb2QJ0HeDImOApWIzE2VEzSCu7UO8Sk",
  authDomain: "etherfishing-4215.firebaseapp.com",
  databaseURL: "https://etherfishing-4215-default-rtdb.firebaseio.com",
  projectId: "etherfishing-4215",
  storageBucket: "etherfishing-4215.appspot.com",
  messagingSenderId: "686258268468",
  appId: "1:686258268468:web:9721ecba4225c2f6129d61",
  measurementId: "G-8F28BG566F"
};

// Initialize Firebase
firebase.initializeApp(fbConfig);
firebase.analytics();
firebase.firestore();
firebase.functions();

const useEmulator = true

// Use emulators if in development mode
if (useEmulator && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.storage().useEmulator("localhost", 9199)
  firebase.firestore().useEmulator("localhost", 8080)
  firebase.functions().useEmulator("localhost", 5001)
}

// Use some of the emulators if in development mode
//coment out the ones you dont want


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))));

const rrfProps = {
  firebase,
  config: fbConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div/>;
  return children
}

ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <Scrollbars style={{width: '100%', height: '100vh'}}>
            <App />
          </Scrollbars>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>,
  document.getElementById('root')
);
