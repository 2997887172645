export const uploadProfilePhoto = (info) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()

        await firebase.storage().ref('users/' + info.uid + '/profile.jpg').put(info.image)
        .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL()
            .then(async (downloadUrl) => {
                await firestore.collection('users').doc(firebase.auth().currentUser.uid).set({photoUrl: downloadUrl}, {merge: true})
                .then(() => {
                    dispatch({type: 'PROFILE_PHOTO_UPLOAD_SUCCESS', downloadUrl})
                })
                .catch((error) => {
                    dispatch({type: 'PROFILE_PHOTO_UPLOAD_ERROR', error})
                })
            })
            .catch((error) => {
                dispatch({type: 'PROFILE_PHOTO_UPLOAD_ERROR', error})
            })
        })
        .catch((error) => {
            dispatch({type: 'PROFILE_PHOTO_UPLOAD_ERROR', error})
        })
    }
}