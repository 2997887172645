import React, { Component } from "react";
import { connect } from 'react-redux';
import { updateUserInfo , changeUserMusicSetting } from '../store/actions/firestoreActions';
import songMain from "../static/music/Etherfishing_Lounge_Theme.wav";
import songGame from "../static/music/Etherfishing_Adventure_Theme.wav";

class AudioPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: this.props.userPlayMusic,  
            curSong: 'Lounge',
        };
        this.Main = new Audio(songMain);
        this.Game = new Audio(songGame);
        this.Main.loop = true;
        this.Main.volume = 0.6
        this.Game.volume = 0.6
        if(this.state.isPlaying)
          this.Main.play();

        this.playPause = this.playPause.bind(this)
        this.swapSong = this.swapSong.bind(this)
        this.adjustVolume = this.adjustVolume.bind(this)
    }

    componentDidMount() {
      const isPlaying = this.props.userPlayMusic
      if(this.state.isPlaying !== isPlaying) {
        this.setState({ isPlaying });
      }
    }

    componentDidUpdate(previousProps) {
      if(this.props.songName !== previousProps.songName) {
        this.swapSong(this.props.songName)
      }
      if(this.props.volume !== previousProps.volume) {
        this.adjustVolume(this.props.volume)
      }
    }

    playPause() {
      let isPlaying = this.state.isPlaying;

      if (isPlaying) {
        if(this.state.curSong.includes('Lounge')) {
          this.Main.pause();
        } else if (this.state.curSong.includes('Adventure')) {
          this.Game.pause();
        }
      } else {
        if(this.state.curSong.includes('Lounge')) {
          this.Main.play();
        } else if (this.state.curSong.includes('Adventure')) {
          this.Game.play();
        }
      }
      this.setState({ isPlaying: !isPlaying });
      this.props.changeUserMusicSetting(!isPlaying)

    };

    swapSong(songName) {
      const isPlaying = this.state.isPlaying
      if (songName === 'Adventure' && isPlaying) {
        this.Main.pause();
        this.Game.play();
        this.setState({curSong: songName})
      } else if (songName === 'Lounge' && isPlaying) {
        this.Game.pause();
        this.Game.currentTime = 0;
        this.Main.play();
        this.setState({curSong: songName})
      }  else if (songName === 'Pause' || songName === 'Play') {
        this.playPause(songName);
      }
    };

    adjustVolume(volume) {
      this.Main.volume = volume / 100
      this.Game.volume = volume / 100
    };

    render() {
      return (
          <div>
          </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    songName: state.audio.songName,
    userInfo: state.firestore.userInfo,
    userPlayMusic: state.firestore.userPlayMusic,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      updateUserInfo: (info) => dispatch(updateUserInfo(info)),
      changeUserMusicSetting: (playMusic) => dispatch(changeUserMusicSetting(playMusic)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);