import snackbarReducer from './snackbarReducer';
import authReducer from './authReducer';
import storageReducer from './storageReducer';
import firestoreReducer from './firestoreReducer';
import openseaReducer from './openseaReducer';
import gameReducer from './gameReducer';
import audioReducer from './audioReducer';
import ethereumReducer from './ethereumReducer';
import speciesAndTraitsReducer from './speciesAndTraitsReducer';
import fishTankReducer from './fishTankReducer';
import marketReducer from './marketReducer';
import wofReducer from './wofReducer';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    storage: storageReducer,
    snackbar: snackbarReducer,
    opensea: openseaReducer,
    game: gameReducer,
    audio: audioReducer,
    ethereum: ethereumReducer,
    speciesAndTraits: speciesAndTraitsReducer,
    market: marketReducer,
    fishTank: fishTankReducer,
    wof: wofReducer,
})

export default rootReducer