export const setGameStarted = (gameStarted) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_GAME_STARTED', gameStarted })
    }
}

export const setHasCast = (hasCast) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_HAS_CAST', hasCast })
    }
}

export const setHasHooked = (hasHooked) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_HAS_HOOKED', hasHooked })
    }
}

export const setFailedToCatch = (failedToCatch) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_FAILED_TO_CATCH', failedToCatch })
    }
}

export const setTapCount = (tapCount) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_TAP_COUNT', tapCount })
    }
}

export const setTime = (time) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_TIME', time })
    }
}

export const setCurrentPole = (pole) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        // await window.localStorage.setItem('currentPole', pole)
        dispatch({ type: 'SET_CURRENT_POLE', pole})
    }
}

export const setCurrentBoat = (boat) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'SET_CURRENT_BOAT', boat })
    }
}

export const getLocations = (boat) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const storage = firebase.storage()
        let locations = []
        const locationsRef = await firestore.collection('locations').get()
        locationsRef.forEach(async (doc) => {
            const data = doc.data()
            if(data?.active === true || doc.id === 'season') {
                let location = {name:data.name, pool:doc.id, boats:data?.boats ?? []}

                const time = new Date()
                const hour = time.getHours()
                let background = data?.background?.day
                if(hour >= 6 && hour < 8 && data?.background?.dawn) {
                    background = data?.background?.dawn
                } else if(hour >= 17 && hour < 19 && data?.background?.dusk) {
                    background = data?.background?.dusk
                } else if((hour >= 19 || hour < 6) && data?.background?.night) {
                    background = data?.background?.night
                }
                if(background){
                    await storage.ref(`gameImages/${location.pool}/${background}`).getDownloadURL()
                    .then((url) => {
                        if (url) {
                            location.imageUrl = url
                        }
                    })
                }
                locations.push(location)
            }
        })


        dispatch({ type: 'SET_LOCATIONS', locations })
    }
}

export const initLocation = (pool) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const storage = firebase.storage()

        let data = (await firestore.collection('locations').doc(pool).get()).data()

        let imageUrl //TODO set default
        if(data){
            const time = new Date()
            const hour = time.getHours()
            let background = data.background.day
            if(hour >= 6 && hour < 8) {
                background = data.background.dawn
            } else if(hour >= 17 && hour < 19 ) {
                background = data.background.dusk
            } else if(hour >= 19 || hour < 6 ) {
                background = data.background.night
            }
            await storage.ref(`gameImages/${pool}/${background}`).getDownloadURL()
            .then(async(url) => {
                if (url) {
                    imageUrl = url
                }
            })
        } else {
            await storage.ref(`gameImages/season/Season8Day.png`).getDownloadURL()
            .then(async(url) => {
                if (url) {
                    imageUrl = url
                }
            })
        }

        let locationInfo = {imageUrl, pool, boats:data?.boats, info: data?.info, infoTitle:data?.infoTitle ?? data.name}
        dispatch({type: 'SET_LOCATION_INFO', locationInfo})
    }
}

export const failedToCatch = (baitType) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        var failedToCatchFunction = await firebase.functions().httpsCallable('failedToCatch')
        await failedToCatchFunction({ uid: firebase.auth().currentUser.uid, baitType })
    }
}

export const getTotalFishRemaining = (pool) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        if(!pool) pool = 'season'
        // Change pool doc to update with season
        const currentSeason = pool === 'season' ? 
            (await firestore.collection('fishPools').doc('seasonData').get()).data()?.currentSeason
            : pool
        const unsubscribe = await firestore.collection('fishPools').doc(currentSeason).onSnapshot(async (info) => {
            let resultData = info.data() ?? {}
            let total = (resultData.golden ?? 0) +
                (resultData.legendary ?? 0) +
                (resultData.trait5 ?? 0) +
                (resultData.trait4 ?? 0) +
                (resultData.trait3 ?? 0) +
                (resultData.trait2 ?? 0) +
                (resultData.trait1 ?? 0)

            let allData = {
                total,
                golden: resultData.golden ?? 0,
                legendary: resultData.legendary ?? 0,
                trait5: resultData.trait5 ?? 0,
                trait4: resultData.trait4 ?? 0,
                trait3: resultData.trait3 ?? 0,
                trait2: resultData.trait2 ?? 0,
                trait1: resultData.trait1 ?? 0,
            }
            dispatch({ type: 'GET_FISH_REMAINING_SUCCESS', totalFishRemaining: allData })
        })

        return unsubscribe
    }
}

export const getReward = (baitType, perfect, timeBonus, pool) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        const state = getState()

        if (state?.game?.gameReward?.imageUrl) {
            window.URL.revokeObjectURL(state?.game?.gameReward?.imageUrl)
        }

        await firestore.collection('users').doc(firebase.auth().currentUser.uid).set({ currentPole: state.game.userCurrentPole }, {merge: true})
        .then(async () => {
            // Successfully set new pole
            var rewardFunction = await firebase.functions().httpsCallable('reward');
            await rewardFunction({ uid: firebase.auth().currentUser.uid, baitType, perfect, timeBonus, pool })
                .then(async (result) => {
                    let resultData = result.data
                    const image = await fetch(resultData.downloadUrl)
                    const imageBlob = await image.blob()
                    resultData.imageUrl = window.URL.createObjectURL(imageBlob)
    
                    dispatch({ type: 'REWARD_SUCCESS', reward: resultData })
    
                })
                .catch((error) => {
                    //bug #2 set poles back if failed to fetch
                    dispatch({ type: 'REWARD_ERROR', error})
                })
        })
        .catch((error) => {
            dispatch({ type: 'REWARD_ERROR', error })
        })

    }
}

export const enterPromoCode = (code) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()
    
        var promoFunction = await firebase.functions().httpsCallable('usePromoCode');
        await promoFunction({ uid: firebase.auth().currentUser.uid, code })
        .then((result) => {
            let resultData = result.data
    
            dispatch({ type: 'PROMO_SUCCESS', reward: resultData.reward})
        })
        .catch((error) => {
            dispatch({ type: 'PROMO_ERROR', error})
        })
    }
}

export const setDisplayReward = (displayReward, gameData) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_DISPLAY_REWARD', displayReward, gameData})
    }
}