import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Divider, IconButton, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProfileEtherbait.css';
import '../Profile.css';
import bait1Pic from '../../../static/images/Bait/bait1.png';
import bait2Pic from '../../../static/images/Bait/bait2.png';
import bait3Pic from '../../../static/images/Bait/bait3.png';
import bait4Pic from '../../../static/images/Bait/bait4.png';
import bait5Pic from '../../../static/images/Bait/bait5.png';
import baitSPic from '../../../static/images/Bait/baitS.png';
import baitLPic from '../../../static/images/Bait/baitL.png';
import baitHPic from '../../../static/images/Bait/baitH.png';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { upgradeBait } from '../../../store/actions/firestoreActions';

class ProfileEtherbait extends Component {

    constructor(props) {
        super(props);

        this.state = {
            etherbaitModalOpen: false,
            etherbaitConfirmModalOpen: false,
            etherbaitDoneModalOpen:false,
            numUpgrading: 0,
            selectedOption: "bait2"
        }

        this.handleSelect = this.handleSelect.bind(this)
        
    }

    handleSelect(changeEvent) {
        this.setState({selectedOption:changeEvent.target.value})
    }
    handleAddClicked() {
        const value = this.state.numUpgrading ?? 0
        const total = this.state.selectedOption === "bait2" ? this.props.userInfo?.bait?.star1?.count ?? 0 : 
            this.state.selectedOption === "bait3" ? this.props.userInfo?.bait?.star2?.count ?? 0 : 
            this.state.selectedOption === "bait4" ? this.props.userInfo?.bait?.star3?.count ?? 0 : 
            this.state.selectedOption === "bait5" ? this.props.userInfo?.bait?.star4?.count ?? 0 : 
            this.state.selectedOption === "baitS" ? this.props.userInfo?.bait?.star5?.count ?? 0 : 
            this.state.selectedOption === "cast" ? this.props.userInfo?.bait?.super?.count ?? 0 : 0
        const maxUpgrades = total/3
        if(value <= maxUpgrades - 1) {
            this.setState({numUpgrading: value + 1})
        }
    }

    handleRemoveClicked() {
        const value = this.state.numUpgrading ?? 0
        if (value > 0) {
            this.setState({numUpgrading: value - 1})
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h4">Etherbait&nbsp;</Typography>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={() => this.setState({etherbaitModalOpen: true})}
                    >
                        Upgrade
                    </Button>
                </div>
                <Divider className="profile-divider"/>
                {this.renderEtherbaitList()}
                {this.renderEtherbaitModal()}
                {this.renderEtherbaitConfirmModal()}
                {this.renderEtherbaitDoneModal()}
            </div>
        )
    }

    renderEtherbaitList() {
        return (
            <div className="profile-etherbait-list">
                <Grid container className="collectible-grid-center">
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <Grid container className="collectible-grid-item">
                            <img src={bait1Pic} alt={''}/>
                            <Typography variant="h6" style={{height: '100%'}}>
                                1★: {this.props.userInfo?.bait?.star1?.count ?? 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <Grid container className="collectible-grid-item">
                            <img src={bait2Pic} alt={''}/>
                            <Typography variant="h6" style={{height: '100%'}}>
                                2★: {this.props.userInfo?.bait?.star2?.count ?? 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <img src={bait3Pic} alt={''}/>
                        <Typography variant="h6" style={{height: '100%'}}>
                            3★: {this.props.userInfo?.bait?.star3?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={6} md={3}>
                        <img src={bait4Pic} alt={''}/>
                        <Typography variant="h6" style={{height: '100%'}}>
                            4★: {this.props.userInfo?.bait?.star4?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={6} md={3}>
                        <img src={bait5Pic} alt={''}/>
                        <Typography variant="h6" style={{height: '100%'}}>
                            5★: {this.props.userInfo?.bait?.star5?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <img src={baitSPic} alt={''}/>
                        <Typography variant="h6">
                            Super: {this.props.userInfo?.bait?.super?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <img src={baitLPic} alt={''}/>
                        <Typography variant="h6">
                            Legendary: {this.props.userInfo?.bait?.legendary?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={6} sm={4} md={3}>
                        <img src={baitHPic} alt={''}/>
                        <Typography variant="h6">
                            Hybrid: {this.props.userInfo?.bait?.hybrid?.count ?? 0}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderEtherbaitModal() {
        return (
            <Dialog
                open={this.state.etherbaitModalOpen}
                onClose={() => this.setState({etherbaitModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Upgrade your etherbait</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                    {/*<Alert severity='error'>
                        <AlertTitle>No etherbait available at this time</AlertTitle>
                    </Alert>*/}
                   < Typography variant="h6">Upgrade Options:</Typography>
                    <Grid container className="collectible-grid-center">
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6" style={{height: '100%'}}>
                                3 x 1★ Bait ➤ 2★ Bait <input type="radio" value="bait2" checked={this.state.selectedOption === "bait2"} onChange={this.handleSelect}/>
                            </Typography>
                            
                        </Grid>
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6" style={{height: '100%'}}>
                                3 x 2★ Bait ➤ 3★ Bait <input type="radio" value="bait3" checked={this.state.selectedOption === "bait3"} onChange={this.handleSelect}/>
                            </Typography>
                        </Grid>
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6" style={{height: '100%'}}>
                                3 x 3★ Bait ➤ 4★ Bait <input type="radio" value="bait4" checked={this.state.selectedOption === "bait4"} onChange={this.handleSelect}/>
                            </Typography>
                        </Grid>
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6" style={{height: '100%'}}>
                                3 x 4★ Bait ➤ 5★ Bait <input type="radio" value="bait5" checked={this.state.selectedOption === "bait5"} onChange={this.handleSelect}/>
                            </Typography>
                        </Grid>
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6" style={{height: '100%'}}>
                                3 x 5★ Bait ➤ Super Bait <input type="radio" value="baitS" checked={this.state.selectedOption === "baitS"} onChange={this.handleSelect}/>
                            </Typography>
                        </Grid>
                        <Grid item className="collectible-grid-item" xs={12} md={6}>
                            <Typography variant="h6">
                                3 x Super Bait ➤ 1 Cast <input type="radio" value="cast" checked={this.state.selectedOption === "cast"} onChange={this.handleSelect}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({etherbaitModalOpen: false})}
                    >
                        Close
                    </Button>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({etherbaitModalOpen: false, etherbaitConfirmModalOpen:true})}
                    >
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEtherbaitDoneModal() {
        return (
            <Dialog
                open={this.state.etherbaitDoneModalOpen}
                onClose={() => this.setState({etherbaitDoneModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Upgrade Confirmed</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                   < Typography variant="h6">Your upgrade is being processed</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({etherbaitDoneModalOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEtherbaitConfirmModal() {
        return (
            <Dialog
                open={this.state.etherbaitConfirmModalOpen}
                onClose={() => this.setState({etherbaitConfirmModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm your upgrade</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                    <Grid className="collectible-grid-item">
                        <Typography>Remaining {
                        this.state.selectedOption === "bait2" ? " 1★ Bait = " + ((this.props.userInfo?.bait?.star1?.count ?? 0) - (3 * this.state.numUpgrading)) : 
                        this.state.selectedOption === "bait3" ? " 2★ Bait = " + ((this.props.userInfo?.bait?.star2?.count ?? 0) - (3 * this.state.numUpgrading)) : 
                        this.state.selectedOption === "bait4" ? " 3★ Bait = " + ((this.props.userInfo?.bait?.star3?.count ?? 0) - (3 * this.state.numUpgrading)) : 
                        this.state.selectedOption === "bait5" ? " 4★ Bait = " + ((this.props.userInfo?.bait?.star4?.count ?? 0) - (3 * this.state.numUpgrading)) : 
                        this.state.selectedOption === "baitS" ? " 5★ Bait = " + ((this.props.userInfo?.bait?.star5?.count ?? 0) - (3 * this.state.numUpgrading)) : 
                        this.state.selectedOption === "cast" ? " Super Bait = " + ((this.props.userInfo?.bait?.super?.count ?? 0) - (3 * this.state.numUpgrading)) : 0
                        } 
                        </Typography>
                        <Typography> Number of upgrades
                            <IconButton color='primary' onClick={() => this.handleRemoveClicked()}><RemoveIcon size="small"/></IconButton>
                            <IconButton className="profile-etherbait-buy-list-item-count" size="small" disabled>{this.state.numUpgrading ?? 0}</IconButton>
                            <IconButton color='primary' onClick={() => this.handleAddClicked()}><AddIcon size="small"/></IconButton>
                        </Typography>
                        <Typography>Adding {this.state.numUpgrading} {
                            this.state.selectedOption === "bait2" ? " 2★ Bait": 
                            this.state.selectedOption === "bait3" ? " 3★ Bait": 
                            this.state.selectedOption === "bait4" ? " 4★ Bait": 
                            this.state.selectedOption === "bait5" ? " 5★ Bait": 
                            this.state.selectedOption === "baitS" ? " Super Bait": 
                            this.state.selectedOption === "cast" ? " Casts" : "Error"
                            } 
                        </Typography>
                        <Typography>Total of{
                            this.state.selectedOption === "bait2" ? " 2★ Bait = " + ((this.props.userInfo?.bait?.star2?.count ?? 0) + this.state.numUpgrading) : 
                            this.state.selectedOption === "bait3" ? " 3★ Bait = " + ((this.props.userInfo?.bait?.star3?.count ?? 0) + this.state.numUpgrading) : 
                            this.state.selectedOption === "bait4" ? " 4★ Bait = " + ((this.props.userInfo?.bait?.star4?.count ?? 0) + this.state.numUpgrading) : 
                            this.state.selectedOption === "bait5" ? " 5★ Bait = " + ((this.props.userInfo?.bait?.star5?.count ?? 0) + this.state.numUpgrading) : 
                            this.state.selectedOption === "baitS" ? " Super Bait = " + ((this.props.userInfo?.bait?.super?.count ?? 0) + this.state.numUpgrading) : 
                            this.state.selectedOption === "cast" ? " Casts = " + ((this.props.userInfo?.casts ?? 0) + this.state.numUpgrading) : 0
                            } 
                        </Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({etherbaitConfirmModalOpen: false})}
                    >
                        Close
                    </Button>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => {
                            this.setState({etherbaitConfirmModalOpen: false, etherbaitDoneModalOpen:true, numUpgrading:0})
                            this.props.upgradeBait(this.state.selectedOption, this.state.numUpgrading)
                        }}
                        disabled={this.state.numUpgrading === 0}
                    >
                        Upgrade
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        upgradeBait: (type, amount) => dispatch(upgradeBait(type, amount)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEtherbait)