import { Tab, Tabs, AppBar, CircularProgress, Grid, Grow, Paper, Divider, Typography, Switch, withStyles, DialogContent, DialogActions, Dialog, Button, Icon, Step, StepLabel, Stepper } from '@material-ui/core';
import TabPanel from "../../components/tabs/TabPanel";
import DoneIcon from '@material-ui/icons/Done';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfilePic from './Picture/ProfilePic';
import ProfileBio from './Bio/ProfileBio';
import ProfileOptions from './Options/ProfileOptions';
import ProfileEnjinWallet from './EnjinWallet/ProfileEnjinWallet';
import ProfileOpenSeaWallet from './OpenSeaWallet/ProfileOpenSeaWallet';
import ProfileEthereumWallet from './EthereumWallet/ProfileEthereumWallet';
import ProfileEtherbait from './Etherbait/ProfileEtherbait';
import ProfileCollectibles from './Collectibles/ProfileCollectibles';
import ProfileReferrals from './Referrals/ProfileReferrals';
import ProfileTrophies from './Trophies/ProfileTrophies'
import PromoCode from './PromoCode/PromoCode';
import CastLog from '../../components/CastLog/CastLog';
import './Profile.css'
import { getCurrentUser } from '../../store/actions/authActions';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { changeUserTheme, updateFirstTimePopup } from '../../store/actions/firestoreActions';
import Footer from '../../components/Footer/Footer';
import DialogTitle from '../../components/DialogTitle';
import { mintAirDrop, mintPastTokens } from '../../store/actions/enjinActions';

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disableAirdrop:true,
            displayDailyLogin:false,
            tabValue:0
        }
        
        this.handleChangeTheme = this.handleChangeTheme.bind(this)
        this.handleCloseFirstTimePopup = this.handleCloseFirstTimePopup.bind(this)
        this.handleCloseDailyLogin = this.handleCloseDailyLogin.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)

    }

    componentDidMount() {
        // Validate returning signed in user
        if (!this.props.user?.uid) {
            // Redirect to login page if not logged in
            this.props.history.push('/user/login');
        }
        else {
            this.props.getCurrentUser()
        }
        let attempts = 0
        while((this.props?.userInfo?.claimedAirDrop === false)  && attempts < 5) {
            setTimeout(
                function() {
                    if(this.props?.userInfo?.claimedAirDrop === false) {
                        this.setState({disableAirdrop: false})
                    }
                }.bind(this),2000
            );
            attempts += 1
        }
    }

    componentDidUpdate() {
        if(this.props?.userInfo?.claimedAirDrop === false && this.state.disableAirdrop === true) {
            this.setState({disableAirdrop: false})
        }
    }

    getUserCreatedDate() {
        const date = new Date(Date.parse(this.props.currentUser?.metadata?.creationTime))
        return date.toLocaleDateString('en-US')
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    handleCloseDailyLogin() {
        this.setState({displayDailyLogin: false})
    }

    handleChangeTheme() {
        this.props.changeUserTheme(this.props.userTheme === 'dark' ? 'light' : 'dark')
    }

    handleTabChange(event, newValue) {
        this.setState({ tabValue: newValue })
    }

    tabProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { classes } = this.props;
        let tabStyle = {
            minWidth: 50,
            paddingLeft: 4,
            paddingRight: 4,
          };

        return (
            <div className="profile-page">
                {this.renderFirstTimePopup()}
                {this.renderDailyLoginPopup()}
                 <Grow in={this.props.userInfo !== null} style={{transformOrigin: 'top'}}>
                    <Paper className="profile-paper" elevation={2}>
                        <Grid container spacing={4}>
                            <Grid item className="profile-grid-item" xs={12} md={5}>
                                <ProfilePic/>
                                <Typography variant="h4" className="profile-username-text">{this.props.user.displayName}</Typography>                                
                                {!this.props.userInfo?.claimedAirDrop ? 
                                    <div>
                                        <Button 
                                            color="primary" 
                                            variant="outlined" 
                                            onClick={() => {
                                                this.setState({disableAirdrop: true})
                                                this.props.mintAirDrop(8) 
                                            }}
                                            disabled={this.state.disableAirdrop}
                                        >
                                            Claim Season 8 Airdrop
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                                <Divider className="profile-divider"/>
                                <ProfileBio/>
                            </Grid>
                            <Grid item className="profile-grid-item" xs={12} md={7}>
                                <div className="profile-user-account-info">
                                    <Typography className="profile-user-account-total-fish">
                                        <u>Etherfish</u>: {this.props.userInfo?.fish?.length ?? 0}
                                    </Typography>
                                    <Typography className="profile-user-account-created">
                                        <u>Created</u>: {this.getUserCreatedDate()}
                                    </Typography>
                                    <Switch
                                        checked={this.props.userTheme === 'dark'}
                                        onChange={this.handleChangeTheme}
                                        color="primary"
                                        classes={{root: classes.switch}}
                                        disableRipple
                                        icon={<WbSunnyIcon fontSize="small"/>}
                                        checkedIcon={<Brightness3Icon fontSize="small"/>}
                                    />
                                    <ProfileOptions history={this.props.history}/>
                                </div>
                                <ProfileEnjinWallet/>
                                <ProfileOpenSeaWallet/>
                                <ProfileEthereumWallet/>
                                <PromoCode/>
                                <Button 
                                    color="primary" 
                                    variant="contained" 
                                    onClick={() => {
                                        this.setState({displayDailyLogin: true})
                                    }}
                                    className="profile-wof-button"
                                >
                                    Daily Login Rewards
                                </Button>
                                <Divider className="profile-divider"/>
                                <Grid container>
                                    <Grid item className="profile-casts-info" xs={12} md={7}>
                                        <Typography variant="h6">Casts Remaining: {this.props.userInfo?.casts ?? 0}</Typography>
                                    </Grid>
                                    <Grid item className="profile-casts-info" xs={12} md={5}>
                                        <Typography variant="h6">Total casts: {this.props.userInfo?.totalCasts ?? 0}</Typography>
                                    </Grid>
                                    <Grid item className="profile-casts-info" xs={12}>
                                        <br/>
                                        <CastLog/>
                                    </Grid>
                                </Grid>
                                <Divider className="profile-divider"/>
                                <Grid container className="profile-spins-container">
                                    <Grid item className="profile-casts-info" xs={12} md={7}>
                                        <Typography variant="h6">Spins Remaining: {this.props.userInfo?.spins ?? 0}</Typography>
                                    </Grid>
                                    <Grid item className="profile-casts-info" xs={12} md={5}>
                                        <Typography variant="h6">Total Spins: {this.props.userInfo?.totalSpins ?? 0}</Typography>
                                    </Grid>
                                    <Grid item className="profile-casts-info" xs={12}>
                                        <br/>
                                        <Button 
                                            color="primary"
                                            variant="contained"
                                            onClick={() => this.props.history.push('/wheel-o-fish')}
                                            className="profile-wof-button"
                                        >
                                            Spin The Wheel O' Fish
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider className="profile-divider"/>
                                <Typography variant="h6"> <u>Account Stats</u> </Typography>
                                <Grid container>
                                    <Grid item className="profile-stats-label" xs={6}>
                                        <Typography>Etherfish Catch Percentage: </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container className="profile-stats-var">
                                            <Grid item>
                                                <Typography className="red-text">25%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;+&nbsp;</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className='blue-text'>{Math.round(((this.props.userInfo?.playerEtherfishCatchPercentage ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;= {Math.round(250000 + ((this.props.userInfo?.playerEtherfishCatchPercentage ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item className="profile-stats-label" xs={6}>
                                        <Typography>Golden Catch Percentage: </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container className="profile-stats-var">
                                            <Grid item>
                                                <Typography className="red-text">0.037%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;+&nbsp;</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className='blue-text'>{Math.round(((this.props.userInfo?.playerGoldenCatchPercentage ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;= {Math.round(370 + ((this.props.userInfo?.playerGoldenCatchPercentage ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item className="profile-stats-label" xs={6}>
                                        <Typography>Etherbait Effectiveness: </Typography> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container className="profile-stats-var">
                                            <Grid item>
                                                <Typography className="red-text">100%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;+&nbsp;</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className='blue-text'>{Math.round(((this.props.userInfo?.playerBaitEffectiveness ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;= {Math.round(1000000 + ((this.props.userInfo?.playerBaitEffectiveness ?? 0) + Number.EPSILON) * 10000) / 10000}%</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item className="profile-stats-label" xs={5}>
                                        <Typography>Cast Reduction time: </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container className="profile-stats-var">
                                            <Grid item>
                                                <Typography className="red-text">15 min</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;-&nbsp;</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className='blue-text'>&#x28;15 min x {Math.round(((this.props.userInfo?.playerCastTimeReduction ?? 0) + Number.EPSILON) * 10000) / 10000}%&#x29;</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>&nbsp;= {(Math.round(((15-15*this.props.userInfo?.playerCastTimeReduction/100 ?? 0) + Number.EPSILON) * 10) / 10)} min</Typography><br/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container className="profile-stats-example">
                                        <Grid item>
                                            <Typography className="red-text">Base</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >&nbsp;+&nbsp;</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className='blue-text'>Account Bonus</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography >&nbsp;= Total</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item className="profile-grid-item" xs={12}>
                                <AppBar position="static" color="default" className="profile-appbar">
                                    {window.innerWidth > 400 ?
                                        <div>
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                        >
                                            <Tab label="Trophies" {...this.tabProps(0)} />
                                            <Tab label="Referrals" {...this.tabProps(1)} />
                                            <Tab label="Collectibles" {...this.tabProps(2)} />
                                            <Tab label="bait" {...this.tabProps(3)} />
                                        </Tabs>
                                    </div>    
                                    : 
                                    <div>
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            centered
                                        >
                                            <Tab label="Trophies" style={tabStyle} {...this.tabProps(0)} />
                                            <Tab label="Referrals" style={tabStyle} {...this.tabProps(1)} />
                                            <Tab label="Collectibles" style={tabStyle} {...this.tabProps(2)} />
                                            <Tab label="bait" style={tabStyle} {...this.tabProps(3)} />
                                        </Tabs>
                                    </div>   
                                    }
                                </AppBar> 
                                <Typography>
                                    <br/>
                                </Typography>
                                <TabPanel value={this.state.tabValue} index={0}>
                                    <ProfileTrophies/>
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={1}>
                                    <ProfileReferrals/>
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={2}>
                                    <ProfileCollectibles/>
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={3}>
                                    <ProfileEtherbait/>
                                </TabPanel>
                            </Grid>
                            <Grid item className="profile-grid-item" xs={12}>
                                <Divider className="profile-divider"/>
                                <Footer/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
                {this.props.userInfo === null ? 
                    <Paper className="profile-loading-user-info-paper">
                        <CircularProgress className="profile-loading-user-info" size='4rem'/> 
                    </Paper>
                : null
                }
            </div>
        );
    }

    renderCastLogModal() {
        return (
            <Dialog
                open={this.state.castLogModalOpen}
                onClose={() => this.setState({castLogModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cast Log</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                   <Typography variant="h6">Dispay Cast Log</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({castLogModalOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitle>THIS PAGE IS IMPORTANT!</DialogTitle>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        <b>What you need to PLAY for FREE:</b><br/>
                        1. Set up 
                        <b> <a className="home-page-link" href={'https://enjin.io/'} target="_">Enjin Wallet App</a> </b>
                        on Mobile<br/>
                        2. Verify your e-mail to use the FREE pole in the game menu<br/>
                        <br/>
                        Check out your account settings & roadmap of bonuses for your Collectibles and Referrals!<br/>
                        <br/>
                        <b>For APPLE Products:</b><br/>
                        Choose "Condensed Layout" in profile settings for a better experience!<br/>
                        <br/>
                        Enter PromoCodes (Case Sensitive) for FREE ITEMS!<br />

                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }
    
    renderDailyLoginPopup() {
        const numDays = this.props?.userInfo?.loginRecord?.numDays ?? 1
        const timeLeft = ((this.props?.userInfo?.loginRecord?.nextLoginDay - Date.now())/1000)/60
        const hr = Math.floor(timeLeft/60)
        const min = Math.floor(timeLeft%60)
        return (
            <Dialog open={this.state.displayDailyLogin} onClose={this.handleCloseDailyLogin}>
                <DialogTitle>Daily Login</DialogTitle>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        You've logged in {numDays} day{numDays> 1 ? 's' : ''} in a row. <br/>
                        Next login reward in {hr} hour{hr !== 1 ? 's' : ''} and {min} minute{min !== 1 ? 's' : ''}.<br/>
                        <div className="profile-referrals-container">
                            {this.renderLoginStepper()}
                        </div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={() => this.setState({displayDailyLogin: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderLoginStepper() {
        const numDays = this.props?.userInfo?.loginRecord?.numDays ?? 1
        const numWeeks = Math.floor((this.props?.userInfo?.loginRecord?.numDays?? 0)/7)

        const currentStep = numDays % 7

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal":"vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1 + numWeeks * 7)}>
                        +1 1★ bait
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 2 + numWeeks * 7)}>
                        +1 3★ bait
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 3 + numWeeks * 7)}>
                        +1 5★ bait
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 4 + numWeeks * 7)}>
                        +1 Super bait
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5 + numWeeks * 7)}>
                        +1 Legendary bait
                    </StepLabel>
                </Step>
                <Step key={6}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 6 + numWeeks * 7)}>
                        +1 Hybrid bait
                    </StepLabel>
                </Step>
                <Step key={7}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 7 + numWeeks * 7)}>
                        +1 Cast
                    </StepLabel>
                </Step>
                {/*<Step key={8}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 8 + numWeeks * 7)}>
                        +1 1★ bait
                    </StepLabel>
                </Step>*/}
            </Stepper>
        )
    }

    renderStepperIcon(props, num) {
        const { active, completed } = props;

        return (
            <Icon 
                fontSize='small'
                className="profile-referrals-stepper-icon"
                style={{ backgroundColor: completed ? '#3465b0' : active ? '#6690cb' : 'grey' }}
            >
                {completed ? <DoneIcon /> : <Typography>{num}</Typography>}
            </Icon>
        )
    }
}

const styles = theme => ({
    switch: {
        padding: '6px'
    }
});


const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        currentUser: state.auth.currentUser,
        userInfo: state.firestore.userInfo,
        userTheme: state.firestore.userTheme,
        showFirstTimePopup: state.firestore.profileFirstTimePopup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentUser: () => dispatch(getCurrentUser()),
        changeUserTheme: (theme) => dispatch(changeUserTheme(theme)),
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('profile', newVal)),
        mintPastTokens: () => dispatch(mintPastTokens()),
        mintAirDrop: (season) => dispatch(mintAirDrop(season)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))