import { AppBar, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, Grow, IconButton, Input, InputAdornment, List, ListSubheader, ListItem, ListItemText, Paper, Popover, Radio, RadioGroup, Tab, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import './SpeciesAndTraits.css';
import Footer from '../components/Footer/Footer';
import { connect } from 'react-redux';
import { getSpecies, getTraits } from '../store/actions/speciesAndTraitsActions';
import { Alert, AlertTitle, TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Bar, Pie } from 'react-chartjs-2';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SortIcon from '@material-ui/icons/Sort';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';


class SpeciesAndTraits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filterListClicked: false,
            speciesSearchInput: '',
            speciesTabValue: '1',
            speciesSortClicked: false,
            speciesFilterClicked: false,
            speciesSortOption: 'descending',
            anchorEl: null,
            selectedSpecies: null,
            
            traitTabValue: '1',
            traitsSearchInput: '',
            traitsSortClicked: false,
            traitsFilterClicked: false,
            traitsSortOption: 'descending',
            selectedTrait: null,
        }

        this.handleFilterList = this.handleFilterList.bind(this)
        this.handleChange = this.handleChange.bind(this)

        this.baseChartDatasetOptions = {
            label: 'Total',
            backgroundColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(255, 159, 64, 0.5)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        }
        this.barChartOptions = {
            indexAxis: 'y',
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                }
            },
            maintainAspectRatio: false,
            animations: false,
        }
        this.pieChartOptions = {
            plugins: {
                legend: {
                    display: false
                }
            },
            maintainAspectRatio: false,
            animations: false,
        }
    }

    componentDidMount() {
        this.props.getSpecies()
        this.props.getTraits()
    }

    handleFilterList() {
        this.setState({filterListClicked: !this.state.filterListClicked})
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({ [name]: target.value })
    }

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        return (
            <div className="species-and-traits-page">
                <Grow in={true} style={{ transformOrigin: 'top' }}>
                    <Paper className="species-and-traits-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                {this.renderSpecies()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderTraits()}
                            </Grid>
                            <Grid container>
                                <Grid item xs={false} md={2}></Grid>
                                <Grid item xs={12} md={2}>
                                    <Grid>
                                        <div className="about-page-summary-list-text"><b>Positive Traits:</b><Typography>+0.08% Bait Effectiveness</Typography></div>
                                    </Grid>
                                    <Grid>
                                        <Typography className="species-and-traits-page">
                                        Adventurous<br/>
                                        Affectionate<br/>
                                        Ambitious<br/>
                                        Articulate<br/>
                                        Attentive<br/>
                                        Brilliant<br/>
                                        Careful<br/>
                                        Caring<br/>
                                        Charming<br/>
                                        Cheerful<br/>
                                        Compassionate<br/>
                                        Conscientious<br/>
                                        Confident<br/>
                                        Considerate<br/>
                                        Courageous<br/>
                                        Decisive<br/>
                                        Dependable<br/>
                                        Determined<br/>
                                        Eccentric<br/>
                                        Efficient<br/>
                                        Energetic<br/>
                                        Faithful<br/>
                                        Friendly<br/>
                                        Funny<br/>
                                        Fun-loving<br/>
                                        Gentle<br/>
                                        Generous<br/>
                                        Graceful<br/>
                                        Hardworking<br/>
                                        Happy<br/>
                                        Helpful<br/>
                                        Honest<br/>
                                        Humanitarian<br/>
                                        Humble<br/>
                                        Humorous<br/>
                                        Imaginative<br/>
                                        Independent<br/>
                                        Industrious<br/>
                                        Innocent<br/>
                                        Inspirational<br/>
                                        Intelligent<br/>
                                        Kindly<br/>
                                        Leader<br/>
                                        Likeable<br/>
                                        Loving<br/>
                                        Lovable<br/>
                                        Loyal<br/>
                                        Mature<br/>
                                        Nurturing<br/>
                                        Optimistic<br/>
                                        Patient<br/>
                                        Personable<br/>
                                        Polite<br/>
                                        Proud<br/>
                                        Rational<br/>
                                        Realistic<br/>
                                        Reliable<br/>
                                        Responsible<br/>
                                        Sensible<br/>
                                        Thoughtful<br/>
                                        Trusting<br/>
                                        Trustworthy<br/>
                                        Uninhibited<br/>
                                        Wise<br/>
                                        Witty<br/><br/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Grid>
                                        <div className="about-page-summary-list-text"><b>Neutral Traits:</b><Typography>+0.016% Etherfish Catch Percentage</Typography></div>
                                    </Grid>
                                    <Grid>
                                    <Typography className="species-and-traits-page">

                                        Apologetic<br/>
                                        Bold<br/>
                                        Bossy<br/>
                                        Calm<br/>
                                        Carefree<br/>
                                        Cautious<br/>
                                        Compliant<br/>
                                        Critical<br/>
                                        Daring<br/>
                                        Demanding<br/>
                                        Demure<br/>
                                        Domineering<br/>
                                        Dutiful<br/>
                                        Easy-going<br/>
                                        Fearless<br/>
                                        Idealistic<br/>
                                        Interfering<br/>
                                        Lively<br/>
                                        Materialistic<br/>
                                        Mysterious<br/>
                                        Opinionated<br/>
                                        Passive<br/>
                                        Rebellious<br/>
                                        Reflective<br/>
                                        Reserved<br/>
                                        Sarcastic<br/>
                                        Secretive<br/>
                                        Sensitive<br/>
                                        Sensuous<br/>
                                        Serious<br/>
                                        Shy<br/>
                                        Spontaneous<br/>
                                        Solitary<br/>
                                        Stable<br/>
                                        Strict<br/>
                                        Submissive<br/>
                                        Tolerant<br/>
                                        Wary<br/><br/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Grid>
                                        <div className="about-page-summary-list-text"><b>Negative Traits:</b><Typography>Cast Time Reduction (+0.07%) <br/>Does not affect free pole</Typography></div>
                                    </Grid>
                                    <Grid>
                                    <Typography className="species-and-traits-page">
                                        Aggressive<br/>
                                        Angry<br/>
                                        Anxious<br/>
                                        Arrogant<br/>
                                        Bad-mannered<br/>
                                        Careless<br/>
                                        Clumsy<br/>
                                        Coarse<br/>
                                        Conceited<br/>
                                        Cowardly<br/>
                                        Cruel<br/>
                                        Dangerous<br/>
                                        Depressed<br/>
                                        Depressive<br/>
                                        Devious<br/>
                                        Disagreeable<br/>
                                        Dictatorial<br/>
                                        Dishonest<br/>
                                        Disrespectful<br/>
                                        Dull<br/>
                                        Evil<br/>
                                        Fearful<br/>
                                        Foolish<br/>
                                        Forceful<br/>
                                        Fussy<br/>
                                        Greedy<br/>
                                        Harsh<br/>
                                        Hateful<br/>
                                        Impatient<br/>
                                        Impolite<br/>
                                        Inconsiderate<br/>
                                        Jealous<br/>
                                        Judgemental<br/>
                                        Lazy<br/>
                                        Lonely<br/>
                                        Manipulative<br/>
                                        Melancholic<br/>
                                        Miserable<br/>
                                        Naive<br/>
                                        Nervous<br/>
                                        Overbearing<br/>
                                        Petulant<br/>
                                        Prying<br/>
                                        Reckless<br/>
                                        Restless<br/>
                                        Rigid<br/>
                                        Rude<br/>
                                        Selfish<br/>
                                        Sly<br/>
                                        Stingy<br/>
                                        Stubborn<br/>
                                        Timid<br/>
                                        Unfriendly<br/>
                                        Unkind<br/>
                                        Unstable<br/>
                                        Untidy<br/>
                                        Violent<br/>
                                        Wicked<br/><br/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Grid>
                                        <div className="about-page-summary-list-text"><b>Legendary Traits:</b><Typography>Unique Swimming Animations<br/> COMBINABLE Through EATING</Typography></div>
                                    </Grid>
                                    <Grid>
                                    <Typography className="species-and-traits-page">
                                        Agitated<br/>
                                        Curious<br/>
                                        Dead<br/>
                                        Excited<br/>
                                        Fighter<br/>
                                        Hellish<br/>
                                        Hungry<br/>
                                        Hunter<br/>
                                        Jelly<br/>
                                        Patient<br/>
                                        Persistent<br/>
                                        Powerful<br/>
                                        Puffer<br/>
                                        Predator<br/>
                                        Relaxed<br/>
                                        Righteous<br/>
                                        Showoff<br/>
                                        Skeleton<br/>
                                        Smooth<br/>
                                        Spinner<br/>
                                        Squishy<br/>
                                        Stalker<br/>
                                        Stoned<br/>
                                        Terrifying<br/>
                                        Trailblazing<br/>
                                        Unstoppable<br/>
                                        Zippy<br/><br/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={false} md={1}></Grid>
                                <Grid item xs={12} md={5}>
                                    <div className="about-page-summary-list-text"><b>Trophy Catches:</b>
                                    <Typography>
                                        <b>Increase All Etherfish Catch Percentages (Gained from Collabs) or custom fish)</b> <br/>
                                        +0.001% Golden Etherfish Catch Percentage<br/>
                                        +0.5% Etherfish Catch Percentage<br/>
                                    </Typography></div>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <div className="about-page-summary-list-text"><b>Eaten Property:</b>
                                    <Typography>
                                        <b> Increase Golden Etherfish Catch Percentage (Comes from eating your other Etherfish)</b> <br/>
                                        -EAT a MAX of 5 Other Fish!<br/>
                                        +0.00006% Golden Etherfish Catch Percentage<br/>
                                    </Typography></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="species-and-traits-page">
                                        <b> ALL TRAITS INCREASE ETHERFISH SIZE IN FISH TANK</b> <br/>
                                        Premier Etherfish and Special Traits give Bonus Sizes!<br/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderFooter()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </div>
        )
    }

    renderSpecies() {
        const { classes } = this.props;

        const filteredSpecies = this.props.species?.filter((item) => this.state.speciesSearchInput === '' ? true : item.speciesName.toLowerCase().includes(this.state.speciesSearchInput.toLowerCase()))

        const sortedSpecies = this.state.speciesSortOption === 'descending' ?
            filteredSpecies?.sort((a, b) => a.total < b.total ? 1 : a.total > b.total ? -1 : 0) :
            this.state.speciesSortOption === 'ascending' ?
            filteredSpecies?.sort((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0) :
            this.state.speciesSortOption === 'alphabetical' ?
            filteredSpecies?.sort((a, b) => a.speciesName < b.speciesName ? -1 : a.speciesName > b.speciesName ? 1 : 0) :
            filteredSpecies
            
        const speciesCharLabels = []
        const speciesChartData = []
        sortedSpecies?.forEach((item) => speciesCharLabels.push(item.speciesName))
        sortedSpecies?.forEach((item) => speciesChartData.push(item.total))

        const speciesData = {
            labels: speciesCharLabels,
            datasets: [
                {
                    data: speciesChartData,
                    ...this.baseChartDatasetOptions
                },
            ],
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        return (
            <div className="species-and-traits-page-summary-container">
                <Typography variant="h3">Species</Typography>
                <Divider classes={{ root: classes.divider }} className="species-and-traits-page-content-divider" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TabContext value={this.state.speciesTabValue}>
                            <AppBar position="static" className="species-and-traits-app-bar">
                                <TabList
                                    onChange={(event, newValue) => this.setState({ speciesTabValue: newValue})}
                                    variant="fullWidth"
                                >
                                    <Tab label="Bar" value="1" className="species-and-traits-app-bar"/>
                                    <Tab label="Pie" value="2" className="species-and-traits-app-bar"/>
                                </TabList>
                            </AppBar>
                            <TabPanel value="1" index={0} className="species-and-traits-tab-panel">
                                <Bar data={speciesData} options={this.barChartOptions}/>
                            </TabPanel>
                            <TabPanel value="2" index={1} className="species-and-traits-tab-panel">
                                <Pie data={speciesData} options={this.pieChartOptions}/>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AppBar position="static" className="species-and-traits-app-bar">
                            {!this.state.selectedSpecies ?
                                <Toolbar>
                                    <FormControl style={{flexGrow: 1}}>
                                        <Input
                                            color="secondary"
                                            style={{ color: 'white' }}
                                            name="speciesSearchInput"
                                            type='text'
                                            autoFocus
                                            placeholder="Search"
                                            value={this.state.speciesSearchInput}
                                            onChange={this.handleChange}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => this.setState({ speciesSearchInput: '' })} size="small">
                                                        <ClearIcon style={{ color: 'white' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    {/* {this.renderFilterButton()} */}
                                    {this.renderSortButton()}
                                </Toolbar>
                            :
                                <Toolbar>
                                    <IconButton edge="start" onClick={() => this.setState({ selectedSpecies: null })}>
                                        <ChevronLeftIcon/>
                                    </IconButton>
                                    <Typography variant="h6">{this.state.selectedSpecies?.speciesName}</Typography>
                                </Toolbar>
                            }
                        </AppBar>
                        {this.props.speciesError ? 
                            <Alert severity="error">
                                <AlertTitle>Unable to load species</AlertTitle>
                                {this.props.speciesError}
                            </Alert>
                        : !this.props.species ? 
                            <CircularProgress/>
                        : !this.state.selectedSpecies ?
                            <List className={classes.root}>
                                {sortedSpecies.map((item, index) => (
                                    <ListItem button key={index} onClick={() => this.setState({selectedSpecies: item})}>
                                        <ListItemText primary={item.speciesName} secondary={`Total: ${item.total}`}/>
                                        <IconButton edge="end" disabled>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        :
                            <List className={classes.root}>
                                <ListSubheader>Fish of this species - {this.state.selectedSpecies?.total}</ListSubheader>
                                {this.state.selectedSpecies?.fish?.map((item, index) => (
                                    <ListItem button key={index} onClick={() => this.props.history.push(`/fish/${item.replace('#', '')}`)}>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                ))}
                            </List>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderFilterButton() {
        return (
            <div>
                <Tooltip title="Filter">
                    <IconButton edge="end" color='primary' onClick={this.handleFilterList}>
                        <FilterListIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={this.state.speciesFilterClicked}
                >

                </Popover>
            </div>
        )
    }

    renderSortButton() {
        return (
            <div>
                <Tooltip title="Sort">
                    <IconButton edge="end" color='primary' onClick={(event) => this.setState({speciesSortClicked: true, anchorEl: event.currentTarget})}>
                        <SortIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={this.state.speciesSortClicked}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({speciesSortClicked: false})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl component="fieldset" style={{margin: '15px'}}>
                        <FormLabel component="legend">Sort</FormLabel>
                        <RadioGroup name="sort" value={this.state.speciesSortOption} onChange={(event) => this.setState({ speciesSortOption: event.target.value})}>
                            <FormControlLabel value="descending" control={<Radio/>} label="Descending"/>
                            <FormControlLabel value="ascending" control={<Radio/>} label="Ascending"/>
                            <FormControlLabel value="alphabetical" control={<Radio/>} label="Alphabetical"/>
                        </RadioGroup>
                    </FormControl>
                </Popover>
            </div>
        )
    }

    renderTraits() {
        const { classes } = this.props;

        const filteredTraits = this.props.traits?.filter((item) => this.state.traitsSearchInput === '' ? true : item.traitName.toLowerCase().includes(this.state.traitsSearchInput.toLowerCase()))

        const sortedTraits = this.state.traitsSortOption === 'descending' ?
            filteredTraits?.sort((a, b) => a.total < b.total ? 1 : a.total > b.total ? -1 : 0) :
            this.state.traitsSortOption === 'ascending' ?
                filteredTraits?.sort((a, b) => a.total < b.total ? -1 : a.total > b.total ? 1 : 0) :
                this.state.traitsSortOption === 'alphabetical' ?
                    filteredTraits?.sort((a, b) => a.traitName < b.traitName ? -1 : a.traitName > b.traitName ? 1 : 0) :
                    filteredTraits

        const traitsCharLabels = []
        const traitsChartData = []
        sortedTraits?.forEach((item) => traitsCharLabels.push(item.traitName))
        sortedTraits?.forEach((item) => traitsChartData.push(item.total))

        const traitData = {
            labels: traitsCharLabels,
            datasets: [
                {
                    data: traitsChartData,
                    ...this.baseChartDatasetOptions
                },
            ],
        }

        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        return (
            <div className="species-and-traits-page-summary-container">
                <Typography variant="h3">Traits</Typography>
                <Divider classes={{ root: classes.divider }} className="species-and-traits-page-content-divider" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TabContext value={this.state.traitTabValue}>
                            <AppBar position="static" className="species-and-traits-app-bar">
                                <TabList
                                    onChange={(event, newValue) => this.setState({ traitTabValue: newValue })}
                                    variant="fullWidth"
                                >
                                    <Tab label="Bar" value="1" className="species-and-traits-app-bar" />
                                    <Tab label="Pie" value="2" className="species-and-traits-app-bar" />
                                </TabList>
                            </AppBar>
                            <TabPanel value="1" index={0} className="species-and-traits-tab-panel">
                                <Bar data={traitData} options={this.barChartOptions} />
                            </TabPanel>
                            <TabPanel value="2" index={1} className="species-and-traits-tab-panel">
                                <Pie data={traitData} options={this.pieChartOptions} />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AppBar position="static" className="species-and-traits-app-bar">
                            {!this.state.selectedTrait ?
                                <Toolbar>
                                    <FormControl style={{ flexGrow: 1 }}>
                                        <Input
                                            color="secondary"
                                            style={{ color: 'white' }}
                                            name="traitsSearchInput"
                                            type='text'
                                            autoFocus
                                            placeholder="Search"
                                            value={this.state.traitsSearchInput}
                                            onChange={this.handleChange}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => this.setState({ traitsSearchInput: '' })} size="small">
                                                        <ClearIcon style={{ color: 'white' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    {this.renderTraitSortButton()}
                                </Toolbar>
                                :
                                <Toolbar>
                                    <IconButton edge="start" onClick={() => this.setState({ selectedTrait: null })}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography variant="h6">{this.state.selectedTrait?.traitName}</Typography>
                                </Toolbar>
                            }
                        </AppBar>
                        {this.props.traitsError ?
                            <Alert severity="error">
                                <AlertTitle>Unable to load traits</AlertTitle>
                                {this.props.traitsError}
                            </Alert>
                            : !this.props.traits ?
                                <CircularProgress />
                                : !this.state.selectedTrait ?
                                    <List className={classes.root}>
                                        {sortedTraits.map((item, index) => (
                                            <ListItem button key={index} onClick={() => this.setState({ selectedTrait: item })}>
                                                <ListItemText primary={item.traitName} secondary={`Total: ${item.total}`} />
                                                <IconButton edge="end" disabled>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                    :
                                    <List className={classes.root}>
                                        <ListSubheader>Fish with this trait - {this.state.selectedTrait?.total}</ListSubheader>
                                        {this.state.selectedTrait?.fish?.map((item, index) => (
                                            <ListItem button key={index} onClick={() => this.props.history.push(`/fish/${item.replace('#', '')}`)}>
                                                <ListItemText primary={item} />
                                            </ListItem>
                                        ))}
                                    </List>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderTraitSortButton() {
        return (
            <div>
                <Tooltip title="Sort">
                    <IconButton edge="end" color='primary' onClick={(event) => this.setState({ traitsSortClicked: true, anchorEl: event.currentTarget })}>
                        <SortIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={this.state.traitsSortClicked}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ traitsSortClicked: false })}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl component="fieldset" style={{ margin: '15px' }}>
                        <FormLabel component="legend">Sort</FormLabel>
                        <RadioGroup name="sort" value={this.state.traitsSortOption} onChange={(event) => this.setState({ traitsSortOption: event.target.value })}>
                            <FormControlLabel value="descending" control={<Radio />} label="Descending" />
                            <FormControlLabel value="ascending" control={<Radio />} label="Ascending" />
                            <FormControlLabel value="alphabetical" control={<Radio />} label="Alphabetical" />
                        </RadioGroup>
                    </FormControl>
                </Popover>
            </div>
        )
    }

    renderFooter() {
        const { classes } = this.props;
        return (
            <div>
                <Divider classes={{ root: classes.divider }} className="species-and-traits-page-content-divider" />
                <Footer />
            </div>
        )
    }

}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 600,
    },
});

const mapStateToProps = (state) => {
    return {
        species: state.speciesAndTraits.species,
        speciesError: state.speciesAndTraits.speciesError,
        traits: state.speciesAndTraits.traits,
        traitsError: state.speciesAndTraits.traitsError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSpecies: () => dispatch(getSpecies()),
        getTraits: () => dispatch(getTraits()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpeciesAndTraits))