const initState = {
    authError: null,
    currentUser: null,
    verifyEmailOpen: false,
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_ACCOUNT_ERROR':
        case 'FORGOT_PASSWORD_ERROR':
        case 'CHANGE_PASSWORD_ERROR':
        case 'LOGIN_ERROR':
        case 'SEND_VERIFICATION_EMAIL_ERROR':
            return {
                ...state,
                authError: action.error.message
            }
        case 'CREATE_ACCOUNT_SUCCESS':
        case 'FORGOT_PASSWORD_SUCCESS':
        case 'CHANGE_PASSWORD_SUCCESS':
        case 'LOGIN_SUCCESS':
        case 'SEND_VERIFICATION_EMAIL_SUCCESS':
            return {
                ...state,
                authError: null
            }
        case 'GET_CURRENT_USER_SUCCESS':
            return {
                ...state,
                currentUser: action.currentUser
            }
        case 'SET_VERIFY_EMAIL':
            return {
                ...state,
                verifyEmailOpen: action.verificationOpen
            }
        case 'SIGNOUT_SUCCESS':
        default:
            return state
    }
}

export default authReducer