const initState = {
    songName: ''
}

const audioReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SWAP_SONG':
            return {
                ...state,
                songName: action.songName,
            }
            case 'ADJUST_VOLUME':
                return {
                    ...state,
                    volume: action.volume,
                }
        default:
            return state
    }
}

export default audioReducer