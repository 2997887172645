const initState = {
    hasCast: false,
    hasHooked: false,
    gameStarted: false,
    gameReward: null,
    gameRewardError: null,
    gameTapCount: 0,
    gameTime: 0,
    displayReward: false,
    totalFishRemaining: 0,
    totalFishRemainingError: null,
    promoReward: null,
    promoRewardError: null,
    failedToCatch: false,
    userCurrentPole: 'starting',
    userCurrentBoat: 'starting',
    songName: 'Lounge',
    locationInfo: null,
    locations: [{name:'season', boats:[]}]
}

const gameReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_HAS_CAST':
            return {
                ...state,
                hasCast: action.hasCast
            }
        case 'SET_HAS_HOOKED':
            return {
                ...state,
                hasHooked: action.hasHooked
            }
        case 'SET_GAME_STARTED':
            return {
                ...state,
                gameStarted: action.gameStarted
            }
        case 'SET_FAILED_TO_CATCH':
            return {
                ...state,
                failedToCatch: action.failedToCatch
            }
        case 'SET_TAP_COUNT':
            return {
                ...state,
                gameTapCount: action.tapCount
            }
        case 'SET_TIME':
            return {
                ...state,
                gameTime: action.time
            }
        case 'SET_CURRENT_POLE':
            return {
                ...state,
                userCurrentPole: action.pole
            }
        case 'SET_CURRENT_BOAT':
            return {
                ...state,
                userCurrentBoat: action.boat
            }
        case 'REWARD_SUCCESS':
            return {
                ...state,
                gameReward: action.reward,
                gameRewardError: null,
            }
        case 'REWARD_ERROR':
            return {
                ...state,
                gameRewardError: action.error.message,
                gameReward: null,
            }
        case 'SET_DISPLAY_REWARD':
            return {
                ...state,
                displayReward: action.displayReward
            }
        case 'GET_FISH_REMAINING_SUCCESS':
            return {
                ...state,
                totalFishRemaining: action.totalFishRemaining
            }
        case 'GET_FISH_REMAINING_ERROR':
            return {
                ...state,
                totalFishRemainingError: action.error
            }
        case 'PROMO_SUCCESS':
            return {
                ...state,
                promoReward: action.reward,
                promoRewardError: null,
            }
        case 'PROMO_ERROR':
            return {
                ...state,
                promoRewardError: action.error,
                promoReward: null,
            }
        case 'SWAP_SONG':
            return {
                ...state,
                songName: action.songName,
            }
        case 'SET_LOCATION_INFO':
            return {
                ...state,
                locationInfo: action.locationInfo,
            }
        case 'SET_LOCATIONS':
            return {
                ...state,
                locations: action.locations,
            }
        default:
            return state
    }
}

export default gameReducer