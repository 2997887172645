import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { enterPromoCode } from "../../../store/actions/gameActions";
import './PromoCode.css';
import { clearSnackbar, showErrorSnackbar, showSuccessSnackbar } from "../../../store/actions/snackbarActions";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

class PromoCode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enteringPromoCode: false,
            promoCode: '',
            promoInfo: false,
        }

        this.handleEnterPromoCode = this.handleEnterPromoCode.bind(this)
        this.handleUpdatePromoCode = this.handleUpdatePromoCode.bind(this)
    }

    async handleEnterPromoCode() {
        if (this.state.promoCode) {
            this.props.clearSnackbar()
            this.setState({ uploadingPromoCode: true })
            await this.props.enterPromoCode(this.state.promoCode)
            if (this.props.promoReward) {
                this.setState({ promoCode: '', enteringPromoCode: false, uploadingPromoCode: false })
                this.props.showSuccessSnackbar(this.props.promoReward.description)
            } else if (this.props.promoRewardError) {
                this.setState({ uploadingPromoCode: false })
                this.props.showErrorSnackbar('Code already used or is invalid')
            }
        }
    }

    handleUpdatePromoCode(event) {
        const target = event.target
        const name = target.name
        this.setState({ [name]: target.value })
    }

    render() {
        return (
            <div className="promo-code-container">
                {this.state.enteringPromoCode ?
                    <div>
                    <FormControl
                        className="promo-code-input"
                        variant="outlined"
                    >
                        <Input
                            color="primary"
                            label="Enter Promo Code"
                            name={'promoCode'}
                            type='text'
                            autoFocus
                            inputProps={{ maxLength: 20 }}
                            value={this.state.promoCode}
                            onChange={this.handleUpdatePromoCode}
                            onKeyPress={(event) => {
                                if (event.code === 'Enter') this.handleEnterPromoCode()
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => this.setState({ promoCode: '', enteringPromoCode: false })} size="small">
                                        <ClearIcon />
                                    </IconButton>
                                    <IconButton onClick={this.handleEnterPromoCode} size="small">
                                        {this.state.uploadingPromoCode ? <CircularProgress size="1.5rem" /> : <DoneIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <br/>
                    <Typography>
                        "PromoCodes are case sensitive"
                    </Typography>
                    </div>
                    :
                    <Button
                        color="primary"
                        variant="contained"
                        className="promo-code-button"
                        onClick={() => this.setState({ enteringPromoCode: true })}
                    >
                        Enter Promo Code
                    </Button>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        promoReward: state.game.promoReward,
        promoRewardError: state.game.promoRewardError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enterPromoCode: (code) => dispatch(enterPromoCode(code)),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
        showErrorSnackbar: (message) => dispatch(showErrorSnackbar(message)),
        clearSnackbar: () => dispatch(clearSnackbar()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode)