import { Button, Divider, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../Marketplace.css';
import boat1 from '../../static/images/Boats/greenPaddleBoardNFT.png';
import boat2 from '../../static/images/Boats/goldJetSkiNFT.png';
import { updateOrder } from '../../store/actions/marketActions';

const name1 = "Green Paddle Board"
const name2 = "Gold Jet Ski"
const var1 = "greenPaddleBoard"
const var2 = "goldJetSki"

class MarketBoats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            amount: 0,
            item: "none",
            item1Purchased: false,
            item2Purchased: false,
        }

        this.handleAddToOrder = this.handleAddToOrder.bind(this)
    }

    componentDidMount() {
        if (this.props.userInfo?.boats?.[var1]) {
            this.setState({item1Purchased: true})
        } else {
            this.setState({item1Purchased: false})    
        }
        if (this.props.userInfo?.boats?.[var2]) {
            this.setState({item2Purchased: true})
        } else {
            this.setState({item2Purchased: false})    
        }

    }

    async handleAddToOrder(name, cost) {
        //check if owned
        if((name === var1 && !this.state.item1Purchased) || (name === var2 && !this.state.item2Purchased)) {
            let order = this.props.order
            let item = {
                name: name,
                type:"boat",
                cost: cost,
                amount: 1
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === name) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Boats - Upgrade your ride!</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={boat1} alt={''} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'>
                                    {name1} <br/> 5 JENJ : {this.convertToEth(5)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button className="market-bait-buy-button" size="small" color="primary" variant="contained"
                                    disabled={this.state.item1Purchased}
                                    onClick={() => this.handleAddToOrder(var1, 5)}
                                > 
                                    Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 25 <br/>
                            Remaining: {this.props.marketBoats?.[var1] ?? 0} 
                            <br/><br/>
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={boat2} alt={''} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'>
                                    {name2} <br/> 5 JENJ : {this.convertToEth(5)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    disabled={this.state.item2Purchased}
                                    onClick={() => this.handleAddToOrder(var2, 5)}
                                > Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 25 <br/>
                            Remaining: {this.props.marketBoats?.[var2] ?? 0} 
                            <br/><br/>
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
            </div>
        )
    }

    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketBoats: state.firestore.marketBoats,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketBoats)