export const addEnjinUser = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var linkEnjin = await firebase.functions().httpsCallable('linkEnjin');
        await linkEnjin({ uid: await firebase.auth().currentUser?.uid })
        .then(async(result) => {
            let {
                    linkingCode,
                    linkingCodeQr
            } = result.data
            dispatch({type: 'ENJIN_LINK_DATA', linkingCode, linkingCodeQr})
        })
        .catch((error) => {
            console.error("add enjin user" + error)
        })
    }
}

export const getEnjinWallet = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var getWallet = await firebase.functions().httpsCallable('getEnjinWallet');
        await getWallet({ uid: await firebase.auth().currentUser?.uid })
        .catch((error) => {
            console.error("get enjin wallet error" + error)
        })
    }
}

export const unlinkEnjinWallet = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        
        var unlinkEnjin = await firebase.functions().httpsCallable('unlinkEnjin');
        await unlinkEnjin({ uid: await firebase.auth().currentUser?.uid })
        .then(async(result) => {
            let {
                    linkingCode,
                    linkingCodeQr
            } = result.data
            
            dispatch({type: 'ENJIN_LINK_DATA', linkingCode, linkingCodeQr})
        })
        .catch((error) => {
            console.error("Unlink Enjin wallet " + error)
        })
    }
}

export const buyItem = (order, totalPrice) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        const uid = await firebase.auth().currentUser.uid
        await firestore.collection("users").doc(uid).set({tradeStatus:"LOADING"},{merge:true})
        var spendJenj = await firebase.functions().httpsCallable('spendJenj');
        await spendJenj({uid, order, totalPrice})
        .then((result) => {
            let { status } = result.data
            console.error(status)
            dispatch({type: 'BUY_ITEM_SUCCESS', status})
        })
        .catch((error) => {
            if (error === 'Error: internal') {
                console.log("interal : expected not sure why")

            } else if (error === 'Error: deadline-exceeded') {
                console.log('timeout bug')
            } else {
                console.error("failed to buy item " + error)
            }
            dispatch({type: 'BUY_ITEM_ERROR', error})
        })
    }
}

export const getEnjinTokens = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const uid = await firebase.auth().currentUser.uid
        console.log("token update")
        var getEnjinTokens = await firebase.functions().httpsCallable('getEnjinTokensTest');
        await getEnjinTokens({uid})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const airDrop = (uid, num, enj) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var airDropFunc = await firebase.functions().httpsCallable('airDrop');
        await airDropFunc({uid, numPremFish:num, numJEnjin:enj})
        .catch((error) => {
            console.error(error)
        })
    }
}



/*export const mintToken = (tokenId) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const uid = await firebase.auth().currentUser.uid
        var mintToken = await firebase.functions().httpsCallable('mintToken');
        await mintToken({uid, tokenId})
        .catch((error) => {
            console.error(error)
        })
    }
}*/

export const fishMaker = (fishNum) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const uid = await firebase.auth().currentUser.uid
        var mintToken = await firebase.functions().httpsCallable('fishMaker');

        await mintToken({uid, fishNum})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const mintBacklogStart = (fishNum) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var mintBacklog = await firebase.functions().httpsCallable('addToMintBacklog');

        await mintBacklog()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const mintPastTokens = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var mintPastTokens = await firebase.functions().httpsCallable('mintPastTokens');
        const uid = await firebase.auth().currentUser.uid
        const firestore = getFirestore()
        await firestore.collection("users").doc(uid).set({mintPastTokens:true},{merge:true})
        await mintPastTokens({uid})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const mintAirDrop = (season) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var mintAirDrop = await firebase.functions().httpsCallable('mintAirDrop');
        const uid = await firebase.auth().currentUser.uid
        const firestore = getFirestore()
        await firestore.collection("users").doc(uid).set({claimedAirDrop:true},{merge:true})
        await mintAirDrop({uid,season})
        .catch((error) => {
            console.log(error)
        })
    }
}

export const mintAirDrop2 = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var mintAirDrop = await firebase.functions().httpsCallable('mintAirDrop');
        const uid = await firebase.auth().currentUser.uid
        const firestore = getFirestore()
        await firestore.collection("users").doc(uid).set({claimedAirDrop2:true},{merge:true})
        await mintAirDrop({uid,season:4})
        .catch((error) => {
            console.error(error)
        })
    }
}