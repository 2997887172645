import { Divider, Grid, Typography, withStyles, DialogContent, DialogActions, Dialog, Button } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CastLog.css'
import DialogTitle from '../DialogTitle';

class CastLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            castLogModalOpen:false,
        }
    }

    render() {
        
        return (
            <div className="profile-page">
                {this.renderCastLogModal()}
                <Button 
                    color={this.props?.style === "alt" ? "secondary" : "primary"}
                    variant="contained"
                    onClick={() => this.setState({castLogModalOpen:true})}
                    className={this.props?.style === "alt" ? "" : "button"}
                    disableElevation={this.props?.style === "alt" ? true : false}
                >
                    {this.props?.style === "alt" ? "" : "View"} Cast Log
                </Button>
            </div>
        );
    }

    renderCastLogModal() {
        const log = this.props.userInfo?.castLog ?? []
        const castLogList = log.map(
            (castData)=>{
                const date = new Date(castData.date)
                const minutes = date.getMinutes().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })
                const displayDate = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear()%100+ " at " + (date.getHours() < 13 ? date.getHours() + ":" + minutes + " AM" :(date.getHours()-12)  + ":" + minutes + " PM")
                const baitType = castData.bait === 0 ? "No" : 
                                castData.bait === 1 ? "1★" : 
                                castData.bait === 2 ? "2★" : 
                                castData.bait === 3 ? "3★" : 
                                castData.bait === 4 ? "4★" : 
                                castData.bait === 5 ? "5★" : 
                                castData.bait === 6 ? "Super" : 
                                castData.bait === 7 ? "Legendary" : 
                                castData.bait === 8 ? "Hybrid" : "error"
                const poleName = castData.pole.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })
                const reward = castData.reward.replace(" Star","★")
                return(
                    <li key={date}>
                        <Grid item xs={12}>
                            <Typography>{displayDate}</Typography>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} class="center-text">
                            <img class="fit-picture" src={castData.image}  alt=""/>
                            <Typography>You caught a {reward}!</Typography>
                            <Typography>Pole used: {poleName} Pole</Typography>
                            <Typography>Bait used: {baitType} Bait</Typography>
                        </Grid>
                        
                        {/*<Button 
                            color={this.props?.style === "alt" ? "secondary" : "primary"}
                            variant="contained"
                            onClick={() => {
                                window.open('http://twitter.com/share?url='+encodeURIComponent(castData.image)+'&text='+encodeURIComponent('I caught a '+reward+'!'),'','left=0,top=0,width=550,height=450,personalbar=0,toolbars=0,sscrollbars=0,resizable=0')
                            }}
                            className={"button"}
                        >
                            tweet
                        </Button>*/}
                        <br/>
                    </li>
                )
            }
        )
        return (
            <Dialog
                open={this.state.castLogModalOpen}
                onClose={() => this.setState({castLogModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cast Log</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                   {castLogList}
                   <Typography>Note: Cast Log only shows up to your last 5 casts</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({castLogModalOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    switch: {
        padding: '6px'
    }
});


const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        currentUser: state.auth.currentUser,
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CastLog))