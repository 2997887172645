import { Grow, Paper, Button, TextField, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import './Admin.css'
import { connect } from 'react-redux';
import { getCurrentUser } from '../store/actions/authActions';
import { getEnjinTokens, airDrop, fishMaker, mintBacklogStart } from '../store/actions/enjinActions';
import { getAllOpenSeaFish } from '../store/actions/openseaActions';
import { testEtherscan } from '../store/actions/ethereumActions';
import { getSpecies, getTraits, snapshot, addFish, updateSupply, findMissingFish, resetContestCounter, resetContestCounter2, resetTrophyPiece, fixTraits,mostCaught, enjinCrossCheck } from '../store/actions/firestoreActions';
import { sortFish, sortUnknownFish, openSeaSnapshot, openSeaSnapshotAdr, openSeaSnapshotData, getUidFish, theThing  } from '../store/actions/firestoreActions';
import { createMetadata } from '../store/actions/realTimeActions'

class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            num: "#1010",
            imageName: "folder/fish.png",
            species: "Etherfish",
            type: "ntrait",
            from: 10000,
            to: 15000,
            uid: "",
            airDropUid: "Enter reciever uid Here", 
            airDropNumFish: 1,
            airDropEnjPer: 4.770,
            collection: "Season3"
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)        
    }

    componentDidMount() {
        // Validate returning signed in user
        if (this.props.user?.uid) {
            this.props.getCurrentUser()
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    render() {
        return (
            <div className="profile-page">
                <Grow in={this.props.user?.uid === 'G0GxUzHUJsaSSrwolFgaM58Cpu72'} style={{transformOrigin: 'top'}}>
                    <Paper className="profile-paper" elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.species();
                                    }}
                                > species
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.traits();
                                    }}
                                > traits
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.getEnjinTokens();
                                    }}
                                > update user NFTS
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.snapshot();
                                    }}
                                > snapshot
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.openSea();
                                    }}
                                > Get Opensea
                                </Button>
                                {this.renderFieldFrom()}
                                {this.renderFieldTo()}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.sortFish(this.state.from, this.state.to);
                                    }}
                                > sort fish
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.sortUnknownFish();
                                    }}
                                > sort unknown fish
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                            this.props.openSeaSnapshot(this.state.from, this.state.to);
                                            let from = parseInt(this.state.from) + 10
                                            let to = parseInt(this.state.to) + 10
                                            this.setState({from, to})
                                        
                                    }}
                                > Get Opensea Token Ids
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.openSeaSnapshotAdr(this.state.from, this.state.to);
                                    }}
                                > Get Opensea Snapshot addresses
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.openSeaSnapshotData(this.state.from, this.state.to);
                                    }}
                                > Get Opensea Snapshot data
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.getUidFish(this.state.uid);
                                    }}
                                > Get UID fish
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.updateSupply();
                                    }}
                                > update Market Supply
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.findMissingFish();
                                    }}
                                > find misiing fish
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.resetContestCounter();
                                    }}
                                > Reset Contest Counter
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.resetContestCounter2();
                                    }}
                                > Reset Contest Counter 2
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.resetTrophyPiece();
                                    }}
                                > Reset Trophy Pieces
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.testEtherscan();
                                    }}
                                > Test Etherscan
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.createMetadata();
                                    }}
                                > metadata
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.fixTraits({from: this.state.from, to: this.state.to, uid: this.state.uid});
                                    }}
                                > Fix double traits
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.mintBacklogStart();
                                    }}
                                > Start Backlog Cycle
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                            <br></br>
                            <br></br>
                            <br></br>
                            {this.renderFieldNum()}
                            {this.renderFieldImage()}
                            {this.renderFieldSpecies()}
                            {this.renderFieldCollection()}
                            {this.renderFieldType()}
                            {this.renderFieldUid()}
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => { 
                                    this.props.addNewFish(this.state.num, this.state.species, this.state.type, this.state.collection, this.state.imageName, this.state.uid);
                                }}
                            > add Fish
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => { 
                                    this.props.fishMaker(this.state.num);
                                }}
                            > Make Fish
                            </Button>
                            </Grid>
                            <Grid item xs={12}>
                            <br></br>
                            <br></br>
                            <br></br>
                            {this.renderFieldAirDropUid()}
                            {this.renderFieldAirDropNumFish()}
                            {this.renderFieldAirDropEnjPer()}
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => { 
                                    this.props.airDrop(this.state.airDropUid, this.state.airDropNumFish, this.state.airDropEnjPer);
                                }}
                            > Send Jenj
                            </Button>
                            </Grid>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.mostCaught({from:this.state.from, to:this.state.to});
                                    }}
                                > mostCaught
                            </Button>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.theThing(this.state.from);
                                        let from = parseInt(this.state.from) + 1
                                        this.setState({from})
                                    }}
                                >Do the thing
                            </Button>
                            <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => { 
                                        this.props.enjinCrossCheck()
                                    }}
                                >enjin cross check
                            </Button>
                        </Grid>
                    </Paper>
                </Grow>
                <Grow in={this.props.user?.uid === 'I1m5agtgsiWxvZ0lbIRpYn99xih2'} style={{transformOrigin: 'top'}}>
                    <Paper className="profile-paper" elevation={2}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => { 
                                this.props.resetContestCounter();
                            }}
                        > Reset Contest Counter
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => { 
                                this.props.resetContestCounter2();
                            }}
                        > Reset Contest Counter 2
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => { 
                                this.props.mostCaught({from:this.state.from, to:this.state.to});
                            }}
                        > mostCaught
                        </Button>
                        {this.renderFieldFrom()}
                        {this.renderFieldTo()}
                    </Paper>
                </Grow>
                
            </div>
        );
    }

    renderFieldNum() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="num"
                    name="num"
                    autoFocus
                    value={this.state.num}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldImage() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="image"
                    name="imageName"
                    autoFocus
                    value={this.state.imageName}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldSpecies() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="species"
                    name="species"
                    autoFocus
                    value={this.state.species}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldUid() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="uid"
                    name="uid"
                    autoFocus
                    value={this.state.uid}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldCollection() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="collection"
                    name="collection"
                    autoFocus
                    value={this.state.collection}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldType() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="type"
                    name="type"
                    autoFocus
                    value={this.state.type}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldTo() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="to"
                    name="to"
                    autoFocus
                    value={this.state.to}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }
    
    renderFieldFrom() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="from"
                    name="from"
                    autoFocus
                    value={this.state.from}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldAirDropUid() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="airDropUid"
                    name="airDropUid"
                    autoFocus
                    value={this.state.airDropUid}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldAirDropNumFish() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="airDropNumFish"
                    name="airDropNumFish"
                    autoFocus
                    value={this.state.airDropNumFish}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }

    renderFieldAirDropEnjPer() {
        return (
            <div>
                <TextField
                    id="standard-multiline-static"
                    label="airDropEnjPer"
                    name="airDropEnjPer"
                    autoFocus
                    value={this.state.airDropEnjPer}
                    onChange={this.handleChange}
                    variant="outlined"
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        currentUser: state.auth.currentUser,
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentUser: () => dispatch(getCurrentUser()),
        species: () => dispatch(getSpecies()),
        traits: () => dispatch(getTraits()),
        getEnjinTokens: () => dispatch(getEnjinTokens()),
        snapshot: () => dispatch(snapshot()),
        openSea: () => dispatch(getAllOpenSeaFish()),
        addNewFish: (num, species, type, collection, imageName, uid) => dispatch(addFish(num, species, type, collection, imageName, uid)),
        sortFish: (from,to) =>dispatch(sortFish(from,to)),
        sortUnknownFish: () =>dispatch(sortUnknownFish()),
        openSeaSnapshot: (from,to) =>dispatch(openSeaSnapshot(from,to)),
        openSeaSnapshotAdr: (from,to) =>dispatch(openSeaSnapshotAdr(from,to)),
        openSeaSnapshotData: (from,to) =>dispatch(openSeaSnapshotData(from,to)),
        getUidFish: (uid) => dispatch(getUidFish(uid)),
        airDrop: (bank, uid, num, enj) => dispatch(airDrop(bank, uid, num, enj)),
        updateSupply: () => dispatch(updateSupply()),
        findMissingFish: () => dispatch(findMissingFish()),
        resetContestCounter: () => dispatch(resetContestCounter()),
        resetContestCounter2: () => dispatch(resetContestCounter2()),
        resetTrophyPiece: () => dispatch(resetTrophyPiece()),
        testEtherscan: () => dispatch(testEtherscan()),
        createMetadata:() => dispatch(createMetadata()),
        fixTraits:(dataIn) => dispatch(fixTraits(dataIn)),
        fishMaker:(fishNum) => dispatch(fishMaker(fishNum)),
        mintBacklogStart:() => dispatch(mintBacklogStart()),
        mostCaught:(dataIn) => dispatch(mostCaught(dataIn)),
        theThing:(from) => dispatch(theThing()),
        enjinCrossCheck:() => dispatch(enjinCrossCheck()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)