import { Avatar, Button, Dialog, DialogContent, Divider, Grid, Grow, Icon, IconButton, Paper, Step, StepContent, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import DialogTitle from '../components/DialogTitle';
import React, { Component } from 'react';
//import logo from '../static/images/logo.png';
import discordLogo from '../static/images/discord-logo-white.svg';
import openseaLogo from '../static/images/opensea-logo.svg';
import buildAFishGiveawayImage from '../static/images/build-a-fish-giveaway.png';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import HelpIcon from '@material-ui/icons/Help';
import './Home.css';
import Footer from '../components/Footer/Footer';
import topCatch1 from '../static/images/topCatch/Etherfish1.gif'
import topCatch2 from '../static/images/topCatch/Etherfish2.gif'
import topCatch3 from '../static/images/topCatch/Etherfish3.gif'
import topCatch4 from '../static/images/topCatch/Etherfish4.gif'
import squareAd from '../static/images/Square_Ad.gif'
import newsImage1 from  '../static/images/news/japanNews.png'
import { updateFirstTimePopup } from '../store/actions/firestoreActions';
import { connect } from 'react-redux';

class Home extends Component {

    constructor(props) {
        super(props);

        this.handleClosePopup = this.handleClosePopup.bind(this)
    }

    handleClosePopup() {
        this.props.updateFirstTimePopup(false)
    }
    
    render() {
        return (
            <div className="home-page">
                <Grow in={true} style={{transformOrigin: 'top'}}>
                    <Paper className="home-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            {/*<Grid item className="home-page-logo-grid" xs={12} md={6}>
                                {this.renderEtherfishingLogo()}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.renderEtherfishingSummary()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderEtherfishingNewToCrypto()}
                            </Grid>*/}
                            <Grid item xs={12}>
                                {this.renderEtherfishingNews()}
                            {/*</Grid>
                            <Grid item xs={12}>
                                {this.renderEtherfishingTopCatch()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderEtherfishingPremierFish()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderEtherfishingRoadMap()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderWorkWithUs()}*/}
                                <Footer/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
                {this.renderFirstTimePopup()}
            </div>
        );
    }

    renderEtherfishingLogo() {
        return (
            <div>
                <Avatar className="home-page-logo" src={squareAd}/>
            </div>
        )
    }

    renderEtherfishingSummary() {
        const { classes } = this.props;
        return (
            <div>
                <div className="home-page-content-title-main">
                    <Typography variant="h3" className="home-page-content-title-text">
                        Etherfishing
                    </Typography>
                    <div className="home-page-social-icons-container">
                        <IconButton className="home-page-social-icons-instagram" href="https://www.instagram.com/etherfishing/" target="_">
                            <InstagramIcon/>
                        </IconButton>
                        <IconButton className="home-page-social-icons-twitter" href="https://twitter.com/EtherFishing" target="_">
                            <TwitterIcon/>
                        </IconButton>
                        <IconButton className="home-page-social-icons-discord" href="https://discord.gg/Q8SZutwRCR" target="_">
                            <Icon classes={{root: classes.iconRoot}}>
                                <img className={classes.imageIcon} src={discordLogo} alt=""/>
                            </Icon>
                        </IconButton>
                        <IconButton className="home-page-social-icons-opensea" href="https://opensea.io/collection/etherfishing" target="_">
                            <Icon classes={{root: classes.iconRoot}}>
                                <img className={classes.imageIcon} src={openseaLogo} alt=""/>
                            </Icon>
                        </IconButton>
                    </div>
                </div>
                <Divider classes={{root: classes.divider}} className="home-page-etherfishing-summary-divider"/>
                <Typography variant="h5" className="home-page-etherfishing-summary-text">
                    Watch this video to see a step-by step video of how to start playing now!<br/>
                    <div className='video-container'>
                        <iframe title="video" width="400" height="300" src="https://www.youtube.com/embed/D7jj6lBCzMg" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </Typography>
            </div>
        )
    }

    renderEtherfishingTutorials() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Tutorials</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h5" className="home-page-content-title-disabled">how to get jenj</Typography>
            </div>
        )
    }

    renderBuildAFishGiveaways() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Build a Fish Giveaways</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <img className="home-page-giveaway-image" src={buildAFishGiveawayImage} alt=""/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" className="home-page-etherfishing-summary-text">
                        Build your own Etherfish NFT!
                        {<div/>}
                        YOUR OWN TROPHY CATCH!
                        {<div/>}
                        Select Body, Color, Style, Background, and 5 Traits! (excludes special properties and bodies)
                        {<div><br/></div>}
                        <ul>
                            <li>Top 5 Collectors</li>
                            <Typography>The 5 collectors with the highest number of Etherfish owned by game launch will win the ability to build a fish!</Typography>
                            {<div><br/></div>}
                            <li>1 A Week</li>
                            <Typography>Giveaways once a week to Etherfish owners in Discord! Make sure to verify ownership in #role-verification and react to #giveaways!</Typography>
                        </ul>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderEtherfishingTopCatch() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Season 9 Top Fish to Catch</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Grid container spacing={2} className="home-page-top-catch-container">
                    <Grid item xs={12} sm={6} md={3} className="home-page-top-catch-item">
                        <div className="home-top-image-container">
                            <img className="home-top-image" src={topCatch1} alt=""/>
                        </div>
                        <Typography className="home-page-content-news">Etherfish</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="home-page-top-catch-item">
                        <div className="home-top-image-container">
                            <img className="home-top-image" src={topCatch2} alt=""/>
                        </div>
                        <Typography className="home-page-content-news">Etherfish</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="home-page-top-catch-item">
                        <div className="home-top-image-container">
                            <img className="home-top-image" src={topCatch3} alt=""/>
                        </div>
                        <Typography className="home-page-content-news">Etherfish</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="home-page-top-catch-item">
                        <div className="home-top-image-container">
                            <img className="home-top-image" src={topCatch4} alt=""/>
                        </div>
                        <Typography className="home-page-content-news">Etherfish</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderEtherfishingPremierFish() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Premier Etherfish: Membership Benefits</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h5" className="home-page-content-title">
                ★ Possible Airdrop in JENJ at the End of Each Season
                {<div></div>}
                ★ Rare Fish Tank Backgrounds Depending on the Premier Etherfish #
                {<div></div>}
                ★ Voting Rights for Future Updates & Plans
                {<div></div>}
                ★ Exclusive Discord Events & Giveaways
                {<div></div>}
                ★ Bonus referals in profile
                </Typography>
            </div>
        )
    }

    renderEtherfishingNewToCrypto() {
        const { classes } = this.props;
        return (
            <div>
                <div className="home-page-content-title-main">
                    <Typography variant="h3" className="home-page-content-title">New to Crypto or Etherfishing?</Typography>
                    <Button 
                        className="home-page-help-button"
                        color="primary" 
                        variant="contained"
                        onClick={() => this.props.history.push('/help')}
                        startIcon={<HelpIcon fontSize="large"/>}
                    >
                        <Typography variant="h5">Beginners Guide</Typography>
                    </Button>
                </div>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Grid container className="home-page-content-news">
                    <Grid className="home-news-text" item xs={10}>
                        <Typography variant="h5">
                            In this game, you can play a fishing mini-game to take a chance at catching a Golden Etherfish
                            worth $1000's and Etherfish NFTs that you can sell on the marketplace or combine in your Fish
                            Tanks! NFTs are Non-Fungible-Tokens that exist on the blockchain and have verifiable ownership! 
                            Download and connect your Enjin Wallet to receive these NFTs! <b><a className="home-page-link" href="https://enjin.io/" target="_">https://enjin.io/</a></b>
                        </Typography>
                        {<div><br/></div>}
                    </Grid>
                    <Grid className="home-news-video" item xs={10}>
                        <Typography variant="h6">
                            Check out our tutorial on how to get JENJ! (PRO TIP- Transfer Ethereum to Enjin and swap to ENJ in the Enjin Wallet, then to JENJ)
                        </Typography>
                       {<div><br/></div>}
                        <iframe className="youtube-video" src="https://www.youtube.com/embed/adLp2uoOhwQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                       {<div><br/></div>}
                    </Grid>
                </Grid>
            </div>
        )
    }
    renderEtherfishingNews() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Etherfishing News</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h4" className="home-page-content-title">November 27th, 2023</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            ATTENTION! Important News!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                            Due to Enjins migration to thier new network starting December 8th, and after a lengthy consideration of our options, the team
                            has decided to shut down Etherfishing and the website. To find out m ore information regarding how this affects
                            you, please read the full announcement in our discord.
                            <br/><br/><a href="https://discord.com/channels/827167825298456607/852247065529942087">Discord Announcements</a>
                        </Typography>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>      
                {/*<Grid className="home-page-content-news" item xs={12}>
                        <Typography variant="h5">Join Discord for the Latest News and Contests!<br/><br/></Typography>
                </Grid>
                <Grid className="home-page-content-news" item xs={12}>
                    <Button 
                        className="" 
                        variant="contained" 
                        color="primary"
                        size="large"
                        onClick={() => this.props.history.push('/news')} 
                    >
                        <Typography>
                            Older News
                        </Typography>
                    </Button>
        </Grid>*/}
                
            </div>
        )
    }

    renderEtherfishingRoadMap() {
        const roadmapContent = [
            {title: "Drop Premier Etherfish!", description: "1000 OG Premier Etherfish with Membership benefits!"},
            {title: "White Paper Summary", description: "A full, comprehensive Etherfishing Summary showcasing everything we have to offer in an easy to read and transparent method.", link: "https://docs.google.com/document/d/1PZRXbNa7TYX8zVDdF5dYqd1pstdW6XWkUiZ7OWB4x74/edit?usp=sharing"},
            {title: "Etherfish Tattoo", description: "Got it on my chest! Check it out on the fishcord anoucements!"},
            {title: "Etherfish Merch", description: "Check out our merchandise! More Options to come!", link: "https://www.zazzle.com/store/etherfishing/products"},
            {title: "Launch the Game - September 29th", description: "The start of season 1! Play on the web with both desktop and mobile.  "},
            {title: "Private Fishing Locations", description: "Limited Pool of NFTs for each Boat NFT holders and collaborations!"},
            {title: "Additional Quality-of-life Features and Game Mechanics", description: "There are several parts of Etherfishing that we hope to make better over time!"},
            {title: "Turn All Fish into NFTs", description: "Turn all fish previously caught plus all fish going forward into Enjin Jumpnet NFTs."},
            {title: "Breeding Mechanic", description: "Allow two fish of the same species to breed together to create another Etherfish NFT!"},
            {title: "Eating Mechanic", description: "Allow one fish to eat another to gain all unique traits from eaten fish plus a \"Eaten #\" trait to increase your chance at catching a Golden Etherfish. Eat up to 5 other fish!"},
            {title: "Upgrade Etherfish Breeding", description: "Add in a method to charge other players to Breed with your Etherfish!"},
            {title: "Add in NFT Fishing Pool", description: "Essentially a liquidity pool for all supported NFTs."},
            {title: "Fish from Other Player’s Tanks", description: "Add in a method to set your price for other players to take a chance at catching from your pool!"},
            {title: "Etherfishing Mini-Game", description: "Idle Mini-Game adding further value to some of Etherfishing's NFTs."},
            {title: "Profile Picture Collection", description: "PFP Collection of Standing with Randomly Generated Accessories and Awesome Animated Backgrounds! Additional value for holding PFP, Premier Owners mint with WL & Discount!"},
        ]
        const { classes } = this.props;

        return (
            <div>
                <Typography variant="h3" className="home-page-content-title">Roadmap</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Stepper activeStep={8} orientation="vertical">
                    {roadmapContent.map((item, index) => (
                        <Step key={index} expanded={true}>
                            <StepLabel StepIconProps={{
                                classes: {
                                    root: classes.icon,
                                    completed: classes.iconCompleted,
                                    active: classes.iconActive,
                                }
                            }}>
                                <Typography variant="h4">{item.title}{item.link ? <b> <a className="home-page-link" href={item.link} target="_">Link</a></b> : null}</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography className="home-page-roadmap-step-content">{item.description}</Typography>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        )
    }

    renderWorkWithUs() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Work With Us</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Typography variant="h5">
                    Want to work with us? Want to promote yourself or your business? Want to do some collaboration with Etherfishing?
                    {<div><br/></div>}
                    We have many different options available and are very interested and excited to work with other projects!
                </Typography>
            </div>
        )
    }

    renderFishList() {
        return (
            <div key={this.props.userFish} className="fish-list">
                {this.props.userFish?.map((fishData, index) => (
                    <img key={index} src={fishData.imageUrl} alt=""/>
                    ))}
            </div>
        )
    }

    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleClosePopup}>
                <DialogTitle onClose={this.handleClosePopup}>Welcome to Etherfishing!</DialogTitle>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        Join our 
                        <b> <a className="home-page-link" href={'https://discord.gg/PmvaDAwxwe'} target="_">Discord</a> </b>
                        to get the latest information and quickest support!
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    icon: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconActive: {
        color: "#6690cb",
    },
    iconCompleted: {
        color: "#3465b0",
    },
    imageIcon: {
        height: '100%',
        width: '100%',
    },
    iconRoot: {
        textAlign: 'center'
    }
});

const mapStateToProps = (state) => {
    return {
        showFirstTimePopup: state.firestore.homeFirstTimePopup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('home', newVal)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))