export const swapSong = (songName) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SWAP_SONG', songName })
    }
}

export const adjustVolume = (volume) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'ADJUST_VOLUME', volume })
    }
}