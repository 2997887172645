import { Button, CircularProgress, TextField, Fade, Typography, ButtonGroup } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorSnackbar } from '../../../store/actions/snackbarActions';
import { updateUserInfo } from '../../../store/actions/firestoreActions';
import EditIcon from '@material-ui/icons/Edit';
import './ProfileBio.css'

class ProfileBio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileEditingBio: false,
            profileBio: '',
            profileSavingBio: false,
            profileBioHover: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleUpdateBio = this.handleUpdateBio.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleUpdateBio() {
        this.setState({profileSavingBio: true}, async () => {
            await this.props.updateUserInfo({bio: this.state.profileBio})
            
            if (this.props.firestore.firestoreError) {
                this.props.showErrorSnackbar(`Profile bio error\n${this.props.firestore.firestoreError}`);
                this.setState({profileSavingBio: false})
            }
            else {
                this.setState({profileEditingBio: false, profileBioHover: false, profileSavingBio: false})
            }
        })
    }

    render() {
        return (
            <div className="profile-bio-container">
                {this.state.profileEditingBio ? 
                    <div className="profile-bio-textbox-container">
                        <TextField
                            id="standard-multiline-static"
                            className="profile-bio"
                            label="Bio"
                            name="profileBio"
                            multiline
                            autoFocus
                            value={this.state.profileBio}
                            onChange={this.handleChange}
                            variant="outlined"
                            inputProps={{maxLength: 200}}
                        />
                        <ButtonGroup className="profile-bio-textbox-button-group" color="primary">
                            <Button className="profile-bio-textbox-button" onClick={() => this.setState({profileEditingBio: false, profileBioHover: false})}>
                                Cancel
                            </Button>
                            <Button className="profile-bio-textbox-button" onClick={this.handleUpdateBio}>
                                {this.state.profileSavingBio ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Save'}
                            </Button>
                        </ButtonGroup>
                    </div>
                :
                this.props.userInfo && this.props.userInfo.bio ?
                    <Button 
                        className="profile-bio-edit-button"
                        onClick={() => this.setState({profileEditingBio: true, profileBio: this.props.userInfo.bio})}
                        onMouseEnter={() => this.setState({profileBioHover: true})}
                        onMouseLeave={() => this.setState({profileBioHover: false})}
                    >
                        <EditIcon className="profile-bio-edit-icon-hidden" style={{opacity: 0}}/>
                        <Typography style={{color: 'grey'}}>
                            {this.props.userInfo.bio}
                        </Typography>
                        <Fade in={this.state.profileBioHover}>
                            <EditIcon className="profile-bio-edit-icon"/>
                        </Fade>
                    </Button>
                :
                    <Button
                        endIcon={<EditIcon/>}
                        onClick={() => this.setState({profileEditingBio: true})}
                    >
                        Add profile bio
                    </Button>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        firestore: state.firestore,
        userInfo: state.firestore.userInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        showErrorSnackbar: (message) => dispatch(showErrorSnackbar(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBio)