import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Menu, MenuItem, Switch, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/authActions';
import { showSuccessSnackbar } from '../../../store/actions/snackbarActions';
import VerifyEmail from "../../../Game/GameComponents/VerifyEmail/VerifyEmail";
import { setOpenVerifyEmail } from "../../../store/actions/authActions";
import { MoreHoriz } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MailIcon from '@material-ui/icons/Mail';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import './ProfileOptions.css'
import { changeUserMusicSetting, clearUserInfo, setCondensedLayout, updateAllFirstTimePopups } from '../../../store/actions/firestoreActions';

class ProfileOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileOptionsOpen: false,
            profileOptionsAnchor: null,
            settingsOpen: false,
        }

        this.handleLogout = this.handleLogout.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleProfileOptionsClick = this.handleProfileOptionsClick.bind(this)
        this.handleProfileOptionsClose = this.handleProfileOptionsClose.bind(this)
        this.handleOpenSettings = this.handleOpenSettings.bind(this)
        this.handleCloseSettings = this.handleCloseSettings.bind(this)
        this.handleOpenVerify = this.handleOpenVerify.bind(this)
        this.handleChangeMusicSetting = this.handleChangeMusicSetting.bind(this)
        this.handleChangeCondensedSetting = this.handleChangeCondensedSetting.bind(this)
        this.handleOpenHelp = this.handleOpenHelp.bind(this)
        this.handleChangeShowFirstTimePopups = this.handleChangeShowFirstTimePopups.bind(this)
    }

    async handleLogout() {
        await this.props.signOut()
        await this.props.clearUserInfo()
        this.props.showSuccessSnackbar('Successfully logged out');
        this.props.history.push('/user/login')
    }

    handleOpenHelp() {
        this.props.history.push('/help')
    }

    handleChangePassword() {
        this.props.history.push('/user/change-password')
    }

    handleOpenSettings() {
        this.setState({ settingsOpen: true, profileOptionsOpen: false })
    }

    handleCloseSettings() {
        this.setState({settingsOpen: false})
    }

    handleProfileOptionsClick(event) {
        this.setState({profileOptionsOpen: true, profileOptionsAnchor: event.currentTarget})
    }

    handleProfileOptionsClose() {
        this.setState({profileOptionsOpen: false, profileOptionsAnchor: null})
    }

    async handleChangeMusicSetting(event) {
        await this.props.changeUserMusicSetting(event.target.checked)
    }

    async handleChangeCondensedSetting(event) {
        await this.props.setCondensedLayout(event.target.checked)
    }

    handleChangeShowFirstTimePopups(event) {
        this.props.updateAllFirstTimePopups(event.target.checked)
    }

    handleOpenVerify() {
        this.setState({settingsOpen: false})
        this.props.setOpenVerifyEmail()
    }

    render() {
        return (
            <div>
                <VerifyEmail/>
                <Tooltip title="Profile Options">
                    <IconButton 
                        onClick={this.handleProfileOptionsClick}
                        color='primary'
                        className="profile-options-button"
                    >
                        <MoreHoriz/>
                    </IconButton>
                </Tooltip>
                <Menu
                    keepMounted
                    anchorEl={this.state.profileOptionsAnchor}
                    open={this.state.profileOptionsOpen}
                    onClose={this.handleProfileOptionsClose}
                >
                    <MenuItem onClick={this.handleOpenHelp}>
                        <HelpOutlineIcon className="profile-options-icon"/>Help
                    </MenuItem>
                    <MenuItem onClick={this.handleOpenSettings}>
                        <SettingsIcon className="profile-options-icon"/>Settings
                    </MenuItem>
                    <MenuItem onClick={this.handleChangePassword}>
                        <VpnKeyIcon className="profile-options-icon"/>Change password
                    </MenuItem>
                    <MenuItem onClick={this.handleOpenVerify}>
                        <MailIcon className="profile-options-icon"/>Send Verification Email
                    </MenuItem>
                    <MenuItem onClick={this.handleLogout}>
                        <ExitToAppIcon className="profile-options-icon"/>Logout
                    </MenuItem>
                </Menu>
                {this.renderSettingsModal()}
            </div>
        )
    }

    renderSettingsModal() {
        return (
            <Dialog
                open={this.state.settingsOpen}
                onClose={this.handleCloseSettings}
            >
                <DialogTitle>Settings</DialogTitle>
                <DialogContent className="profile-settings-content">
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={this.props.userPlayMusic} onChange={this.handleChangeMusicSetting} name="turnOffGameMusic" />}
                            label="Play Music"
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={this.props.condensedLayout} onChange={this.handleChangeCondensedSetting} name="turnOnCondensedView" />}
                            label="Condensed Layout"
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Switch checked={this.props.showNewUserPopups} onChange={this.handleChangeShowFirstTimePopups} name="turnOnCondensedView" />}
                            label="Show New User Popups"
                            labelPlacement="end"
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleCloseSettings}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userPlayMusic: state.firestore.userPlayMusic,
        condensedLayout: state.firestore.condensedLayout,
        showNewUserPopups: state.firestore.homeFirstTimePopup ||
                           state.firestore.gameFirstTimePopup ||
                           state.firestore.tankFirstTimePopup ||
                           state.firestore.marketFirstTimePopup ||
                           state.firestore.aboutFirstTimePopup ||
                           state.firestore.profileFirstTimePopup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
        clearUserInfo: () => dispatch(clearUserInfo()),
        setOpenVerifyEmail: () => dispatch(setOpenVerifyEmail(true)),
        changeUserMusicSetting: (setting) => dispatch(changeUserMusicSetting(setting)),
        setCondensedLayout: (isCondensed) => dispatch(setCondensedLayout(isCondensed)),
        updateAllFirstTimePopups: (newVal) => dispatch(updateAllFirstTimePopups(newVal)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOptions)