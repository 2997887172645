import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react';
import './TermsOfService.css';
import TermsOfServiceText from '../../static/text/TermsOfServiceText';

export default class TermsOfService extends Component {

    render() {
        //while(this.props.open === null) {}
        return (
            <Dialog
                open={this.props.open}
            >
                <DialogTitle>Etherfishing Terms of Use</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        <TermsOfServiceText/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.props.handleClose}
                    >
                        {this.props.closeText ?? "Cancel"}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.props.handleAccept}
                    >
                        Agree and Continue
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}