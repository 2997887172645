import { Button, Divider, Typography, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../Marketplace.css';
import bundleSmallImg from "../../static/images/Bundles/bundleSmall.png"
import { updateOrder } from '../../store/actions/marketActions';
import bundleImg1 from "../../static/images/Bundles/aussieBund.png"
import bundleImg2 from "../../static/images/Bundles/downUnderBund.png"

const bundle1Name = "Aussie"
const bundle1Var = "aussie"

const bundle2Name = "Down Under"
const bundle2Var = "downUnder"

class MarketBundles extends Component {

    constructor(props) {
        super(props);
       
        this.state = {
            amount: 1,
            bundleName: "none",
            bundle1Purchased: true,
            bundle2Purchased: true,
        }

        this.handleAddToOrder = this.handleAddToOrder.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        if (this.props.userInfo?.poles?.[bundle1Var]) {
            this.setState({bundle1Purchased: true})
        } else {
            this.setState({bundle1Purchased: false})    
        }
        if (this.props.userInfo?.poles?.[bundle2Var]) {
            this.setState({bundle2Purchased: true})
        } else {
            this.setState({bundle2Purchased: false})    
        }

    }

    async handleAddToOrder(bundleName, bundleCost) {
        //check if owned
        if((bundleName ===  bundle1Var && !this.state.bundle1Purchased) || (bundleName ===  bundle2Var && !this.state.bundle2Purchased)) {
            let order = this.props.order
            let item = {
                name: bundleName,
                type:"bundle",
                cost: bundleCost,
                amount: 1
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === bundleName) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }else if (bundleName === "smallBundle1")  {
            let order = this.props.order
            let item = {
                name: bundleName,
                type:"smallBundle",
                cost: bundleCost * this.state.amount,
                amount: this.state.amount
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === bundleName) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Bundles - For the whale within...</Typography>
                </div>
                <Divider className="profile-divider"/>
                {(
                    (this.state.bundle1Purchased === true || this.props.marketBundles?.[bundle2Var] <= 0)
                 && (this.state.bundle2Purchased === true || this.props.marketBundles?.[bundle1Var] <= 0)
                ) ?
                <div>
                    <Typography variant="h6">Check back later. <br/><br/></Typography>

                </div> :
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                    {(this.state.bundle1Purchased === false && (this.props.marketBundles?.[bundle1Var] ?? 0) > 0) ? 
                        <div>
                            <img src={bundleImg1} alt={'item1'} className="market-img"/>
                            <Grid container className="market-grid-container">
                                <Grid item xs={7} className="market-grid-item">
                                    <Typography className='profile-etherbait-empty'> 
                                        {bundle1Name} Bundle <br/> 50 JENJ : {this.convertToEth(50)} ETH
                                    </Typography>
                                </Grid>
                                    <Grid item xs={5} className="market-grid-item">
                                    <Button 
                                        className="market-bait-buy-button" 
                                        size="small" 
                                        color="primary" 
                                        variant="contained"
                                        onClick={() => this.handleAddToOrder(bundle1Var, 50)}
                                    > Add to Cart
                                    </Button>
                                </Grid>
                                <Typography>
                                    Total supply: 50 <br/>
                                    Remaining: {this.props.marketBundles?.[bundle1Var] ?? 0}
                                    <br/><br/>
                                </Typography>
                            </Grid>
                        </div>:
                        <div></div>
                    }
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        {(this.state.bundle2Purchased === false && (this.props.marketBundles?.[bundle2Var] ?? 0) > 0) ?
                            <div>
                            <img src={bundleImg2} alt={'item2'} className="market-img"/>
                            <Grid container className="market-grid-container">
                                <Grid item xs={7} className="market-grid-item">
                                    <Typography className='profile-etherbait-empty'>
                                        {bundle2Name} Bundle<br/> 100 JENJ : {this.convertToEth(100)} ETH
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} className="market-grid-item">
                                    <Button 
                                        className="market-bait-buy-button" 
                                        size="small" 
                                        color="primary" 
                                        variant="contained"
                                        onClick={() => this.handleAddToOrder(bundle2Var, 100)}
                                    > Add to Cart
                                    </Button>
                                </Grid>
                                <Typography>
                                    Total supply: 50 <br/>
                                    Remaining: {this.props.marketBundles?.[bundle2Var] ?? 0}
                                    <br/><br/>
                                </Typography>
                            </Grid>
                        </div>:
                        <div></div>
                    }
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={bundleSmallImg} alt={'item3'} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item className="market-grid-item" xs={3}>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Small Bundles"
                                    name="amount"
                                    autoFocus
                                    value={this.state.amount}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'>
                                    Small Bundle<br/> 15 JENJ : {this.convertToEth(15)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    onClick={() => this.handleAddToOrder("smallBundle1", 15)}
                                > Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                </Grid>
                </Grid>
                }
                <Typography>{<br/>}</Typography>
            </div>
        )
    }
    
    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketBundles: state.firestore.marketBundles,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketBundles)