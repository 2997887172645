import { Grid, Paper, Divider, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Box } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import React, { Component } from "react";
import { connect } from "react-redux";
import './Marketplace.css';
import MarketEtherbait from './Etherbait/MarketEtherbait';
import MarketCasts from './Casts/MarketCasts';
import MarketBundles from './Bundles/MarketBundles';
import ProfileEnjinWallet from "../User/Profile/EnjinWallet/ProfileEnjinWallet";
import ProfileEthereumWallet from "../User/Profile/EthereumWallet/ProfileEthereumWallet";
import { refreshEthPayment, startEthPayment } from "../store/actions/ethereumActions";
import Footer from '../components/Footer/Footer';
import MarketBoats from './Boats/MarketBoats';
import MarketBackgrounds from './Backgrounds/MarketBackgrounds';
import MarketPoles from './Poles/MarketPoles';
import { getMarketInfo } from "../store/actions/firestoreActions";
import DialogTitleFirstTime from '../components/DialogTitle';
import { updateFirstTimePopup } from '../store/actions/firestoreActions';
import { clearOrder, updateOrder } from '../store/actions/marketActions';
import { buyItem } from "../store/actions/enjinActions";
import MarketTransactionInfo from "./MarketTransactionInfo";
import MarketSpins from './Spins/MarketSpins';


class Marketplace extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loggedIn: false,
            helpEthModalOpen: false,
            helpEnjModalOpen: false,
            statusModalOpen:false,
            statusEnjModalOpen:false,
            statusEthModalOpen:false,
            curStatus: '',
            walletEnjConnected: false,
            walletEthConnected: false,
            ethModalOpen: false,
            enjModalOpen: false,
            curPrice: 0,
            toggleRender:true
        }
        
        this.handleGoToCreateAccount = this.handleGoToCreateAccount.bind(this)
        this.handleGoToProfile = this.handleGoToProfile.bind(this)
        this.handleHelpEthModalOpen = this.handleHelpEthModalOpen.bind(this)
        this.handleHelpEthModalClose = this.handleHelpEthModalClose.bind(this)
        this.handleHelpEnjModalOpen = this.handleHelpEnjModalOpen.bind(this)
        this.handleHelpEnjModalClose = this.handleHelpEnjModalClose.bind(this)
        this.handleStatusModalOpen = this.handleStatusModalOpen.bind(this)
        this.handleStatusModalClose = this.handleStatusModalClose.bind(this)
        this.handleStatusEthModalOpen = this.handleStatusEthModalOpen.bind(this)
        this.handleStatusEthModalClose = this.handleStatusEthModalClose.bind(this)
        this.handleStatusEnjModalOpen = this.handleStatusEnjModalOpen.bind(this)
        this.handleStatusEnjModalClose = this.handleStatusEnjModalClose.bind(this)
        this.handleCloseFirstTimePopup = this.handleCloseFirstTimePopup.bind(this)
        this.handleBuyEnj = this.handleBuyEnj.bind(this)
        this.handleBuyEth = this.handleBuyEth.bind(this)
    }

    componentDidMount() {
        if (this.props.user?.uid) {
            this.setState({loggedIn: true})   
        }
        this.props.getMarketInfo()

        if (this.props.userInfo?.enjinWalletAddress) {
            this.setState({walletEnjConnected: true})
        } else {
            this.setState({walletEnjConnected: false})    
        }
        if (this.props.userInfo?.ethereumWalletAddress) {
            this.setState({walletEthConnected: true})
        } else {
            this.setState({walletEthConnected: false})    
        }
        this.setState({curStatus: this.props?.userInfo?.tradeStatus ?? (this.props?.userInfo?.enjinWalletAddress ? 'READY' : 'CONNECT ENJIN WALLET')})
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.userInfo?.tradeStatus !== this.props.userInfo?.tradeStatus || prevProps.userInfo?.enjinWalletAddress !== this.props.userInfo?.enjinWalletAddress) {
            this.setState({curStatus: this.props?.userInfo?.tradeStatus ?? (this.props?.userInfo?.enjinWalletAddress ? 'READY' : 'CONNECT ENJIN WALLET')})
        }
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    handleGoToCreateAccount() {
        this.props.history.push('/user/create-account')
    }
    
    handleGoToProfile() {
        this.props.history.push('/user/profile')
    }

    handleHelpEnjModalOpen() { this.setState({ helpEnjModalOpen: true }) }
    handleHelpEnjModalClose() { this.setState({ helpEnjModalOpen: false }) }

    handleHelpEthModalOpen() { this.setState({ helpEthModalOpen: true }) }
    handleHelpEthModalClose() { this.setState({ helpEthModalOpen: false }) }

    handleStatusModalOpen() { this.setState({ statusModalOpen: true }) }
    handleStatusModalClose() { this.setState({ statusModalOpen: false }) }

    handleStatusEnjModalOpen() { this.setState({ statusEnjModalOpen: true }) }
    handleStatusEnjModalClose() { this.setState({ statusEnjModalOpen: false }) } 
    
    handleStatusEthModalOpen() { this.setState({ statusEthModalOpen: true }) }
    handleStatusEthModalClose() { this.setState({ statusEthModalOpen: false }) }

    render() {
        return (
            <div className="market-page">
                {/*<Grow in={true} style={{transformOrigin: 'top'}}>*/}
                    <Paper className="market-paper" elevation={2}>
                        <Grid container>
                        <Grid item className="market-enj-help" xs={6}> 
                            <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.handleHelpEnjModalOpen}
                            > How to Acquire JENJ
                            </Button>
                        </Grid>
                        <Grid item className="market-eth-help" xs={6}> 
                            <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.handleHelpEthModalOpen}
                            > How to use metamask
                            </Button>
                        </Grid>
                        </Grid>
                        <Grid item className="market-grid-item">
                            <Typography variant="h3">Marketplace</Typography>
                            {this.state.loggedIn ?
                                <div>
                                    <ProfileEnjinWallet/>
                                    <ProfileEthereumWallet/> 
                                </div>
                            : 
                            <Typography>
                                Log in to attach Enjin Wallet
                            </Typography>
                            }   
                            <Grid item className="market-grid-item" xs={12}>
                                <Button 
                                    color="primary"
                                    variant="outlined" 
                                    onClick={this.handleStatusModalOpen}>
                                    Transaction status: {this.state.curStatus} 
                                </Button>
                            </Grid>
                                {this.props.userInfo?.tradeEthWaiting ?
                                    <div>
                                        <Divider className="market-divider"/>
                                        <Grid item className="market-grid-item">
                                            <Typography>
                                                Last ETH transaction status: PENDING
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Grid item className="market-grid-item" xs={9}>
                                                <Typography className="market-transaction-refresh">
                                                    Transaction Hash: {this.props.userInfo.tradeEthWaiting}
                                                </Typography>
                                            </Grid>
                                            <Grid item className="market-grid-item" xs={3}>
                                                <Tooltip title="Copy">
                                                    <Button
                                                        className="profile-referrals-copy-button"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => { 
                                                            navigator.clipboard.writeText(this.props.userInfo.tradeEthWaiting)
                                                            this.props.showSuccessSnackbar('Copied to clipboard')
                                                        }}
                                                    > <AssignmentIcon/>
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid item className="market-grid-item">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="market-trade-status-button"
                                                disabled={!this.props.userInfo.tradeEthWaiting || this.state.curStatus !== "READY"}
                                                onClick={() => { 
                                                    this.props.refreshEthPayment();
                                                }}
                                            >
                                                Refresh Status
                                            </Button>
                                        </Grid>
                                    </div>
                                    :
                                    <div> 
                                    </div>
                                }
                            
                            <Divider className="market-divider"/>
                        </Grid>
                        {<div><br/></div>}
                        <Box sx={{
                            bgcolor: '#6494dd',//#6494dd',
                            border: '3px solid',
                            borderRadius: 20,
                            borderColor: '#3465b0',
                            px: 2,
                            zIndex: 'tooltip',
                            boxShadow: 8,
                        }}>
                        <Grid item className="market-grid-item" xs={12} key={this.props.updatedOrder}>
                            <Typography variant="h4">Cart</Typography>
                            <Divider className="market-divider"/>
                            {this.props.order.length !== 0 ? this.props.order?.map((item) =>
                                <div key = {item.name}>                
                                    <Typography>
                                        {item.amount} {item.name !== 'cast' && item.name !== 'spin' && item.name !== 'smallBundle1' ? item.name : ''} {item.type === "smallBundle" ? "Small Bundle" : item.type} {item.cost} JENJ : {(item.cost * this.props.marketConversionRate).toFixed(4)} ETH <br/>
                                    </Typography>
                                </div>
                            ) :
                                <div>                
                                    <Typography>
                                        empty
                                    </Typography>
                                </div>
                            }
                            <Typography>
                                <br/>
                                Cart Total {this.props.curTotal} JENJ : {(this.props.curTotal * this.props.marketConversionRate).toFixed(4)} ETH 
                            </Typography>
                            <Grid container>
                                <Grid item xs={false} sm={4}/>
                                <Grid item xs={6} sm={2} className="market-grid-item">
                                    <Button 
                                        color="primary" 
                                        variant="contained" 
                                        style={{
                                            margin: '3px'
                                        }}
                                        onClick={() => {if(this.props.curTotal > 0) {this.setState({enjModalOpen: true, curPrice: this.props.curTotal})}}}
                                        disabled={!this.state.walletEnjConnected || this.state.curStatus !== 'READY'}
                                    >Buy with Jumpnet Enjin
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={2} className="market-grid-item">
                                    <Button 
                                        color="primary" 
                                        variant="contained"
                                        style={{
                                            margin: '3px'
                                        }}
                                        onClick={() => {if(this.props.curTotal > 0) {this.setState({ethModalOpen: true, curPrice: (this.props.curTotal * this.props.marketConversionRate).toFixed(4)})}}}
                                        disabled={!this.state.walletEthConnected || this.state.curStatus !== 'READY'}
                                    >Buy with Ethereum
                                    </Button>
                                </Grid>
                                <Grid item xs={false} sm={4}/>
                            </Grid>
                            <Grid container>
                                <Grid item xs={1} sm={4}/>
                                <Grid item xs={10} sm={4} className="market-grid-item">
                                    <Button 
                                        color="primary" 
                                        variant="contained"
                                        style={{
                                            margin: '3px'
                                        }}
                                        onClick={() => {
                                            this.props.clearOrder()
                                        }}
                                    >Clear Cart
                                    </Button>
                                </Grid>
                                <Grid item xs={1} sm={4}/>
                            </Grid>
                            <Typography><br/></Typography>
                        </Grid>
                        </Box>
                        <Typography><br/></Typography>
                        {/*<Grid item className="market-grid-item" xs={12}>
                            <MarketCasts/> 
                        </Grid> <br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketSpins/> 
                        </Grid> <br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketEtherbait/>
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketBundles/>
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketPoles/>
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketBoats/>
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <MarketBackgrounds/>
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <Typography variant="h5">Etherfishing Merchandise</Typography>                                
                            <Divider className="market-divider"/>
                            <Typography variant="h6">
                                <b><a href="https://www.zazzle.com/store/etherfishing/products" target="_">Purchase Items through Zazzle</a></b>
                                <br/>
                            </Typography>  
                            <Typography variant="h6">
                                <b><a href="https://discord.com/channels/@me/842219317297938442/994071747970220122" target="_">Purchase Metal Etherishing Coin from CryptoCornucopia</a></b>
                                <br/>
                            </Typography> 
                            <Typography>
                                Custom merch coming soon!  
                                <br/><br/>
                            </Typography>  
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <Typography variant="h5">Fish Tank Items</Typography>                                
                            <Divider className="market-divider"/>
                            <Typography>
                                Coming soon <br/><br/>
                            </Typography>   
                        </Grid><br/>
                        <Grid item className="market-grid-item" xs={12}>
                            <Typography variant="h5">Fish Market</Typography>                                
                            <Divider className="market-divider"/>
                            <Typography>
                                Coming soon <br/><br/>
                            </Typography>  
                                    </Grid>*/}
                        <Grid item className="market-grid-item" xs={12}>
                            <Typography variant="h5">Market Closed</Typography>                                     
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderFooter()}
                        </Grid> 
                    </Paper>
                {/*</Grow>*/}
                {this.renderHelpEnjModal()}
                {this.renderHelpEthModal()}
                {this.renderStatusModal()}
                {this.renderEnjStatusModal()}
                {this.renderEthStatusModal()}
                {this.renderFirstTimePopup()}
                {this.renderBuyEnjModal()}
                {this.renderBuyEthModal()}
            </div> 
        )
    }

    renderHelpEnjModal() {
        return (
            <Dialog
                open={this.state.helpEnjModalOpen}
                onClose={this.handleHelpEnjModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tutorial</DialogTitle>
                <DialogContent id="alert-dialog-description" className="game-menu-help-modal-content">
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/q5PR0TLiGCE" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleHelpEnjModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderHelpEthModal() {
        return (
            <Dialog
                open={this.state.helpEthModalOpen}
                onClose={this.handleHelpEthModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tutorial</DialogTitle>
                <DialogContent id="alert-dialog-description" className="game-menu-help-modal-content">
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/OsRIHlr0_Iw" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleHelpEthModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    async handleBuyEnj() {
        this.props.buyWithEnj(this.props.order, this.state.curPrice)
        this.setState({enjModalOpen: false, infoModalOpen: true, curPrice: 0})
        this.props.clearOrder()
        await new Promise(r => setTimeout(r, 300 * 1000)); //wait
        this.setState({infoModalOpen: false})
    }

    async handleBuyEth() {
        this.props.buyWithEth(this.props.order, this.state.curPrice)
        this.setState({ethModalOpen: false, infoModalOpen: false, curPrice: 0})
        this.props.clearOrder()
        await new Promise(r => setTimeout(r, 300 * 1000)); //wait
        this.setState({infoModalOpen: false})
    } 

    renderBuyEthModal() {
        let status = this.props.userInfo?.tradeStatus ?? 'READY'
        return (
            <Dialog
                open={this.state.ethModalOpen}
                onClose={() => this.setState({ethModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Purchase Order With Ethereum</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                        <Typography>
                            DISCLAIMER: You are agreeing to purchase your specified order for the specific amount of cryptocurrency.{<br/>}{<br/>}
                            Casts are used to Cast Standard Poles to play the Etherfishing Game.{<br/>}
                            Bait are used to increase the chance of catching an Etherfish within the Etherfishing Game. They are one-time use items.{<br/>}
                            Etherfishing Poles are swappable in your Game Menu and have a Separate Cast time from other Etherfising Poles. It uses standard purchased Casts to play Etherfishing's Game more often!{<br/>}
                            Tank Backgrounds are swappable in your Fish Tank and gives you a different visual to watch your Etherfish swim around in.{<br/>}
                            Etherfishing Boats are swappable in your Game Menu and gives you a different visual while playing Etherfishing's Game.{<br/>}
                            {<br/>}
                            To purchase, first make sure you're on the Ethereum Mainnet!
                            {<br/>}{<br/>}
                            Then, press BUY and check Ethereum wallet requests. 
                        </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({ethModalOpen: false})}
                    >Close
                    </Button>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={this.handleBuyEth}
                        disabled={!this.state.walletEthConnected || status !== 'READY'}
                    >Purchase
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderBuyEnjModal() {
        let status = this.state.curStatus ?? 'READY'
        return (
            <Dialog
                open={this.state.enjModalOpen}
                onClose={() => this.setState({enjModalOpen: false})}
                aria-labelledby="alert-dialog-titleEtherfishing Boat is swappable in your Game Menu and gives you a different visual while playing Etherfishing's Game."
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Purchase Order With Jumpnet Enjin</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                        <Typography>
                            DISCLAIMER: You are agreeing to purchase your specified order for the specific amount of cryptocurrency.{<br/>}{<br/>}
                            Casts are used to Cast Standard Poles to play the Etherfishing Game.{<br/>}
                            Bait are used to increase the chance of catching an Etherfish within the Etherfishing Game. They are one-time use items.{<br/>}
                            Etherfishing Poles are swappable in your Game Menu and have a Separate Cast time from other Etherfising Poles. It uses standard purchased Casts to play Etherfishing's Game more often!{<br/>}
                            Tank Backgrounds are swappable in your Fish Tank and gives you a different visual to watch your Etherfish swim around in.{<br/>}
                            Etherfishing Boats are swappable in your Game Menu and gives you a different visual while playing Etherfishing's Game.{<br/>}
                            {<br/>}
                            To purchase, press BUY and then check requests in your Enjin Wallet App.
                        </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({enjModalOpen: false})}
                    >Close
                    </Button>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={this.handleBuyEnj}
                        disabled={!this.state.walletEnjConnected || status !== 'READY'}
                    >Purchase
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderBuyInfoModal() {
        return (
            <Dialog
                open={this.state.infoModalOpen}
                onClose={() => this.setState({infoModalOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Transaction in progress...</DialogTitle>
                <DialogContent id="alert-dialog-description" className="">
                    <MarketTransactionInfo/>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({infoModalOpen: false})}
                    >Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderStatusModal() {
        let prevStatus = this.props?.userInfo?.tradeStatusPrev ?? 'NONE'
        return (
            <Dialog
                open={this.state.statusModalOpen}
                onClose={this.handleStatusModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="market-trade-status-info"
            >
                <DialogTitle id="alert-dialog-title">Trade Status Info</DialogTitle>
                <DialogContent id="alert-dialog-description" className="market-trade-status-info">
                    <Grid>
                        <Grid>
                            <Typography>
                                Current transaction status: {this.state.curStatus} 
                                <br></br>Last transaction status: {prevStatus}
                                <br></br>{this.props.userInfo?.tradeWarning}
                            </Typography>
                            <Divider className="market-divider"/>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="market-trade-status-button"
                                    onClick={this.handleStatusEnjModalOpen}
                                >
                                    Enjin Status Info
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="market-trade-status-button"
                                    onClick={this.handleStatusEthModalOpen}
                                >
                                    Ethereum Status Info
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleStatusModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEnjStatusModal() {
        return (
            <Dialog
                open={this.state.statusEnjModalOpen}
                onClose={this.handleStatusEnjModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="market-trade-status-info"
            >
                <DialogTitle id="alert-dialog-title">Enjin Status Info</DialogTitle>
                <DialogContent id="alert-dialog-description" className="market-trade-status-info">
                    <Grid>
                        <Grid>
                            <Typography variant="h8">
                            TRANSACTION_STATUS: Description
                                <Divider className="market-divider"/>
                                READY: Your wallet is connected and you have no pending transactions in place.<br></br><br></br>
                                LOADING: The transaction is being sent to our server to processing.<br></br><br></br>
                                CREATING_TRANSACTION: The transaction is being made from our server to Enjin's Jumpnet network.<br></br><br></br>
                                SENT_TRADE_REQUEST: The transaction has successfully been made and is waiting for you to sign the transaction.<br></br><br></br>
                                ACCEPTED_BY_USER: The transaction was successfully signed.<br></br><br></br>
                                WAITING_FOR_DAEMON: The transaction has been sent to the Etherfishing wallet daemon to be signed.<br></br><br></br>
                                EXECUTED: Enjin transaction has been confirmed and your purchase is being sent to your account.<br></br><br></br>
                                CANCELED_BY_USER: The transaction was canceled by you.<br></br><br></br>
                                USER_TRADE_TIMEOUT: The transaction wasn't signed within 5 minutes of making the request.<br></br><br></br>
                                NOT_ENOUGH_FUNDS: Enjin sent an error upon trying to send the Etherfishing wallet daemon a request.
                                 This usually means you do not have enough JENJ in your wallet for the transaction.
                                 Remember you need to convert your ENJ to JENJ to make purchases on the Jumpnet network.<br></br><br></br>
                                TRADE_FAILED: Enjin sent an error not allowing the transaction to be signed.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleStatusEnjModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEthStatusModal() {
        return (
            <Dialog
                open={this.state.statusEthModalOpen}
                onClose={this.handleStatusEthModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="market-trade-status-info"
            >
                <DialogTitle id="alert-dialog-title">Ethereum Status Info</DialogTitle>
                <DialogContent id="alert-dialog-description" className="market-trade-status-info">
                    <Grid>
                        <Grid>
                            <Typography>
                                TRANSACTION_STATUS: Description
                            </Typography>
                            <Divider className="market-divider"/>
                        </Grid>
                        <Grid>
                            <Typography>
                                VALIDATING TRANSACTION: A quick pre-check to make sure the transaction is correct.<br></br><br></br>
                                PENDING: Your transaction is waiting to be mined on the Ethereum network.<br></br><br></br>
                                MINED: Success! Your transaction has been mined to the Ethereum network.<br></br><br></br>
                                VALIDATING BLOCK - %: For security reasons, it's smart to wait for a certain amount of blocks to be minted completing a transaction.<br></br><br></br>
                                CONFIRMED: Your items are being sent to your account.<br></br><br></br>
                                INVALID: Your transaction was invalid upon verification. If this continues, please contact us through e-mail or discord<br></br><br></br>
                                WAITING: Your transaction wasn't mined in a fast enough time.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleStatusEthModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    
    renderFooter() {
        return(
            <div>
                <Divider className="market-divider"/>
                <Footer/>
            </div>
        )   
    }

    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitleFirstTime onClose={this.handleCloseFirstTimePopup}>Link your wallets in Profile to purchase!</DialogTitleFirstTime>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        Currently, the least expensive method of paying is buying with Ethereum (ETH)!<br/><br/>
                        Until we switch this message, JENJ is quite costly in ETH gas fees when switching from Enjin Coin (ENJ) to Enjin Jumpnet (JENJ).
                        <div><br /></div>
                        TRANSACTION STATUS should read "READY" when you are able to purchase and after your transactions have completely gone through successfully. Be patient for a couple minutes and refresh to see new items. Join discord if you have any issues!
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo, 
        showFirstTimePopup: state.firestore.marketFirstTimePopup,
        order: state.market.order,
        curTotal: state.market.curTotal,
        updatedOrder: state.market.updatedOrder,
        marketConversionRate: state.firestore.marketConversionRate,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
        clearOrder: () => dispatch(clearOrder()),
        refreshEthPayment: (txHash) => dispatch(refreshEthPayment(txHash,'casts')),
        getMarketInfo: () => dispatch(getMarketInfo()),
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('market', newVal)),
        buyWithEnj: (order, price) => dispatch(buyItem(order, price)),
        buyWithEth: (order, price) => dispatch(startEthPayment(order, price))
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Marketplace)