import React, { Component } from 'react';
import p5 from "p5";
import background from '../static/images/background.png';
import { connect } from 'react-redux';
import './FishTank.css';
import lsdShadow from '../static/images/LsdShadow.png';
import { setLoadingFishTank } from '../store/actions/openseaActions';
import getSpeciesScale from './SpeciesScale'

/*eslint-disable*/

let bgs;


function jitter(p, x, y, xjitter, yjitter) {
    x += p.random(-xjitter, xjitter);
    y += p.random(-yjitter, yjitter);
    return [x, y];
}

function pythagorean(sideA, sideB) {
    return Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
}

function setfishSize(size) {//the Higher the size the Smaller the Fish
    const screenScaler = Math.floor((window.innerHeight < window.innerWidth ? window.innerHeight : window.innerWidth)/80)
    const finalScaler = screenScaler > 8 ? 8 : screenScaler
    return (finalScaler * (size + 8))
}

class fish {
    constructor(p, img, ani, fishSpeed, size, fishNum, hidden) {
        this.fishNum = fishNum
        this.hidden = hidden
        this.size = size
        this.animation = ani;
        this.angle = (p.random(20, 34) / 360);
        this.p = p;
        this.fishSpeed = fishSpeed
        this.speedRandomness = p.random(-2.5, 2.5)
        this.xspeed = this.calculateSpeed()
        this.dSpeed = 0
        this.yspeed = this.xspeed * this.angle//31/360 is 5 degree angle rise over run
        this.img = img
        //Fish cords and their postions 
        this.bottom = (window.innerHeight - (window.innerHeight * (1 / 11)));
        this.top = (window.innerHeight / 9)
        this.x = p.random(window.innerWidth);
        this.y = p.random(this.top, this.bottom);
        this.xdirection = 1// Left or Right
        this.ydirection = 1// Top to Bottom
        this.yFlag = true
        this.yAnimationDirection = -1
        //img width
        this.width = img.width;
        this.height = img.height;
        //The Diameter of the fish
        this.aspect = img.width / img.height;
        this.pythagorean = pythagorean(window.innerHeight, window.innerWidth)
        this.length = setfishSize(this.size);
        //This diameter Multiplyer
        this.dx = 0;
        this.dy = 0;
        //sin multiplyer
        this.a = p.random(0, 0.5);
        this.c = 0.0;//cos
        this.s = 0.0;//sin
        //If flip is true then we the fish is moving towards left
        this.flip = (Math.random() < 0.5);
        this.isFishFlipping = false
        this.spin = 1;//if spin is true spin is 1 its upright if spin is -1 its upsidedown
        this.zspin = 0 
        this.zspinDirection = 1 //1 clockwise, -1 counter-clockwise
        this.blink = 0;//if(blink is true dont render the frame)
        this.frameCounter = 0;

        //checks if the fish spawned go the other way and flips it 
        if (this.flip == 0) {
            this.xdirection *= -1;
        }
        if(Math.random() < 0.5) {
            this.ydirection *= -1;
        }
        this.Shadow = p.loadImage(lsdShadow);
    }
    calculateSpeed() {
        let frameRate = Number.isFinite(this.p.frameRate()) && this.p.frameRate() > 0 ? this.p.frameRate() : 60
        return ((1 / frameRate) * window.innerWidth / 100) * (this.speedRandomness + this.fishSpeed * 2.7)
    }

    move() {
        //console.log(this.x, this.y, this.hidden)
        const xspd = this.calculateSpeed()
        this.xspeed = xspd - this.dSpeed

        /*animation Jitter*/
        if (this.animation == 1) {//1 slow jitter
            [this.x, this.y] = jitter(this.p, this.x, this.y, this.p.random(-1, 1), this.p.random(-1, 1));
        }
        else if (this.animation == 2) {//2 jitter more jitter
            [this.x, this.y] = jitter(this.p, this.x, this.y, this.p.random(-3, 3), this.p.random(-3, 3));
        }
        else if (this.animation == 3) {//3 jitter Crack jitter
            [this.x, this.y] = jitter(this.p, this.x, this.y, this.p.random(-7, 7), this.p.random(-7, 7));
        } else if (this.animation == 16) {//16 jitter Crack jitter
            [this.x, this.y] = jitter(this.p, this.x, this.y, this.p.random(-10, 10), this.p.random(-10, 10));
        }

        /*animation Speed Modifiers*/
        ///5 Pulse {Fast Slow}
        if (this.animation == 5) {
            if (this.dSpeed > xspd * 3/5) {
                this.dSpeed = 0
            };
            this.dSpeed += .01;
        }

        //adjust coordinates
        this.x = this.x + this.xspeed * this.xdirection;
        this.y = (this.y + (this.yspeed) * this.ydirection);

        //Check X boundaries - flip
        if ((this.flip == 1 && this.x > (window.innerWidth + this.length)) || (this.flip == 0 && (this.x < (0 - this.length)))) {
            this.xdirection *= -1;
            this.flip = !this.flip;
            this.angle = (Math.random() < 0.5) * (this.p.random(10, 33) / 360);
            this.isFishFlipping = true
        } else {
            this.isFishFlipping = false
        }

        //Check Y boundaries - bounce
        if ((this.y > this.bottom || this.y < this.top) && this.yFlag == true) {
            this.yFlag = false
            this.ydirection *= -1
        } else if ((this.y > window.innerHeight * 3/5 || this.y < window.innerHeight * 2/5) && this.yFlag == false) {
            this.yFlag = true
        }

        //check if way out of bounds
        /*if(this.x > (window.innerWidth + 2 * this.width) || this.x < (0 - 2 * this.width) 
            || this.y > (window.innerHeight + this.height) || this.y < (0 - this.height)
        ) {
            this.moveToEdge()
            /*
            this.y = Math.random(this.top, this.bottom);
            if(this.xdirection == 1) {
                this.x = 0 - this.width
                this.flip = 1
            } else {
                this.x = window.innerWidth + this.width
                this.flip = -1
            }
        }*/
    }

    display() {
        let dx = 0;
        let dy = 0;
        //Cosign Fuction to have a smoother Transtion

        this.a += 0.05;
        this.c = Math.cos(this.a);
        this.s = Math.sin(this.a);

        if (this.a == 1) {
            this.a = 0;
        }

        /*animation canvas Modifiers*/
        if (this.animation == 4) {//Blink
            let wave;
            this.blink += 0.1;
            wave = (Math.cos(this.blink) + .5);
            if (wave < 0) {
                return;
            }
        } else if (this.animation == 6) {//squish jelly
            dx = Math.abs(this.s * this.aspect * (this.length / 2))
            let pixelshift = (this.length / 10) * 2
            dy = Math.abs(this.c * pixelshift)
        } else if (this.animation == 7) {//squish jelly
            dx = Math.abs((this.s * this.aspect) * (this.length / 2))
            let pixelshift = (this.length / 10) * 4
            dy = Math.abs(this.c * pixelshift)
        } else if (this.animation == 8) {//squish 
            dx = Math.abs(this.s * this.aspect * (this.length / 2))
            let pixelshift = (this.length / 10) * 6
            dy = Math.abs(this.c * pixelshift)
        }
        //This.push and translate is where icreate and postion the fish
        //The animations in side this block have to do with animating the image itself
        //this.aspect = aspectofwindow(this.p);
        this.p.push();
        this.p.translate(this.x, this.y)
        if (this.flip) {
            this.p.scale(-1, 1)
        }
        if (this.animation == 9) {//spinX 
            this.p.scale(1, this.c);
        }
        else if (this.animation == 10) {//spinY
            this.p.scale(this.s, 1);
        }
        else if (this.animation == 11) {//Dead
            this.xspeed = .1;
            this.p.scale(1, -1);
            this.y = (window.innerHeight / 10) * 1.5;
        }

        else if (this.animation == 12) {//Lsd Shadow
            this.p.image(this.Shadow, 0, 0, this.length + dx * 1.2, this.length + dy * 1.2)
        }
        
        else if (this.animation == 13) { //grow - x & y change at same rate
            dx = Math.abs(this.s * this.aspect * (this.length / 2))
            let pixelshift = (this.length / 10) * 2
            dy = Math.abs(this.s * pixelshift)
        }
        else if (this.animation == 14) { //flip out - zspin
            this.zspin += 5
            this.p.rotate(3.141592/180 * this.zspin)
            if(this.zspin >= 360) {
                this.zspin = 0
            }
        }
        else if (this.animation == 15) { //chillaxin - swimming up and down
            this.angle = 10/360
            this.zspin += .5  * this.zspinDirection
            this.y += 1 * this.yAnimationDirection
            this.p.rotate(3.141592/180 * this.zspin)
            if(this.zspin >= 40 || this.zspin < -40) {
                this.zspinDirection *= -1
            }
            if(this.zspin == 0) {
                this.yAnimationDirection *= -1
            }
        }
        this.p.image(this.img, 0, 0, this.length + dx, this.length + dy);
        this.p.pop();

    }

    isFlipping() {
        return this.isFishFlipping
    }

    moveToEdge() {
        // If not already at edge
        if (!(this.x > (window.innerWidth + this.length) || this.x < (0 - this.length))) {
            if (this.flip == 1) {
                this.x = window.innerWidth + this.length
            } else {
                this.x = 0 - this.length
            }
        }   
    }
    
    getFishNum() {
        return this.fishNum
    }

    getHidden() {
        return this.hidden
    }

    setHidden(hidden) {
        this.hidden = hidden
    }
}


class FishTank extends Component {
    constructor(props) {
        super(props);

        this.processingRef = React.createRef();
        this.p5 = null;
        this.fishes = []
    }

    Sketch = (p) => {
        let userFishImages = []

        p.preload = () => {
            this.props.setLoadingFishTank(true)

            bgs = p.loadImage(this.props.userInfo?.background?.imageUrl ?? background);

            if (this.props.userFish?.length > 0) {
                for (var i = 0; i < this.props.userFish.length; i++) {
                    let userFish = this.props.userFish[i]
                    userFishImages.push({ ...userFish, imageUrl: p.loadImage(userFish.imageUrl) })
                }
            }
        }

        p.setup = () => {
            p.createCanvas(window.innerWidth, window.innerHeight);
            // Create objects
            ///**
            /**const Animation = [
              1  'jitter2',    // jitter
              2  'jitter3',    //super jitter max
              3  'jitter4',   // SuperSuper Jitter
              4 'Blink',     //slow toggle image canvas on and off
              5 'Pulse',     //fast slow
              6 'Squish1',  //squish
              7 'Squish2',  //faster squish
              8 'Squish3',  //fastest squish
              9 'SpinX',     //spin x axis
              10 'SpinY',     //spin y axis
              11 'dead',      //stopped at top
            
              12 'Lsd Shark, //adds a Black Lsd shark In the background
            ]
             */

            userFishImages.forEach((fishData) => {
                const isHidden = this.props.hiddenFish?.includes(fishData.fishNum)
        
                //determine size
                const speciesSize = getSpeciesScale(fishData.species) ?? 0
                const premierValue = fishData.fishCollection === "premier" ? 5 : 0
                const numBasicTraits = (fishData?.fishTraitsPositive.length ?? 0) + (fishData?.fishTraitsNeutral.length ?? 0) + (fishData?.fishTraitsNegative.length ?? 0)
                const traitCeiling = 15
                const traitSize = numBasicTraits <= traitCeiling ? numBasicTraits : Math.floor((numBasicTraits-traitCeiling)/5) + traitCeiling
                const totalTraitSize = (fishData?.fishTraitsLegendary?.length ?? 0) * 2  + fishData?.trophyCatch ?? null !== null ? 2 : 0 + traitSize
                const fishScale = speciesSize + premierValue + (totalTraitSize > 30 ? 30 : totalTraitSize)

                //console.log(fishData.fishNum + ' ' + fishData.species + ' scale: ' + fishScale)
                this.fishes.push(
                    new fish(
                        p,
                        fishData.imageUrl, 
                        fishData.animation ?? 1, 
                        fishData.speed ?? 4, 
                        fishScale, 
                        fishData.fishNum, 
                        isHidden
                    )
                );
            })
            this.sortFishByHidden()

            this.props.setLoadingFishTank(false)
        };

        p.draw = () => {
            p.imageMode(p.CENTER)
            var bgsWidthRatio = bgs.height / bgs.width
            var bgsHeightRatio = bgs.width / bgs.height
            var bgWidth;
            var bgHeight;

            if (window.innerWidth * bgsWidthRatio < window.innerHeight) {
                bgWidth = window.innerHeight * bgsHeightRatio
                bgHeight = window.innerHeight
            } else {
                bgWidth = window.innerWidth
                bgHeight = window.innerWidth * bgsWidthRatio
            }

            p.image(bgs, p.width / 2, p.height / 2, bgWidth, bgHeight)

            let numFishToDisplay = Math.floor(this.fishes.length * ((this.props.maxFish % 101) / 100))
            for (let i = 0; i < numFishToDisplay; i++) {
                if (this.fishes[i].getHidden()) {
                    continue
                }

                if (numFishToDisplay < this.fishes.length && this.fishes[i].isFlipping() && p.random() < 0.5) {
                    // Chance to change fish when flipping (if not displaying all fish)
                    const removedFish = this.fishes[i]
                    const newFish = this.fishes[numFishToDisplay]
                    if (newFish.getHidden()) continue
                    newFish.moveToEdge()
                    this.fishes[i] = newFish
                    this.fishes.splice(numFishToDisplay, 1)
                    this.fishes.push(removedFish)
                    this.sortFishByHidden()
                }
                this.fishes[i].move();
                this.fishes[i].display();
            }
        };
        p.windowResized = () => {
            p.resizeCanvas(window.innerWidth, window.innerHeight);
        }
    };

    sortFishByHidden() {
        // Put hidden fish at end of array
        this.fishes.sort((a, b) => {
            var aIsHidden = a.getHidden()
            var bIsHidden = b.getHidden()

            return aIsHidden === bIsHidden ? 0 : bIsHidden ? -1 : 1
        })
    }

    componentDidMount() {
        if (!this.props.gameStarted) {
            this.p5 = new p5(this.Sketch, this.processingRef.current);
        }
    }

    componentDidUpdate(prevProps) {
        // Make sure user fish or background has changed before rerendering
        const { userFish, userInfo, gameStarted, hiddenFishUpdated, hiddenFish } = this.props
        const { userFish: prevUserFish, userInfo: prevUserInfo, gameStarted: prevGameStarted, hiddenFishUpdated: prevHiddenFishUpdated } = prevProps
        if (JSON.stringify(userFish?.map((fish) => fish.fishNum)) !== JSON.stringify(prevUserFish?.map((fish) => fish.fishNum)) || userInfo?.background?.imageUrl !== prevUserInfo?.background?.imageUrl) {
            if (!this.props.gameStarted) {
                this.p5.remove()
                this.p5 = new p5(this.Sketch, this.processingRef.current);
            } else {
                this.p5.remove()
            }
        }

        if (prevGameStarted === false && gameStarted === true) {
            // Don't play fish tank animation while game is overlayed (performance)
            this.p5.remove()
        } else if (prevGameStarted === true && gameStarted === false) {
            // Start animation again if not on game page
            this.p5.remove()
            this.p5 = new p5(this.Sketch, this.processingRef.current);
        }

        // Update hidden fish
        if (hiddenFishUpdated !== prevHiddenFishUpdated) {
            this.fishes?.forEach((p5Fish) => {
                const isHidden = hiddenFish?.includes(p5Fish.getFishNum())
                p5Fish.setHidden(isHidden)
            })
            this.sortFishByHidden()
        }
    }

    render() {
        return (
            <div className="fish-tank-page">
                <div className="fish-tank-canvas" id="fishtank" ref={this.processingRef} />
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        userFish: state.firestore.userFish,
        gameStarted: state.game.gameStarted,
        maxFish: state.fishTank.maxFish,
        hiddenFish: state.fishTank.hiddenFish,
        hiddenFishUpdated: state.fishTank.hiddenFishUpdated,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoadingFishTank: (loading) => dispatch(setLoadingFishTank(loading)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FishTank)
