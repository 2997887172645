import { Typography, IconButton , Icon, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import discordLogo from '../../static/images/discord-logo-white.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';

import "./Footer.css"

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div className="footer-summary-container">
                <Typography variant="h5">
                    {<div><br/></div>}
                    <b>Contact us</b>
                    {<div/>}
                </Typography>
                <IconButton className="footer-social-icons-instagram" href="https://www.instagram.com/etherfishing/" target="_">
                    <InstagramIcon fontSize="large"/>
                </IconButton>
                <IconButton className="footer-social-icons-twitter" href="https://twitter.com/EtherFishing" target="_">
                    <TwitterIcon fontSize="large"/>
                </IconButton>
                <IconButton className="footer-social-icons-discord" href="https://discord.gg/Q8SZutwRCR" target="_">
                    <Icon classes={{root: classes.iconRoot}} fontSize="large">
                        <img className={classes.imageIcon} src={discordLogo} alt=""/>
                    </Icon>
                </IconButton>
                <IconButton className="footer-social-icons-email" href="mailto: Etherfishing@gmail.com" target="_">
                    <EmailIcon fontSize="large"/>
                </IconButton>
            </div>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    icon: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconActive: {
        color: "#6690cb",
    },
    iconCompleted: {
        color: "#3465b0",
    },
    imageIcon: {
        height: '100%',
        width: '100%',
    },
    iconRoot: {
        textAlign: 'center'
    }
});

export default withStyles(styles)(Footer)