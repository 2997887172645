import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, CircularProgress, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUserInfo } from '../../../store/actions/firestoreActions';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import './ProfileEthereumWallet.css';
import { Alert, AlertTitle } from '@material-ui/lab';
import { attachUserEthereum} from '../../../store/actions/ethereumActions';
import { showSuccessSnackbar } from '../../../store/actions/snackbarActions';

class ProfileEthereumWallet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ethereumWalletError: null,
            ethereumWalletModalOpen: false,
            ethereumVerifyAttachLoading: false,
            ethereumInfoOpen: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveEthereumWalletAddress = this.handleSaveEthereumWalletAddress.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    async handleSaveEthereumWalletAddress() {
        this.setState({ethereumVerifyAttachLoading: true})
        await this.props.attachUserEthereum()

        if (this.props.ethereumError) {
            this.setState({ethereumWalletError: this.props.ethereumError, ethereumVerifyAttachLoading: false})
        } else {
            if (this.props.ethereumUserData) {
                this.setState({ethereumVerifyAttachLoading: false})
                await this.props.updateUserInfo({ethereumWalletAddress: this.props.ethereumUserData[0]},{merge:true})
                this.props.showSuccessSnackbar('Successfully attached Ethereum account')
                this.setState({ethereumWalletModalOpen: false, ethereumWalletError: null, ethereumVerifyAttachLoading: false})
            } else {
                this.setState({ethereumWalletError: 'Unable to retrieve Ethereum user data', ethereumVerifyAttachLoading: false})
            }
        }
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.props.userInfo?.ethereumWalletAddress ?
                        <div className="profile-opensea-wallet-address-container">
                            <Typography className="profile-opensea-wallet-address">
                                <u>Ethereum</u>: {this.props.userInfo.ethereumWalletAddress}
                            </Typography>
                            <IconButton 
                                onClick={() => this.setState({ethereumWalletModalOpen: true})} 
                                size="small"
                            >
                                <EditIcon size="small"/>
                            </IconButton>
                        </div>
                    :
                        <div className="profile-opensea-wallet-address-container">
                            <Button 
                                className="profile-opensea-wallet-button" 
                                color="primary"
                                variant="contained"
                                onClick={() => this.setState({ethereumWalletModalOpen: true})}
                            >
                                Attach Ethereum Wallet
                            </Button>
                            <IconButton 
                                onClick={() => this.setState({ethereumInfoOpen: true})}
                                color='primary'
                                className="profile-options-button"
                            >
                                <InfoIcon size="small"/>
                            </IconButton>
                        </div>
                    }
                    {this.renderEthereumModal()}
                    {this.renderEthereumInfo()}
                </Grid>
            </Grid>
        )
    }

    renderEthereumModal() {
        return (
            <Dialog
                open={this.state.ethereumWalletModalOpen}
                onClose={() => this.setState({ethereumModalOpen: false, ethereumWalletError: null})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach Ethereum wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-opensea-wallet-modal-content">
                    {this.renderEthereumError()}
                    <Button 
                        onClick={this.handleSaveEthereumWalletAddress}
                        variant="contained"
                        color="primary"
                    >
                        {this.state.EthereumVerifyAttachLoading ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Attach with MetaMask'}
                        
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({ethereumWalletModalOpen: false, EthereumWalletError: null})}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEthereumInfo() {
        return (
            <Dialog
                open={this.state.ethereumInfoOpen}
                onClose={() => this.setState({ethereumInfoOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach Ethereum wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-opensea-wallet-modal-content">
                    <Typography>
                    Link your
                    <b> <a className="home-page-link" href={'https://metamask.io/'} target="_">Metamask</a> </b>
                    if you wish to purchase with ETH to save Gas Fees
                    <br/>
                    1. Click "Attach Ethereum Wallet"<br/>
                    2. Click "Attach with 
                    <b> <a className="home-page-link" href={'https://metamask.io/'} target="_">Metamask</a></b>
                    "<br/>
                    3. Allow Etherfishing.com to read data<br/>    
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({ethereumInfoOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderEthereumError() {
        return (
            <div className='profile-opensea-upload-error'>
                {this.state.ethereumWalletError ? 
                <Alert severity='error'>
                    <AlertTitle>Ethereum error</AlertTitle>
                    {this.state.ethereumWalletError }
                </Alert> :
                null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        firestoreError: state.firestore.firestoreError,
        ethereumError: state.ethereum.ethereumError,
        ethereumUserData: state.ethereum.ethereumUserData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        attachUserEthereum: () => dispatch(attachUserEthereum()),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEthereumWallet)