import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home/Home';
import About from './About/About';
import Profile from './User/Profile/Profile';
import CreateAccount from './User/CreateAccount/CreateAccount';
import Login from './User/Login/Login';
import ForgotPassword from './User/ForgotPassword/ForgotPassword';
import ChangePassword from './User/ChangePassword/ChangePassword';
import FishTankPage from './Bowl/FishTankPage';
import Game from './Game/Game';
import Marketplace from './Marketplace/Marketplace';
import Help from './Help/Help';
import SpeciesAndTraits from './SpeciesAndTraits/SpeciesAndTraits';
import Fish from './Fish/Fish';
import WOF from './WheelOFish/wheelOFish';
import news from './News/News';
import Admin from './Admin/Admin';
import Fishdex from './Fish/Fishdex';

export default class Routes extends Component {
    render () {
        return (
            <Switch>
                <Route path='/' exact component={Home}/>
                {/*<Route path='/about' component={About}/>*/}
                <Route path='/help' component={Help}/>
                <Route path='/user/profile' component={Profile}/>
                <Route path='/user/create-account' component={CreateAccount}/>
                <Route path='/user/login' component={Login}/>
                <Route path='/user/forgot-password' component={ForgotPassword}/>
                <Route path='/user/change-password' component={ChangePassword}/>
                <Route path='/bowl' component={FishTankPage}/>
                {/*<Route path='/game' component={Game}/>
                <Route path='/marketplace' component={Marketplace}/>*/}
                <Route path='/species-and-traits' component={SpeciesAndTraits}/>
                <Route path='/fish/:id' component={Fish}/>
                <Route path='/fishdex' component={Fishdex}/>
                <Route path='/wheel-o-fish' component={WOF}/>
                <Route path='/news' component={news}/>
                <Route path='/adfin' component={Admin}/>
            </Switch>
        );
    }
}