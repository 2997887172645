const initState = {
    spin:0,
    spinning:0,
}

const wofReducer = (state = initState, action) => {
    switch(action.type) {
        case 'GET_SPIN_SUCCESS':
            return {
                ...state,
                spin:action.spin.data,
            }
        case 'GET_SPIN_ERROR':
            return {
                ...state,
                spin:0,
                spinning:0,
            }
        case 'START_SPIN':
            return {
                ...state,
                spinning:1,
                spin: 0
            }
        case 'END_SPIN':
            return {
                ...state,
                spinning:0
            }
        
        default:
            return state
    }
}

export default wofReducer