export const signIn = (creds) => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password)
        .then(() => {
            dispatch({type: 'LOGIN_SUCCESS'})
        })
        .catch((error) => {
            dispatch({type: 'LOGIN_ERROR', error})
        })
    }
}

export const signOut = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        await firebase.auth().signOut()
        .then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'})
        })
    }
}

export const createAccount = (info) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase()
        const firestore = getFirestore()

        await firebase.auth().createUserWithEmailAndPassword(info.email, info.password)
        .then((result) => {
            // Set username provided
            result.user.updateProfile({
                displayName: info.username
            })
            dispatch({type: 'CREATE_ACCOUNT_SUCCESS'})

            // Process referral code
            if (info.referralCode) {
                var referralFunction = firebase.functions().httpsCallable('processReferralCode');
                referralFunction({ newUid: result.user.uid, referralUid: info.referralCode })
            }

            firestore.collection('users').doc(result.user.uid).set({username: info.username, over21: info.over21, acceptedToS: info.acceptedToS}, {merge: true})

            result.user.sendEmailVerification()
        })
        .catch((error) => {
            dispatch({type: 'CREATE_ACCOUNT_ERROR', error})
        })
    }
}

export const forgotPassword = (email) => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        await firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            dispatch({type: 'FORGOT_PASSWORD_SUCCESS'})
        })
        .catch((error) => {
            dispatch({type: 'FORGOT_PASSWORD_ERROR', error})
        })
    }
}

export const changePassword = (newPassword) => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        await firebase.auth().currentUser.updatePassword(newPassword)
        .then(() => {
            dispatch({type: 'CHANGE_PASSWORD_SUCCESS'})
        })
        .catch((error) => {
            dispatch({type: 'CHANGE_PASSWORD_ERROR', error})
        })
    }
}

export const getCurrentUser = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()

        let currentUser = await firebase.auth().currentUser

        dispatch({type: 'GET_CURRENT_USER_SUCCESS', currentUser})
    }
}

export const sendVerificationEmail = () => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()

        await firebase.auth().currentUser.sendEmailVerification()
        .then(() => {
            dispatch({ type: 'SEND_VERIFICATION_EMAIL_SUCCESS' })
        })
        .catch((error) => {
            dispatch({ type: 'SEND_VERIFICATION_EMAIL_ERROR', error })
        })
    }
}

export const setOpenVerifyEmail = (verificationOpen) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'SET_VERIFY_EMAIL', verificationOpen })
    }
}