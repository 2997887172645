import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEnjinUser, getEnjinWallet, unlinkEnjinWallet } from '../../../store/actions/enjinActions';
import { updateUserInfo } from '../../../store/actions/firestoreActions';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import './ProfileEnjinWallet.css'
import { showSuccessSnackbar } from '../../../store/actions/snackbarActions';

class ProfileEnjinWallet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enjinWalletError: null,
            enjinWalletModalOpen: false,
            enjinVerifyAttachLoading:false,
            disableEnjinBtn:false,
            enjinWalletUnlinkModalOpen: false,
            disableUnlinkBtn: false,
            disableLinkBtn: false,
            enjinInfoOpen: false ,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCreateEnjinUser = this.handleCreateEnjinUser.bind(this)
        //this.handleGetEnjinWallet = this.handleGetEnjinWallet(this)
        //this.handleUnlinkEnjinWallet = this.handleUnlinkEnjinWallet(this)
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    async handleCreateEnjinUser() {
        this.setState({disableEnjinBtn: true})
        await this.props.addEnjinUser()
        //await this.props.getEnjinWallet();
        this.setState({enjinWalletModalOpen: true})
    }

    /*async handleGetEnjinWallet() {
        await this.props.getEnjinWallet();
        this.setState({enjinWalletModalOpen: false, enjinWalletError: null, disableEnjinBtn:false})
    }   

    async handleUnlinkEnjinWallet() {
        this.setState({disableUnlinkBtn: true, disableEnjinBtn: true})
        await this.props.unlinkEnjinWallet()
        this.setState({enjinWalletUnlinkModalOpen: false , enjinWalletModalOpen: true})
    }*/

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.props.userInfo?.enjinWalletAddress && this.props.userInfo?.enjinWalletAddress !== null ?
                        <div className="profile-enjin-wallet-address-container">
                            <Typography className="profile-enjin-wallet-address">
                                <u>Enjin</u>: {this.props.userInfo.enjinWalletAddress}
                            </Typography>
                            <IconButton 
                                disabled = {this.state.disableEnjinBtn}
                                onClick={() => this.setState({enjinWalletUnlinkModalOpen: true})} 
                                size="small"
                            >
                                <EditIcon size="small"/>
                            </IconButton>
                        </div>
                            :
                        <div className="profile-opensea-wallet-address-container">

                            <Button 
                                className="profile-enjin-wallet-button" 
                                color="primary"
                                variant="contained"
                                disabled =  {this.state.disableEnjinBtn}
                                onClick={this.handleCreateEnjinUser}
                            >
                                Attach Enjin Wallet
                            </Button>
                            <IconButton 
                            onClick={() => this.setState({enjinInfoOpen: true})}
                            color='primary'
                            className="profile-options-button"
                        >
                            <InfoIcon size="small"/>
                        </IconButton>
                    </div>
                    }
                    {this.renderEnjinModal()}
                    {this.renderUnlinkEnjinModal()}
                    {this.renderEnjinInfo()}
                </Grid>
            </Grid>
        )
    }

    renderEnjinModal() {
        return (
            <Dialog
                open={this.state.enjinWalletModalOpen}
                onClose={() => this.setState({enjinWalletModalOpen: false, enjinWalletError: null, disableEnjinBtn:false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach Enjin wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-enjin-wallet-modal-content">
                    <Typography className="profile-enjin-wallet-linkcode" variant="h6">
                       Link code: { this.props.enjinLinkCode }
                    </Typography>
                    <img
                        src={this.props.enjinLinkQr}
                        alt="QRcode"
                        className="profile-enjin-wallet-qr"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        className="profile-enjin-wallet-button"
                        disabled = {this.state.disableLinkBtn}
                        onClick={async () => {
                            this.setState({disableLinkBtn: true})
                            await this.props.getEnjinWallet();
                            this.setState({enjinWalletModalOpen: false, enjinWalletError: null, disableEnjinBtn:false, disableLinkBtn:false})
                        }}
                    >I linked to the game
                    </Button>
                    <a className="help-page-link" href={"https://www.youtube.com/watch?v=GTmCG4APkgk"} target="_">Need Help?</a>
                </DialogActions>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => {
                            this.setState({enjinWalletModalOpen: false, enjinWalletError: null, disableEnjinBtn:false});
                            this.props.getEnjinWallet();
                        }}
                    >Cancel
                    </Button>
                </DialogActions>
            </Dialog>    
        )
    }

    renderUnlinkEnjinModal() {
        return (
            <Dialog
                open={this.state.enjinWalletUnlinkModalOpen}
                onClose={() => this.setState({enjinWalletUnlinkModalOpen: false, enjinWalletError: null, enjinWalletModalOpen: false, disableUnlinkBtn: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Dettach Enjin wallet</DialogTitle>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        className="profile-enjin-wallet-button"
                        disabled = {this.state.disableUnlinkBtn}
                        onClick={async () => {
                            this.setState({disableUnlinkBtn: true, disableEnjinBtn: true})
                            await this.props.unlinkEnjinWallet()
                            this.setState({enjinWalletUnlinkModalOpen: false , enjinWalletModalOpen: false, disableEnjinBtn: false, disableUnlinkBtn: false})
                        }}
                    >Unlink Enjin Wallet
                    </Button>
                </DialogActions>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => {
                            this.setState({enjinWalletUnlinkModalOpen: false, enjinWalletError: null, disableEnjinBtn:false, disableUnlinkBtn: false}); 
                        }}
                    >Cancel
                    </Button>
                </DialogActions>
            </Dialog>    
        )
    }

    renderEnjinInfo() {
        return (
            <Dialog
                open={this.state.enjinInfoOpen}
                onClose={() => this.setState({enjinInfoOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach Enjin wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-opensea-wallet-modal-content">
                    <Typography>
                    Connect your Enjin wallet to play the game and recieve nfts    
                    <br/>
                    1. Download the 
                    <b> <a className="home-page-link" href={'https://enjin.io/'} target="_">Enjin Wallet App</a> </b>
                    to recieve your caught NFTs!<br/>
                    2. Safely and discreetly store your Seed Phrase <br/>
                    3. Click "Attach Enjin Wallet" (takes a seconed)<br/>
                    4. Go To Linked Projects in Enjin App and scan QR code<br/>
                    5. Click "I Attached my Wallet" to finish attaching<br/>
                    6. Verify your email to use the Free Pole<br/>
                    Happy Fishing!<br/>
                    <br/>
                    <b><a href="https://www.youtube.com/embed/QkFDzpD0bXY" target="_">Step-by-step Video</a></b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({enjinInfoOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        firestoreError: state.firestore.firestoreError,
        enjinLinkCode: state.firestore.linkingCode,
        enjinLinkQr: state.firestore.linkingCodeQr,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        addEnjinUser: () => dispatch(addEnjinUser()),
        getEnjinWallet: () => dispatch(getEnjinWallet()),
        unlinkEnjinWallet: () => dispatch(unlinkEnjinWallet()),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEnjinWallet)