import React, { Component } from 'react';
import p5 from "p5";
/*eslint-disable*/

import Bubbling_Surface from '../static/images/GameImages/Ripple3.gif';
import cloud1s from '../static/images/GameImages/Clouds/cloud1.png';
import cloud2s from '../static/images/GameImages/Clouds/cloud2.png';
import cloud3s from '../static/images/GameImages/Clouds/cloud3.png';
import cloud4s from '../static/images/GameImages/Clouds/cloud4.png';
import cloud5s from '../static/images/GameImages/Clouds/cloud1.png';
import boat1s from '../static/images/GameImages/Vehicles/PirateShip1.png';
import boat2s from '../static/images/GameImages/Vehicles/PirateShip2.png';
import StartingBoat from '../static/images/GameImages/boat.png';
import LoadScene from '../static/images/GameImages/GameBackground/Loading.png';

import Fishing_Pole from '../static/images/Poles/starting.png';
import F2PPole from '../static/images/Poles/free.png'
import bob from '../static/images/GameImages/bobber.png';

import minusOneImg from '../static/images/GameImages/-1.png';
import plusOneImg from '../static/images/GameImages/+1.png';
import oofImg from '../static/images/GameImages/OOOF.png';
import niceImg from '../static/images/GameImages/NICE.png';

import chooseWhere from '../static/images/GameImages/ChooseWhere.png';
import downArrow from '../static/images/GameImages/ArrowDown.png';

import nomBotImg from '../static/images/GameImages/NOMB.png';
import nomLeftImg from '../static/images/GameImages/NOML.png';
import nomRightImg from '../static/images/GameImages/NOMR.png';
import gotAwayImg from '../static/images/GameImages/smallGOTAWAY.png';
import bamImg from '../static/images/GameImages/BAM.png';
import hookedImg from '../static/images/GameImages/ITSHOOKED.png';
import fwooshImg from '../static/images/GameImages/FWOOSH.png';

import {
    setDisplayReward,
    setFailedToCatch,
    setGameStarted,
    setHasCast,
    setHasHooked,
    setTapCount,
    setTime
} from '../store/actions/gameActions';
import { swapSong } from '../store/actions/audioActions';
import Splashing_Left from '../static/images/GameImages/Splash_Left.gif';
import Splash_Right from '../static/images/GameImages/Splash_Right.gif';
import Splash_Center from '../static/images/GameImages/Splash_Center.gif';

import { connect } from 'react-redux';

let size = 0.5
let bubble, bobber
let splashUp, splashRight, splashLeft
let minusOne, plusOne
let oof, nice
let centerBoxes = [] //the center of each hit box
let canvasBG //game itself
let popUpsHooked = [] //Array of PopUPnumbers
let popUps = [] //Array of PopUPnumbers
let endofpoleX
let endofpoleY
const BOBBER_OFFSET = 0
const BOBBER_FALL_SPEED = 65
const BOBBER_SCALE = 4
let fwoosh, bam, itsHooked, gotAway, nomB, nomL, nomR
const loadBoats = false //imagess that go across the screen
let BG

class centerPop {
    constructor(pt, text, position) {
        this.p = pt
        this.reset = 0
        this.fadeOut = 255
        this.fadeRate = 15
        this.text = text
        this.startFade = 700

        this.ticks = 0
        this.startTick = 0

        this.img = fwoosh
        this.size = 3
        this.x = centerBoxes[position *2]
        this.y = (canvasBG.height / 10) * 5
        if(text === "nomB") {
            this.img = nomB
            this.startFade = 100
            this.fadeRate = 25
            this.size = 2.4
            this.y = canvasBG.height * 143/256
        } else if(text === "nomL") {
            this.img = nomL
            this.startFade = 100
            this.fadeRate = 25
            this.startTick = 3
            this.size = 2.4
            this.y = canvasBG.height * 143/256
        } else if(text === "nomR") {
            this.img = nomR
            this.startFade = 100
            this.fadeRate = 25
            this.size = 2.4
            this.startTick = 6
            this.y = canvasBG.height * 143/256
        } else if(text === "It's Hooked!") {
            this.img = itsHooked
            this.startTick = 1
            //this.x = canvasBG.width * 7 / 10
        } else if(text === "bam") {
            this.img = bam
            this.x = canvasBG.width * 3 / 10
        } else if(text === "Got Away...") {
            this.img = gotAway
            this.size = 4
            this.y = canvasBG.height/4 //* 17/32
        } else {
            this.size = 4
            this.startFade = 1000
        }
        this.imgWidth = this.img.width * size * this.size
        this.imgHeight = this.img.height * size * this.size
        this.x -= this.imgWidth / 2
        this.y -= this.imgHeight / 2

        this.startTime = Date.now()
        this.started = false
    }
    
    getStart() {
        return this.startTime
    }

    fade() {
        if(this.startTime + this.startFade < Date.now())
            this.fadeOut -= this.fadeRate
    }

    setPosition(position) {
        this.x = centerBoxes[position*2]
    }

    display() {
        this.p.push()
        this.p.tint(255, this.fadeOut)
        if(this.ticks >= this.startTick) {
            if(this.started === false) {
                this.startTime = Date.now()
                this.started = true
            }
            this.p.image(this.img, this.x, this.y, this.imgWidth, this.imgHeight)
            this.fade()
        } 
        this.ticks += 1
        this.p.pop()
    }

    res() {
        this.fadeOut = 255
    }

}

class plusOneAni {
    constructor(pt, xt, yt, text, start) {
        this.p = pt
        this.x = xt
        this.y = yt
        this.up = 0
        this.reset = 0
        this.fontSize = canvasBG.width / 20
        this.fadeOut = 255
        this.text = text

        if(text === '+1') {
            this.img = plusOne
        } else {
            this.img = minusOne
        }
        let size = 3
        this.imgWidth = this.img.width * size
        this.imgHeight = this.img.height * size

        this.start = start
    }
    
    move() {
        if (this.fadeOut < 0) {
            this.reset = 1
        }
        else {
            this.up -= 2
            this.fadeOut -= 4
        }
    }

    getStart() {
        return this.start
    }

    display() {
        this.p.push()

        if (this.reset == 0)
            this.p.tint(255, this.fadeOut)

        if (this.fadeOut < 10) {
            this.p.pop()
            return;
        }
        this.p.image(this.img,this.x, this.y + (this.up), this.imgWidth * size, this.imgHeight * size)
        this.move()
        this.p.pop()
    }
    res() {
        this.up = 0
        this.fadeOut = 255
        this.reset = 0
    }

}

class plusOnePop {
    constructor(pt, xt, yt, text, start) {
        this.p = pt
        this.x = xt
        this.y = canvasBG.height/4
        this.fadeOut = 255
        this.text = text

        if(text === '+1') {
            this.pop = nice
        } else {
            this.pop = oof
        }
        this.popWidth = this.pop.width * 3 * size
        this.popHeight = this.pop.height * 3 * size

        const sectionWidth = canvasBG.width / 5
        if(this.x < sectionWidth) {
            this.x = sectionWidth / 2
        } else if(this.x < sectionWidth * 2) {
            this.x = sectionWidth * 1 + sectionWidth / 2
        } else if(this.x < sectionWidth * 3) {
            this.x = sectionWidth * 2 + sectionWidth / 2
        } else if(this.x < sectionWidth * 4) {
            this.x = sectionWidth * 3 + sectionWidth / 2
        } else if(this.x < sectionWidth * 5) {
            this.x = sectionWidth * 4 + sectionWidth / 2
        }
        this.x -= this.popWidth / 2
        this.y -= this.popHeight / 2

        this.start = start
    }
    

    getStart() {
        return this.start
    }

    fade() {
        this.fadeOut -= 6
    }

    animate() {
        this.fade()
    }

    display() {
        this.p.push()

        if (this.fadeOut < 10) {
            this.p.pop()
            return;
        }
        this.p.tint(255, this.fadeOut),
        this.p.image(this.pop,this.x, this.y, this.popWidth, this.popHeight)
        this.animate()
        this.p.pop()
    }

    res() {
        this.fadeOut = 255
    }

}

class splashObj {
    constructor(p, startX, startY, seconds) {
        this.p = p
        this.x = startX
        this.y = startY
        this.moveX = centerBoxes[4]
        this.moveY = startY
        this.seconds = seconds
        this.xspeed = this.calculateSpeed()
        this.yspeed = 4
        this.splashSize = 2
        this.moves = 0 //0 splash//1 SplashRight //2 SplashLeft //3 bubble //4 Bobber
        this.s = 0
        this.a = p.random(0, 0.5)
        this.casts = 0
        this.location = 2
        this.frame = 0
        this.framespeed = 4;
    }

    calculateSpeed() {
        return (1 / this.p.frameRate()) * (canvasBG.width / this.seconds)
    }

    moveTo(mX, mY) {
        this.moveX = mX
        this.moveY = mY
    }

    setMove(mMove) {
        this.moves = mMove
    }

    posMove(pM) {
        this.moveX = centerBoxes[pM * 2]
    }

    posSet(pM) {
        this.x = centerBoxes[pM * 2]
        this.posMove(pM)
    }

    cast() {
        this.y = 0
        this.casts = 1
    }

    checkSplashLocation() {
        if (this.x < (canvasBG.width / 5) && this.x > (canvasBG.width / 5 * 0)) {
            this.location = 0
            return 0
        }
        if (this.x < (canvasBG.width / 5 * 2) && this.x > (canvasBG.width / 5 * 1)) {
            this.location = 1
            return 1
        }
        if (this.x < (canvasBG.width / 5 * 3) && this.x > (canvasBG.width / 5 * 2)) {
            this.location = 2
            return 2
        }

        if (this.x < (canvasBG.width / 5 * 4) && this.x > (canvasBG.width / 5 * 3)) {
            this.location = 3
            return 3
        }
        if (this.x < (canvasBG.width / 5 * 5) && this.x > (canvasBG.width / 5 * 4)) {
            this.location = 4
            return 4
        }
    }

    isNotMoving() {
        if (this.moves === 0) {
            return true
        }
        return false
    }

    move() {
        this.xspeed = this.calculateSpeed()
        this.a += .05
        this.s = Math.sin(this.a)

        if (this.moveX > this.x && (this.moveX - this.x) > this.xspeed) {
            this.x = this.x + this.xspeed
            this.moves = 1 //Right
        } else if (this.moveX < this.x && (this.x - this.moveX) > this.xspeed) {
            this.x = this.x - this.xspeed
            this.moves = 2 //left
        } else if (this.moves === 4) { //Bobbing Animations
            this.y = this.y + (this.s * .20)
        } else if (this.moves === 5) {//from down to height/2
            if (this.casts === 0) {
                this.cast()
            } else if (this.y > (canvasBG.height / 20) * 11) {
                this.casts = 0
                this.moves = 4
            } else {
                this.y += canvasBG.height / BOBBER_FALL_SPEED
            }
        } else if (this.moves === 3 || this.moves === 7) {
            //don't move
        } else {
            this.moves = 0
        }
    }

    gifPlaying(gifImg) {
        gifImg.pause();
        let maxFrame = (gifImg.numFrames() - 1) * this.framespeed
        let realFrame = this.framespeed % this.frame
        this.frame = this.frame + 1
        if (maxFrame < this.frame) { this.frame = 0 }
        gifImg.setFrame(this.p.floor(this.frame / this.framespeed));
        this.p.image(gifImg, this.x, this.y, gifImg.width * size * this.splashSize, gifImg.height * size * this.splashSize)
    }
    display() {
        this.p.push()
        this.p.imageMode(this.p.CENTER)

        if (this.moves === 1) {
            this.p.tint(255,127)
            this.gifPlaying(splashRight)
        }
        else if (this.moves === 2) {
            this.p.tint(255,127)
            this.gifPlaying(splashLeft);
        }
        else if (this.moves === 3) {
            this.p.tint(255,127)
            //this.gifPlaying(bubble);
            this.p.image(bubble, this.x, this.y, bubble.width * size * this.splashSize, bubble.height * size * this.splashSize)

        }
        else if (this.moves === 4 || this.moves === 5) {
            this.p.image(bobber, this.x, this.y + BOBBER_OFFSET, (bobber.width * size * this.splashSize) / BOBBER_SCALE, (bobber.height * size * this.splashSize) / BOBBER_SCALE)
            bubble.reset()
        }
        else if (this.moves === 7) {
            //Dont Draw anything
        }
        else if (this.moves === 0) {
            this.p.tint(255,127)
            this.gifPlaying(splashUp);
        }
        this.p.pop()
    }
}

class cloud {
    constructor(p, cloudImg, cloudX, cloudY, speed) {
        this.p = p
        this.x = cloudX
        this.y = cloudY * .8
        this.xspeed = speed * .2
        this.yspeed = 1
        this.cloudImg = cloudImg
        this.cloudImgWidth = this.cloudImg.width * 6
        this.cloudImgHeight = this.cloudImg.height * 4
    }

    move() {
        this.x = this.x + this.xspeed * .7
        if (this.x > canvasBG.width + 5) {
            this.x = -this.cloudImgWidth * size - 5
        }
    }
    display() {
        this.p.image(this.cloudImg, this.x, this.y, this.cloudImgWidth * size, this.cloudImgHeight * size)
    }
}

class vehicle {
    constructor(p, img, x, y, speed) {
        this.p = p
        this.x = x
        this.y = y * .8
        this.xspeed = speed * .2
        this.img = img
        this.imgWidth = this.img.width * 4
        this.imgHeight = this.img.height * 4
    }

    move() {
        this.x = this.x + this.xspeed * .7
        if (this.x > canvasBG.width + 5) {
            this.x = -this.imgWidth * size - 5
        }
    }
    display() {
        this.p.image(this.img, this.x, this.y, this.imgWidth * size, this.imgHeight * size)
    }
}

class timedBox {
    constructor(p, X, Y) {
        this.p = p
        this.mouseHold = false
        this.x = X
        this.y = Y
        this.div = 5
        this.width = canvasBG.width / this.div
        this.height = canvasBG.height
        this.overBox = true
        this.hold = false
        this.time = 0.0
        this.Pressed = true
        //  if(this.x+(window.innerWidth/10)>20){
        centerBoxes.push(this.x + (this.width / 2), this.y)
        //  }
    }

    setWidth(w) {
        this.width = w
    }

    setHeight(h) {
        this.height = h
    }

    setSplash(posX, posY) {
        this.x = posX
        this.y = posY
    }

    ifOver() {
        if (
            this.p.mouseX > this.x &&
            this.p.mouseX < this.x + this.width &&
            this.p.mouseY > this.y &&
            this.p.mouseY < this.y + this.height
        ) {
            this.overBox = true

            if (!this.hold) {
                this.p.stroke(255)
                this.p.strokeWeight(2)
                this.p.fill(244, 122, 158, 0)
                this.hold = false
                this.time = this.p.millis() + 10000
                this.Pressed = true
            }
            else {
                this.p.stroke(52, 101, 176)
                this.p.strokeWeight(2)
                this.p.fill(255, 255, 255, 0)
                this.hold = true
                this.Pressed = false
            }

        } else {
            this.p.stroke(52, 101, 176, 25)
            this.p.strokeWeight(2)
            this.p.fill(244, 122, 158, 0)
            this.time = 0
            this.overBox = false
            this.hold = false
        }
    }

    setMouseHold(held) {
        this.hold = held
    }

    isPressed() {
        if (this.hold === true && this.overBox === true) {
            return true
        }
        return false
    }

    display() {
        this.ifOver()
        this.p.rect(this.x, this.y, this.width, this.height)
    }
}
class GameRender extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clicked: false,
        }

        this.myRef = React.createRef()
    }

    Sketch = (p) => {
        //image renders
        let clouds = []
        let boxes = []
        
        let cloud1, cloud2, cloud3, cloud4, cloud5, boat1, boat2
        let splash
        let arrow
        let chooseText

        let displayChoose = true

        //game variables
        const BOX_COUNT = 5
        const MAX_TAPS = 10
        let tapCount = 0
        this.props.setTapCount(tapCount)
        let fishLocation = 2
        let prevFishLocation = -1
        let castLocation = 2
        let nextCastLocationChangeTime = 0
        let nextTapTime = 0

        //flags
        let bubbling = false
        let casted = false
        let hooked = false

        //timers
        let endTime = 0
        let wobbleTime = 0
        let bubbleTime = 0

        let firstDraw = true
        let pressed = false//this is used I promise 672 uder boxes setmouseHold

        let stringColor = '#d7df23'
        //time constants
        const GAME_TIME_LIMIT = 15000
        const CAST_DELAY = 1000
        const BASE_WOBBLE_TIME = 3000
        const RAND_WOBBLE_TIME = 7000
        const BASE_BUBBLE_TIME = 1500
        const CHOOSE_DELAY = 200

        const handleButtonPress = (buttonNumber) => {
            if (boxes[buttonNumber].isPressed() === true) {
                return true
            }
            return false
        }

        p.preload = () => {
            BG = p.loadImage(LoadScene)
            bobber = p.loadImage(bob)
            cloud1 = p.loadImage(cloud1s)
            cloud2 = p.loadImage(cloud2s)
            cloud3 = p.loadImage(cloud3s)
            cloud4 = p.loadImage(cloud4s)
            cloud5 = p.loadImage(cloud5s)
            if(loadBoats){
                boat1 = p.loadImage(boat1s)
                boat2 = p.loadImage(boat2s)
            }

            minusOne = p.loadImage(minusOneImg)
            plusOne = p.loadImage(plusOneImg)
            oof = p.loadImage(oofImg)
            nice = p.loadImage(niceImg)

            nomB = p.loadImage(nomBotImg)
            nomL = p.loadImage(nomLeftImg)
            nomR = p.loadImage(nomRightImg)

            arrow = p.loadImage(downArrow)
            chooseText = p.loadImage(chooseWhere)
            
            gotAway = p.loadImage(gotAwayImg)
            itsHooked = p.loadImage(hookedImg)
            bam = p.loadImage(bamImg)
            fwoosh = p.loadImage(fwooshImg)

            bubble = p.loadImage(Bubbling_Surface)
            splashUp = p.loadImage(Splash_Center)
            splashLeft = p.loadImage(Splashing_Left)
            splashRight = p.loadImage(Splash_Right)
        }

        p.tapCounterDisplay = () => {
            let timeRemaining = 0
            if (endTime !== 0) {
                timeRemaining = Math.round((endTime - Date.now()) / 100) / 10
                this.props.setHasHooked(true)
            }
            this.props.setTime(timeRemaining)
        }

        p.setup = () => {
            reset()
        }

        const reset = () => {
            // SETUP code here
            canvasBG = null
            boxes = []
            splash = null
            clouds = []
            firstDraw = true
            centerBoxes = []

            let xStart = 0
            let aspect = BG.height / BG.width; //0.5625
            let windowAspect = window.innerHeight / window.innerWidth
            if (aspect > windowAspect) {
                canvasBG = p.createCanvas(window.innerHeight * (1 / aspect), window.innerHeight);
            }
            else {
                canvasBG = p.createCanvas(window.innerWidth, window.innerWidth * (aspect));
            }

            for (let i = 0; i < BOX_COUNT; i++) {
                boxes[i] = new timedBox(p, xStart, 0)
                xStart += canvasBG.width / BOX_COUNT
            }
            splash = new splashObj(p, canvasBG.width / 2, canvasBG.height / 2, 1.5);
            //clouds.push(new cloud(p, cloud1, 300, (canvasBG.height / 50) * 2, 1.75))
            //clouds.push(new cloud(p, cloud2, -200, (canvasBG.height / 50) * 1, 0.8))
            //clouds.push(new cloud(p, cloud3, 900, (canvasBG.height / 50) * 3, 1))
            //clouds.push(new cloud(p, cloud4, 500, (canvasBG.height / 50) * 1, 1.3))
            //clouds.push(new cloud(p, cloud5, 200, (canvasBG.height / 50) * 2, 1))
            if(loadBoats) {
                clouds.push(new vehicle(p, boat1, -200, (canvasBG.height / 5), .8))
                clouds.push(new vehicle(p, boat2, -600, (canvasBG.height / 4.9), .85))
            }

            this.pole = this.myP5.loadImage(Fishing_Pole) // set starting pole image until other image loads
            this.setPoleImage()

            this.boat = this.myP5.loadImage(StartingBoat)
            this.setBoatImage()

            this.setBackgroundImage()

            size = canvasBG.height/5500
        }


        p.draw = () => {
            p.background(BG);
            for (let i = 0; i < clouds.length; i++) {
                clouds[i].move()
                clouds[i].display()
            }

            splash.move()
            if (firstDraw) {
                splash.posSet(2)
                splash.setMove(7)
                firstDraw = false
            }
            splash.display()

            p.image(this.boat, p.width / 2 - ((this.boat.width * size * 4) / 2), p.height + 20, this.boat.width * size * 4, -this.boat.height * size * 4);
            p.drawPole(this.pole);

            p.tapCounterDisplay()
            //display hit box
            p.push()
            for (let i = 0; i < BOX_COUNT; i++) {
                boxes[i].display()
            }
            p.pop()
            //display text
            for (let i = 0; i < popUps.length; i++) {
                popUps[i].display();
                if (Date.now() - popUps[i].getStart() > 1400 || hooked) (
                    popUps.splice(i, 1)
                )
            }
            //display text
            for (let i = 0; i < popUpsHooked.length; i++) {
                popUpsHooked[i].display();
                if (Date.now() - popUpsHooked[i].getStart() > 1400) (
                    popUpsHooked.splice(i, 1)
                )
            }

            if (this.props.hasCast) {
                if (!hooked) {
                    this.props.swapSong('Adventure')
                    if (!casted) {
                        displayChoose = true
                        popUps.push(new centerPop(p, "fwoosh", 2))
                        splash.setMove(5)
                        casted = true
                        wobbleTime = Math.floor(Math.random() * RAND_WOBBLE_TIME) + BASE_WOBBLE_TIME + CAST_DELAY + Date.now()
                    }
                    p.drawline(stringColor, 0)
                    if (bubbleTime < Date.now() && bubbling) {
                        popUps.push(new centerPop(p, "Got Away...", fishLocation))
                        bubbling = false
                        splash.setMove(4)
                        wobbleTime = Math.floor(Math.random() * RAND_WOBBLE_TIME) + BASE_WOBBLE_TIME + Date.now()
                    }

                    if (wobbleTime < Date.now()) {
                        if (!bubbling) {
                            popUps.push(new centerPop(p, "nomB", fishLocation))
                            popUps.push(new centerPop(p, "nomL", fishLocation))
                            popUps.push(new centerPop(p, "nomR", fishLocation))
                            bubbling = true
                            splash.setMove(3)
                            bubbleTime = Date.now() + BASE_BUBBLE_TIME
                        }
                        hooked = handleButtonPress(fishLocation)
                        //p.drawline(stringColor,1)
                    }

                    if (hooked) {
                        popUpsHooked.push(new centerPop(p, "It's Hooked!", 2))
                        //popUps.push(new centerPop(p, "bam"))
                        prevFishLocation = fishLocation
                        fishLocation = (fishLocation + (Math.floor(Math.random() * 4)) + 1) % 5
                        splash.posMove(fishLocation)
                        casted = false
                        splash.setMove(0)
                        endTime = Date.now() + GAME_TIME_LIMIT
                    }
                }

                if (hooked) {
                    p.drawline(stringColor, 2)
                    let correctTap = false
                    let incorrectTap = false

                    if (Date.now() < endTime) {
                        //Button listner
                        if (Date.now() > endTime - (GAME_TIME_LIMIT - 500) && (splash.checkSplashLocation() !== prevFishLocation || splash.isNotMoving())) {
                            correctTap = handleButtonPress(fishLocation)
                            if (!correctTap) {
                                if (nextTapTime < Date.now()) {
                                    for (let i = 1; i < BOX_COUNT; i++) {
                                        incorrectTap = handleButtonPress((fishLocation + i) % 5)
                                        if (incorrectTap) {
                                            break
                                        }
                                    }
                                }
                            }

                            //incorrect button tap delay
                            if (correctTap || incorrectTap) {
                                nextTapTime = Date.now() + 500
                            }
                        }


                        if (correctTap) {
                            popUpsHooked.push(new plusOneAni(p, p.mouseX, p.mouseY, "+1", Date.now()))
                            popUpsHooked.push(new plusOnePop(p, p.mouseX, p.mouseY, "+1", Date.now()))
                            prevFishLocation = fishLocation
                            fishLocation = (fishLocation + (Math.floor(Math.random() * 4)) + 1) % 5
                            splash.posMove(fishLocation)
                            tapCount += 1
                            this.props.setTapCount(tapCount)
                        } else if (incorrectTap) {
                            popUpsHooked.push(new plusOneAni(p, p.mouseX, p.mouseY, "-1", Date.now()))
                            popUpsHooked.push(new plusOnePop(p, p.mouseX, p.mouseY, "-1", Date.now()))
                            prevFishLocation = fishLocation
                            fishLocation = Math.floor(Math.random() * 5)
                            splash.posMove(fishLocation)
                            if (tapCount > 0) {
                                tapCount -= 1
                                this.props.setTapCount(tapCount)
                            }
                        }

                        //exit condition 1
                        if (tapCount === MAX_TAPS) {
                            this.props.setDisplayReward(true)
                            this.props.setHasCast(false)
                            this.props.setHasHooked(false)
                            endTime = 0
                            tapCount = 0
                            this.props.setTapCount(tapCount)
                            fishLocation = 2
                            prevFishLocation = -1
                            hooked = false
                            firstDraw = true
                            wobbleTime = 0
                            bubbleTime = 0
                            casted = false
                            bubbling = false
                            this.props.swapSong('Lounge')
                        }
                        //exit condition 2  
                    } else {
                        // Ran out of time
                        this.props.setHasCast(false)
                        this.props.setFailedToCatch(true)
                        this.props.setHasHooked(false)
                        endTime = 0
                        tapCount = 0
                        this.props.setTapCount(tapCount)
                        fishLocation = 2
                        prevFishLocation = -1
                        hooked = false
                        firstDraw = true
                        wobbleTime = 0
                        bubbleTime = 0
                        casted = false
                        bubbling = false
                        this.props.swapSong('Lounge')
                    }

                }
            } else {
                if(nextCastLocationChangeTime < Date.now()) {
                    if(castLocation != fishLocation) {
                        prevFishLocation = fishLocation
                        fishLocation = castLocation
                        splash.posSet(fishLocation)
                        displayChoose = false
                    }

                    for(let i = 0; i < 5; i++) {
                        if(handleButtonPress(i)) {
                            castLocation = i
                            nextCastLocationChangeTime = Date.now() + CHOOSE_DELAY
                        }
                    }
                }
                p.image(arrow, canvasBG.width * 3/40 + (canvasBG.width/5 * fishLocation), canvasBG.height/20, canvasBG.width/20 , canvasBG.height/15 )
                if(displayChoose) {
                    p.image(chooseText, canvasBG.width/2 - canvasBG.width/ 3 / 2, canvasBG.height/3, canvasBG.width/3 , canvasBG.height/10)
                }
            }
        }

        p.mousePressed = () => {
            for (let i = 0; i < BOX_COUNT; i++) {
                if (boxes[i]) {
                    boxes[i].setMouseHold(true)
                }
            }
            pressed = true;

        }

        p.drawline = (color, type) => {
            p.push()
            p.stroke(color)
            if (type == 0) {//bobbber
                p.line(endofpoleX, endofpoleY, splash.x, (splash.y) * .99)
            }
            else if (type == 1) {//bubble
                p.line(endofpoleX, endofpoleY, splash.x, (splash.y))
            }
            else if (type == 2) {//splash
                p.line(endofpoleX, endofpoleY, splash.x, (splash.y) * 1.15)
            }
            p.pop()
        }
        p.mouseReleased = () => {
            for (let i = 0; i < BOX_COUNT; i++) {
                if (boxes[i]) {
                    boxes[i].setMouseHold(false)
                }
            }
            pressed = false;
        }

        p.drawPole = (poleIMG) => {
            p.push()
            p.translate(p.width / 2, p.height)
            let rotation = p.asin(p.dist(p.mouseX, p.mouseY, p.width / 2, p.mouseY) / p.dist(p.mouseX, p.mouseY, p.width / 2, p.height))
            if (p.mouseX < p.width / 2) rotation = 2 * p.PI - rotation
            p.rotate(rotation)
            p.image(poleIMG, -((poleIMG.width * size))/0.8, (poleIMG.height * size) * .05, (poleIMG.width * size) * 2.5, (-poleIMG.height * size) * 2.5)
            p.pop()
            let tempLengthPole = 2.36
            endofpoleX = p.width / 2 - (((poleIMG.height * size) * tempLengthPole) * p.sin(rotation)) * -1
            endofpoleY = p.height - (((poleIMG.height * size) * tempLengthPole) * p.cos(rotation))
        }

        p.windowResized = () => {
            reset()
        }

    }

    setPoleImage() {
        if (this.props.userCurrentPole && this.props.userCurrentPole !== 'starting') {
            if (this.props.userCurrentPole === 'free') {
                this.myP5.loadImage(F2PPole, (result) => {
                    this.pole = result
                })
            } else if (this.props.userPoles && this.props.userPoles?.some((pole) => pole.poleNum === this.props.userCurrentPole)) {
                const poleImage = this.props.userPoles?.find((pole) => pole.poleNum === this.props.userCurrentPole)?.localImageUrl
                this.myP5.loadImage(poleImage, (result) => {
                    this.pole = result
                })
            }
        } else {
            this.myP5.loadImage(Fishing_Pole, (result) => {
                this.pole = result
            })
        }
    }

    setBoatImage() {
        if (this.props.userCurrentBoat && this.props.userCurrentBoat !== 'starting') {
            if (this.props.userBoats && this.props.userBoats?.some((boat) => boat.boatNum === this.props.userCurrentBoat)) {
                const boatImage = this.props.userBoats?.find((boat) => boat.boatNum === this.props.userCurrentBoat)?.localImageUrl
                this.myP5.loadImage(boatImage, (result) => {
                    this.boat = result
                })
            }
        } else {
            this.myP5.loadImage(StartingBoat, (result) => {
                this.boat = result
            })
        }
    }

    setBackgroundImage() {
        if(this.props?.locationInfo?.imageUrl) {
            this.myP5.loadImage(this.props.locationInfo.imageUrl, (result) => {
                BG = result
            })
        }
    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current)
        this.props.setGameStarted(true)
    }

    componentWillUnmount() {
        this.myP5.remove()
        this.props.setGameStarted(false)
        this.props.setHasCast(false)
        this.props.setHasHooked(false)
        this.props.setTapCount(0)
        this.props.setTime(0)
    }

    async componentDidUpdate(prevProps) {
        if (this.props.userCurrentPole !== prevProps.userCurrentPole) {
            this.setPoleImage()
        }

        if (this.props.userCurrentBoat !== prevProps.userCurrentBoat || JSON.stringify(this.props.userBoats) !== JSON.stringify(prevProps.userBoats)) {
            this.setBoatImage()
        }

        if (this.props.locationInfo !== prevProps.locationInfo || JSON.stringify(this.props.locationInfo) !== JSON.stringify(prevProps.locationInfo)) {
            this.setBackgroundImage()
        }
    }

    render() {
        return (
            <div ref={this.myRef} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        userPoles: state.firestore.userPoles,
        userBoats: state.firestore.userBoats,
        hasCast: state.game.hasCast,
        displayReward: state.game.displayReward,
        userCurrentPole: state.game.userCurrentPole,
        userCurrentBoat: state.game.userCurrentBoat,
        locationInfo: state.game.locationInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setHasCast: (hasCast) => dispatch(setHasCast(hasCast)),
        setGameStarted: (gameStarted) => dispatch(setGameStarted(gameStarted)),
        setDisplayReward: (displayReward) => dispatch(setDisplayReward(displayReward)),
        setFailedToCatch: (failedToCatch) => dispatch(setFailedToCatch(failedToCatch)),
        setTapCount: (tapCount) => dispatch(setTapCount(tapCount)),
        setTime: (time) => dispatch(setTime(time)),
        setHasHooked: (hasHooked) => dispatch(setHasHooked(hasHooked)),
        swapSong: (songName) => dispatch(swapSong(songName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameRender)