const initState = {
    firestoreError: null,
    userInfo: null,
    userInfoError: null,
    userFish: [],
    userBackgrounds: [],
    userTrophies: [],
    updateUserFishError: null,
    userTheme: window.localStorage.getItem('theme') ?? 'light',
    userPlayMusic: window.localStorage.getItem('playMusic') ? window.localStorage.getItem('playMusic') === 'true' : true,
    userPoles: null,
    userPolesUpdated: false,
    userBoats: null,
    linkingCode: null,
    linkingCodeQr: null,
    status: 'None',
    currentFish: null,
    currentFishError: null,
    condensedLayout: window.localStorage.getItem('condensedLayout') ? window.localStorage.getItem('condensedLayout') === 'true' : false,
    marketBackgrounds: null,
    marketBoats: null,
    marketBundles: null,
    marketPoles: null,
    marketConversionRate: null,
    homeFirstTimePopup: false,
    gameFirstTimePopup: false,
    tankFirstTimePopup: false,
    marketFirstTimePopup: false,
    aboutFirstTimePopup: false,
    profileFirstTimePopup: false,
    currentLeaderboard:new Array(5).fill(0),
    overallLeaderboard:new Array(5).fill(0),
    spin:-1,
}

const firestoreReducer = (state = initState, action) => {
    switch(action.type) {
        case 'SET_USER_INFO_ERROR':
            return {
                ...state,
                firestoreError: action.error.message
            }
        case 'SET_USER_INFO_SUCCESS':
            return { 
                ...state,
                firestoreError: null
            }
        case 'SET_USER_MUSIC_SUCCESS':
            return { 
                ...state,
                userPlayMusic: action.playMusic
            }
        case 'GET_USER_POLES_SUCCESS':
            return {
                ...state,
                userPoles: action.poles,
            }
        case 'GET_USER_BOATS_SUCCESS':
            return {
                ...state,
                userBoats: action.boats,
            }
        case 'GET_USER_INFO_ERROR':
            return {
                ...state,
                userInfo: action.info,
                userInfoError: action.error.message
            }
        case 'GET_USER_INFO_SUCCESS':
            return {
                ...state,
                userInfo: action.info,
                userInfoError: null,
            }
        case 'GET_USER_INFO_FISH_SUCCESS':
            return {
                ...state,
                userInfo: action.info,
                userFish: action.fish
            }
        case 'GET_USER_INFO_BACKGROUND_SUCCESS':
            return {
                ...state,
                userInfo: action.info,
                userBackgrounds: action.backgrounds,
            }
        case 'GET_USER_INFO_FISH_BACKGROUND_SUCCESS':
            return {
            ...state,
                userInfo: action.info,
                userFish: action.fish,
                userBackgrounds: action.backgrounds,
                userTrophies: action.trophies,
            }
        case 'UPDATE_USER_FISH_SUCCESS':
            return {
                ...state,
                userFish: action.fish
            }
        case 'UPDATE_USER_FISH_ERROR':
            return {
                ...state,
                updateUserFishError: action.error
            }
        case "CLEAR_USER_INFO":
            return initState
        case 'SET_USER_THEME_SUCCESS':
            return {
                ...state,
                userTheme: action.theme
            }
        case 'ENJIN_LINK_DATA':
            return {
                ...state,
                linkingCode:action.linkingCode,
                linkingCodeQr:action.linkingCodeQr
            }
        case 'ENJIN_TRANSACTION_STATUS':
            return {
                ...state,
                status:action.status
            }
        case 'SET_USER_POLES_UPDATED':
            return {
                ...state,
                userPolesUpdated: action.polesUpdated
            }
        case 'GET_FISH_SUCCESS':
            return {
                ...state,
                currentFish: action.fishData,
                currentFishError: null
            }
        case 'GET_FISH_ERROR':
            return {
                ...state,
                currentFishError: action.error.message
            }
        case 'UNSET_FISH':
            return {
                ...state,
                currentFish: null,
                currentFishError: null,
            }
        case 'SET_CONDENSED_LAYOUT':
            return {
                ...state,
                condensedLayout: action.isCondensed
            }
        case 'GET_MARKET_INFO_SUCCESS':
            return {
                ...state,
                marketBoats: action.marketBoats,
                marketBundles: action.marketBundles,
                marketPoles: action.marketPoles,
                marketBackgrounds: action.marketBackgrounds,
                marketConversionRate: action.marketConversionRate
            }
        case 'HOME_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                homeFirstTimePopup: action.newVal
            }
        case 'GAME_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                gameFirstTimePopup: action.newVal
            }
        case 'TANK_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                tankFirstTimePopup: action.newVal
            }
        case 'MARKET_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                marketFirstTimePopup: action.newVal
            }
        case 'ABOUT_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                aboutFirstTimePopup: action.newVal
            }
        case 'PROFILE_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                profileFirstTimePopup: action.newVal
            }
        case 'ALL_FIRST_TIME_POPUP_UPDATE':
            return {
                ...state,
                homeFirstTimePopup: action.newVal,
                gameFirstTimePopup: action.newVal,
                tankFirstTimePopup: action.newVal,
                marketFirstTimePopup: action.newVal,
                aboutFirstTimePopup: action.newVal,
                profileFirstTimePopup: action.newVal,
            }
        case 'GET_LEADERBOAD_INFO_SUCCESS':
            return {
                ...state,
                currentLeaderboard: action.currentLeaderboard,
                overallLeaderboard: action.overallLeaderboard
            }
        case 'GET_SPIN_SUCCESS':
            return {
                ...state,
                spin:action.spin,
            }
        default:
            return state
    }
}

export default firestoreReducer