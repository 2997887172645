export const changeUserTheme = (theme) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        window.localStorage.setItem('theme', theme)
        dispatch({ type: 'SET_USER_THEME_SUCCESS', theme })
    }
}

export const changeUserMusicSetting = (playMusic) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        await window.localStorage.setItem('playMusic', playMusic)
        dispatch({ type: 'SET_USER_MUSIC_SUCCESS', playMusic })
    }
}

export const setUserPolesUpdated = (polesUpdated) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'SET_USER_POLES_UPDATED', polesUpdated })
    }
}

export const setCondensedLayout = (isCondensed) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        await window.localStorage.setItem('condensedLayout', isCondensed)
        dispatch({ type: 'SET_CONDENSED_LAYOUT', isCondensed })
    }
}

export const updateUserInfo = (info) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()

        await firestore.collection('users').doc(firebase.auth().currentUser.uid).set({...info}, {merge: true})
        .then(() => {
            dispatch({type: 'SET_USER_INFO_SUCCESS'})
        })
        .catch((error) => {
            dispatch({type: 'SET_USER_INFO_ERROR', error})
        })

    }
}

export const updateAllFirstTimePopups = (newVal) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'ALL_FIRST_TIME_POPUP_UPDATE', newVal})
    }
}

export const updateFirstTimePopup = (page, newVal) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        switch (page) {
            case 'home': dispatch({ type: 'HOME_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            case 'game': dispatch({ type: 'GAME_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            case 'tank': dispatch({ type: 'TANK_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            case 'market': dispatch({ type: 'MARKET_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            case 'about': dispatch({ type: 'ABOUT_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            case 'profile': dispatch({ type: 'PROFILE_FIRST_TIME_POPUP_UPDATE', newVal }); break;
            default: break
        }
    }
}

// FOR TESTING PURPOSES
// Will upload all 1000 fish metadata into firestore
// Have to temporarily allow write access in firestore.rules
export const uploadFishData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        for (let i = 0; i < 1000; i++) {
            let fishNumStr = '#'

            if (parseInt(i/100) <= 0) {
                fishNumStr += '0'
            }
            if (parseInt(i/10) <= 0) {
                fishNumStr += '0'
            }
            fishNumStr += i
            
            await firestore.collection('fish').doc(fishNumStr).set({fishNum: fishNumStr, fishCollection: 'premier'}, {merge: true})
            .then(() => {
                console.log('uploaded ', fishNumStr)
            })
            .catch((error) => {
                console.log(error)
            })

        }
    }
}

export const getUserInfo = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        let firstTimePopupsRetrieved = false
        let canClaim = true

        
        const unsubscribe = await firestore.collection('users').doc(firebase.auth().currentUser?.uid).onSnapshot(async (info) => {
            const state = getState()
            let userInfoData = info.data() ?? {}
            let uid = firebase.auth().currentUser?.uid
            let numPremierFish = 0

            if(canClaim && userInfoData?.openSeaWalletAddress) {
                const apiUrl = 'https://snapshots.firebaseio.com/snapshot8/' + userInfoData?.openSeaWalletAddress.toLowerCase()
                await fetch(apiUrl + '.json', {
                    method: 'GET',
                })
                .then((response) => response.json())
                .then(async(data) => {
                    const sent = data?.sent ?? false
                    if(sent === false && data?.owned > 0) {
                        await firestore.collection('users').doc(uid).set({ claimedAirDrop: false }, { merge: true })
                        canClaim = false
                    }
                    
                })
                .catch(error => console.log(error))
            }

            if (!firstTimePopupsRetrieved) {
                const showPopups = userInfoData?.showFirstTimePopups ?? true
                if (showPopups) {
                    dispatch({ type: 'ALL_FIRST_TIME_POPUP_UPDATE', newVal: true })
                    await firestore.collection('users').doc(uid).set({ showFirstTimePopups: false }, {merge: true})
                }
                firstTimePopupsRetrieved = true
            }

            // Set username property if not already set
            if (!userInfoData?.username) {
                await firestore.collection('users').doc(uid).set({ username: firebase.auth().currentUser?.displayName }, {merge: true})
            }

            userInfoData['numReferrals'] = (userInfoData?.referrals?.length ?? 0)
            var getUserBonusesFunction = await firebase.functions().httpsCallable('getUserBonuses')
            const bonuses = (await getUserBonusesFunction({ userInfo: userInfoData, uid })).data
            userInfoData = {...userInfoData, ...bonuses}
            var getLoginRewardFunction = await firebase.functions().httpsCallable('getLoginRewards')
            await getLoginRewardFunction({ uid })

            if (JSON.stringify(userInfoData?.poles) !== JSON.stringify(state.firestore.userInfo?.poles)) {
                state.firestore.userPoles?.forEach((pole) => {
                    if (pole.localImageUrl) {
                        window.URL.revokeObjectURL(pole.localImageUrl)
                    }
                })

                var userPolesFunction = await firebase.functions().httpsCallable('getUserPoles')
                await userPolesFunction({ userInfoData })
                    .then((result) => {
                        let { mappedPoles } = result.data

                        mappedPoles.forEach(async (pole) => {
                            // Get local urls for pole images
                            const image = await fetch(pole.imageUrl)
                            const imageBlob = await image.blob()
                            pole.localImageUrl = await window.URL.createObjectURL(imageBlob)
                        })

                        dispatch({ type: 'GET_USER_POLES_SUCCESS', poles: mappedPoles })
                        dispatch({ type: 'SET_USER_POLES_UPDATED', polesUpdated: true })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                dispatch({ type: 'SET_USER_POLES_UPDATED', polesUpdated: true })
            }

            if (JSON.stringify(userInfoData?.boats) !== JSON.stringify(state.firestore.userInfo?.boats)) {
                state.firestore.userBoats?.forEach((boat) => {
                    if (boat.localImageUrl) {
                        window.URL.revokeObjectURL(boat.localImageUrl)
                    }
                })

                var userBoatsFunction = await firebase.functions().httpsCallable('getUserBoats')
                await userBoatsFunction({ userInfoData })
                .then((result) => {
                    let { mappedBoats } = result.data

                    mappedBoats.forEach(async (boat) => {
                        const image = await fetch(boat.imageUrl)
                        const imageBlob = await image.blob()
                        boat.localImageUrl = await window.URL.createObjectURL(imageBlob)
                    })

                    dispatch({ type: 'GET_USER_BOATS_SUCCESS', boats: mappedBoats })
                })
                .catch((error) => {
                    console.error(error)
                })
            }

            // If new changes to user fish, remap fish data
            if (
                firebase.auth().currentUser?.uid && 
                JSON.stringify(userInfoData?.fish?.map((fish) => fish.fishNum)) !== JSON.stringify(state.firestore.userInfo?.fish?.map((fish) => fish.fishNum))
            ) {
                // Free local urls (reduce memory leaks)
                state.firestore.userFish?.forEach((fish) => {
                    if (fish.imageUrl) {
                        window.URL.revokeObjectURL(fish.imageUrl)
                    }
                })
                state.firestore.userBackgrounds?.forEach((background) => {
                    if (background.localImageUrl) {
                        window.URL.revokeObjectURL(background.localImageUrl)
                    }
                })

                // Return initial user data before loading in user fish
                dispatch({type: 'GET_USER_INFO_SUCCESS', info: userInfoData})

                var userInfoFunction = await firebase.functions().httpsCallable('getUserInfo');
                userInfoFunction({ userInfoData, uid: firebase.auth().currentUser?.uid })
                    .then((result) => {
                        let { userInfoData, mappedFish, backgrounds, trophies, error } = result.data

                        if (error) console.error(error)

                        mappedFish.forEach(async (fish) => {
                            // Get local urls for fish images
                            const image = await fetch(fish.imageUrl)
                            const imageBlob = await image.blob()
                            fish.imageUrl = await window.URL.createObjectURL(imageBlob)
                        })
                        backgrounds.forEach(async (background) => {
                            // Get local urls for background images
                            const image = await fetch(background.imageUrl)
                            const imageBlob = await image.blob()
                            background.localImageUrl = await window.URL.createObjectURL(imageBlob)
                        })

                        numPremierFish = mappedFish?.filter((fish) => fish.fishCollection === 'premier').length ?? 0
                        let addedReferrals = getAddedReferrals(numPremierFish)
                        const updatedState = getState()
                        userInfoData['numReferrals'] = (updatedState.firestore.userInfo?.numReferrals ?? 0) + addedReferrals
                        dispatch({ type: 'GET_USER_INFO_FISH_BACKGROUND_SUCCESS', info: userInfoData, fish: mappedFish, backgrounds, trophies })
                    })

            } else {
                dispatch({ type: 'GET_USER_INFO_SUCCESS', info: userInfoData })
            }

        })

        return unsubscribe;
    }
}

export const getMarketInfo = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        
        let marketBackgrounds = await firestore.collection('marketData').doc("backgrounds").get()
        let marketBoats = await firestore.collection('marketData').doc("boats").get()
        let marketBundles = await firestore.collection('marketData').doc("bundles").get()
        let marketPoles = await firestore.collection('marketData').doc("poles").get()
        let marketConversionRate = await firestore.collection('marketData').doc("conversions").get()

        dispatch({
            type: 'GET_MARKET_INFO_SUCCESS', 
            marketBackgrounds:marketBackgrounds.data(), 
            marketBoats:marketBoats.data(), 
            marketBundles:marketBundles.data(), 
            marketPoles:marketPoles.data(),
            marketConversionRate:marketConversionRate.data()?.latest
        })

    }
}

const getAddedReferrals = (numPremierFish) => {
    return numPremierFish >= 55 ? 15 :
           numPremierFish >= 22 ? 10 :
           numPremierFish >= 11 ? 5 :
           numPremierFish >= 5 ? 3 :
           numPremierFish >= 1 ? 1 :
           0
}

export const clearUserInfo = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'CLEAR_USER_INFO'})
    }
}

export const updateUserFish = (fishNum, fishName) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const state = getState()

        let userFish = state.firestore.userFish
        userFish.filter((fish) => fish.fishNum === fishNum)[0].fishName = fishName

        
        var setFishNameFunction = await firebase.functions().httpsCallable('updateUserFishName');
        await setFishNameFunction({ fishNum, fishName })
        .then((result) => {
            let { error } = result

            if (error) {
                dispatch({ type: 'UPDATE_USER_FISH_ERROR', error })
            } else {
                dispatch({type: 'UPDATE_USER_FISH_SUCCESS', fish: userFish})
            }
        })
        .catch((error) => {
            dispatch({type: 'UPDATE_USER_FISH_ERROR', error})
        })

    }
}

export const getFish = (id) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const storage = firebase.storage()
        const firestore = getFirestore()
        const state = getState()

        await firestore.collection('fish').doc(`#${id}`).get()
            .then(async (doc) => {
                if (doc.exists) {
                    if (state.firestore?.currentFish?.localImageUrl) {
                        window.URL.revokeObjectURL(state.firestore.currentFish.localImageUrl)
                    }

                    const fishData = doc.data()

                    if (!fishData.imageUrl) {
                        const parsedFishNum = parseInt(fishData.fishNum.match(/\d+/)[0]) ?? null
                        let fishImagePartialPath = fishData.fishCollection === 'premier' ? 'premier fish/' : 'other fish/'

                        if (parsedFishNum < 10000) {
                            let fishFileType = parsedFishNum === 969 ? '.gif' : '.png'
                            await storage.ref(`fish bodies/${fishImagePartialPath}${fishData.fishNum}${fishFileType}`).getDownloadURL()
                            .then((url) => {
                                if (url) {
                                    fishData.imageUrl = url
                                }
                            })
                        }
                    }

                    const image = await fetch(fishData.imageUrl)
                    const imageBlob = await image.blob()
                    fishData.localImageUrl = await window.URL.createObjectURL(imageBlob)

                    dispatch({ type: 'GET_FISH_SUCCESS', fishData })

                } else {
                    dispatch({ type: 'GET_FISH_ERROR', error: { message: "No fish found" } })
                }
            })
            .catch((error) => {
                dispatch({ type: 'GET_FISH_ERROR', error })
            })
    }
}

export const unsetFish = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: 'UNSET_FISH' })
    }
}


//Admin firestore functions
export const getSpecies = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var getSpeciesFunc = await firebase.functions().httpsCallable('getSpecies');
        await getSpeciesFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const getTraits = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var getTraitsFunc = await firebase.functions().httpsCallable('getTraits');
        await getTraitsFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const snapshot = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var takeSnapshot = await firebase.functions().httpsCallable('snapshot');
        await takeSnapshot()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const addFish = (num, species, type, collection, imageName, uid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var addFishFunc = await firebase.functions().httpsCallable('addFish');

        await addFishFunc({num, species, type, collection, imageName, uid})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const sortFish = (from,to) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var sortFishFunc = await firebase.functions().httpsCallable('sortFish');
        console.log(from,to)
        await sortFishFunc({from,to})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const sortUnknownFish = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var sortUnknownFishFunc = await firebase.functions().httpsCallable('sortUnknownFish');
        await sortUnknownFishFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const enjinCrossCheck = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var func = await firebase.functions().httpsCallable('Crosscheck');
        for(let i = 378; i < 756; i++) {
            await new Promise(r => setTimeout(r, 3000)); //wait
            await func({page:i})
            .catch((error) => {
                console.error(error)
            })
        }
        console.log("done")
    }
}

export const openSeaSnapshot = (fromIn,to) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var openSeaSnapshotFunc = await firebase.functions().httpsCallable('openSeaSnapshot');
        for(let i = 0; i < 1002; i++) {
            await new Promise(r => setTimeout(r, 1500)); //wait

            let from = fromIn + i

            await openSeaSnapshotFunc({from,to})
            .catch((error) => {
                console.error(error)
            })
        }
    }
}

export const openSeaSnapshotAdr = (from,to) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var openSeaSnapshotAdrFunc = await firebase.functions().httpsCallable('openSeaSnapshotAdr');
        await openSeaSnapshotAdrFunc({from,to})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const openSeaSnapshotData = (from,to) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var openSeaSnapshotDataFunc = await firebase.functions().httpsCallable('openSeaSnapshotData');
        await openSeaSnapshotDataFunc({from,to})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const getUidFish = (uid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var getUidFishFunc = await firebase.functions().httpsCallable('getUidFish');
        await getUidFishFunc({uid})
        .catch((error) => {
            console.error(error)
        })
    }
}

export const updateSupply = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var updateMarketSupply = await firebase.functions().httpsCallable('updateMarketSupply');
        await updateMarketSupply()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const findMissingFish = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var findMissingFishFunc = await firebase.functions().httpsCallable('findMissingFish');
        await findMissingFishFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const resetContestCounter = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var resetContestCounterFunc = await firebase.functions().httpsCallable('resetContestCounter');
        await resetContestCounterFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const resetContestCounter2 = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var resetContestCounterFunc = await firebase.functions().httpsCallable('resetContestCounter2');
        await resetContestCounterFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const resetTrophyPiece = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var resetTrophyPieceFunc = await firebase.functions().httpsCallable('resetTrophyPiece');
        await resetTrophyPieceFunc()
        .catch((error) => {
            console.error(error)
        })
    }
}

export const fixTraits = (dataIn) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        let func = (await firebase.functions().httpsCallable('fixTraits'))
        await func(dataIn).catch((error) => {
            console.error(error)
        })
    }
}

export const mostCaught = (dataIn) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        let func = (await firebase.functions().httpsCallable('mostCaught'))
        await func(dataIn).catch((error) => {
            console.error(error)
        })
    }
}

export const getLeaderboards = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        
        let users = await firestore.collection('users').get()
        let currentLeaderboard = new Array(5).fill(0)
        let overallLeaderboard = new Array(5).fill(0)

        users.forEach(async(user) => {
            const userData = user.data()
            let counter1 = userData?.contestCounter ?? 0
            let counter2 = userData?.contestCounter2 ?? 0
            let setCur = false
            let setOver = false
            for(let i = 0; i < 5; i++) {
                if(currentLeaderboard[i] < counter1 && setCur === false) {
                    for(let j = 4; j >= i; j--) {
                        currentLeaderboard[j] = currentLeaderboard[j-1]
                    }
                    currentLeaderboard[i] = counter1
                    setCur = true
                }
                if(overallLeaderboard[i] < counter2 && setOver === false) {
                    for(let j = 4; j > i; j--) {
                        overallLeaderboard[j] = overallLeaderboard[j-1]
                    }
                    overallLeaderboard[i] = counter2
                    setOver = true
                }
            }
        })     

        dispatch({
            type: 'GET_LEADERBOAD_INFO_SUCCESS', 
            currentLeaderboard:currentLeaderboard,
            overallLeaderboard:overallLeaderboard,
        })

    }
}

export const theThing = (i) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var func = await firebase.functions().httpsCallable('doTheThing');
        await func().catch((error) => {
            console.error(error)
        })
        //var func2 = await firebase.functions().httpsCallable('doTheOtherThing');
        /*let maxPage = 2
        for(let page = 0; page < maxPage; page ++) 
        {
            await func({page}).catch((error) => {
                console.error(error)
            })
            await new Promise(r => setTimeout(r, 30 * 1000)); //wait
        }
        /*await func().then(async(data) =>{
            //const airDropData = new Array(Object.entries(data.data));
            //console.log(airDropData)
            //await func2({uid:airDropData[0][i][0],adr:airDropData[0][i][1].enjAdr,amount:airDropData[0][i][1].total})
            //.catch((error) => {
            //    console.error(error)
            //})
        })
        .catch((error) => {
            console.error(error)
        })*/
    }
}

export const upgradeBait = (type, amount) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        console.log({type, amount})
        var func = await firebase.functions().httpsCallable('upgradeBait');
        await func({type, amount, uid:firebase.auth().currentUser.uid})
        .catch((error) => {
            console.error(error)
        })
    }
}