import { Button, Divider, Typography, Grid , TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './MarketEtherbait.css';
import '../Marketplace.css';
import baitSPic from '../../static/images/Bait/baitS.png';
import baitLPic from '../../static/images/Bait/baitL.png';
import baitHPic from '../../static/images/Bait/baitH.png';
import { updateOrder } from '../../store/actions/marketActions';

class MarketEtherbait extends Component {

    constructor(props) {
        super(props);

        this.state = {
            etherbaitEnjModalOpen: false,
            etherbaitEthModalOpen: false,
            infoModalOpen: false,
            item: '',
            amount: 0,
            numSuper: 10,
            numLegendary: 10,
            numHybrid: 10,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAddToOrder = this.handleAddToOrder.bind(this)
    }

    async handleAddToOrder(bait) {
        let numItem = parseInt(bait === "super" ? this.state.numSuper : this.state.numLegendary)
        if(numItem > 0) {
            let order = this.props.order
            let item = {
                name:bait,
                type:"bait",
                cost: numItem/2,
                amount: numItem
            }
            
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === bait) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Etherbait - Bait your hooks!</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={6}>
                        <img src={baitSPic} alt={''}/>
                        <Typography variant="h6">
                            Super: {this.props.userInfo?.bait?.super?.count ?? 0}
                        </Typography>
                        <Typography>
                            +6% Etherfish Catch %
                        </Typography>
                        <Grid container className="market-grid-container">
                            <Grid item className="market-grid-item" xs={3} sm={2}>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Super"
                                    name="numSuper"
                                    autoFocus
                                    value={this.state.numSuper}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item className="market-grid-item" xs={6}>
                                <Typography className='profile-etherbait-empty'>
                                    {this.state.numSuper} Super Bait {<br/>} {this.state.numSuper/2} JENJ <br/> {this.convertToEth(this.state.numSuper)} ETH 
                                </Typography>
                            </Grid>
                            <Grid item className="market-grid-item" xs={3} sm={2}>
                                <Button 
                                    className="market-items-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    onClick={() => {
                                        this.handleAddToOrder("super")
                                    }}
                                >Add to cart
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={6}>
                        <img src={baitLPic} alt={''}/>
                        <Typography variant="h6">
                            Legendary: {this.props.userInfo?.bait?.legendary?.count ?? 0}
                        </Typography>
                        <Typography>
                            +4% Etherfish Catch Rarity
                        </Typography>
                        <Grid container className="market-grid-container">
                            <Grid item className="market-grid-item" xs={4} sm={2}>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Legendary"
                                    name="numLegendary"
                                    autoFocus
                                    value={this.state.numLegendary}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item className="market-grid-item" xs={5} sm={6}>
                                <Typography className='profile-etherbait-empty'>
                                    {this.state.numLegendary} Legendary Bait {<br/>} {this.state.numLegendary/2} JENJ <br/> {this.convertToEth(this.state.numLegendary)} ETH 
                                </Typography>
                            </Grid>
                            <Grid item className="market-grid-item" xs={3} sm={2}>
                                <Button 
                                    className="market-items-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    onClick={ () => {
                                        this.handleAddToOrder("legendary")
                                    }}
                                >Add to cart
                                </Button>
                            </Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={false} md={3}/>
                    <Grid item className="collectible-grid-item" xs={12} md={6}>
                        <img src={baitHPic} alt={''}/>
                        <Typography variant="h6">
                            Hybrid: {this.props.userInfo?.bait?.hybrid?.count ?? 0}
                        </Typography>
                        <Typography>
                            +3% Etherfish Catch % and +2% Etherfish Catch Rarity
                        </Typography>
                        <Grid container className="market-grid-container">
                            <Grid item className="market-grid-item" xs={4} sm={2}>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Hybrid"
                                    name="numHybrid"
                                    autoFocus
                                    value={this.state.numHybrid}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item className="market-grid-item" xs={5} sm={6}>
                                <Typography className='profile-etherbait-empty'>
                                    {this.state.numHybrid} Hybrid Bait {<br/>} {this.state.numHybrid/2} JENJ <br/> {this.convertToEth(this.state.numHybrid)} ETH 
                                </Typography>
                            </Grid>
                            <Grid item className="market-grid-item" xs={3} sm={2}>
                                <Button 
                                    className="market-items-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    onClick={ () => {
                                        this.handleAddToOrder("hybrid")
                                    }}
                                >Add to cart
                                </Button>
                            </Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={false} md={3}/>

                    <Typography>{<br/>}{<br/>}</Typography>
                </Grid>
             </div>
        )
    }

    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketEtherbait)