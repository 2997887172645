import { Box } from "@material-ui/core";
import React, { Component } from "react";


export default class TabPanel extends Component {
    
    render() {
        const { children, value, index, ...other } = this.props;
        return (
            <div
                style={{ //maxWidth: '640px', 
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={1} style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
}