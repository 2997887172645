import { Divider, Grid, Grow, Paper, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import Footer from '../components/Footer/Footer';
import './Help.css';

class Help extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <div className="help-page">
                <Grow in={true} style={{ transformOrigin: 'top' }}>
                    <Paper className="help-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                {this.renderMainContent()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderFaq()}
                            </Grid>
                            <Grid item xs={12}>
                                <Footer />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </div>
        )
    }

    renderMainContent() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h2" className="help-page-content-title">Beginners Guide</Typography>
                <Divider classes={{root: classes.divider}} className="help-page-content-divider"/>
                <Typography variant="h6">
                    &emsp;If you are new to NFTs and Cryptocurrencies, or Etherfishing in general, this is the page for you! For full details on everything Etherfishing, check out our <a className="help-page-link" href={"https://docs.google.com/document/d/1PZRXbNa7TYX8zVDdF5dYqd1pstdW6XWkUiZ7OWB4x74/edit?usp=sharing"} target="_">Whitepaper Summary!</a><br />
                </Typography>
                <br/>
                <br/>
                <Typography variant="h4">
                    What is an NFT?
                </Typography>
                <Typography variant="h6">
                    &emsp;NFT stands for Non-Fungible Token. Non-fungible basically means that it is unique and can not be duplicated. While the same artwork can be sold in 2 different NFTs, each has a non-fungible identifier on the blockchain to be tracked allowing for complete ownership of a virtual item! All Caught Etherfish will be JENJ (Enjin Jumpnet) NFTs that you personally own and can sell on the Enjin Marketplace!<br />
                </Typography>
                <br/>
                <br/>
                <Typography variant="h4">
                    How to Play
                </Typography>
                <Typography variant="h6">
                    &emsp; To play Etherfishing, you will need to <a className="help-page-link" href={"https://etherfishing.com/user/create-account"} target="_">make an account</a>, verify your email, and link your Enjin wallet to receive your NFTs! We require email on account creation because it is the most used option Firebase Storage would allow, which is the database we decided on. We will NEVER use or sell your information.<br/>
                    &emsp; After connecting your wallet (IF on Mobile, Use the DAPP browser inside the Mobile App), you can play on Desktop or Mobile on MOST Browsers. If you use an Apple product, you may want to use the "Condensed layout" in your profile settings to have a better experience!<br/>
                    &emsp; Head to the Go Fish page to Play! If you are new, check out the <a className="help-page-link" href={"https://www.youtube.com/watch?v=adLp2uoOhwQ"} target="_">game tutorial</a>.<br />
                    <br/>
                </Typography>
                <Typography variant="h6">                
                    Head to the <a className="help-page-link" href={"https://etherfishing.com/game"} target="_">Go Fish!</a> page to Play! If you are new, check out the <a className="help-page-link" href={"https://www.youtube.com/watch?v=adLp2uoOhwQ"} target="_">game tutorial</a>.<br />
                </Typography>
                <Typography variant="h6" className="help-page-list"> 
                    <ol>           
                        <li> <b>Choose your Pole</b> <br/>
                        Select your Pole in your Game Menu, they each have their own Cooldown! The Free Pole has a permanent 24-Hour Cooldown while every other pole has a 15 Minute Cooldown decreasing with Cast Time Reduction!</li>
                        <li><b>Cast</b><br/>
                        Choose any EtherBait to boost your next cast! You can fish without bait.</li>
                        <li><b>Wait for Something to Bite</b><br/>
                        The animations will change when something is biting, click during the animations to hook the fish! It takes less than 10 seconds for something to bite, keep going until you hook something!</li>
                        <li><b>Click in the Right Zone to Score</b><br/>
                        Wait for the splashing to stop in a zone, then click click in the zone to score! Get 1 Point for each successful click, you need 10 Points in 15 seconds to successfully catch something! Failing to catch something gives you your cast back!</li>
                    </ol>
                    <br/>
                </Typography>
                <Typography variant="h6"> 
                    If you successfully catch something, you have the chance at catching 1 of 4 things.
                </Typography>
                <Typography variant="h6" className="help-page-list"> 
                    <ul>
                        <li><b>Golden Etherfish (&#62;1%)</b><br/>
                        These are NFTs with lots of cryptocurrency attached to them!</li>
                        <li><b>Etherfish NFTs (25%)</b><br/>
                        These are NFTs that have random <a className="help-page-link" href={"https://etherfishing.com/species-and-traits"} target="_">traits</a> that positively influence your experience and can be seen swimming in your Fish Tank! They can also be sold on the Enjin Marketplace or melted for cryptocurrency!</li>
                        <li><b>Etherbait (30%)</b><br/>
                        These can be used to increase the catch percentage on your next cast! You don’t need any bait to cast.
                        These are single use items, you lose them after your next cast.</li>
                        <li><b>Collectibles (&#60;44%)</b><br/>
                        These are profile items that add up to bonuses over time! Check out your <a className="help-page-link" href={"https://etherfishing.com/user/profile"} target="_">Profile Page</a> to see your collected items and Roadmap of bonuses!</li>
                    </ul>
                    <br/>
                </Typography>
                <Typography variant="h6"> 
                     Etherfishing is Played in Seasons. Each season, there is a limited and specific amount of Etherfish NFTs that will be fished 
                    until everything is caught! Once a rarity category like “3 Trait Etherfish” is fished out, that category % is added to the next 
                    highest rarity tier. This is repeated until all NFTs are caught, including the Golden Etherfish! See the exact percentages in 
                    our <a className="help-page-link" href={"https://docs.google.com/document/d/1PZRXbNa7TYX8zVDdF5dYqd1pstdW6XWkUiZ7OWB4x74/"} target="_">White Paper</a>. Same rules apply during Off-Season, however, the pools 
                    of fish are much smaller and are fished out very fast! Times will always be posted in our <a className="help-page-link" href={"https://discord.gg/W6DWZ49a4t"} target="_">Discord</a>, early bird gets the fish!
                    <br/>
                    <br/>
                     Once you have Etherfish NFTs, you can check them out swimming in your <a className="help-page-link" href={"https://etherfishing.com/bowl"} target="_">Fish Tank</a>! In your Fish Tank, you can swap betweens Fish Tanks Backgrounds, Eat, Breed, View your Fish Data, and even Name them!
                    You can use your Etherfish NFTs to earn in many ways!<br/>
                </Typography>
                <Typography variant="h6" className="help-page-list"> 
                    1. Sell the NFTs on the Market<br/>
                    2. Melt any Jumpnet NFT into Crypto<br/>
                    3. Eat Etherfish to Upgrade<br/>
                    4. Breed to Create New NFTs<br/>
                    5. Set a Price for other Players to Breed with Your Etherfish<br/>
                    6. Create Your Season and Set a Price for Players to Fish from it<br/>
                </Typography>
                <br/>
                <Typography variant="h6"> 
                     If you want to play more than once every 24 Hours, head to the <a className="help-page-link" href={"https://etherfishing.com/marketplace"} target="_">Marketplace Page</a> to purchase Casts to use your other Pole NFTs. You can purchase additional Pole NFTs to Cast more often, each has their own Cast Timer. Collect Boat NFTs to gain access to Private Fishing Locations, all boats will get some! Collect Fish Tank NFTs to separate your Etherfish and Create your own Season! All NFTs are limited and most are rotated EVERY Season! Get them before they are gone!
                </Typography>
                <br/>
                <br/>
                <Typography variant="h4">
                    Premier Etherfish
                </Typography>
                <Typography variant="h6">
                    &emsp;Premier Etherfish are Etherfish #000-999 and are listed on <a className="help-page-link" href={"https://opensea.io/collection/etherfishing?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"} target="_">Opensea</a>. These Etherfish, besides swimming in your pool, give owners several Membership Benefits! These include: Possible Airdrop at the End of Each Season, Rare Fish Tank Backgrounds Depending on the Premier Etherfish #(0-199, 200-399 ect.), Voting Rights for Future Updates and Plans, Exclusive Discord Events and Giveaways and extra referrals on your profile!<br />
                    {<div><br /></div>}
                    &emsp;If you want to purchase a Premier Etherfish, go to <a className="help-page-link" href={"https://opensea.io/collection/etherfishing?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"} target="_">Etherfishing Opensea</a>. You will need to download the <a className="help-page-link" href={"https://metamask.io/"} target="_">MetaMask extension</a> for your Browser and load it up with some Ethereum (ETH)! You can purchase ETH using USD and most other currencies from <a className="help-page-link" href={"https://www.coinbase.com/"} target="_">Coinbase</a> or swap from other many other cryptocurrencies using <a className="help-page-link" href={"https://app.uniswap.org/#/swap"} target="_">Uniswap</a>.<br />
                    &emsp;After you purchase a Premier Etherfish, connect your Opensea using the button in your profile. Currently, Metamask is your only option for connection at this point. If you connect on your phone to our website, use the Metamask Browser inside the Metamask Phone App!<br />
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                    Enjin Jumpnet (JENJ)
                </Typography>
                <Typography variant="h6" className="help-page-list">                
                    &emsp;Jumpnet Enjin, or JENJ, is the main coin we use for this game because it allows everyone to transfer these NFTs and the Coin itself for nearly nothing, unlike the coins you used to get JENJ. As you can imagine with all the Etherfish NFTs we send out, free transactions to send these NFTs is what makes the game functional. <b>You only need to create and link the free Enjin wallet to play, not purchase currency.</b><br/>
                    &emsp;Download the <a className="help-page-link" href={"https://enjin.io/"} target="_">Mobile Enjin Wallet</a> and connect to our website in your profile! If you are connecting to our website on your phone, use the DAPP browser inside the Enjin App!<br/>
                    &emsp;If you need help acquiring JENJ, check out the <a className="help-page-link" href={"https://www.youtube.com/embed/q5PR0TLiGCE"} target="_">Overview Tutorial Video</a> I made. You need to make 2 transactions to get from ETH to JENJ, and it can be costly and timely when done at the wrong time. Make sure you have enough ETH in your ENJ wallet and swap only when you can choose fast!<br/>
                    &emsp;<b>To save on transaction fees currently, purchase with ETH! (Price Changes occur randomly to maintain currency ratios)</b><br/>
                    &emsp;<b>DO NOT send JENJ to your Metamask! You will lose the currency. You need to swap back to Enjin Coin in your Enjin App first!</b><br/>
                    <br/>
                </Typography>
                <Typography variant="h5">                
                    How to connect your Enjin Wallet:<br/>
                </Typography>
                <Typography variant="h6" className="help-page-list">                
                    1. Downlad the Enjin App, currently only for mobile devices<br/>
                    2. Open Enjin app and create new wallet or import a wallet<br/>
                    3. Open menu and press Scan QR<br/>
                    4. Go to your profile and click [Attach Enjin Account]<br/>
                    5. Scan the code that pops up and enter your password on your wallet<br/>
                    6. And click [I Linked To The Game]<br/><br/>
                </Typography>
                <Typography variant="h4">
                    Pro Crypto Tips
                </Typography>
                <Typography variant="h6" className="help-page-list">
                    <ul>
                        <li>Double check everything, you are responsible for your own wealth management in Crypto!</li>
                        <li>Transactions can take a little while depending on the amount of activity, be patient. Same goes for receiving items after a payment. Same goes for recieving items after payment.</li>
                        <li>
                            Swapping to JENJ requires multiple transactions, there are a couple ways to save!<br/>
                            1. Import your wallet into the Enjin App instead of sending it, saving 1 gas fee.<br />
                            2. Swap to Enjin Coin (ENJ) inside the Exchange tab in the Enjin App!<br />
                            3. Use the <a className="help-page-link" href={"https://etherscan.io/gastracker"} target="_">Etherium Gas Tracker</a> to determine when to send and swap to JENJ. I suggest swapping any time Gas (gwei) is under 50. You should choose an average or fast swap time everytime so your transaction isn’t pending for hours!<br />
                            4. PURCHASE WITH ETH- The JENJ swap can be costly and timely depending on the activity, it is common to spend $30+ on a single transaction if you don't wait for super low gas periods.<br/>
                        </li>
                    </ul>
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                    Pro Safety Tips
                </Typography>
                <Typography variant="h6" className="help-page-list">
                    <ol>
                        <li><b>NEVER give your secret wallet Seed Phrase out to anyone!!</b> Your secret wallet Seed Phrase are the 12 words (sometimes more) that you get when creating a wallet. These can not be changed and give anyone who has them full access to the wallet permanently. The ONLY time you should use your wallet Seed Phrase is to recover your wallet on another computer or import your wallet to another TRUSTED wallet.</li>
                        <li>Write/Save your wallet Seed Phrase to an external (not your main computer) drive or paper and split them up in different locations for maximum security!</li>
                        <li>Always check the links of where you are clicking! There are SO many scammers in this day and age waiting to take your money if you are careless. I find/get 5-10 scam attempts daily saying that I have won something or that there are BIG $$$ NFTs listed cheap on this fake site. If something feels too good to be true, it most likely is… just double check the full link and NEVER type your Seed Phrase in. You can connect to any legit site by signing the transaction in your wallet on the same pop-up as always.</li>
                    </ol>
                </Typography>
            </div>
        )
    }

    renderFaq() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h2" className="help-page-content-title">FAQ</Typography>
                <Divider classes={{ root: classes.divider }} className="help-page-content-divider" />
                <Typography variant="h4">
                    How does the fishing game work?
                </Typography>
                <Typography variant="h6" className="help-page-list">
                    <ol>
                        <li>After logging in, verifying your email, and attaching your free Enjin wallet, you can go to "Go Fish!", swap to your Free Pole, and click "Cast" to begin the Game!</li>
                        <li>After hooking the fish by tapping at the right time, you have to fight the fish by tapping in the zone that the fish stops in.</li>
                        <li>When the Splashing animation is going straight up, you can click/tap to score 1 point. Choosing the wrong zone deducts your points by 1!</li>
                        <li>Score 10 in 15 seconds to catch one of 4 things: Golden Etherfish, Etherfish, Bait, Collectible. Check out our <a className="help-page-link" href={"https://www.youtube.com/watch?v=mZ4qZmvYtxA"} target="_">Game Tutorial Video</a>!</li>
                    </ol>
                </Typography>
                <br/>
                <Typography variant="h4">
                    Where will I see the Etherfish NFTs that I catch in-game?
                </Typography>
                <Typography variant="h6">
                    You will see these in your Enjin Wallet! As soon as Enjin solves the issue with automatic creation/sending, we will airdrop all of the NFTs to your Enjin Wallet! You will be able to sell them through their marketplace!
                </Typography>
                <br />
                <Typography variant="h4">
                    What do I get from owning a Premier Etherfish?                    
                </Typography>
                <Typography variant="h6">
                    These Etherfish, besides swimming in your pool, give owners several Membership Benefits! These include: Possible Airdrop at the End of Each Season, Rare Fish Tank Backgrounds Depending on the Premier Etherfish #(0-199, 200-399 ect.), Voting Rights for Future Updates & Plans, Exclusive Discord Events & Giveaways
                </Typography>
                <br />
                <Typography variant="h4">
                    How does the "Airdrop Work" for premier Etherfish?
                </Typography>
                <Typography variant="h6">
                    You will have to connect your Opensea Wallet and Enjin Wallet to your Etherfishing Profile. Airdrops come at the End of the Season, you can claim them in your Profile!</Typography>
                <br />
                <Typography variant="h4">
                    Do the traits mean anything?
                </Typography>
                <Typography variant="h6">
                    Yes they do! Traits will positively influence several aspects of the game. Check out our <a className="help-page-link" href={"https://etherfishing-4215--admin16-g75staju.web.app/species-and-traits"} target="_">Traits and Species</a> page.
                </Typography>
                <br />
                <Typography variant="h4">
                    How can I buy Premier Etherfish?
                </Typography>
                <Typography variant="h6">
                    All our Premier Etherfish are listed for sale on <a className="help-page-link" href={"https://opensea.io/collection/etherfishing?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"} target="_">Opensea</a>!
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                    More Info
                </Typography>
                <Typography variant="h6">
                    &emsp;Want to know everything going on behind the scenes and extras exclusive to Etherfishing Members? Join our Discord, named <a className="help-page-link" href={"https://t.co/Ij3yx3SQ1A?amp=1"} target="_">Fishcord!</a><br />
                    &emsp;In Fishcord, you can get to know our community full of Fishers! We have fishing information available, we talk about the game, and show off what we have caught! We also host many giveaways and competitions for both promotion and fishing in the discord, always giving incentive to participate!<br />
                    &emsp;Come across any bugs while on Etherfishing.com? Let us know in the Bug Channel in Discord or Email us at Etherfishing@gmail.com!<br />
                </Typography>
            </div>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
});

export default withStyles(styles)(Help)