import { Button, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Grow, InputLabel, LinearProgress, MenuItem, Select, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { failedToCatch, getLocations, getReward, getTotalFishRemaining, initLocation, setDisplayReward, setFailedToCatch, setHasCast } from "../store/actions/gameActions";
import './Game.css';
import GameRender from "./GameRender.js";
import { withStyles } from "@material-ui/styles";
import { showErrorSnackbar } from "../store/actions/snackbarActions";
import GameMenu from "./GameComponents/Menu/GameMenu";
import VerifyEmail from "./GameComponents/VerifyEmail/VerifyEmail";
import { setOpenVerifyEmail } from "../store/actions/authActions";
import { setUserPolesUpdated, updateFirstTimePopup } from "../store/actions/firestoreActions";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DialogTitleFirstTime from "../components/DialogTitle";
import successImage from '../static/images/GameImages/success.png';
import perfectImage from '../static/images/GameImages/perfect.png';
import Locations from "./GameComponents/Locations/locations";


class Game extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loggedIn: false,
            buyCastModalOpen: false,
            linkEnjinModalOpen: false,
            displayRewardOpen: false,
            loadingReward: false,
            intervalId: 0,
            totalCastCooldownRatio: 0,
            totalCastHoursRemaining: 0,
            totalCastMinutesRemaining: 0,
            totalCastSecondsRemaining: 0,
            coolDownStarted: false,
            selectedBait: '',
            fishRemainingUnsub: null,
            correctTap: false,
            incorrectTap: false,
            currentPoleInfo: null,
            fishGotAway: false,
            etherfishRemainingOpen: false,
            etherfishEventOpen: false,
            contestOpen:true,
            perfect: false,
            statsModalOpen: false,
            gameStats: {
                isPerfect: false,
                time: -1,
                poleUsed: null,
                baitUsed: null,
            }
        }

        this.handleCast = this.handleCast.bind(this)
        this.handleBuyCastModalClose = this.handleBuyCastModalClose.bind(this)
        this.handleLinkEnjinModalClose = this.handleLinkEnjinModalClose.bind(this)
        this.handleRewardModalClose = this.handleRewardModalClose.bind(this)
        this.handleBaitSelect = this.handleBaitSelect.bind(this)
        this.handleFishGotAwayModalClose = this.handleFishGotAwayModalClose.bind(this)
        this.handleCloseFirstTimePopup = this.handleCloseFirstTimePopup.bind(this)
        this.handleStatsModalOpen = this.handleStatsModalOpen.bind(this)
        this.handleStatsModalClose = this.handleStatsModalClose.bind(this)
    }

    async componentDidMount() {
        if (this.props.user?.uid) {
            this.setState({loggedIn: true})            
        }

        const currentPoleInfo = this.props.userInfo?.poles ? this.props.userInfo?.poles[this.props.userCurrentPole ?? 'starting'] : null
        this.setState({ currentPoleInfo })
        
        if(this.props.locationInfo === null) {
            this.props.initLocation()
        }
        this.props.getLocations()

        const fishRemainingUnsub = await this.props.getTotalFishRemaining()
        this.setState({ fishRemainingUnsub })

        const newIntervalId = setInterval(() => {
            this.calculateCastCooldown()
        }, 1000);



        this.setState(prevState => {
            return {
                ...prevState,
                intervalId: newIntervalId,
            };
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
        if (this.state.fishRemainingUnsub) {
            this.state.fishRemainingUnsub()
        }
    }

    calculateCastCooldown() {
        let lastCastTime = null
        let nextCastTime = null
        let totalCooldown = 0
        let timeRemaining = 0
        const currentTime = Date.now()
        let minutes = 0
        let seconds = 0
        let hours = 0
        if (this.state.currentPoleInfo?.lastCastTime && this.state.currentPoleInfo?.nextCastTime) {
            lastCastTime = this.state.currentPoleInfo?.lastCastTime
            nextCastTime = this.state.currentPoleInfo?.nextCastTime
        }

        totalCooldown = nextCastTime - lastCastTime
        timeRemaining = nextCastTime - currentTime
        hours = parseInt(Math.abs(timeRemaining) / (1000 * 60 * 60) % 60);
        minutes = parseInt(Math.abs(timeRemaining) / (1000 * 60) % 60);
        seconds = parseInt(Math.abs(timeRemaining) / (1000) % 60);

        if (timeRemaining >= 0) {
            this.setState({
                totalCastCooldownRatio: Math.floor((timeRemaining / totalCooldown) * 100),
                totalCastHoursRemaining: hours,
                totalCastMinutesRemaining: minutes,
                totalCastSecondsRemaining: seconds,
            });
        } else {
            this.setState({
                totalCastCooldownRatio: 0,
                totalCastHoursRemaining: 0,
                totalCastMinutesRemaining: 0,
                totalCastSecondsRemaining: 0,
            });
        }

        this.setState({ coolDownStarted: true })
    }

    removeDuplicateBaitAndSort(data) {
        if (data) {
            // Sort data
            let sortedList = []
            if (data?.hybrid?.count > 0) {
                sortedList.push({baitNum: 'hybrid', ...data.hybrid})
            }
            if (data?.legendary?.count > 0) {
                sortedList.push({baitNum: 'legendary', ...data.legendary})
            }
            if (data?.super?.count > 0) {
                sortedList.push({baitNum: 'super', ...data.super})
            }
            if (data?.star5?.count > 0) {
                sortedList.push({baitNum: '5star', ...data.star5})
            }
            if (data?.star4?.count > 0) {
                sortedList.push({baitNum: '4star', ...data.star4})
            }
            if (data?.star3?.count > 0) {
                sortedList.push({baitNum: '3star', ...data.star3})
            }
            if (data?.star2?.count > 0) {
                sortedList.push({baitNum: '2star', ...data.star2})
            }
            if (data?.star1?.count > 0) {
                sortedList.push({baitNum: '1star', ...data.star1})
            }

            return sortedList
        }
    }

    async componentDidUpdate(prevProps) {
        const { displayReward, failedToCatch, gameTapCount } = this.props
        const { displayReward: prevDisplayReward, failedToCatch: prevFailedToCatch, gameTapCount: prevGameTapCount } = prevProps

        if (this.props.userCurrentPole !== prevProps.userCurrentPole || this.props.userInfo?.poles !== prevProps.userInfo?.poles) {
            const currentPoleInfo = this.props.userInfo?.poles ? this.props.userInfo?.poles[this.props.userCurrentPole ?? 'starting'] : null
            this.setState({ currentPoleInfo }, () => {
                this.calculateCastCooldown()
            })
        }

        let baitNum = this.state.selectedBait?.baitNum === 'hybrid' ? 8 :
                      this.state.selectedBait?.baitNum === 'legendary' ? 7 :
                      this.state.selectedBait?.baitNum === 'super' ? 6 :
                      this.state.selectedBait?.baitNum === '5star' ? 5 :
                      this.state.selectedBait?.baitNum === '4star' ? 4 :
                      this.state.selectedBait?.baitNum === '3star' ? 3 :
                      this.state.selectedBait?.baitNum === '2star' ? 2 :
                      this.state.selectedBait?.baitNum === '1star' ? 1 :
                      0

        if (failedToCatch === true && prevFailedToCatch === false) {
            this.setState({ selectedBait: this.state.selectedBait?.count > 1 ? this.state.selectedBait : '', fishGotAway: true })
            await this.props.failedToCatchFunc(baitNum)
            await this.props.setFailedToCatch(false)
        }
        if (displayReward === true && prevDisplayReward === false) {
            // Call function to get reward and display it
            this.setState({ loadingReward: true })
            this.props.setUserPolesUpdated(false)
            this.setState({ gameStats:{isPerfect: this.state.perfect, time: this.props.gameTime, baitUsed: baitNum ?? null, poleUsed: this.props.userCurrentPole}})
            await this.props.getReward(baitNum, this.state.perfect, this.props.gameTime/5, this.props.locationInfo.pool)
            this.calculateCastCooldown()
            this.setState({ loadingReward: false, perfect: false})
            if (this.props.reward) {
                this.setState({ displayRewardOpen: true, selectedBait: this.state.selectedBait?.count > 1 ? this.state.selectedBait : '' })
            } else if (this.props.rewardError === "Failed to fetch") {
                this.props.showErrorSnackbar(`Reward Generator Timeout\n${this.props.rewardError}`)
                this.props.setDisplayReward(false)
                this.props.setUserPolesUpdated(true)
            } else if (this.props.rewardError) {
                this.props.showErrorSnackbar(`Reward error\n${this.props.rewardError}`)
                this.props.setDisplayReward(false)
                this.props.setUserPolesUpdated(true)
            }
        }

        if (prevGameTapCount < gameTapCount) {
            this.setState({ correctTap: true, incorrectTap: false })
        } else if (prevGameTapCount > gameTapCount) {
            this.setState({ correctTap: false, incorrectTap: true, perfect:false })
        }

        if (this.props.locationInfo !== prevProps.locationInfo || JSON.stringify(this.props.locationInfo) !== JSON.stringify(prevProps.locationInfo)) {
            if (this.state.fishRemainingUnsub) {
                this.state.fishRemainingUnsub()
            }
            const fishRemainingUnsub = await this.props.getTotalFishRemaining(this.props.locationInfo.pool)
            this.setState({ fishRemainingUnsub })
        }
    }

    handleCast() {
        if ((this.props.userInfo?.enjinWalletAddress ?? null) === null) {
            this.setState({linkEnjinModalOpen: true})
        } else if (this.props.userCurrentPole === 'free' || (this.props.userInfo?.casts ?? 0) > 0) {
            if (this.props.userCurrentPole === 'free' && !this.props.user.emailVerified) {
                this.props.setOpenVerifyEmail(true)
            } else {
                // Double check pole not on cooldown
                let currentPoleCooldown = this.props.userPoles?.find(pole => pole.poleNum === this.props.userCurrentPole)?.nextCastTime
                if (!currentPoleCooldown || currentPoleCooldown < Date.now()) {
                    // Has enough casts and is verified
                    this.props.setHasCast(true);
                    this.setState({ perfect: true, coolDownStarted:true })
                } else {
                    this.props.showErrorSnackbar(`Cast Error\nCurrent pole has ${Math.floor((currentPoleCooldown - Date.now()) / 1000)}s left on cooldown`)
                }
            }
        } else {
            // Out of casts
            this.setState({buyCastModalOpen: true})
        }
    }

    handleBuyCastModalClose() {
        this.setState({ buyCastModalOpen: false })
    }

    handleLinkEnjinModalClose() {
        this.setState({ linkEnjinModalOpen: false })
    }

    handleRewardModalClose() {
        this.setState({ displayRewardOpen: false, loadingReward: false, selectedBait: ''})
        this.props.setDisplayReward(false)
    }

    handleBaitSelect(event) {
        this.setState({ selectedBait: event.target.value })
    }

    handleFishGotAwayModalClose() {
        this.setState({fishGotAway: false})
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    handleStatsModalOpen() {
        this.setState({ statsModalOpen: true })
    }

    handleStatsModalClose() {
        this.setState({ statsModalOpen: false })
    }

    render() {
        return (
            <div id="game" className="game-page" onContextMenu={e => e.preventDefault()}>
                {/*this.state.loggedIn ?*/} 
                    <div>
                        {!this.props.hasCast ? this.renderLayout() : this.renderTapsAndTime()}
                        {this.renderRewardModal()}
                        {this.renderStatsModal()}
                        {this.renderFishGotAwayModal()}
                        {this.renderFirstTimePopup()}
                        <VerifyEmail/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <GameRender
                            
                            />
                        </div>
                    </div>
                {/*}:
                    <div className="game-page-grayed">
                        <div className="game-page-text">
                            <Typography variant="h3">
                                Login to play game!
                            </Typography>
                            <Button className="game-page-login-button" onClick={() => this.props.history.push('/user/login')} color="primary" variant="contained">
                                Login
                            </Button>
                        </div>
                    </div>
                */}
            </div>
        )
    }

    renderLayout() {
        const { classes } = this.props
        return (
            <div className={this.props.condensedLayout ? "game-page-cast-container-condensed" : "game-page-cast-container"}>
                <Grid container className="game-page-cast-container-grid">
                    <Grid item xs={12} sm={this.props.condensedLayout ? 12 : 4} md={this.props.condensedLayout ? 12 : 4}>    
                            {this.renderSeasonEvent()}                    
                            {this.renderEtherfishRemaining()}
                            {this.renderDailyContest()}
                    </Grid>
                    <Grid item xs={12} sm={this.props.condensedLayout ? 12 : 4} md={this.props.condensedLayout ? 12 : 4} className="game-page-buttons-bottom-align">
                        <div>{!this.props?.userInfo ?? false ? 
                            <Button 
                                className="game-page-cast-button" 
                                variant="contained" 
                                color="primary"
                                size="large"
                                onClick={() => this.props.history.push('/user/login')} 
                            >
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                                    Login
                                </Typography>
                            </Button>
                            :
                            <Button 
                                className="game-page-cast-button" 
                                variant="contained" 
                                color="primary"
                                size="large"
                                onClick={this.handleCast}
                                disabled={
                                    !this.props.userInfo || 
                                    this.props.totalFishRemaining.total <= 0 || 
                                    !this.state.coolDownStarted || 
                                    this.state.totalCastCooldownRatio > 0 ||
                                    !this.props.userPolesUpdated ||
                                    ((this.props.locationInfo?.boats ?? []).length > 0 && !(this.props.locationInfo?.boats ?? []).find(x=>(x === this.props?.userCurrentBoat)))
                                }
                                classes={{disabled: classes.button}}
                            >
                                {this.state.totalCastCooldownRatio > 0 ?
                                    <div style={{ width: window.innerWidth > 960 && !this.props.condensedLayout ? '150px' : '100px', height: window.innerWidth > 960 && !this.props.condensedLayout ? "57px !important" : "48px !important" }}>
                                        <Typography variant={window.innerWidth > 960 ? "h4" : "h6"}>
                                            {this.state.totalCastHoursRemaining > 0 ? this.state.totalCastHoursRemaining + ':' : ''}
                                            {this.state.totalCastHoursRemaining > 0 && this.state.totalCastMinutesRemaining < 10 ? '0' : ''}
                                            {this.state.totalCastMinutesRemaining}:
                                            {this.state.totalCastSecondsRemaining < 10 ? '0' : ''}{this.state.totalCastSecondsRemaining}
                                        </Typography>
                                        <LinearProgress color="primary" variant="determinate" value={this.state.totalCastCooldownRatio} />
                                    </div>
                                : !this.props.userPolesUpdated ?
                                    <div style={{ width: window.innerWidth > 960 && !this.props.condensedLayout ? '150px' : '100px', height: window.innerWidth > 960 && !this.props.condensedLayout ? "57px !important" : "48px !important" }}>
                                        <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "h7"} style={{margin: '4px'}}>
                                            Loading
                                        </Typography>
                                        <LinearProgress color="primary"/>
                                    </div>
                                :
                                    <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                                        Cast [{this.props.userCurrentPole === 'free' ? 'free' : this.props.userInfo?.casts ?? 0}]
                                    </Typography>
                                }

                            </Button>}
                            <FormControl 
                                variant="outlined" 
                                className="game-page-bait-selection"
                                focused={false}
                                style={{ height: window.innerWidth > 960 && !this.props.condensedLayout ? "57px" : "48px" }}
                            >
                                {this.state.selectedBait === '' ?
                                    <InputLabel variant="outlined" style={{color: 'white'}} htmlFor="outlined-age-native-simple">Bait</InputLabel>
                                :
                                    null
                                }
                                <Select
                                    id="demo-customized-select"
                                    label="Bait"
                                    value={this.state.selectedBait}
                                    onChange={this.handleBaitSelect}
                                    className="game-page-bait-selection-input"
                                    style={{ height: window.innerWidth > 960 && !this.props.condensedLayout ? "57px" : "48px" }}
                                    renderValue={(value) => value.name}
                                >
                                    <MenuItem key={0} value="">None</MenuItem>
                                    {this.removeDuplicateBaitAndSort(this.props.userInfo?.bait)?.map((userBait, index) => (
                                        <MenuItem key={index + 1} value={userBait} className="game-page-bait-menu-item">
                                            <Typography className="game-page-bait-menu-item-text">{userBait.name}</Typography>&nbsp;
                                            <Chip size="small" label={userBait.count} color="secondary" />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={this.props.condensedLayout ? 12 : 4} md={this.props.condensedLayout ? 12 : 4} className="game-page-buttons-bottom-align">
                        <Locations/>
                        <GameMenu/>
                    </Grid>
                </Grid>
                {this.renderBuyCastModal()}
                {this.renderLinkEnjinModal()}
            </div>
        )
    }

    renderEtherfishRemaining() {
        return (
            <div className="game-page-fish-remaining-container">
                <Button 
                    className="game-page-fish-remaining-text"
                    endIcon={this.state.etherfishRemainingOpen ? <KeyboardArrowDownIcon fontSize="large"/> : <KeyboardArrowUpIcon fontSize="large" />}
                    onClick={() => this.setState({ etherfishRemainingOpen: !this.state.etherfishRemainingOpen })}
                    disableRipple
                >
                    <div  style={{display: 'flex', flexDirection: 'column'}}>
                        <Collapse in={this.state.etherfishRemainingOpen}>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>Golden</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.golden ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>Legendary</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.legendary ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>5 Trait</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.trait5 ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>4 Trait</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.trait4 ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>3 Trait</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.trait3 ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>2 Trait</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.trait2 ?? 0}</Typography>
                            </div>
                            <div className="game-page-fish-remaining-breakdown-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>1 Trait</Typography>
                                <Divider className="game-page-fish-remaining-breakdown-divider"/>
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>{this.props.totalFishRemaining.trait1 ?? 0}</Typography>
                            </div>
                        </Collapse>
                        <Typography
                            variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}
                        >
                            Etherfish remaining: {this.props.totalFishRemaining.total ?? 0}
                        </Typography>
                    </div>
                </Button>
            </div>
        )
    }

    renderTapsAndTime() {
        return (
            <div className="game-page-taps-time-container">
                <Grid container style={{ position: 'fixed' }}>
                    <Grid item xs={12}>
                        <div className="game-page-fish-remaining-container">
                            <div className="game-page-taps-time" style={{ width: '140px', padding: window.innerWidth > 960 ? "10px" : "5px" }}>
                                <Typography
                                    style={{color: this.props.hasHooked ? 'white' : 'lightgrey'}}
                                    variant={window.innerWidth > 960 ? "h4" : "h6"}
                                >
                                    Taps: 
                                </Typography>
                                <Typography
                                    style={{ color: this.props.hasHooked ? 'white' : 'lightgrey', flexGrow: '1'}}
                                    variant={window.innerWidth > 960 ? "h4" : "h6"}
                                >
                                    {this.props.hasHooked ? this.props.gameTapCount : '-'}
                                </Typography>
                            </div>
                            <div className="game-page-taps-time" style={{ width: '255px', padding: window.innerWidth > 960 ? "10px" : "5px"}}>
                                <Typography
                                    style={{ color: this.props.hasHooked ? 'white' : 'lightgrey'}}
                                    variant={window.innerWidth > 960 ? "h4" : "h6"}
                                >
                                    Time left: 
                                </Typography>
                                <Typography
                                    style={{ color: this.props.hasHooked ? this.props.gameTime < 5 ? '#fc4444' : 'white' : 'lightgrey', flexGrow: '1' }}
                                    variant={window.innerWidth > 960 ? "h4" : "h6"}
                                >
                                    {this.props.hasHooked ? this.props.gameTime.toFixed(1) : '-'}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderFishGotAwayModal() {
        return (
            <Dialog
                open={this.state.fishGotAway}
                onClose={this.handleFishGotAwayModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent id="alert-dialog-description" className="buy-casts-modal-content">
                    <Typography variant="h3">Fish got away!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleFishGotAwayModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    renderSeasonEvent() {
        return (
            <div className="game-page-fish-remaining-container">
                <Button 
                    className="game-page-fish-remaining-text"
                    onClick={() => this.setState({ etherfishEventOpen: !this.state.etherfishEventOpen })}
                    disableRipple
                    endIcon={this.state.etherfishEventOpen ? <KeyboardArrowDownIcon fontSize="large"/> : <KeyboardArrowUpIcon fontSize="large" />}
                >{this.props?.locationInfo?.info ? 
                    <div  style={{display: 'flex', flexDirection: 'column'}}>
                        <Collapse in={this.state.etherfishEventOpen}>
                            <div className="game-page-season-info-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>
                                    {this.props.locationInfo.info.map((value) => (<div>{value}</div>))}        
                                </Typography>                    
                            </div>
                        </Collapse>
                        <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                            {this.props.locationInfo.infoTitle || this.props.locationInfo.name}
                        </Typography>
                    </div>
                    : 
                    <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                        {this.props?.locationInfo?.name ?? 'coming soon'}
                    </Typography>
                }
                </Button>
            </div>
        )
    }

    renderDailyContest() {
        const contests = [
            "4 trait fish",
            "negative trait fish",
            "1 trait fish",
            "fish with a super bait",
            "2 trait fish",
            "positive trait fish",
            "5 trait fish",
            "neutral trait fish",
            "3 trait fish"
        ]
        const prizes = ["5 star bait","super bait","legendary bait","hybrid bait","cast"]

        const curDay = Math.floor(new Date().getTime() / 86400000)
        const contestChoice = curDay % contests.length
        const contest = contests[contestChoice]
        const curPrizeNum = curDay > (this.props.userInfo?.contestInfo?.date ?? 0) ? 0 : (this.props.userInfo?.contestInfo?.prizeNum ?? 0)
        const curPrize = prizes[curPrizeNum]
        return (
            <div className="game-page-fish-remaining-container">
                <Button 
                    className="game-page-fish-remaining-text"
                    onClick={() => this.setState({ contestOpen: !this.state.contestOpen })}
                    disableRipple
                    endIcon={this.state.contestOpen ? <KeyboardArrowDownIcon fontSize="large"/> : <KeyboardArrowUpIcon fontSize="large" />}
                >
                    <div  style={{display: 'flex', flexDirection: 'column'}}>
                        <Collapse in={this.state.contestOpen}>
                            <div className="game-page-season-info-container">
                                <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h5" : "body1"}>
                                    Catch a {contest}<br/>
                                    Reward: a {curPrize}
                                </Typography>
                            </div>
                        </Collapse>
                        <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                            Daily Contest
                        </Typography>
                    </div>
                </Button>
            </div>
        )
    }

    renderBuyCastModal() {
        return (
            <Dialog
                open={this.state.buyCastModalOpen}
                onClose={this.handleBuyCastModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Out of casts</DialogTitle>
                <DialogContent id="alert-dialog-description" className="buy-casts-modal-content">
                <Button 
                    className="home-page-help-button"
                    color="secondary" 
                    variant="contained"
                    onClick={() => this.props.history.push('/help')}
                >
                    <Typography variant="h5">Buy More!</Typography>
                </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleBuyCastModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderLinkEnjinModal() {
        return (
            <Dialog
                open={this.state.linkEnjinModalOpen}
                onClose={this.handleLinkEnjinModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                
                <DialogContent id="alert-dialog-description" className="buy-casts-modal-content">
                <Typography variant="h4">                
                    Link your Enjin wallet!<br/>
                </Typography>
                <Typography variant="h6">
                    You must link your Enjin wallet to play the game.<br/>
                    Please go to your profile and link your enjin wallet.<br/>
                    <br/>
                </Typography>
                <Typography variant="h5">                
                    How to connect your Enjin Wallet<br/>
                </Typography>
                <Typography variant="h6" className="help-page-list">                
                    1. Downlad the Enjin App, currently only for mobile devices<br/>
                    2. Open Enjin app and create new wallet or import a wallet<br/>
                    3. Open menu and press Scan QR<br/>
                    4. Go to your profile and click [Attach Enjin Account]<br/>
                    5. Scan the code that pops up and enter your password on your wallet<br/>
                    6. And click [I Linked To The Game]<br/><br/>
                </Typography>
                    <Button 
                            className="home-page-help-button"
                            color="primary" 
                            variant="contained"
                            onClick={() => this.props.history.push('/user/profile')}
                        >
                            <Typography variant="h5">Profile</Typography>
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleLinkEnjinModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderRewardModal() {
        const { classes } = this.props
        return (
            <div>
                {(this.state.loadingReward && !this.state.gameStats.isPerfect) ? 
                    <div className="game-page-grayed" style={{display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', position: 'absolute', flexDirection: 'column'}}>
                        <img src={successImage} alt="Success!" className="game-page-reward-text"/>                  
                        <Typography variant="h4"><br/>Calculating reward</Typography>
                        <CircularProgress size='4rem'/>
                    </div>
                : (this.state.loadingReward && this.state.gameStats.isPerfect) ? 
                    <div className="game-page-grayed" style={{display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', position: 'absolute', flexDirection: 'column'}}>
                        <img src={perfectImage} alt="Perfect!" className="game-page-reward-text"/>
                        <Typography variant="h4"><br/>Calculating reward</Typography>
                        <CircularProgress size='4rem'/>
                    </div>
                :
                    <Grow in={this.state.displayRewardOpen} className="game-page-reward-grow">
                        <div style={{width: '100%'}}>
                            <Dialog
                                open={this.state.displayRewardOpen}
                                onClose={this.handleRewardModalClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                classes={{ paper: classes.dialog }}
                            >
                                <DialogContent id="alert-dialog-description" className="display-reward-modal-content">
                                    <Typography variant="h3" style={{marginBottom: '12px'}}>{this.state.gameStats.isPerfect ? "Perfect!!" : "Success!"}</Typography>
                                    <img src={this.props.reward?.imageUrl} alt=""/>                    
                                    {this.props.reward?.rewardType === 'Collectible' ? 
                                        <Typography variant="h4">Collectible</Typography>
                                        :
                                        null
                                    }
                                    <Typography variant="h5" style={{marginTop: '3px'}}>{this.props.reward?.name}</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleStatsModalOpen} color="primary" variant="outlined">
                                        Stats
                                    </Button>
                                    <Button onClick={this.handleRewardModalClose} color="primary" variant="outlined">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Grow>
                }
            </div>
        )
    }

    renderStatsModal() {
        const { classes } = this.props
        return (
            <div>
                <Grow in={this.state.statsModalOpen} className="game-page-reward-grow">
                    <div style={{width: '100%'}}>
                        <Dialog
                            open={this.state.statsModalOpen}
                            onClose={this.handleStatsModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{ paper: classes.dialog }}
                        >
                            <DialogContent id="alert-dialog-description" className="display-reward-modal-content">
                                <Typography variant={window.innerWidth > 960 ? "h3" : "h4"} style={{marginBottom: '12px'}}>Game Stats</Typography>
                                <Divider className="game-divider"/>
                                <Typography variant={window.innerWidth > 500 ? "h5" : "subtitle1"} style={{marginTop: '3px'}}>
                                    Bait Used: {this.state.gameStats.baitUsed === 0 ? "None" 
                                        : this.state.gameStats.baitUsed === 1 ? "1 Star"
                                        : this.state.gameStats.baitUsed === 2 ? "2 Star"
                                        : this.state.gameStats.baitUsed === 3 ? "3 Star"                                        
                                        : this.state.gameStats.baitUsed === 4 ? "4 Star"
                                        : this.state.gameStats.baitUsed === 5 ? "5 Star"
                                        : this.state.gameStats.baitUsed === 6 ? "Super"
                                        : this.state.gameStats.baitUsed === 7 ? "Legendary" 
                                        : this.state.gameStats.baitUsed === 8 ? "Hybrid" 
                                        : "Error"
                                    }<br/>
                                    Catch Time: {Math.round((15 - this.state.gameStats.time) * 10)/10} Seconds<br/>
                                    Time Bonus: +{Math.round(this.state.gameStats.time/5 * 100)/100}%<br/>
                                    Total Catch Rate:  {this.state.gameStats.baitUsed !== 7 ? 
                                    Math.round((((this.props.userInfo?.playerEtherfishCatchPercentage ?? 0) + 25 + Math.round(this.state.gameStats.time/5 * 100)/100) + this.state.gameStats.baitUsed === 8 ? 3 : this.state.gameStats.baitUsed + Number.EPSILON) * 10000) / 10000 :
                                    Math.round((((this.props.userInfo?.playerEtherfishCatchPercentage ?? 0) + 25 + Math.round(this.state.gameStats.time/5 * 100)/100) + Number.EPSILON) * 10000) / 10000}%<br/>
                                    Perfect Bonus: {this.state.gameStats.isPerfect ? "0.5" : "0"}%<br/>
                                    Total Rarity Bonus: {this.state.gameStats.baitUsed !== 7 ? 
                                        this.state.gameStats.isPerfect ? this.state.gameStats.baitUsed === 8 ? "2.5" : "0.5" : 
                                                                         this.state.gameStats.baitUsed === 8 ? "2" : "0" : 
                                        this.state.gameStats.isPerfect ? "3.5" : "3"}%
                                   <br/>

                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleStatsModalClose} color="primary" variant="outlined">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Grow>
            </div>
        )
    }
    
    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitleFirstTime onClose={this.handleCloseFirstTimePopup}>Go Fish! Explanation </DialogTitleFirstTime>
                <DialogContent dividers className="">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/adLp2uoOhwQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                </DialogContent>
            </Dialog>
        )
    }
}

const styles = theme => ({
    dialog: {
        border: 'solid #6690cb',
        borderWidth: '8px'
    },
    button: {
        "&:disabled": {
            backgroundColor: 'rgba(82, 82, 82, 0.6)',
            color: 'white'
        }
    },
    select: {
        '& .MuiOutlinedInput-root:hover' : {
            border: 'none',
        },
        '&.MuiOutlinedInput-notchedOutline' : {
            border: 'none',
        },

    },
    selectFocused: {},
    notchedOutline: {
        border: 'none'
    }
});

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userPoles: state.firestore.userPoles,
        userPolesUpdated: state.firestore.userPolesUpdated,
        userInfo: state.firestore.userInfo,
        hasCast: state.game.hasCast,
        reward: state.game.gameReward,
        rewardError: state.game.gameRewardError,
        displayReward: state.game.displayReward,
        totalFishRemaining: state.game.totalFishRemaining,
        failedToCatch: state.game.failedToCatch,
        gameTapCount: state.game.gameTapCount,
        gameTime: state.game.gameTime,
        hasHooked: state.game.hasHooked,
        userCurrentPole: state.game.userCurrentPole,
        userCurrentBoat: state.game.userCurrentBoat,
        condensedLayout: state.firestore.condensedLayout,
        showFirstTimePopup: state.firestore.gameFirstTimePopup,
        locationInfo: state.game.locationInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setHasCast: (hasCast) => dispatch(setHasCast(hasCast)),
        setFailedToCatch: (failedToCatch) => dispatch(setFailedToCatch(failedToCatch)),
        failedToCatchFunc: (baitType) => dispatch(failedToCatch(baitType)),
        getReward: (baitType, perfect, timeBonus, pool) => dispatch(getReward(baitType, perfect, timeBonus, pool)),
        setDisplayReward: (displayReward) => dispatch(setDisplayReward(displayReward)),
        getTotalFishRemaining: (pool) => dispatch(getTotalFishRemaining(pool)),
        showErrorSnackbar: (message) => dispatch(showErrorSnackbar(message)),
        setOpenVerifyEmail: (isOpen) => dispatch(setOpenVerifyEmail(isOpen)),
        setUserPolesUpdated: (polesUpdated) => dispatch(setUserPolesUpdated(polesUpdated)),
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('game', newVal)),
        initLocation: () => dispatch(initLocation('season')),
        getLocations:()=> dispatch(getLocations())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Game))