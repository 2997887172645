import React, { Component } from 'react';
import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import './Login.css'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            usernameError: false,
            password: "",
            passwordError: false,
            showPassword : false,
            validatingCreds: false,
            loginErrorCode: null,
            loginErrorMessage: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleShowPassword = this.handleShowPassword.bind(this)
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        // Validate returning signed in user
        if (this.props.user.uid) {
            // Redirect to profile page if logged in
            this.props.history.push('/user/profile');
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        const errorReset = name === 'username' ? 'usernameError' : 'passwordError'
        this.setState({[name]: target.value, [errorReset]: false})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    handleShowPassword() {
        this.setState({showPassword: !this.state.showPassword})
    }

    handleMouseDownPassword(event) {
        event.preventDefault()
    }

    handleSubmit() {
        // Make sure username and password are provided
        if (!this.state.username) {
            this.setState({usernameError: true})
        }
        if (!this.state.password) {
            this.setState({passwordError: true})
        }

        if (this.state.username && this.state.password) {
            // Validate credentials
            this.setState({validatingCreds: true}, async () => {
                await this.props.signIn({email: this.state.username, password: this.state.password})

                if (this.props.auth.authError) {
                    this.setState({
                        validatingCreds: false, 
                        loginErrorMessage: this.props.auth.authError
                    })
                } else {
                    this.props.history.push('/user/profile')
                }
            })

        }
    }

    render() {
        return (
            <div className="page">
                <Paper className="login" elevation={3}>
                    <Typography variant="h4" className='loginText'>Login</Typography>
                    {this.renderLoginError()}
                    <div/>
                    {this.renderUsernameField()}
                    <div/>
                    {this.renderPasswordField()}
                    <div/>
                    <Link className='loginLink' to="/user/forgot-password">
                        <Typography variant="body1">Forgot password?</Typography>
                    </Link>
                    <div/>
                    {this.renderSubmitButton()}
                </Paper>
                <div/>
                <Link className='loginLinkMarg' to='/user/create-account'>
                    <Typography variant="body1">Create new account</Typography>
                </Link>
            </div>
        );
    }

    renderLoginError() {
        return (
            <div className='loginErrorMessage'>
                {this.state.loginErrorMessage ? 
                <Alert severity='error'>
                    <AlertTitle>Sign in error</AlertTitle>
                    {this.state.loginErrorMessage }
                </Alert> :
                null}
            </div>
        )
    }

    renderUsernameField() {
        return (
            <FormControl className="username">
                <InputLabel htmlFor="username-comp">Email address</InputLabel>
                <Input
                    id="username-comp"
                    name='username'
                    type='text'
                    autoFocus
                    error={this.state.usernameError}
                    value={this.state.username}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                />
            </FormControl>
        )
    }

    renderPasswordField() {
        return (
            <FormControl className="password">
                <InputLabel htmlFor="password-comp">Password</InputLabel>
                <Input
                    id="password-comp"
                    name='password'
                    type={this.state.showPassword ? 'text' : 'password'}
                    error={this.state.passwordError}
                    value={this.state.password}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    renderSubmitButton() {
        return (
            <Button
                id='sumbit-comp'
                variant='contained'
                color='primary'
                className='submitButton'
                onClick={this.handleSubmit}
            >{this.state.validatingCreds ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Submit'}</Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)