export const spinWheel = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var func = await firebase.functions().httpsCallable('spinWheel');
        let state = getState()
        if(state.wof.spinning === 0){
            try {
                if((firebase.auth()?.currentUser.uid ?? null) !== null) {
                    dispatch({
                        type: 'START_SPIN', 
                        spin: 0
                    }) 
                    await func({uid:firebase.auth().currentUser.uid}).then((spin) => {
                        dispatch({
                            type: 'GET_SPIN_SUCCESS', 
                            spin:spin,
                        })    
                    })
                    .catch((error) => {
                        dispatch({
                            type: 'GET_SPIN_ERROR', 
                            spin:0,
                        }) 
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'GET_SPIN_ERROR', 
                    spin:0,
                }) 
            }
        }
    }
}

export const spinEnd = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'END_SPIN', 
        }) 
    }
}