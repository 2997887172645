import React, { Component } from "react";
import PropTypes from 'prop-types';
import TabPanel from "../../../components/tabs/TabPanel";
import { Button, Typography, Grid, Paper, Drawer, IconButton, Tooltip} from "@material-ui/core";
import { connect } from "react-redux";
import './locations.css'
import { initLocation } from "../../../store/actions/gameActions";
import { withStyles } from "@material-ui/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


class locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            gameMenuOpen: false,
            tabValue: 0,
            leaderTabValue: 0,
            helpModalOpen: false,
        }

        this.handleMenuOpen = this.handleMenuOpen.bind(this)
        this.handleMenuClose = this.handleMenuClose.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleLeaderTabChange = this.handleLeaderTabChange.bind(this)
        this.handleHelpModalOpen = this.handleHelpModalOpen.bind(this)
        this.handleHelpModalClose = this.handleHelpModalClose.bind(this)
    }

    async componentDidMount() {
    }

    handleMenuOpen(event) {
        this.setState({ anchorEl: event.currentTarget, gameMenuOpen: true })
    }

    handleMenuClose() {
        this.setState({ anchorEl: null, gameMenuOpen: false })
    }

    handleTabChange(event, newValue) {
        this.setState({ tabValue: newValue })
    }    
    
    handleLeaderTabChange(event, newValue) {
        this.setState({ leaderTabValue: newValue })
    }
    
    handleChangeLoc(pool) {
        this.props.initLocation(pool)
    }

    handleHelpModalOpen() {
        this.setState({ helpModalOpen: true })
    }

    handleHelpModalClose() {
        this.setState({ helpModalOpen: false })
    }

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    a11yLeaderProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { classes } = this.props;
        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        return (
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleMenuOpen}
                    style={{marginBottom:'5px'}}
                >
                    <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                        Locations
                    </Typography>
                </Button>
                <Drawer
                    className="fish-tank-list-drawer"
                    classes={{ paper: classes.drawerPaper }}
                    variant="persistent"
                    anchor="right"
                    open={this.state.gameMenuOpen}
                    onClose={this.handleMenuClose}
                >
                    
                    <Grid className={"game-menu-container"} container style={{width:(window.innerWidth > 400) ? '400px' : window.innerWidth}}>
                        <Grid item xs={1}>
                            <Tooltip title="Close" >
                                <IconButton className="fish-tank-drawer-button" onClick={this.handleMenuClose}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}/>
                        <Grid item xs={4} >
                            <Typography variant="h5" style={{ justifyContent: 'center', width: '100%', color: 'white' }}>Locations</Typography>
                        </Grid>
                        <Grid item xs={4}/>
                    </Grid>

                    {this.renderLocations()}
                    {this.renderBoatOptions()}
                </Drawer>
            </div>
        )
    }

    renderBoatOptions() {
        return (
            <div style={{color:'white'}}>
                <Typography>Useable Boats:</Typography>
                {
                    this.props.locationInfo?.boats?.length > 0 ? (
                        this.props.locationInfo?.boats.map(name =>{
                            return (
                                <Typography key={name}>{name}</Typography>
                            )
                        })
                    ):  <Typography>Any</Typography>
                }
            </div>
        )
    }

    renderLocations() {
        if(this.props?.locations.length > 0)  {
            return (
                <div className="game-menu-rod-main-container">
                    {this.props?.locations.map(location => {
                        let hasBoat = location.pool === 'season' 
                            || location?.boats?.length === 0 
                            || location?.boats.find(locBoat => this.props?.userInfo?.boats?.[locBoat] ?? false)
                        return( 
                            <Button disabled={!this.props.userInfo} disableRipple key={location?.pool} className="game-menu-boat-button" onClick={() => this.handleChangeLoc(location.pool)}>
                                <Paper
                                    className="game-menu-boat-container"
                                    style={{ 
                                        border: location.pool === this.props?.locationInfo?.pool ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none', 
                                        opacity: hasBoat ? 1 : 0.6,
                                    }}
                                >
                                    <Typography className="game-menu-boat-text">{location?.name ?? location.pool}</Typography>
                                    {location?.imageUrl ? <img className="game-menu-rod-image" src={location.imageUrl} alt="" style={{width:"96px", height:'64px'}}/>:null}
                                </Paper>
                            </Button>
                        )
                    })}
                </div>
            )
        }
    }

}

const styles = theme => ({
    drawerPaper: {
        height: 'calc(100% - 80px)',
        top: 80,
        backgroundColor: '#3465b0',
    }
});

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        userBoats: state.firestore.userBoats,
        userTheme: state.firestore.userTheme,
        userCurrentBoat: state.game.userCurrentBoat,
        condensedLayout: state.firestore.condensedLayout,
        locationInfo: state.game.locationInfo,
        locations:state.game.locations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initLocation:(pool) => dispatch(initLocation(pool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(locations))