import { Button, TextField, Divider, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './MarketSpins.css'; 
import '../Marketplace.css';
import { updateOrder } from '../../store/actions/marketActions';


class MarketSpins extends Component {

    constructor(props) {
        super(props);

        this.state = {
            spinModalOpen: false,
            spinEthModalOpen: false,
            infoModalOpen: false,
            amount: 0,

            numSpins: 1,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleAddToOrder = this.handleAddToOrder.bind(this)

    }

    componentDidMount() {
        if (this.props.userInfo?.enjinWalletAddress) {
            this.setState({walletEnjConnected: true})
        } else {
            this.setState({walletEnjConnected: false})    
        }
        if (this.props.userInfo?.ethereumWalletAddress) {
            this.setState({walletEthConnected: true})
        } else {
            this.setState({walletEthConnected: false})    
        }
    }

    async handleAddToOrder() {
        let numSpins = parseInt(this.state.numSpins)
        if(numSpins > 0) {
            let order = this.props.order
            let spins = {
                name:"spin",
                type:"spins",
                cost: 5 * numSpins,
                amount: numSpins
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === "spin") {
                    order.splice(i, 1)
                }
            }
            order.push(spins)
            this.props.updateOrder(order)
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    render() {
        
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Spins - Spin The Wheel!</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="market-grid-item" xs={false} sm={4}>
                    </Grid>
                    <Grid item className="market-grid-item" xs={3} sm={1}>
                        <TextField
                            id="standard-multiline-static"
                            label="Spins"
                            name="numSpins"
                            autoFocus
                            value={this.state.numSpins}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item className="market-grid-item" xs={6} sm={2}>
                        <Typography className='profile-etherbait-empty'>
                            {this.state.numSpins} Spins <br/> {this.state.numSpins * 5} JENJ <br/> {this.convertToEth(this.state.numSpins * 5)} ETH
                        </Typography>
                    </Grid>
                    <Grid item className="market-grid-item" xs={3} sm={1}>
                        <Button 
                            className="market-items-buy-button" 
                            size="small" 
                            color="primary" 
                            variant="contained"
                            onClick={this.handleAddToOrder}
                        >Add to cart
                        </Button>
                    </Grid>
                    <Grid item className="market-grid-item" xs={false} sm={4}>
                    </Grid>
                </Grid>
                <Typography variant="h6">Spins Remaining: {this.props.userInfo?.spins ?? 0}{<br/>}{<br/>}</Typography>
            </div>
        )
    }

    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketSpins)