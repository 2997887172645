import { Button, Divider, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../Marketplace.css';
import item1 from '../../static/images/Background/FishTank_v6.png';
import item2 from '../../static/images/Background/FishTank_v7.png';
import { updateOrder } from '../../store/actions/marketActions';

class MarketBackgrounds extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemJenjModalOpen: false,
            itemEthModalOpen: false,
            infoModalOpen: false,
            amount: 0,
            item: "none",
            item1Purchased: false,
            item2Purchased: false
        }

        this.handleAddToOrder = this.handleAddToOrder.bind(this)
    }
    
    componentDidMount() {
        if (this.props.userInfo?.backgroundTokens?.v6) {
            this.setState({item1Purchased: true})
        } else {
            this.setState({item1Purchased: false})    
        }
        if (this.props.userInfo?.backgroundTokens?.v7) {
            this.setState({item2Purchased: true})
        } else {
            this.setState({item2Purchased: false})    
        }

    }

    async handleAddToOrder(name, cost) {
        //check if owned
        if((name ===  "v6" && !this.state.item1Purchased) || (name ===  "v7" && !this.state.item2Purchased)) {
            let order = this.props.order
            let item = {
                name: name,
                type:"background",
                cost: cost,
                amount: 1
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === name) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Fish Tanks - Swim somewhere else!</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={item1} alt={'item1'} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'> 
                                    Fish Tank 6 <br/> 10 JENJ : {this.convertToEth(10)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    disabled={this.state.item1Purchased}
                                    onClick={() => this.handleAddToOrder("v6", 10)}
                                >Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 50 <br/>
                            Remaining: {this.props.marketBackgrounds?.v6 ?? 0} 
                            <br/><br/>
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={item2} alt={'item2'} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'> 
                                    Fish Tank 7 <br/> 10 JENJ : {this.convertToEth(10)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    disabled={this.state.item2Purchased}
                                    onClick={() => this.handleAddToOrder("v7", 10)}
                                >Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 50 <br/>
                            Remaining: {this.props.marketBackgrounds?.v7 ?? 0}
                            <br/><br/>
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
            </div>
        )
    }

    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketBackgrounds: state.firestore.marketBackgrounds,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketBackgrounds)