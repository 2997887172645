import { Divider, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProfileTrophies.css';
import '../Profile.css';

class ProfileTrophies extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h4">Trophy Case</Typography>
                </div>
                <Divider className="profile-divider"/>
                {this.props.userTrophies?.length > 0 ?
                    <div>{this.renderTrophyList()}</div>
                :
                    <Typography>No Trophies Yet</Typography>
                }
            </div>
        )
    }

    renderTrophyList() {
        return (
            <div key={this.props.userTrophies} className="profile-Trophies-container">
                {this.props.userTrophies?.map((trophy, index) => (
                    <img key={trophy.imageUrl} className="profile-trophy-image" src={trophy.imageUrl} alt=""/>
                ))}
            </div>
        )
    }
}
    

const mapStateToProps = (state) => {
    return {
        userTrophies: state.firestore.userTrophies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTrophies)