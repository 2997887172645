const initState = {
  successSnackbarOpen: false,
  successSnackbarMessage: '',
  errorSnackbarOpen: false,
  errorSnackbarMessage: '',
}

const snackbarReducer = (state = initState, action) => {
    switch (action.type) {
      case "SNACKBAR_SUCCESS":
        return {
          ...state,
          successSnackbarOpen: true,
          successSnackbarMessage: action.message
        };
      case "SNACKBAR_ERROR":
        return {
          ...state,
          errorSnackbarOpen: true,
          errorSnackbarMessage: action.message
        }
      case "SNACKBAR_CLEAR":
        return {
          ...state,
          successSnackbarOpen: false,
          errorSnackbarOpen: false,
        };
      default:
        return state;
    }
  };
  
  export default snackbarReducer;