const initState = {
    maxFish: parseInt(window.localStorage.getItem('maxFish') ?? '100') ?? 100,
    buttonsHidden: window.localStorage.getItem('hideFishTankButtons') === 'true',
    hiddenFish: window.localStorage.getItem('hiddenFish') ? window.localStorage.getItem('hiddenFish').split(',') ?? [] : [],
    hiddenFishUpdated: 0,
    limitFish: true,
    numToLoad: 100,
}

const fishTankReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_HIDE_BUTTONS":
            return {
                ...state,
                buttonsHidden: action.hidden
            };
        case "SET_MAX_FISH":
            return {
                ...state,
                maxFish: action.maxFish
            };
        case "SET_HIDDEN_FISH":
            return {
                ...state,
                hiddenFish: action.hiddenFish,
                hiddenFishUpdated: state.hiddenFishUpdated+1
            }
        case "SET_LIMIT_FISH":
            return {
                ...state,
                limitFishLoaded: !state.limitFish,
            }
        case "SET_LIMIT_NUM_FISH":
            return {
                ...state,
                numToLoad: action.numToLoad,
            }
        default:
            return state;
    }
};

export default fishTankReducer;