export const getUserOpenSeaFish = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const state = getState()
        if (state.firestore.userInfo?.openSeaWalletAddress) {

            var getUserOpenSeaFishFunction = await firebase.functions().httpsCallable('getUserOpenSeaFish');
            getUserOpenSeaFishFunction({ userInfo: state.firestore.userInfo, uid: firebase.auth().currentUser.uid })
                .then((result) => {
                    const { error } = result.data

                    if (!error) {
                        dispatch({ type: 'GET_USER_FISH_SUCCESS' })
                    } else {
                        dispatch({ type: 'GET_USER_FISH_ERROR', error })
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'GET_USER_FISH_ERROR', error })
                })

        } else {
            dispatch({type: 'GET_USER_FISH_ERROR', error: {message: 'Must attach OpenSea wallet address first'}})
        }
    }
}

export const getAllOpenSeaFish = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        var getAllOpenSeaFishFunction = await firebase.functions().httpsCallable('getAllOpenSeaFish');
        getAllOpenSeaFishFunction()
            .then((result) => {
                const { error } = result.data

                if (!error) {
                    dispatch({ type: 'GET_USER_FISH_SUCCESS' })
                } else {
                    dispatch({ type: 'GET_USER_FISH_ERROR', error })
                }
            })
            .catch((error) => {
                dispatch({ type: 'GET_USER_FISH_ERROR', error })
            })
    }
}

export const attachUserOpenSea = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        try {
            const response = await window.ethereum?.request({ method: 'eth_requestAccounts' })
 
            if (response) {
                dispatch({type: 'GET_USER_FISH_SUCCESS', data: response})
            } else {
                dispatch({type: 'GET_USER_FISH_ERROR', error: {message: 'No response from MetaMask'}})
            }

        } catch (error) {

            dispatch({type: 'GET_USER_FISH_ERROR', error})
        }
    }
}

export const setLoadingFishTank = (loading) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({type: 'SET_LOADING_FISH_TANK', loading})
    }
}
