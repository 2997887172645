import {Grow, Grid, Paper, Divider, Typography, Button, } from '@material-ui/core';
import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from '../components/Footer/Footer';
import './wheelOFish.css'
import { spinWheel } from '../store/actions/wofActions';
import WheelRender from './wheelRender';

class wheelOFish extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isDisabled: false,
            loggedIn:false,
        }
       
        this.handleSpinWheel = this.handleSpinWheel.bind(this)
}

    componentDidMount() {
        let attempts = 0
        while(this.state.loggedIn === false && attempts < 5) {
            setTimeout(
                function() {
                    if (this.props.user?.uid && (this.props.userInfo?.spins ?? 0) > 0) {
                        this.setState({loggedIn: true})   
                    }
                }.bind(this),2000
            );
            attempts += 1
        }
    }

    handleSpinWheel() {
        this.props.spinWheel(); 
        this.setState({isDisabled: true});
        setTimeout(
            function() {
                this.setState({isDisabled: false});
            }.bind(this),5000);
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    render() {
        return (
            <div className="market-page">
                <Grow in={true} style={{transformOrigin: 'top'}}>
                    <Paper className="market-paper" elevation={2}>
                        
                        <Grid item xs={12}>
                            <Typography variant={window.innerWidth > 500 ? 'h2' : window.innerWidth > 400 ? 'h3' :'h4'} className="wof-text-center">
                                WHEEL O' FISH
                            </Typography>
                            <Divider className="market-divider"/>
                            
                        </Grid>
                        <Grid item className="wof-wheel-center" xs={12}>
                            <WheelRender/>
                        </Grid>
                        <Grid container>
                            <Grid item xs={false} md={3}></Grid>
                            <Grid item className="wof-button-center" xs={12} md={6}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={this.handleSpinWheel}
                                    size="large"
                                    className="wof-spin-button"
                                    disabled={this.state.isDisabled || !this.state.loggedIn}
                                >
                                    <Typography variant="h3">
                                        Spin
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={false} md={3}></Grid>
                        </Grid>
                        <Grid container>
                            <Divider className="market-divider"/>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" className="wof-text-center">
                                    Remaining Num O' Spins: {this.props.userInfo?.spins ?? 0}<br/>
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" className="wof-text-center">
                                    Total Num O' Spins: {this.props.userInfo?.totalSpins ?? 0}<br/>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Divider className="market-divider"/>
                            <Grid item md={4} xs={12}>
                                <Typography variant="h5" className="wof-text-center">
                                    Casts: {this.props.userInfo?.casts ?? 0}<br/>
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="h5" className="wof-text-center">
                                    Super Bait: {this.props.userInfo?.bait?.super?.count ?? 0}<br/>
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography variant="h5" className="wof-text-center">
                                    Legendary Bait: {this.props.userInfo?.bait?.legendary?.count ?? 0}<br/>
                                </Typography>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderFooter()}
                        </Grid> 
                    </Paper>
                </Grow>
            </div> 
        )
    }

    
    renderFooter() {
        return(
            <div>
                <Divider className="market-divider"/>
                <Footer/>
            </div>
        )   
    }

    /*renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitleFirstTime onClose={this.handleCloseFirstTimePopup}>Link your wallets in Profile to purchase!</DialogTitleFirstTime>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        Currently, the least expensive method of paying is buying with Ethereum (ETH)!<br/><br/>
                        Until we switch this message, JENJ is quite costly in ETH gas fees when switching from Enjin Coin (ENJ) to Enjin Jumpnet (JENJ).
                        <div><br /></div>
                        TRANSACTION STATUS should read "READY" when you are able to purchase and after your transactions have completely gone through successfully. Be patient for a couple minutes and refresh to see new items. Join discord if you have any issues!
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }*/
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo, 
        //showFirstTimePopup: state.firestore.wofFirstTimePopup,
        spinning: state.firestore.spinning,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        spinWheel:() => dispatch(spinWheel()),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(wheelOFish)