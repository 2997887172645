const initState = {
    storageError: null,
    downloadUrl: null,
}

const storageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'PROFILE_PHOTO_UPLOAD_ERROR':
            return {
                ...state,
                downloadUrl: null,
                storageError: action.error.message
            }
        case 'PROFILE_PHOTO_UPLOAD_SUCCESS':
            return {
                ...state,
                downloadUrl: action.downloadUrl,
                storageError: null
            }
        default:
            return state
    }
}

export default storageReducer