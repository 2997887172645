import React, { Component } from 'react';
import { Button, Checkbox, CircularProgress, Collapse, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Paper, Tooltip, Typography, } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { connect } from 'react-redux';
import { showSuccessSnackbar } from '../../store/actions/snackbarActions';
import { createAccount, sendVerificationEmail } from '../../store/actions/authActions';
import InfoIcon from '@material-ui/icons/Info';
import './CreateAccount.css'
import TermsOfService from '../../components/TermsOfService/TermsOfService';

class CreateAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            usernameError: false,
            email: '',
            emailError: false,
            password: '',
            passwordError: false,
            confirmPassword: '',
            showPassword: false,
            validatingCreds: false,
            createAccountErrorMessage: null,
            referralCode: '',
            userOver21: false,
            userOver21Error: false,
            referralInfoOpen: false,
            openTermsOfService: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleShowPassword = this.handleShowPassword.bind(this)
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleOver21Checked = this.handleOver21Checked.bind(this)
        this.handleCloseToS = this.handleCloseToS.bind(this)
        this.handleOpenToS = this.handleOpenToS.bind(this)
        this.handleAcceptToS = this.handleAcceptToS.bind(this)
    }

    componentDidMount() {
        // Validate returning signed in user
        if (this.props.user.uid) {
            // Redirect to profile page if logged in
            this.props.history.push('/user/profile');
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.setState({referralCode: urlParams.get("ref")})
        
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        const errorReset = name === 'username' ? 
                                    'usernameError' : 
                                    name === 'email' ?
                                    'emailError' :
                                    'passwordError'
        this.setState({[name]: target.value, [errorReset]: false})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    handleShowPassword() {
        this.setState({showPassword: !this.state.showPassword})
    }

    handleMouseDownPassword(event) {
        event.preventDefault()
    }

    handleOver21Checked(event) {
        this.setState({ userOver21: event.target.checked, userOver21Error: false })
    }

    handleOpenToS() {
        this.setState({ openTermsOfService: true })
    }

    handleCloseToS() {
        this.setState({ openTermsOfService: false })
    }

    handleSubmit() {
        const confirmError = this.state.password !== this.state.confirmPassword

        // Make sure username and password are provided
        if (!this.state.username) {
            this.setState({usernameError: true})
        }
        if (!this.state.email) {
            this.setState({emailError: true})
        }
        if (!this.state.password || !this.state.confirmPassword) {
            this.setState({passwordError: true})
        }

        if (!this.state.userOver21) {
            this.setState({userOver21Error: true})
        }

        if (this.state.userOver21 && this.state.username && this.state.email && !this.invalidEmail() && this.state.password && !this.invalidPassword() && !confirmError) {
            this.handleOpenToS()
        }
    }

    handleAcceptToS() {
        // Create new user
        this.setState({ validatingCreds: true }, async () => {
            let accountData = {
                username: this.state.username,
                email: this.state.email,
                password: this.state.password,
                over21: true,
                acceptedToS: true,
            }
            if (this.state.referralCode) {
                accountData = { ...accountData, referralCode: this.state.referralCode }
            }
            await this.props.createAccount(accountData);

            if (this.props.auth.authError) {
                this.setState({
                    validatingCreds: false,
                    createAccountErrorMessage: this.props.auth.authError
                })
            } else {
                // Redirect to login page after showing snackbar popup
                this.props.showSuccessSnackbar('Successfully created account!')
                this.props.history.push('/user/login')
                this.props.sendVerificationEmail()
                
            }
        })
    }

    invalidEmail() {
        const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !validEmailRegex.test(String(this.state.email).toLowerCase()) && this.state.email ? true : false
    }

    invalidPassword() {
        // Checks for at least 8 characters, 1 uppercase, 1 special char, 1 numbers, 1 lowercase
        const validPasswordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/
        return !validPasswordRegex.test(String(this.state.password)) && this.state.password ? true : false
    }

    invalidReferralCode() {
        const validReferralCodeRegex = /^[0-9a-zA-Z]{28}$/
        return !validReferralCodeRegex.test(String(this.state.referralCode)) && this.state.referralCode ? true : false
    }

    render() {
        return (
            <form>
                <div className="createAccount">
                    <Paper className='createAccountPaper' elevation={3}>
                        <Typography variant="h4" className='createText'>Create Account</Typography>
                        <Typography>
                            Click <b><a href="https://youtu.be/D7jj6lBCzMg" target="_">HERE</a></b> to see a step-by step<br/> video of what you'll need to start<br/> playing now!
                        </Typography>
                        <div/>
                        {this.renderCreateAccountError()}
                        <div/>
                        {this.renderUsernameInput()}
                        <div/>
                        {this.renderEmailInput()}
                        <div/>
                        {this.renderPasswordInput()}
                        <div/>
                        {this.renderConfirmPasswordInput()}
                        <div/>
                        {this.renderReferralCodeInput()}
                        <div/>
                        {this.render21Checkbox()}
                        <div/>
                        {this.renderSubmitButton()}
                    </Paper>
                    <TermsOfService
                        open={this.state.openTermsOfService}
                        handleClose={this.handleCloseToS}
                        handleAccept={this.handleAcceptToS}
                    />
                </div>
            </form>
        );
    }

    renderCreateAccountError() {
        return (
            <div className='createAccountErrorMessage'>
                {this.state.createAccountErrorMessage ? 
                <Alert severity='error'>
                    <AlertTitle>Create account error</AlertTitle>
                    {this.state.createAccountErrorMessage}
                </Alert> :
                ""}
            </div>
        )
    }

    renderUsernameInput() {
        return (
            <FormControl className="inputCreateItem" required>
                <InputLabel htmlFor="username-comp">Username</InputLabel>
                <Input
                    id="username-comp"
                    name='username'
                    type='text'
                    autoFocus
                    error={this.state.usernameError}
                    value={this.state.username}
                    onChange={this.handleChange}
                    inputProps={{maxLength: 20}}
                />

            </FormControl>
        )
    }

    renderEmailInput() {
        return (
            <FormControl className="inputCreateItem" required>
                <InputLabel htmlFor="email-comp">Email address</InputLabel>
                <Input
                    id="email-comp"
                    name='email'
                    type='text'
                    error={this.state.emailError || this.invalidEmail()}
                    value={this.state.email}
                    onChange={this.handleChange}
                />
                {this.invalidEmail() ? <FormHelperText error>Must provide a valid email</FormHelperText> : ""}
            </FormControl>
        )
    }

    renderPasswordInput() {
        return (
            <FormControl className="inputCreateItem" required>
                <InputLabel htmlFor="password-comp">Password</InputLabel>
                <Input
                    id="password-comp"
                    name='password'
                    type={this.state.showPassword ? 'text' : 'password'}
                    error={this.state.passwordError || this.invalidPassword()}
                    value={this.state.password}
                    onChange={this.handleChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <Tooltip title={`${this.state.showPassword ? 'Hide' : 'Show'} Password`}>
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                    }
                />
                {this.invalidPassword() ? 
                 <FormHelperText error>
                     Must meet the required 8 characters and contain 1 uppercase, 1 lowercase, 1 numbers, and 1 special character (!@#$&*)
                 </FormHelperText> :
                 ""
                }
            </FormControl>
        )
    }

    renderConfirmPasswordInput() {
        const confirmError = this.state.password !== this.state.confirmPassword

        return (
            <FormControl className="inputCreateItem" required>
                <InputLabel htmlFor="password-confirm-comp">Confirm Password</InputLabel>
                <Input
                    id="password-confirm-comp"
                    name='confirmPassword'
                    type={this.state.showPassword ? 'text' : 'password'}
                    error={this.state.passwordError || confirmError}
                    disabled={!this.state.password}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    endAdornment={
                    <InputAdornment position="end">
                        <Tooltip title={`${this.state.showPassword ? 'Hide' : 'Show'} Password`}>
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                    }
                />
                {confirmError ? <FormHelperText error>Password doesn't match</FormHelperText> : ""}
            </FormControl>
        )
    }

    renderReferralCodeInput() {
        return (
            <FormControl className="inputCreateItem">
                <InputLabel htmlFor="referral-code-comp">Referral Code</InputLabel>
                <Input
                    id="referral-code-comp"
                    name="referralCode"
                    type="text"
                    value={this.state.referralCode}
                    error={this.invalidReferralCode()}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    inputProps={{ maxLength: 30 }}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title="Info">
                                <IconButton
                                onClick={() => this.setState({referralInfoOpen: !this.state.referralInfoOpen})}
                                onMouseDown={this.handleMouseDownPassword}
                                >
                                    <InfoIcon/>
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                />
                {this.invalidReferralCode() ? <FormHelperText error>Invalid referral code</FormHelperText> : ""}
                <Collapse in={this.state.referralInfoOpen}>
                    <FormHelperText>
                        Enter your friend's referral code to gain 1 Super Bait and 1 Legendary Bait upon your first purchase. (They also receive a bonus!)
                    </FormHelperText>
                </Collapse>
            </FormControl>
        )
    }

    render21Checkbox() {
        return (
            <FormControl required error={this.state.userOver21Error}>
                <FormControlLabel
                    checked={this.state.userOver21}
                    onChange={this.handleOver21Checked}
                    color="primary"
                    style={this.state.userOver21Error ? { color: '#f44336' } : {}}
                    control={<Checkbox name="userOver21"/>}
                    label="I am 21+ years old"
                />
            </FormControl>
        )
    }

    renderSubmitButton() {
        return (
            <Button
                id='sumbit-comp'
                variant='contained'
                color='primary'
                className='submitCreateButton'
                onClick={this.handleSubmit}
            >{this.state.validatingCreds ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Submit'}</Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createAccount: (info) => dispatch(createAccount(info)),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
        sendVerificationEmail:() => dispatch(sendVerificationEmail())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)