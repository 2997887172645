import { Button, Divider, Typography, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../Marketplace.css';
import { updateOrder } from '../../store/actions/marketActions';
import poleImg1 from '../../static/images/Poles/littleRipper.png';
import poleImg2 from '../../static/images/Poles/saltie.png';

const poleName1 = "Little Ripper"
const poleVar1 = "littleRipper"

const pole2 = "Saltie"
const poleVar2 = "saltie"

class MarketPoles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemJenjModalOpen: false,
            itemEthModalOpen: false,
            infoModalOpen: false,
            amount: 0,
            item: "none",
            item1Purchased: false,
            item2Purchased: false
        }

        this.handleAddToOrder = this.handleAddToOrder.bind(this)
    }

    componentDidMount() {
        if (this.props.userInfo?.poles?.[poleVar1]) {
            this.setState({item1Purchased: true})
        } else {
            this.setState({item1Purchased: false})    
        }
        if (this.props.userInfo?.poles?.[poleVar2]) {
            this.setState({item2Purchased: true})
        } else {
            this.setState({item2Purchased: false})    
        }

    }

    async handleAddToOrder(name, cost) {
        //check if owned
        if((name ===  poleVar1 && !this.state.item1Purchased) || (name ===  poleVar2 && !this.state.item2Purchased)) {
            let order = this.props.order
            let item = {
                name: name,
                type:"pole",
                cost: cost,
                amount: 1
            }
            for(let i = 0; i < order.length; i++) {
                if(order[i].name === name) {
                    order.splice(i, 1)
                }
            }
            order.push(item)
            this.props.updateOrder(order)
        }
    }

    render() {
        return (
            <div className='profile-etherbait-container'>
                <div className="profile-items-title">
                    <Typography variant="h5">Poles - Grab an extra pole!</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={poleImg1} alt={'item1'} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'>
                                    {poleName1} Pole <br/> 5 JENJ : {this.convertToEth(5)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    disabled={this.state.item1Purchased}
                                    onClick={() => this.handleAddToOrder(poleVar1, 5)}
                                > Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 25 <br/>
                            Remaining: {this.props.marketPoles?.[poleVar1] ?? 0}
                            <br/><br/>
                        </Typography>
                    </Grid>
                    <br/>
                    <Grid item className="collectible-grid-item" xs={12} sm={6}>
                        <img src={poleImg2} alt={'item2'} className="market-img"/>
                        <Grid container className="market-grid-container">
                            <Grid item xs={7} className="market-grid-item">
                                <Typography className='profile-etherbait-empty'>
                                    {pole2} Pole <br/> 5 JENJ : {this.convertToEth(5)} ETH
                                </Typography>
                            </Grid>
                            <Grid item xs={5} className="market-grid-item">
                                <Button 
                                    className="market-bait-buy-button" 
                                    size="small" 
                                    color="primary" 
                                    variant="contained"
                                    disabled={this.state.item2Purchased}
                                    onClick={() => this.handleAddToOrder(poleVar2, 5)}
                                > Add to Cart
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography>
                            Total supply: 25 <br/>
                            Remaining: {this.props.marketPoles?.[poleVar2] ?? 0}
                            <br/><br/>
                        </Typography>
                    </Grid>
                </Grid>
                <br/>
            </div>
        )
    }

    convertToEth(amount) {
        return (amount * this.props.marketConversionRate).toFixed(4)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        marketPoles: state.firestore.marketPoles,
        marketConversionRate: state.firestore.marketConversionRate,
        order: state.market.order
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return {
        updateOrder: (order) => dispatch(updateOrder(order)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketPoles)