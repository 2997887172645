import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Step, StepLabel, Stepper, Tooltip, Typography } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from "react-redux";
import './ProfileReferrals.css';
import { showSuccessSnackbar } from "../../../store/actions/snackbarActions";


class ProfileReferrals extends Component {

    constructor(props) {
        super(props)

        this.state = {
            referralModalOpen: false,
            infoModalOpen: false,
        }
    }

    render() {
        return (
            <div className="profile-referrals-container">
                <div className="profile-items-title">
                    <IconButton
                        onClick={() => this.setState({ infoModalOpen: true })}
                    >
                        <InfoIcon />
                    </IconButton>
                    <Typography variant="h4">Referrals: {this.props.userInfo?.numReferrals ?? 0}</Typography>
                    <Button
                        className="profile-items-buy-button"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => this.setState({ referralModalOpen: true })}
                        startIcon={<VisibilityIcon/>}
                    >My code</Button>
                </div>
                <Divider className="profile-divider" />
                {this.renderReferralStepper()}
                {this.renderReferralModal()}
                {this.renderInfoModal()}
            </div>
        )
    }

    renderReferralStepper() {
        let numReferrals = this.props.userInfo?.numReferrals ?? 0
        let currentStep = numReferrals >= 25 ? 7 :
                          numReferrals >= 20 ? 6 :
                          numReferrals >= 15 ? 5 :
                          numReferrals >= 10 ? 4 :
                          numReferrals >= 5 ? 3 :
                          numReferrals >= 3 ? 2 :
                          numReferrals >= 1 ? 1 :
                          0

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal" : "vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1)}>
                        +10% Cast Time reduction
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 3)}>
                        +1% Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5)}>
                        Etherfishing Pole NFT!
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 10)}>
                        +10% Cast Time reduction
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 15)}>
                        +10% Bait effectiveness
                    </StepLabel>
                </Step>
                <Step key={6}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 20)}>
                        Etherfishing Boat NFT!
                    </StepLabel>
                </Step>
                <Step key={7}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 25)}>
                        +0.004% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={8}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, '+1')}>
                        One free Super Bait!
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    renderStepperIcon(props, num) {
        const { active, completed } = props;

        return (
            <Icon 
                fontSize='small'
                className="profile-referrals-stepper-icon"
                style={{ backgroundColor: completed ? '#3465b0' : active ? '#6690cb' : 'grey' }}
            >
                {completed ? <DoneIcon /> : <Typography>{num}</Typography>}
            </Icon>
        )
    }

    renderReferralModal() {
        return (
            <Dialog
                open={this.state.referralModalOpen}
                onClose={() => this.setState({ referralModalOpen: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Referral Information</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-referrals-modal-content">
                    <Grid container className="profile-referrals-modal-content">
                        <Grid item className="profile-referrals-modal-content" xs={12}>
                            <Typography>Referral Code</Typography><Typography>&nbsp;</Typography>
                        </Grid>
                        <Grid item className="profile-referrals-modal-content">
                            <Typography className="profile-referrals-code-text">{this.props.user?.uid}</Typography>
                        </Grid>
                        <Grid item className="profile-referrals-modal-content">
                            <Tooltip title="Copy">
                                <Button
                                    className="profile-referrals-copy-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { 
                                        navigator.clipboard.writeText(this.props.user?.uid)
                                        this.props.showSuccessSnackbar('Copied to clipboard')
                                    }}
                                >
                                    <AssignmentIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent id="alert-dialog-description" className="profile-referrals-modal-content">
                    <Grid container className="profile-referrals-modal-content">
                        <Grid item className="profile-referrals-modal-content" xs={12}>
                            <Typography>Referral Link</Typography><Typography>&nbsp;</Typography>
                        </Grid>
                        <Grid item className="profile-referrals-modal-content" xs={10}>
                            <Typography className="profile-referrals-code-text">https://etherfishing.com/user/create-account?ref={this.props.user?.uid}</Typography>
                        </Grid>
                        <Grid item className="profile-referrals-modal-content">
                            <Tooltip title="Copy">
                                <Button
                                    className="profile-referrals-copy-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { 
                                        navigator.clipboard.writeText("https://etherfishing.com/user/create-account?ref=" + this.props.user?.uid)
                                        this.props.showSuccessSnackbar('Copied to clipboard')
                                    }}
                                >
                                    <AssignmentIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => this.setState({ referralModalOpen: false })}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderInfoModal() {
        return (
            <Dialog
                open={this.state.infoModalOpen}
                onClose={() => this.setState({ infoModalOpen: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Referral Info</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-referrals-info-modal-content">
                    <Typography>As soon as the referred player buys a cast, your total referrals will increase.<br /><br /></Typography>
                    <Typography>Every referral over 25 gives you a free cast!<br /><br /></Typography>
                    <Typography>Owning Premier Etherfish gives you bonus referrals.</Typography>
                    <Typography>1 Premier Etherfish = 1 referral</Typography>
                    <Typography>5 Premier Etherfish = 3 referral</Typography>
                    <Typography>11 Premier Etherfish = 5 referral</Typography>
                    <Typography>22 Premier Etherfish = 10 referral</Typography>
                    <Typography>55 Premier Etherfish = 15 referral</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => this.setState({ infoModalOpen: false })}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReferrals)