export const updateOrder = (order) => {
    return (dispatch, getState, { getFirebase }) => {
        let curTotal = 0
        order?.forEach(item => {
            curTotal += item.cost
        })
        dispatch({ type: 'UPDATE_ORDER', order, curTotal })
    }
}

export const clearOrder = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'CLEAR_ORDER' })
    }
}