const initState = {
    openSeaError: null,
    openSeaUserData: null,
    loadingFishTank: false
}

const openseaReducer = (state = initState, action) => {
    switch(action.type) {
        case 'GET_USER_FISH_ERROR':
            return {
                ...state,
                openSeaError: action.error.message
            }
        case 'GET_USER_FISH_SUCCESS':
            return {
                ...state,
                openSeaUserData: action.data,
                openSeaError: null,
            }
        case 'SET_LOADING_FISH_TANK':
            return {
                ...state,
                loadingFishTank: action.loading
            }
        default:
            return state
    }
}

export default openseaReducer