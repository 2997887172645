import { ethers } from "ethers"

export const attachUserEthereum = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        try {
            const response = await window.ethereum?.request({ method: 'eth_requestAccounts' })
 
            if (response) {
                dispatch({type: 'GET_ETHEREUM_SUCCESS', data: response})
            } else {
                dispatch({type: 'GET_ETHEREUM_ERROR', error: {message: 'No response from MetaMask'}})
            }

        } catch (error) {
            dispatch({type: 'GET_ETHEREUM_ERROR', error})
        }
    }
}

//const BANK = "0x19De410480FF953852ed9171683B956FEbEa25F2" //testnet
const BANK = "0xc721434125a120adE683c63B112B4d32F1c7C4D4" // mainnet
const Web3 = require("web3")    
const web3Http = new Web3('https://mainnet.infura.io/v3/6ad03e94d28c4a1da4dccf060be7249a')

export const startEthPayment = (order, totalPrice) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const uid = await firebase.auth().currentUser.uid

        try {
            await firestore.collection("users").doc(uid).set({tradeStatus:"STARTING"},{merge:true})
            await window.ethereum?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x1' }] })
            await window.ethereum?.request({ method: 'eth_requestAccounts' })
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner()
            const tx = await signer.sendTransaction({
                to: BANK,
                value: ethers.utils.parseEther(totalPrice + ""),
            });
            
            //verify correct payment
            await firestore.collection("users").doc(uid).set({tradeStatus:"VERIFYING"},{merge:true})
            await new Promise(r => setTimeout(r, 5000)); //wait
            let txCheck = await web3Http.eth.getTransaction(tx.hash)
            .catch((error)=> {
                console.log("GetTransaction Error" + error)
            })
            var verifyEthTransaction = await firebase.functions().httpsCallable('verifyEthTransaction');
            if(txCheck.hash === null) {
                await firestore.collection("users").doc(uid).set({tradeStatus:"CHECKING ETHERSCAN"},{merge:true})
                await new Promise(r => setTimeout(r, 30 * 1000)); //wait
                const apiUrl = "https://api.etherscan.io/api?module=account&action=txlist&address=" + tx.from + "&startblock=00000000&endblock=25760190&offset=10&sort=desc&apikey=5Z4I7V95V27GBSR3GUV4A9HX78J8EY8U4Vh"
                console.log(apiUrl)
                let req = new XMLHttpRequest();
                req.open("Get", apiUrl, false);
                req.setRequestHeader("Content-Type", 'application/json');
                req.send(null);
                await new Promise(r => setTimeout(r, 5000)); //wait
                const response = JSON.parse(req.responseText)
                await firestore.collection("users").doc(uid).set({tradeStatus:"LOADING"},{merge:true})
                console.log({
                    ogHash:tx.hash,
                    scanHash:response.result[0].hash
                })
                verifyEthTransaction({uid:uid, txHash:response.result[0].hash, order:order, eth:totalPrice})            
            } else {
                await firestore.collection("users").doc(uid).set({tradeStatus:"LOADING"},{merge:true})
                verifyEthTransaction({uid:uid, txHash:tx.hash, order:order, eth:totalPrice})            
            }
        } catch (error) {
            if(error.code === 4001) {
                await firestore.collection("users").doc(uid).set({tradeStatus:"READY", tradeStatusPrev:"Canceled by user"},{merge:true})
            } else {
                console.log(error)
                await firestore.collection("users").doc(uid).set({tradeStatus:"READY",tradeStatusPrev:"please report Error: "+error},{merge:true})
            }
        }
        
    }
}

export const refreshEthPayment = (txHash, item) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()
        const uid = await firebase.auth().currentUser.uid

        try {
            await firestore.collection("users").doc(uid).set({tradeStatus:"REFRESHING"},{merge:true})
            var verifyEthTransaction = await firebase.functions().httpsCallable('verifyEthTransaction');
            verifyEthTransaction({uid:uid, txHash:txHash, item:item})
        } catch (error) {
            console.log("refreshEthPayment: " + error)
        }
    }
}

export const testEtherscan = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()

        try {

            var testEtherscanFunc = await firebase.functions().httpsCallable('testEtherscan');
            testEtherscanFunc()
        } catch (error) {
            console.log("refreshEthPayment: " + error)
        }
    }
}