import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, CircularProgress, Grid} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUserInfo } from '../../../store/actions/firestoreActions';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import './ProfileOpenSeaWallet.css';
import { Alert, AlertTitle } from '@material-ui/lab';
import { attachUserOpenSea } from '../../../store/actions/openseaActions';
import { showSuccessSnackbar } from '../../../store/actions/snackbarActions';

class ProfileOpenSeaWallet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openSeaWalletError: null,
            openSeaWalletModalOpen: false,
            openSeaVerifyAttachLoading: false,
            openSeaInfoOpen: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSaveOpenSeaWalletAddress = this.handleSaveOpenSeaWalletAddress.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    async handleSaveOpenSeaWalletAddress() {
        this.setState({openSeaVerifyAttachLoading: true})
        await this.props.attachUserOpenSea()

        if (this.props.openSeaError) {
            this.setState({openSeaWalletError: this.props.openSeaError, openSeaVerifyAttachLoading: false})
        } else {
            if (this.props.openSeaUserData) {
                this.setState({openSeaVerifyAttachLoading: false})
                await this.props.updateUserInfo({openSeaWalletAddress: this.props.openSeaUserData[0]})
                this.props.showSuccessSnackbar('Successfully attached OpenSea account')
                this.setState({openSeaWalletModalOpen: false, openSeaWalletError: null, openSeaVerifyAttachLoading: false})
            } else {
                this.setState({openSeaWalletError: 'Unable to retrieve OpenSea user data', openSeaVerifyAttachLoading: false})
            }
        }
    }

    render() {
        return (
                <Grid container>
                    <Grid item xs={12}>
                        {this.props.userInfo?.openSeaWalletAddress ?
                            <div className="profile-opensea-wallet-address-container">
                                <Typography className="profile-opensea-wallet-address">
                                    <u>OpenSea</u>: {this.props.userInfo.openSeaWalletAddress}
                                </Typography>
                                <IconButton 
                                    onClick={() => this.setState({openSeaWalletModalOpen: true})} 
                                    size="small"
                                >
                                    <EditIcon/>
                                </IconButton>
                            </div>
                        :
                        <div className="profile-opensea-wallet-address-container">
                            <Button 
                                className="profile-opensea-wallet-button" 
                                color="primary"
                                variant="contained"
                                onClick={() => this.setState({openSeaWalletModalOpen: true})}
                            >
                                Attach OpenSea Wallet
                            </Button>
                            <IconButton 
                                onClick={() => this.setState({openSeaInfoOpen: true})}
                                color='primary'
                                className="profile-options-button"
                            >
                                <InfoIcon size="small"/>
                            </IconButton>
                        </div>

                        }
                        {this.renderOpenseaModal()}
                        {this.renderOpenseaInfo()}
                    </Grid>
                </Grid>
        )
    }

    renderOpenseaModal() {
        return (
            <Dialog
                open={this.state.openSeaWalletModalOpen}
                onClose={() => this.setState({openSeaWalletModalOpen: false, openSeaWalletError: null})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach OpenSea wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-opensea-wallet-modal-content">
                    {this.renderOpenSeaError()}
                    <Button 
                        onClick={this.handleSaveOpenSeaWalletAddress}
                        variant="contained"
                        color="primary"
                    >
                        {this.state.openSeaVerifyAttachLoading ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Attach with MetaMask'}
                        
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({openSeaWalletModalOpen: false, openSeaWalletError: null})}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderOpenseaInfo() {
        return (
            <Dialog
                open={this.state.openSeaInfoOpen}
                onClose={() => this.setState({openSeaInfoOpen: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attach OpenSea wallet</DialogTitle>
                <DialogContent id="alert-dialog-description" className="profile-opensea-wallet-modal-content">
                    <Typography>
                    Only needed to Verify EtherFishing NFTs from 
                    <b> <a className="home-page-link" href={'https://opensea.io/collection/etherfishing'} target="_">Opensea</a> </b>

                    <br/>
                    1. Click "Attach Opensea Wallet"<br/>
                    2. Click "Attach with 
                    <b> <a className="home-page-link" href={'https://metamask.io/'} target="_">Metamask</a></b>
                    "<br/>
                    3. Allow Etherfishing.com to read data<br/>    
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="outlined" 
                        onClick={() => this.setState({openSeaInfoOpen: false})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderOpenSeaError() {
        return (
            <div className='profile-opensea-upload-error'>
                {this.state.openSeaWalletError ? 
                <Alert severity='error'>
                    <AlertTitle>OpenSea error</AlertTitle>
                    {this.state.openSeaWalletError }
                </Alert> :
                null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        firestoreError: state.firestore.firestoreError,
        openSeaError: state.opensea.openSeaError,
        openSeaUserData: state.opensea.openSeaUserData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        attachUserOpenSea: () => dispatch(attachUserOpenSea()),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOpenSeaWallet)