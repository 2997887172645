import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import Navbar from './components/navbar/Navbar';
import SuccessSnackbar from './components/snackbar/SuccessSnackBar';
import ErrorSnackbar from './components/snackbar/ErrorSnackBar';
import UserInfoWatcher from './components/user/UserInfoWatcher';
import UserAgreement  from './components/UserAgreement/UserAgreement';
import history from './history';
import FishTank from './Bowl/FishTank';
import './App.css';
import { createTheme, ThemeProvider, Toolbar } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import AudioPlayer from './components/AudioPlayer';
import TermsOfService from './components/TermsOfService/TermsOfService';
import { signOut } from './store/actions/authActions';
import { clearUserInfo, updateUserInfo } from './store/actions/firestoreActions';
import { showSuccessSnackbar } from './store/actions/snackbarActions';

function App(props) {
  const userTheme = useSelector((state) => state.firestore.userTheme)
  const [ acceptedToS, setAcceptedTos ] = useState(props.acceptedToS)

  useEffect(() => {
    setAcceptedTos(props.acceptedToS)
  }, [props.acceptedToS])
  
  const theme = createTheme({
    typography: {
      "fontFamily": `'Chelsea Market'`,
    },
    palette: {
      primary: {
        main: "#3465b0",
        light: "#6690cb",
      },
      secondary: {
        main: "#6690cb",
      },
      background: {
        paper: userTheme === "dark" ? "#252b39" : "#ffffff"
      },
      type: userTheme
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <div style={{
          minHeight: '100vh',
        }} className="App">
        <FishTank/>
        <UserAgreement/>
        <TermsOfService 
          open={(props.uid ?? false) && (props.userInfo ?? false) && !acceptedToS }
          closeText='Logout'
          handleClose={async () => {
            await props.signOut()
            await props.clearUserInfo()
            props.showSuccessSnackbar('Successfully logged out');
            history.push('/user/login')
          }}
          handleAccept={async () => {
            setAcceptedTos(true)
            await props.updateUserInfo({acceptedToS: true})
          }}
        />
        <UserInfoWatcher/>
        <SuccessSnackbar/>
        <ErrorSnackbar/>
        <Router history={history}>
          {props.hasCast ? null : 
            <div>
              <Navbar/>
              <Toolbar style={{height: '80px'}}/>
            </div>
          }
          <AudioPlayer/>
          <Routes/>
        </Router>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth?.uid,
    userInfo: state.firestore.userInfo,
    acceptedToS: state.firestore.userInfo?.acceptedToS,
    hasCast: state.game.hasCast,
    userPlayMusic: state.firestore.userPlayMusic,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    clearUserInfo: () => dispatch(clearUserInfo()),
    showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message)),
    updateUserInfo: (info) => dispatch(updateUserInfo(info))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
