import { Button, CircularProgress, Collapse, Drawer, Grow, Fab, FormControl, IconButton, Input, InputAdornment, Paper,
     Tooltip, Typography, withStyles, Grid, ClickAwayListener, Popover, FormLabel, RadioGroup, FormControlLabel, Radio, 
     FormGroup, Checkbox, Slider, Dialog, DialogContent, TextField, Switch } from '@material-ui/core';
import { Component } from 'react';
import './FishTankPage.css'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import DoneIcon from '@material-ui/icons/Done';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import SettingsIcon from '@material-ui/icons/Settings';
import LaunchIcon from '@material-ui/icons/Launch';
import { connect } from 'react-redux';
import backgroundv0 from '../static/images/background.png'
import { updateFirstTimePopup, updateUserFish, updateUserInfo, getUserInfo } from '../store/actions/firestoreActions';
import { showErrorSnackbar } from '../store/actions/snackbarActions';
import { setHideButtons, setHideFish, setMaxFishDisplayed,setNumToLoad, setLimitFish } from '../store/actions/fishTankActions';
import DialogTitle from '../components/DialogTitle';
import { Link } from 'react-router-dom';


class FishTankPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fishListSearchInput: '',
            fishListDrawerOpen: false,
            fishListSearchOpen: false,
            fishListFilterOpen: false,
            fishBackgroundDrawerOpen: false,
            anchorEl: null,
            fishListSortClicked: false,
            sortedFishList: [],
            fishListSortOption: 'fishNum',
            fishListSortDirection: 'descending',
            fishListFilterClicked: false,
            fishListFilterLegendaryClicked: true,
            fishListFilterPositiveClicked: true,
            fishListFilterNeutralClicked: true,
            fishListFilterNegativeClicked: true,
            fishListFilterTrophyClicked: true,
            fishSettingsDrawerOpen: false,
            numToLoad: 100
        }
        
        this.handleLoginLink = this.handleLoginLink.bind(this)
        this.handleSetFullscreen = this.handleSetFullscreen.bind(this)
        this.handleOpenDrawer = this.handleOpenDrawer.bind(this)
        this.handleCloseDrawer = this.handleCloseDrawer.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.handleBackgroundOpenDrawer = this.handleBackgroundOpenDrawer.bind(this)
        this.handleBackgroundCloseDrawer = this.handleBackgroundCloseDrawer.bind(this)
        this.handleFishListSortOptionChange = this.handleFishListSortOptionChange.bind(this)
        this.handleFishListSortDirectionChange = this.handleFishListSortDirectionChange.bind(this)
        this.handleSettingsOpenDrawer = this.handleSettingsOpenDrawer.bind(this)
        this.handleSettingsCloseDrawer = this.handleSettingsCloseDrawer.bind(this)
        this.handleCloseFirstTimePopup = this.handleCloseFirstTimePopup.bind(this)
        //this.setLimitFish = this.setLimitFish.bind(this)
        //this.setNumToLoad = this.setNumToLoad.bind(this)
    }

    componentDidMount() {
        this.updateSortedFish()
        this.setHiddenFish()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userFish !== this.props.userFish) {
            this.updateSortedFish()
        }
        if (JSON.stringify(prevProps.hiddenFish) !== JSON.stringify(this.props.hiddenFish)) {
            this.setHiddenFish()
        }
    }

    handleCloseFirstTimePopup() {
        this.props.updateFirstTimePopup(false)
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    handleCheckboxChange(event) {
        this.setState({[event.target.name]: event.target.checked})
    }

    handleLoginLink() {
        this.props.history.push('/user/login')
    }

    handleSetFullscreen() {
        const elem = document.getElementById("fishtank")
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        }
    }

    handleOpenDrawer() {
        this.setState({fishListDrawerOpen: true})
    }

    handleCloseDrawer() {
        this.setState({fishListDrawerOpen: false})
    }

    handleBackgroundOpenDrawer() {
        this.setState({fishBackgroundDrawerOpen: true})
    }

    handleBackgroundCloseDrawer() {
        this.setState({fishBackgroundDrawerOpen: false})
    }

    handleSettingsOpenDrawer() {
        this.setState({fishSettingsDrawerOpen: true})
    }

    handleSettingsCloseDrawer() {
        this.setState({fishSettingsDrawerOpen: false})
    }

    async handleChangeBackground(background) {
        await this.props.updateUserInfo({background})
    }

    handleRenameFish(index, fish) {
        this.setState({ ['fishTankItemUploadingTitle' + index]: true }, async () => {
            await this.props.updateUserFish(fish.fishNum, this.state["fishListTitleInput" + index])
            if (this.props.updateUserFishError) {
                this.props.showErrorSnackbar(`Unable to change etherfish name\n${this.props.updateUserFishError}`)
            } else {
                this.setState({ ['fishTankItemEditingTitle' + index]: !this.state['fishTankItemEditingTitle' + index] })
            }
            this.setState({ ['fishTankItemUploadingTitle' + index]: false })
        })
    }

    handleFishListSortOptionChange(event) {
        this.setState({ fishListSortOption: event.target.value }, () => {
            this.updateSortedFish()
        })
    }

    handleFishListSortDirectionChange(event) {
        this.setState({ fishListSortDirection: event.target.value }, () => {
            this.updateSortedFish()
        })
    }

    updateSortedFish() {
        const sortedFish = this.state.fishListSortOption === 'fishNum' ?
            this.props.userFish?.sort((a, b) => {
                if (b.name?.includes('Beta Fish')) return -1
                const aNum = parseInt(a.fishNum?.replace('#', '')) ?? -1
                const bNum = parseInt(b.fishNum?.replace('#', '')) ?? -1
                return aNum < bNum ? 1 : aNum > bNum ? -1 : 0
            }) :
            this.state.fishListSortOption === 'traits' ?
                this.props.userFish?.sort((a, b) => {
                    if (b.name?.includes('Beta Fish')) return -1
                    const aTotal = (a?.fishTraitsLegendary?.length ?? 0) + (a?.fishTraitsNegative?.length ?? 0) + (a?.fishTraitsNeutral?.length ?? 0) + (a?.fishTraitsPositive?.length ?? 0)
                    const bTotal = (b?.fishTraitsLegendary?.length ?? 0) + (b?.fishTraitsNegative?.length ?? 0) + (b?.fishTraitsNeutral?.length ?? 0) + (b?.fishTraitsPositive?.length ?? 0)
                    return aTotal > bTotal ? -1 : aTotal < bTotal ? 1 : 0
                }) :
                this.state.fishListSortOption === 'species' ?
                    this.props.userFish?.sort((a, b) => {
                        if (b.name?.includes('Beta Fish')) return -1
                        return a.species < b.species ? -1 : a.species > b.species ? 1 : 0
                    }) :
                    this.state.fishListSortOption === 'name' ?
                        this.props.userFish?.sort((a, b) => {
                            if (b.name?.includes('Beta Fish') || !b.fishName) return -1
                            return a.fishName < b.fishName ? -1 : a.fishName > b.fishName ? 1 : 0
                        }) :
                        this.props.userFish

        const orderedFish = this.state.fishListSortDirection === 'ascending' ?
            sortedFish?.reverse() :
            sortedFish

        this.setState({sortedFishList: orderedFish})
    }

    setHiddenFish() {
        this.props.hiddenFish?.forEach((fishNum) => {
            this.setState({ ['fishTankItemHidden' + fishNum]: true })
        })
    }

    render() {
        return (
          <div className="fish-tank-overlay-page">
            {this.props.user?.uid ?
                <div>
                    {this.props.loadingFishTank ? 
                        <div className="fish-tank-loading-container">
                            <Typography variant="h3" className="fish-tank-loading-text">
                                Loading
                            </Typography>
                            <CircularProgress/>
                        </div>
                    : null}
                    <div className="fish-tank-loading-container">
                            {this.props.userInfo !== null && this.props.userInfo?.fish?.length === 0 ? this.renderNoFishDisplay() : null}
                    </div>
                    <div>{this.renderFishTankOptions()}</div>
                    {this.renderFirstTimePopup()}
                </div>
            :
                <div>{this.renderLoginText()}</div>
            }
          </div>
        )
    }

    renderNoFishDisplay() {
        return (
            <Paper style={{padding: '15px'}}>
                <Typography variant="h3">Head to Go Fish and catch your first Etherfish!</Typography>
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => this.props.history.push('/game')}
                >
                    Go fish!
                </Button>
            </Paper>
        )
    }

    renderFishTankOptions() {
        return (
            <div className="fish-tank-options">
                {this.renderSettings()}
                {this.renderBackgrounds()}
                {this.renderFishList()}
                <Tooltip title={this.props.buttonsHidden ? "Show" : "Hide"}>
                    <Fab className="fish-tank-zen-button" color="primary" aria-label="zen" size="small" onClick={() => this.props.setHideButtons(!this.props.buttonsHidden)}>
                        {this.props.buttonsHidden ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                    </Fab>
                </Tooltip>
                <Grow in={!this.props.buttonsHidden} style={{transformOrigin: 'top'}}>
                    <div className="fish-tank-options">
                        <Tooltip title="Settings">
                            <Fab className="fish-tank-zen-button" color="primary" aria-label="zen" size="small" onClick={this.handleSettingsOpenDrawer}>
                                <SettingsIcon/>
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Fullscreen">
                            <Fab className="fish-tank-zen-button" color="primary" aria-label="zen" size="small" onClick={this.handleSetFullscreen}>
                                <FullscreenIcon/>
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Backgrounds">
                            <Fab className="fish-tank-zen-button" color="primary" aria-label="zen" size="small" onClick={this.handleBackgroundOpenDrawer}>
                                <WallpaperIcon/>
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Fish List">
                            <Fab className="fish-tank-zen-button" color="primary" aria-label="list" size="small" onClick={this.handleOpenDrawer}>
                                <MenuOpenIcon/>
                            </Fab>
                        </Tooltip>
                    </div>
                </Grow>
            </div>
        )
    }

    renderNumToLoad() {
        return 
    }

    renderSettings() {
        const { classes } = this.props;
        return (
            <Drawer
                className="fish-tank-list-drawer"
                classes={{ paper: classes.drawerPaper }}
                variant="persistent"
                anchor="right"
                open={this.state.fishSettingsDrawerOpen}
            >
                <div>
                    <Typography className="fish-tank-drawer-title" variant="h5">Settings</Typography>
                    <div style={{ flexGrow: 1 }}>
                        <Tooltip title="Close">
                            <IconButton className="fish-tank-drawer-button" onClick={this.handleSettingsCloseDrawer}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="fish-tank-settings-body">
                    <Typography style={{color: 'white'}} variant="h5">Max fish to display</Typography>
                    <Slider
                        defaultValue={this.props.maxFish}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}%`}
                        onChangeCommitted={(event, value) => this.props.setMaxFishDisplayed(value)}
                        step={10}
                        marks
                        min={0}
                        max={100}
                        color="secondary"
                    />
                    {(this.props.user.uid ==="G0GxUzHUJsaSSrwolFgaM58Cpu72" || this.props.user.uid ==="0NIqpcBWaxV2yJM7ZIRlGlbPGFm1" || this.props.user.uid ==="kcTxb41JfUdlhrtb5ViFjMAs3L02" ?
                        <div>
                            <Typography style={{color: 'white'}} variant="h6">Number of fish to load</Typography>
                            <FormControl>
                                <FormControlLabel
                                    control={<Switch 
                                        style={{color: 'white'}} 
                                        checked={this.props.userInfo?.settings?.limitFish ?? false}
                                        onChange={()=>{
                                            this.props.setLimitFish(!(this.props.userInfo?.settings?.limitFish ?? false))
                                        }} 
                                        name="limitFish"
                                    />}
                                    label="Limit Fish"
                                    labelPlacement="end"
                                />
                            </FormControl>
                            {((this.props.userInfo?.settings?.limitFish ?? false) ?
                                <div>
                                    <TextField
                                        id="standard-multiline-static"
                                        label="Num To Load"
                                        name="numToLoad"
                                        autoFocus
                                        value={this.state.numToLoad}
                                        onChange={ this.handleChange }
                                        variant="outlined"
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => { 
                                            this.props.setNumToLoad(this.state.numToLoad)
                                            this.props.getUserInfo()
                                        }}
                                    > Set
                                    </Button>
                                    <Typography style={{color: 'white'}} variant="h6"> Refresh page in a seconed and it will load the amount specified</Typography>
                                </div> : null 
                            )}
                        </div> : null 
                    )}
                    
                    

                </div>
                {/* <div className="fish-tank-settings-body">
                    <Typography variant="h5">Layer depth</Typography>
                    <Slider
                        defaultValue={this.props.maxFish}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => `${value}%`}
                        onChangeCommitted={(event, value) => this.props.setMaxFishDisplayed(value)}
                        step={1}
                        marks
                        min={0}
                        max={5}
                        color="secondary"
                    />
                </div> */}
            </Drawer>
        )
    }

    renderBackgrounds() {
        const { classes } = this.props;
        return (
            <Drawer
                className="fish-tank-list-drawer"
                classes={{paper: classes.drawerPaper}}
                variant="persistent"
                anchor="right"
                open={this.state.fishBackgroundDrawerOpen}
            >
                <div>
                    <Typography className="fish-tank-drawer-title" variant="h5">Change Background</Typography>
                    <div style={{flexGrow: 1}}>
                        <Tooltip title="Close">
                            <IconButton className="fish-tank-drawer-button" onClick={this.handleBackgroundCloseDrawer}>
                                <ChevronRightIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Button onClick={() => this.handleChangeBackground({version: 'v0', imageUrl: null})}>
                    <img 
                        className="fish-tank-background-image" 
                        src={backgroundv0} 
                        alt=""
                    />
                    <Typography className="fish-tank-background-text">v0</Typography>
                </Button>
                {this.props.userBackgrounds?.map((background, index) => (
                    <Button key={index} onClick={() => this.handleChangeBackground(background)}>
                        <img 
                            className="fish-tank-background-image" 
                            src={background.localImageUrl} 
                            alt=""
                        />
                        <Typography className="fish-tank-background-text">{background.version}</Typography>
                    </Button>
                ))}
            </Drawer>
        )
    }

    renderFishList() {
        const { classes } = this.props;

        return (
            <Drawer
                className="fish-tank-list-drawer"
                classes={{paper: classes.drawerPaper}}
                variant="persistent"
                anchor="right"
                open={this.state.fishListDrawerOpen}
            >
                <div>
                    <Typography className="fish-tank-drawer-title" variant="h5">Fish List</Typography>
                    {this.renderFishListButtons()}
                    {this.renderFishListSearch()}
                </div>
                {this.props.loadingFishTank ? 
                    <div/>
                :
                    <div>
                        {this.state.sortedFishList?.filter((fish) => {
                            // Only filter when one is not clicked
                            if (!(this.state.fishListFilterLegendaryClicked && this.state.fishListFilterPositiveClicked && this.state.fishListFilterNegativeClicked && this.state.fishListFilterNeutralClicked && this.state.fishListFilterTrophyClicked)) {
                                if ((!this.state.fishListFilterLegendaryClicked && fish?.fishTraitsLegendary?.length > 0) ||
                                    (!this.state.fishListFilterPositiveClicked && fish?.fishTraitsPositive?.length > 0) ||
                                    (!this.state.fishListFilterNeutralClicked && fish?.fishTraitsNeutral?.length > 0) ||
                                    (!this.state.fishListFilterNegativeClicked && fish?.fishTraitsNegative?.length > 0) ||
                                    (!this.state.fishListFilterTrophyClicked && fish.trophy)
                                ) {
                                    return false
                                }
                            }

                            return fish.fishNum.includes(this.state.fishListSearchInput) || 
                                fish.fishName?.toLowerCase().includes(this.state.fishListSearchInput.toLowerCase()) ||
                                fish.species?.toLowerCase().includes(this.state.fishListSearchInput.toLowerCase()) ||
                                fish.group?.toLowerCase().includes(this.state.fishListSearchInput.toLowerCase())
                        }).map((fish, index) => (
                            <Paper className="fish-tank-list-card" key={index}>
                                {this.renderFishListRename(fish, index)}
                                <Button 
                                    onClick={() => this.setState({['fishTankItemOpen'+index]: !this.state['fishTankItemOpen'+index]})} 
                                    className="fish-tank-list-button"
                                    disableRipple
                                >
                                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <img className="fish-tank-list-image" src={fish.imageUrl} alt=""/>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }} key={fish.hidden}>
                                            {this.state['fishTankItemOpen'+index] ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                            {this.state['fishTankItemHidden' + fish.fishNum] ? <Typography style={{ "color": "grey" }}><i>Hidden</i></Typography> : null}
                                        </div>
                                    </div>
                                </Button>
                                {this.renderFishListInfo(fish, index)}
                            </Paper>
                        ))}
                    </div>
                }
            </Drawer>
        )
    }

    renderFishListButtons() {
        return (
            <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1}}>
                    <Tooltip title="Close">
                        <IconButton className="fish-tank-drawer-button" onClick={this.handleCloseDrawer}>
                            <ChevronRightIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                <Tooltip title="Search">
                    <IconButton className="fish-tank-drawer-button" onClick={() => this.setState({fishListSearchOpen: !this.state.fishListSearchOpen, fishListSearchInput: ''})}>
                        <SearchIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Filter">
                    <IconButton className="fish-tank-drawer-button" onClick={(event) => this.setState({ fishListFilterClicked: true, anchorEl: event.currentTarget })}>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Sort">
                    <IconButton className="fish-tank-drawer-button" onClick={(event) => this.setState({ fishListSortClicked: true, anchorEl: event.currentTarget })}>
                        <SortIcon/>
                    </IconButton>
                </Tooltip>
                {this.renderFishListFilter()}
                {this.renderFishListSort()}
            </div>
        )
    }

    renderFishListSearch() {
        return (
            <Collapse in={this.state.fishListSearchOpen} timeout="auto" unmountOnExit>
                <FormControl className="fish-tank-search-input">
                    <Input
                        color="secondary"
                        style={{color: 'white'}}
                        name="fishListSearchInput"
                        type='text'
                        autoFocus
                        placeholder="Search"
                        value={this.state.fishListSearchInput}
                        onChange={this.handleChange}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => this.setState({fishListSearchInput: ''})} size="small">
                                    <ClearIcon style={{color: 'white'}}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Collapse>
        )
    }

    renderFishListSort() {
        return (
            <Popover
                open={this.state.fishListSortClicked}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ fishListSortClicked: false })}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <FormControl component="fieldset" style={{ margin: '15px' }}>
                    <FormLabel component="sort-legend">Sort by</FormLabel>
                    <RadioGroup name="sort" value={this.state.fishListSortOption} onChange={this.handleFishListSortOptionChange}>
                        <FormControlLabel value="fishNum" control={<Radio />} label="Etherfish Num" />
                        <FormControlLabel value="traits" control={<Radio />} label="Traits" />
                        <FormControlLabel value="species" control={<Radio />} label="Species" />
                        <FormControlLabel value="name" control={<Radio />} label="Name" />
                    </RadioGroup>
                    <FormLabel component="order-legend">Order</FormLabel>
                    <RadioGroup name="order" value={this.state.fishListSortDirection} onChange={this.handleFishListSortDirectionChange}>
                        <FormControlLabel value="descending" control={<Radio />} label="Descending" />
                        <FormControlLabel value="ascending" control={<Radio />} label="Ascending" />
                    </RadioGroup>
                </FormControl>
            </Popover>
        )
    }

    renderFishListFilter() {
        return (
            <Popover
                open={this.state.fishListFilterClicked}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ fishListFilterClicked: false })}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <FormControl component="fieldset" style={{ margin: '15px' }}>
                    <FormLabel component="filter-legend">Filter by traits</FormLabel>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.fishListFilterLegendaryClicked}
                                    onChange={this.handleCheckboxChange}
                                    name="fishListFilterLegendaryClicked"
                                    color="primary"
                                />
                            } 
                            label="Legendary"
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.fishListFilterPositiveClicked}
                                    onChange={this.handleCheckboxChange}
                                    name="fishListFilterPositiveClicked"
                                    color="primary"
                                />
                            } 
                            label="Positive"
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.fishListFilterNeutralClicked}
                                    onChange={this.handleCheckboxChange}
                                    name="fishListFilterNeutralClicked"
                                    color="primary"
                                />
                            } 
                            label="Neutral"
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.fishListFilterNegativeClicked}
                                    onChange={this.handleCheckboxChange}
                                    name="fishListFilterNegativeClicked"
                                    color="primary"
                                />
                            } 
                            label="Negative"
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox
                                    checked={this.state.fishListFilterTrophyClicked}
                                    onChange={this.handleCheckboxChange}
                                    name="fishListFilterTrophyClicked"
                                    color="primary"
                                />
                            } 
                            label="Trophy"
                        />
                    </FormGroup>
                </FormControl>
            </Popover>
        )
    }

    renderFishListRename(fish, index) {
        return (
            <div>
                {this.state['fishTankItemEditingTitle'+index] ? 
                    <ClickAwayListener onClickAway={() => this.setState({['fishTankItemEditingTitle'+index]: !this.state['fishTankItemEditingTitle'+index]})}>
                        <FormControl className="fish-tank-list-item-title-input">
                            <Input
                                color="primary"
                                name={"fishListTitleInput"+index}
                                type='text'
                                autoFocus
                                inputProps={{maxLength: 20}}
                                value={this.state["fishListTitleInput"+index]}
                                onChange={this.handleChange}
                                onKeyPress={(event) => {
                                    if (event.code === 'Enter') this.handleRenameFish(index, fish)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => this.setState({['fishTankItemEditingTitle'+index]: !this.state['fishTankItemEditingTitle'+index]})} size="small">
                                            <ClearIcon/>
                                        </IconButton>
                                        <IconButton onClick={() => this.handleRenameFish(index, fish)} size="small">
                                            {this.state['fishTankItemUploadingTitle' + index] ? <CircularProgress size="1.5rem"/> : <DoneIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </ClickAwayListener>
                :
                    <Button 
                        onClick={() => this.setState({['fishTankItemEditingTitle'+index]: !this.state['fishTankItemEditingTitle'+index], ["fishListTitleInput"+index]: fish.fishName ?? fish.fishNum})}
                        className="fish-tank-list-title-button" 
                        fullWidth
                    >
                        <Typography variant="h5">{fish.fishName ?? fish.fishNum}</Typography>
                    </Button>
                }
            </div>
        )
    }

    renderFishListInfo(fish, index) {
        return (
            <Collapse in={this.state['fishTankItemOpen'+index]} timeout="auto" unmountOnExit>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Button disabled variant="contained" color="primary" fullWidth>Eat</Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button disabled variant="contained" color="primary" fullWidth>Breed</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={() => this.setState({ ['fishTankItemSettingsOpen' + index]: !this.state['fishTankItemSettingsOpen' + index]})} style={{padding: '5px'}}>
                            <SettingsIcon/>
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderFishListItemSettings(fish, index)}
                    </Grid>

                    {fish.species ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Species</b></Typography>
                            <Typography>{fish.species}</Typography>
                        </Grid>
                    : null}
                    {fish.fishNum ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Etherfish</b></Typography>
                            <Typography>{fish.fishNum}</Typography>
                        </Grid>
                    : null}
                    {fish?.fishTraitsLegendary?.length > 0 ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Legendary</b></Typography>
                            {fish?.fishTraitsLegendary.map((legendaryTrait, index) => (
                                <Typography key={index}>{legendaryTrait.trait}</Typography>
                            ))}
                        </Grid>
                    : null}
                    {fish?.fishTraitsPositive?.length > 0 ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Positive</b></Typography>
                            {fish?.fishTraitsPositive.map((positiveTrait, index) => (
                                <Typography key={index}>{positiveTrait.trait}</Typography>
                            ))}
                        </Grid>
                    : null}
                    {fish?.fishTraitsNeutral?.length > 0 ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Neutral</b></Typography>
                            {fish?.fishTraitsNeutral.map((neutralTrait, index) => (
                                <Typography key={index}>{neutralTrait.trait}</Typography>
                            ))}
                        </Grid>
                    : null}
                    {fish?.fishTraitsNegative?.length > 0 ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Negative</b></Typography>
                            {fish?.fishTraitsNegative.map((negativeTrait, index) => (
                                <Typography key={index}>{negativeTrait.trait}</Typography>
                            ))}
                        </Grid>
                    : null}
                    {fish.trophy ? 
                        <Grid item xs={6} className="fish-list-item-grid">
                            <Typography variant="h6"><b>Trophy</b></Typography>
                            <Typography>{fish.trophy}</Typography>
                        </Grid>
                    : null}
                </Grid>
            </Collapse>
        )
    }

    renderFishListItemSettings(fish, index) {
        return (
            <Collapse in={this.state['fishTankItemSettingsOpen' + index]} timeout="auto" unmountOnExit className="fish-list-item-settings">
                <Button 
                    startIcon={<LaunchIcon />} 
                    className="fish-list-item-settings-open-page"
                    onClick={() => this.props.history.push(`/fish/${fish.fishNum?.replace('#', '')}`)}
                    color="primary"
                    variant="contained"
                >
                    Open fish page
                </Button>
                <FormControlLabel
                    className="fish-list-item-settings-checkbox"
                    control={
                        <Checkbox 
                            name={'fishTankItemHidden' + fish.fishNum} 
                            checked={this.state['fishTankItemHidden' + fish.fishNum] ?? false}
                            onChange={(event) => {
                                this.setState({ [event.target.name]: event.target.checked })
                                this.props.setHideFish(fish.fishNum, event.target.checked)
                            }}
                        />
                    }
                    label="Hide fish"
                />
            </Collapse>
        )
    }

    renderLoginText() {
        return (
            <div className="fish-tank-login-container">
                <div className="fish-tank-page-grayed">
                    <Typography className="fish-tank-login-text" variant="h3">
                        Login to view your Etherfish!
                    </Typography>
                    <Button className="fish-tank-login-button" onClick={this.handleLoginLink} color="primary" variant="contained">
                        Login
                    </Button>
                </div>
            </div>
        )
    }

    renderFirstTimePopup() {
        return (
            <Dialog open={this.props.showFirstTimePopup} onClose={this.handleCloseFirstTimePopup}>
                <DialogTitle onClose={this.handleCloseFirstTimePopup}>Fish Tank</DialogTitle>
                <DialogContent dividers className="">
                    <Typography variant="h6">
                        All Etherfish NFTs will show up here!
                        <div><br/></div>
                        Explore the variety of settings, filters, information and more to come on the right side of this page!
                        <div><br/></div>
                        Head to 
                        <b> <Link className="home-page-link" to="/game">Go Fish!</Link> </b>
                        to start filling up your tank and collection!
                    </Typography>
                </DialogContent>
            </Dialog>
        )
    }

}

const styles = theme => ({
    drawerPaper: {
        height: 'calc(100% - 80px)', 
        top: 80,
        backgroundColor: '#3465b0',
        width: '330px',
        maxWidth: '330px',
    }
});

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        userFish: state.firestore.userFish,
        userBackgrounds: state.firestore.userBackgrounds,
        loadingFishTank: state.opensea.loadingFishTank,
        updateUserFishError: state.firestore.updateUserFishError,
        maxFish: state.fishTank.maxFish,
        limitFish: state.fishTank.limitFish,
        numToLoad: state.fishTank.numToLoad,

        buttonsHidden: state.fishTank.buttonsHidden,
        hiddenFish: state.fishTank.hiddenFish,
        showFirstTimePopup: state.firestore.tankFirstTimePopup,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        updateUserFish: (fishNum, fishName) => dispatch(updateUserFish(fishNum, fishName)),
        showErrorSnackbar: (message) => dispatch(showErrorSnackbar(message)),
        setMaxFishDisplayed: (maxFish) => dispatch(setMaxFishDisplayed(maxFish)),
        setHideButtons: (hidden) => dispatch(setHideButtons(hidden)),
        setHideFish: (fishNum, hidden) => dispatch(setHideFish(fishNum, hidden)),
        updateFirstTimePopup: (newVal) => dispatch(updateFirstTimePopup('tank', newVal)),
        setLimitFish: (x) => dispatch(setLimitFish(x)),
        setNumToLoad: (num) => dispatch(setNumToLoad(num)),
        getUserInfo:() => dispatch(getUserInfo())
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FishTankPage))