import { Button, FormControl, CircularProgress, FormHelperText, IconButton, Input, InputAdornment, InputLabel, Paper } from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { showSuccessSnackbar } from '../../store/actions/snackbarActions';
import { changePassword } from '../../store/actions/authActions';
import './ChangePassword.css';

class ChangePassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            password: '',
            passwordError: false,
            confirmPassword: '',
            showPassword: false,
            loading: false,
            newPasswordErrorMessage: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleShowPassword = this.handleShowPassword.bind(this)
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        // Validate returning signed in user
        if (!this.props.user.uid) {
            // Redirect to login page if not logged in
            this.props.history.push('/user/login');
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        const errorReset = name === 'passwordError'
        this.setState({[name]: target.value, [errorReset]: false})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    handleShowPassword() {
        this.setState({showPassword: !this.state.showPassword})
    }

    handleMouseDownPassword(event) {
        event.preventDefault()
    }

    handleSubmit() {
        const confirmError = this.state.password !== this.state.confirmPassword

        if (!this.state.password || !this.state.confirmPassword) {
            this.setState({passwordError: true})
        }

        if (this.state.password && !this.invalidPassword() && !confirmError) {
            // Create new user
            this.setState({loading: true}, async () => {
                await this.props.changePassword(this.state.password);

                if (this.props.auth.authError) {
                    this.setState({
                        loading: false, 
                        newPasswordErrorMessage: this.props.auth.authError
                    })
                } else {
                    // Redirect to login page after showing snackbar popup
                    this.props.showSuccessSnackbar('Successfully changed password!')
                    this.props.history.push('/user/profile')
                }
            })
        }
    }

    invalidPassword() {
        // Checks for at least 8 characters, 2 uppercase, 1 special char, 2 numbers, 3 lowercase
        const validPasswordRegex = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
        return !validPasswordRegex.test(String(this.state.password)) && this.state.password ? true : false
    }

    render() {
        return (
            <form>
                <div className="change-password">
                    <Paper className='change-password-paper ' elevation={3}>
                        <h1 className='change-password-text'>Change Password</h1>
                        <div/>
                        {this.renderChangePasswordError()}
                        <div/>
                        {this.renderPasswordInput()}
                        <div/>
                        {this.renderConfirmPasswordInput()}
                        <div/>
                        {this.renderSubmitButton()}
                    </Paper>
                </div>
            </form>
        )
    }

    renderChangePasswordError() {
        return (
            <div className='change-password-error-message'>
                {this.state.newPasswordErrorMessage ? 
                <Alert severity='error'>
                    <AlertTitle>Change password error</AlertTitle>
                    {this.state.newPasswordErrorMessage}
                </Alert> :
                ""}
            </div>
        )
    }

    renderPasswordInput() {
        return (
            <FormControl className="change-password-input">
                <InputLabel htmlFor="change-password-comp">New Password</InputLabel>
                <Input
                    id="change-password-comp"
                    name='password'
                    type={this.state.showPassword ? 'text' : 'password'}
                    error={this.state.passwordError || this.invalidPassword()}
                    value={this.state.password}
                    onChange={this.handleChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                {this.invalidPassword() ? 
                 <FormHelperText>
                     Must meet the required 8 characters and contain 2 uppercase, 3 lowercase, 2 numbers, and 1 special character
                 </FormHelperText> :
                 ""
                }
            </FormControl>
        )
    }

    renderConfirmPasswordInput() {
        const confirmError = this.state.password !== this.state.confirmPassword

        return (
            <FormControl className="change-password-input">
                <InputLabel htmlFor="password-confirm-comp">Confirm Password</InputLabel>
                <Input
                    id="password-confirm-comp"
                    name='confirmPassword'
                    type={this.state.showPassword ? 'text' : 'password'}
                    error={this.state.passwordError || confirmError}
                    disabled={!this.state.password}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
                {confirmError ? <FormHelperText>Password doesn't match</FormHelperText> : ""}
            </FormControl>
        )
    }

    renderSubmitButton() {
        return (
            <Button
                id='sumbit-comp'
                variant='contained'
                color='primary'
                className='change-password-submit-button'
                onClick={this.handleSubmit}
            >{this.state.loading ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Submit'}</Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (info) => dispatch(changePassword(info)),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
