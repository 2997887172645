import React, { Component } from 'react';
import { Button, CircularProgress, FormControl, Input, InputLabel, Paper, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { connect } from 'react-redux';
import { showSuccessSnackbar } from '../../store/actions/snackbarActions';
import { forgotPassword } from '../../store/actions/authActions';
import './ForgotPassword.css'

class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailError: false,
            emailErrorMessage: null,
            sendingEmailReset: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        // Validate returning signed in user
        if (this.props.user.uid) {
            // Redirect to profile page if logged in
            this.props.history.push('/user/profile');
        }
    }

    handleChange(event) {
        const target = event.target
        const name = target.name
        this.setState({[name]: target.value})
    }

    handleKeyPress(event) {
        if (event.code === 'Enter') {
            this.handleSubmit()
        }
    }

    handleSubmit() {
        // Make sure username and password are provided
        if (!this.state.email) {
            this.setState({emailError: true})
        }

        if (this.state.email) {
            // Send verification code to email
            this.setState({sendingEmailReset: true}, async () => {
                await this.props.forgotPassword(this.state.email)

                if (this.props.auth.authError) {
                    this.setState({
                        sendingEmailReset: false, 
                        emailErrorMessage: this.props.auth.authError
                    })
                } else {
                    // Redirect to login page after showing snackbar popup
                    this.props.showSuccessSnackbar('Password reset email sent!\nCheck supplied email for details')
                    this.props.history.push('/user/login')
                }
            })
        }
    }

    render() {
        return (
            <div className="forgotPassword">
                <Paper className='forgotPasswordPaper' elevation={3}>
                    <Typography variant="h4" className='forgotPasswordText'>Forgot password?</Typography>
                    <div/>
                    <Typography>Enter email address to reset</Typography>
                    <div/>
                    {this.renderForgotPasswordError()}
                    <div/>
                    {this.renderEmailField()}
                    <div/>
                    {this.renderSubmitButton()}
                </Paper>
            </div>
        );
    }

    renderForgotPasswordError() {
        return (
            <div className='forgotPasswordErrorMessage'>
                {this.state.emailErrorMessage ? 
                <Alert severity='error'>
                    <AlertTitle>Forgot password error</AlertTitle>
                    {this.state.emailErrorMessage}
                </Alert> :
                ""}
            </div>
        )
    }

    renderEmailField() {
        return (
            <FormControl className="emailReset">
                <InputLabel htmlFor="emailReset-comp">Email address</InputLabel>
                <Input
                    id="emailReset-Comp"
                    name='email'
                    type='text'
                    autoFocus
                    error={this.state.emailError}
                    value={this.state.email}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                />
            </FormControl>
        )
    }

    renderSubmitButton() {
        return (
            <Button
                id='sumbit-comp'
                variant='contained'
                color='primary'
                className='submitEmailResetButton'
                onClick={this.handleSubmit}
            >{this.state.sendingEmailReset ? <CircularProgress id='loading-comp' size='1.5rem' style={{color: 'white'}}/> : 'Submit'}</Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (email) => dispatch(forgotPassword(email)),
        showSuccessSnackbar: (message) => dispatch(showSuccessSnackbar(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)