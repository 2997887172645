export const setHideButtons = hidden => {
    return async dispatch => {
        await window.localStorage.setItem('hideFishTankButtons', hidden)
        dispatch({ type: 'SET_HIDE_BUTTONS', hidden })
    }
}

export const setMaxFishDisplayed = maxFish => {
    return async dispatch => {
        await window.localStorage.setItem('maxFish', maxFish)
        dispatch({ type: "SET_MAX_FISH", maxFish });
    };
};

export const setHideFish = (fishNum, hidden) => {
    return (dispatch, getState) => {
        const state = getState()
        let hiddenFish = state?.fishTank?.hiddenFish
        const hiddenIndex = hiddenFish.findIndex((hiddenFishNum) => hiddenFishNum === fishNum)

        if (hiddenIndex !== -1 && !hidden) {
            hiddenFish.splice(hiddenIndex, 1)
        } else if (hidden) {
            hiddenFish.push(fishNum)
        }

        window.localStorage.setItem('hiddenFish', hiddenFish.join(','))
        dispatch({ type: "SET_HIDDEN_FISH", hiddenFish })
    }
}

export const setLimitFish= (limitFish) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        let uid = firebase.auth().currentUser?.uid
        await firestore.collection('users').doc(uid).set({ settings: {limitFish} }, { merge: true })
    };
}

export const setNumToLoad= (numToLoad) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase()
        const firestore = getFirestore()
        let uid = firebase.auth().currentUser?.uid
        await firestore.collection('users').doc(uid).set({ settings: { numToLoad} }, { merge: true })
    };
}