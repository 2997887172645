import DoneIcon from '@material-ui/icons/Done';
import React, { Component } from 'react';
import { Divider, Grid, Icon, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { connect } from 'react-redux';
import './ProfileCollectibles.css'
import '../Profile.css'
import clamPic from '../../../static/images/Collectibles/clam.png'
import spongePic from '../../../static/images/Collectibles/sponge.png'
import shrimpPic from '../../../static/images/Collectibles/shrimp.png'
import lobsterPic from '../../../static/images/Collectibles/lobster.png'
import starfishPic from '../../../static/images/Collectibles/starfish.png'
import crabPic from '../../../static/images/Collectibles/crab.png'
import treasuePic from '../../../static/images/Collectibles/treasure.png'

class ProfileCollectibles extends Component {
    render() {
        return (
            <div className="profile-collectibles-container">
                <div className="profile-items-title">
                    <Typography variant="h4">Collectibles</Typography>
                </div>
                <Divider className="profile-divider"/>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={'https://firebasestorage.googleapis.com/v0/b/etherfishing-4215.appspot.com/o/collectibles%2FtrophyPiece.png?alt=media&token=b60e2292-4410-4d39-9668-c7f724d15830'} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Trophy <br></br>Pieces: {this.props.userInfo?.collectibles?.trophyPiece?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        {this.renderTrophyStepper(this.props.userInfo?.collectibles?.trophyPiece?.count)}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={clamPic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Clams: {this.props.userInfo?.collectibles?.clam?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.clam?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.clam?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.clam?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={crabPic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Crabs: {this.props.userInfo?.collectibles?.crab?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.crab?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.crab?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.crab?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={lobsterPic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Lobsters: {this.props.userInfo?.collectibles?.lobster?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.shrimp?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.lobster?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.lobster?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={shrimpPic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Shrimp: {this.props.userInfo?.collectibles?.shrimp?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.shrimp?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.shrimp?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.shrimp?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={spongePic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Sponges: {this.props.userInfo?.collectibles?.sponge?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.sponge?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.sponge?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.sponge?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={starfishPic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Starfish: {this.props.userInfo?.collectibles?.starfish?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.starfish?.count ?? 0) <= 100 ?
                            this.renderCollectibleStepper(this.props.userInfo?.collectibles?.starfish?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.starfish?.count)
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className="collectible-grid-item" xs={12} md={2}>
                        <img src={treasuePic} alt={''} className="collectible-image"/>
                        <Typography variant="h6">
                            Treasure: {this.props.userInfo?.collectibles?.treasure?.count ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item className="collectible-grid-item" xs={12} md={10}>
                        { 
                            (this.props.userInfo?.collectibles?.treasure?.count ?? 0) <= 20 ?
                            this.renderTreasureStepper(this.props.userInfo?.collectibles?.treasure?.count) :
                            this.renderWheelStepper(this.props.userInfo?.collectibles?.treasure?.count, true)
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
    
    renderCollectibleStepper(numCollectible) {
        let numCollectibles = numCollectible ?? 0
        let currentStep =   numCollectibles >= 100 ? 7 :
                            numCollectibles >= 70 ? 6 :
                            numCollectibles >= 45 ? 5 :
                            numCollectibles >= 25 ? 4 :
                            numCollectibles >= 10 ? 3 :
                            numCollectibles >= 5 ? 2 :
                            numCollectibles >= 1 ? 1 :
                            0

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal" : "vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1)}>
                        +0.3% Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5)}>
                        +2.5% Cast Time reduction
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 10)}>
                        +3% Bait effectiveness
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 25)}>
                        +0.4% Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 45)}>
                        +3% Cast Time reduction
                    </StepLabel>
                </Step>
                <Step key={6}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 70)}>
                        +4.5% Bait effectiveness
                    </StepLabel>
                </Step>
                <Step key={7}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 100)}>
                        +0.001% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    renderTreasureStepper(numTreasure) {
        let currentStep =   numTreasure >= 20 ? 7 :
                            numTreasure >= 15 ? 6 :
                            numTreasure >= 10 ? 5 :
                            numTreasure >= 5 ? 4 :
                            numTreasure >= 3 ? 3 :
                            numTreasure >= 2 ? 2 :
                            numTreasure >= 1 ? 1 :
                            0

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal" : "vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1)}>
                        +0.001% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 2)}>
                        +6% Cast Time reduction
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 3)}>
                        +5% Bait effectiveness
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5)}>
                        +0.001% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 10)}>
                        +0.002% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={6}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 15)}>
                        +1% Etherfish catch percentage
                    </StepLabel>
                </Step>
                <Step key={7}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 20)}>
                        +0.003% Golden Etherfish catch percentage
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    renderWheelStepper(numCollectible, treasure= false) {
        let currentStep 

        if(!treasure) {
            currentStep =   numCollectible % 25 >= 25 ? 5 :
                            numCollectible % 25 >= 20 ? 4 :
                            numCollectible % 25 >= 15 ? 3 :
                            numCollectible % 25 >= 10 ? 2 :
                            numCollectible % 25 >= 5 ? 1 :
                            0
        }else {
            currentStep =   numCollectible % 5 >= 5 ? 5 :
                            numCollectible % 5 >= 4 ? 4 :
                            numCollectible % 5 >= 3 ? 3 :
                            numCollectible % 5 >= 2 ? 2 :
                            numCollectible % 5 >= 1 ? 1 :
                            0
        }

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal" : "vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1)}>
                        20%
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 2)}>
                        40%
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 3)}>
                        60%
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 4)}>
                        80%
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5)}>
                        100% +1 wheel o' Fish Spin
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    renderTrophyStepper(numTrophy) {
        let currentStep =   numTrophy >= 5 ? 5 :
                            numTrophy >= 4 ? 4 :
                            numTrophy >= 3 ? 3 :
                            numTrophy >= 2 ? 2 :
                            numTrophy >= 1 ? 1 :
                            0

        return (
            <Stepper
                className="profile-boat-stepper"
                style={{margin: '20px'}}
                alternativeLabel={window.innerWidth > 960}
                orientation={window.innerWidth > 960 ? "horizontal" : "vertical"}
                activeStep={currentStep}
            >
                <Step key={1}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 1)}>
                        20%
                    </StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 2)}>
                        40%
                    </StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 3)}>
                        60%
                    </StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 4)}>
                        80%
                    </StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel StepIconComponent={(props) => this.renderStepperIcon(props, 5)}>
                        100% - Season Trophy NFT
                    </StepLabel>
                </Step>
            </Stepper>
        )
    }

    renderStepperIcon(props, num) {
        const { active, completed } = props;

        return (
            <Icon 
                fontSize='small'
                className="profile-referrals-stepper-icon"
                style={{ backgroundColor: completed ? '#3465b0' : active ? '#6690cb' : 'grey' }}
            >
                {completed ? <DoneIcon /> : <Typography>{num}</Typography>}
            </Icon>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCollectibles)