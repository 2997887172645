import React, { Component } from "react";
import PropTypes from 'prop-types';
import TabPanel from "../../../components/tabs/TabPanel";
import { AppBar, Button, Tab, Tabs, Typography, Grid, Paper, Dialog, Drawer, IconButton, Tooltip, DialogTitle, DialogContent, DialogActions, Divider} from "@material-ui/core";
import { connect } from "react-redux";
import baseRod from "../../../static/images/GameImages/Fishing_Pole.png"
import freeRod from '../../../static/images/GameImages/F2PPole.png'
import baseBoat from "../../../static/images/GameImages/boat.png"
import './GameMenu.css'
import { setCurrentBoat, setCurrentPole } from "../../../store/actions/gameActions";
import { withStyles } from "@material-ui/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getLeaderboards } from "../../../store/actions/firestoreActions";
import CastLog from "../../../components/CastLog/CastLog"

class GameMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            gameMenuOpen: false,
            tabValue: 0,
            leaderTabValue: 0,
            helpModalOpen: false,
        }

        this.handleMenuOpen = this.handleMenuOpen.bind(this)
        this.handleMenuClose = this.handleMenuClose.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleLeaderTabChange = this.handleLeaderTabChange.bind(this)
        this.handleHelpModalOpen = this.handleHelpModalOpen.bind(this)
        this.handleHelpModalClose = this.handleHelpModalClose.bind(this)
    }

    async componentDidMount() {
        await this.props.getLeaderboards()
    }

    handleMenuOpen(event) {
        this.setState({ anchorEl: event.currentTarget, gameMenuOpen: true })
    }

    handleMenuClose() {
        this.setState({ anchorEl: null, gameMenuOpen: false })
    }

    handleTabChange(event, newValue) {
        this.setState({ tabValue: newValue })
    }    
    
    handleLeaderTabChange(event, newValue) {
        this.setState({ leaderTabValue: newValue })
    }

    handleChangePole(poleNum) {
        this.props.setCurrentPole(poleNum)
    }
    
    handleChangeBoat(boatNum) {
        this.props.setCurrentBoat(boatNum)
    }

    handleHelpModalOpen() {
        this.setState({ helpModalOpen: true })
    }

    handleHelpModalClose() {
        this.setState({ helpModalOpen: false })
    }

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    a11yLeaderProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { classes } = this.props;
        TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        };

        return (
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleMenuOpen}
                >
                    <Typography variant={window.innerWidth > 960 && !this.props.condensedLayout ? "h4" : "h6"}>
                        Menu
                    </Typography>
                </Button>
                <Drawer
                    className="fish-tank-list-drawer"
                    classes={{ paper: classes.drawerPaper }}
                    variant="persistent"
                    anchor="right"
                    open={this.state.gameMenuOpen}
                    onClose={this.handleMenuClose}
                >
                    <div className={"game-menu-container"}>
                        <Grid className={"game-menu-container"} container>
                            <Grid item xs={1}>
                                <Tooltip title="Close" >
                                    <IconButton className="fish-tank-drawer-button" onClick={this.handleMenuClose}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} sm={3}/>
                            <Grid item xs={4} >
                                <Typography variant="h5" style={{ justifyContent: 'center', width: '100%', color: 'white' }}>Game menu</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <CastLog/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <Button onClick={this.handleHelpModalOpen} disableElevation style={{ margin: '5px' }} variant="contained" color="secondary">
                                    Tutorial
                                </Button>
                            </Grid>
                        </Grid>
                        

                        
                    </div>
                    <AppBar
                        position="static"
                        color="default"
                    >
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Top Fishers" {...this.a11yProps(0)} />
                            <Tab label="Stats" {...this.a11yProps(1)} />
                            <Tab label="Poles" {...this.a11yProps(2)} />
                            <Tab label="Boats" {...this.a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.tabValue} index={0}>
                        {this.renderLeaderboardTab()}
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>
                        {this.renderStatsTab()}
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={2}>
                        {this.renderRodsTab()}
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={3}>
                        {this.renderBoatsTab()}
                    </TabPanel>
                </Drawer>
                {this.renderHelpModal()}
            </div>
        )
    }

    renderStatsTab() {
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={9}>
                        <Typography variant="h6" className="game-menu-typography-white">Etherfish Catch Percentage: </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={3}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {Math.round((((this.props.userInfo?.playerEtherfishCatchPercentage ?? 0) + 25) + Number.EPSILON) * 10000) / 10000}%
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={9}>
                        <Typography variant="h6" className="game-menu-typography-white">Golden Etherfish Catch Percentage: </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={3}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {Math.round((((this.props.userInfo?.playerGoldenCatchPercentage ?? 0) + 0.037) + Number.EPSILON) * 10000) / 10000}%
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={9}>
                        <Typography variant="h6" className="game-menu-typography-white">Etherbait effectiveness: </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={3}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +{Math.round(((this.props.userInfo?.playerBaitEffectiveness ?? 0) + Number.EPSILON) * 10000) / 10000}%
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={9}>
                        <Typography variant="h6" className="game-menu-typography-white">Cast Reduction time: </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={3}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {Math.round(((this.props.userInfo?.playerCastTimeReduction ?? 0) + Number.EPSILON) * 10000) / 10000}%
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderRodsTab() {
        return (
            <div className="game-menu-rod-main-container">
                {!this.props.userCurrentPole || !this.props.userPoles?.some(pole => pole.poleNum === 'starting') ?
                    <Button disabled={!this.props.userInfo} disableRipple className="game-menu-rod-button" onClick={() => this.handleChangePole('starting')}>
                        <Paper
                            className="game-menu-rod-container"
                            style={{ border: this.props.userCurrentPole === 'starting' ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none' }}
                        >
                            <Typography className="game-menu-rod-text">Starting</Typography>
                            <img className="game-menu-rod-image" src={baseRod} alt="" />
                        </Paper>
                    </Button>
                    :
                    null
                }
                {!this.props.userCurrentPole || !this.props.userPoles?.some(pole => pole.poleNum === 'free') ?
                    <Button disabled={!this.props.userInfo} disableRipple className="game-menu-rod-button" onClick={() => this.handleChangePole('free')}>
                        <Paper
                            className="game-menu-rod-container"
                            style={{ border: this.props.userCurrentPole === 'free' ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none' }}
                        >
                            <Typography className="game-menu-rod-text">Free</Typography>
                            <img className="game-menu-rod-image" src={freeRod} alt="" />
                        </Paper>
                    </Button>
                    :
                    null
                }
                {this.props.userPoles?.map((pole, index) => (
                    <Button disabled={!this.props.userInfo} disableRipple key={index} className="game-menu-rod-button" onClick={() => this.handleChangePole(pole.poleNum)}>
                        <Paper
                            className="game-menu-rod-container"
                            style={{ border: this.props.userCurrentPole === pole.poleNum ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none' }}
                        >
                            <Typography className="game-menu-rod-text">{pole.name}</Typography>
                            <img className="game-menu-rod-image" src={pole.localImageUrl} alt="" />
                        </Paper>
                    </Button>
                ))}
            </div>
        )
    }

    renderBoatsTab() {
        return (
            <div className="game-menu-rod-main-container">
                {!this.props.userCurrentBoat || !this.props.userBoats?.some(boat => boat.boatNum === 'starting') ?
                    <Button disabled={!this.props.userInfo} disableRipple className="game-menu-boat-button" onClick={() => this.handleChangeBoat('starting')}>
                        <Paper 
                            className="game-menu-boat-container" 
                            style={{ border: this.props.userCurrentBoat === 'starting' ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none' }}
                        >
                            <Typography className="game-menu-boat-text">Starting</Typography>
                            <img className="game-menu-boat-image" src={baseBoat} alt="" />
                        </Paper>
                    </Button>
                    :
                    null
                }
                {this.props.userBoats?.map((boat, index) => (
                    <Button disabled={!this.props.userInfo} disableRipple key={index} className="game-menu-boat-button" onClick={() => this.handleChangeBoat(boat.boatNum)}>
                        <Paper
                            className="game-menu-boat-container"
                            style={{ border: this.props.userCurrentBoat === boat.boatNum ? `medium solid ${this.props.userTheme === 'dark' ? '#ffffff' : '#252b39'}` : 'none' }}
                        >
                            <Typography className="game-menu-boat-text">{boat.name}</Typography>
                            <img className="game-menu-boat-image" src={boat.localImageUrl} alt="" />
                        </Paper>
                    </Button>
                ))}
            </div>
        )
    }

    renderBaitTab() {
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">1★: {this.props.userInfo?.bait?.star1?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +1% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">2★: {this.props.userInfo?.bait?.star2?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +2% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">3★: {this.props.userInfo?.bait?.star3?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +3% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                 <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">4★: {this.props.userInfo?.bait?.star4?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +4% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">5★: {this.props.userInfo?.bait?.star5?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +5% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">Super: {this.props.userInfo?.bait?.super?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +6% Etherfish Catch %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">Legendary: {this.props.userInfo?.bait?.legendary?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +4% Etherfish Catch Rarity
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="game-menu-stats-container">
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">Legendary: {this.props.userInfo?.bait?.legendary?.count ?? 0}</Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            +6% Etherfish Catch % <br/>
                            +2% Etherfish Catch Rarity
                        </Typography>
                    </Grid>
                </Grid>  
                <Grid container className="game-menu-rod-main-container">
                <Button 
                        className="home-page-help-button"
                        color="secondary" 
                        variant="contained"
                        onClick={() => this.props.history.push('/help')}
                    >
                        <Typography variant="h5">Market</Typography>
                </Button>
                </Grid>              
                
            </div>
        )
    }

    renderLeaderboardTab() {
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <AppBar
                        position="static"
                        color="default"
                    >
                        <Tabs
                            value={this.state.leaderTabValue}
                            onChange={this.handleLeaderTabChange}
                            aria-label="simple tabs example"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Current" {...this.a11yLeaderProps(0)} />
                            <Tab label="Overall" {...this.a11yLeaderProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.leaderTabValue} index={0}>
                        {this.rendeCurrentBoardTab()}
                    </TabPanel>
                    <TabPanel value={this.state.leaderTabValue} index={1}>
                        {this.renderOverallBoardTab()}
                    </TabPanel>      
                    <Button 
                        className="home-page-help-button"
                        color="secondary" 
                        variant="contained"
                        onClick={() => this.props.getLeaderboards()}
                    >
                        <Typography variant="h5">Refresh</Typography>
                </Button>   
                
            </div>
        )
    }

    rendeCurrentBoardTab() {
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <Grid container className="game-menu-stats-container">
                    <Typography variant="h4" className="game-menu-typography-white">
                        Current Leaderboard
                    </Typography>
                    <Divider className="game-divider"/>
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            1st <br/>
                            2nd <br/>
                            3rd <br/>
                            4th <br/>
                            5th <br/>
                        </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {this.props.currentLeaderboard[0]}  <br/>
                            {this.props.currentLeaderboard[1]}  <br/>
                            {this.props.currentLeaderboard[2]}  <br/>
                            {this.props.currentLeaderboard[3]}  <br/>
                            {this.props.currentLeaderboard[4]}  <br/>
                        </Typography>
                    </Grid>
                    <Divider className="game-divider"/>
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            you
                        </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {this.props?.userInfo?.contestCounter ?? 0}
                        </Typography>
                    </Grid>
                </Grid>            
            </div>
        )
    }
    
    renderOverallBoardTab() {
        return (
            <div style={{ width: '100%', padding: '10px' }}>
                <Grid container className="game-menu-stats-container">
                    <Typography variant="h4" className="game-menu-typography-white">
                        Overall Leaderboard
                    </Typography>
                    <Divider className="game-divider"/>
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            1st <br/>
                            2nd <br/>
                            3rd <br/>
                            4th <br/>
                            5th <br/>
                        </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {this.props.overallLeaderboard[0]} <br/>
                            {this.props.overallLeaderboard[1]}  <br/>
                            {this.props.overallLeaderboard[2]}  <br/>
                            {this.props.overallLeaderboard[3]}  <br/>
                            {this.props.overallLeaderboard[4]}  <br/>
                        </Typography>
                    </Grid>
                    <Divider className="game-divider"/>
                    <Grid item className="game-menu-stats-text" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            you
                        </Typography>
                    </Grid>
                    <Grid item className="game-menu-stats-value" xs={6}>
                        <Typography variant="h6" className="game-menu-typography-white">
                            {this.props?.userInfo?.contestCounter2 ?? 0}
                        </Typography>
                    </Grid>
                </Grid>            
            </div>
        )
    }

    renderHelpModal() {
        return (
            <Dialog
                open={this.state.helpModalOpen}
                onClose={this.handleHelpModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tutorial</DialogTitle>
                <DialogContent id="alert-dialog-description" className="game-menu-help-modal-content">
                    <iframe 
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/adLp2uoOhwQ" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleHelpModalClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

}

const styles = theme => ({
    drawerPaper: {
        height: 'calc(100% - 80px)',
        top: 80,
        backgroundColor: '#3465b0',
    }
});

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        userPoles: state.firestore.userPoles,
        userBoats: state.firestore.userBoats,
        userTheme: state.firestore.userTheme,
        userCurrentPole: state.game.userCurrentPole,
        userCurrentBoat: state.game.userCurrentBoat,
        condensedLayout: state.firestore.condensedLayout,
        currentLeaderboard: state.firestore.currentLeaderboard,
        overallLeaderboard: state.firestore.overallLeaderboard,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentPole: (pole) => dispatch(setCurrentPole(pole)),
        setCurrentBoat: (boat) => dispatch(setCurrentBoat(boat)),
        getLeaderboards: () => dispatch(getLeaderboards()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameMenu))