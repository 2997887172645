import { Divider, Grid, Grow, Paper, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import './News.css';
import Footer from '../components/Footer/Footer';
import deepSea from  '../static/images/news/hunting.png'
import hunting1 from  '../static/images/news/hunting.png'
import hunting2 from  '../static/images/news/huntingTimeline.png'
import channel1 from  '../static/images/news/s6Events.png'
import tournament from  '../static/images/news/s5Tournaments.png'
import surf1 from  '../static/images/news/April.png'
import surf2 from  '../static/images/news/s4TimeLine3.png'
import surfSpecial from  '../static/images/news/s4TimeLine2.png'
import surfStart from  '../static/images/news/s4TimeLine.png'
import febSpecial from  '../static/images/news/s3TimeLine.png'
import s7Event from  '../static/images/news/s7Event.png'
import pirateDerby from  '../static/images/news/PirateDerbyCalendar.png'

class News extends Component {
    render() {
        return (
            <div className="home-page">
                <Grow in={true} style={{transformOrigin: 'top'}}>
                    <Paper className="home-page-paper" elevation={2}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                {this.renderEtherfishingNews()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderWorkWithUs()}
                                <Footer/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </div>
        );
    }

    renderEtherfishingNews() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="h2" className="home-page-content-title">Etherfishing News</Typography>
                <Divider classes={{root: classes.divider}} className="home-page-content-divider"/>
                <Typography variant="h4" className="home-page-content-title">February 10th, 2023</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            World Tour: Japan
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                            We have journeyed to the mystical waters outside Kanagawa where we can fish with a beautiful view of Mt. Fuji in the background.<br/>
                            This season has 4000 fish, featuring: 35 Golden Etherfish, 100 themed Legendaries & 10 new species!<br/>
                            <br/>
                            Major Change:<br/>
                            1. Private locations are out! Check out the new Locations Menu to see where you can fish! <br/>
                            NOTICE: Each location requires you to use specific Boat NFTs to fish!<br/>
                            2. NFTs are almost completely caught up! All fish you own will be in your wallets soon along with any boat or pole NFT previously purchased and trophies as well.<br/>
                            3. Log-in Bonuses! Interact with the website every 24 hours to get rewards that increase over the week. Check out when to log in next in your profile.<br/>
                            <br/>
                        </Typography>
                        {/*<Grid item xs={12}>
                            <img className="home-page-news-image" src={newsImage2} alt=""/>
                        </Grid>*/}
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid><Typography variant="h4" className="home-page-content-title">February 5th, 2023</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Private locations are out! First Location: North Pole
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                            We are turning back the time to the holidays we missed with this first alternate location.<br/>
                            This will be one of the few alternate locations that will be open to everyone!<br/>
                            Use promocode "ToTheNorth" for a free Candy Cane Boat. This boat is necessary to fish at the North Pole.<br/>
                            Catch all new legendary fish like the santa etherfish, helper elf etherfish, 2023 etherfish and standing penguins!<br/>
                            <br/>
                        </Typography>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid> 
                <Typography variant="h4" className="home-page-content-title">September 29th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 8: Tropical Getaway
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                        Take a load off, grab a drink and catch some fish in this luxurious tropical setting.<br/>
                            This season has 4000 fish, featuring 25 Golden Etherfish, 50 Legendaries, 12 new species, and more!<br/>
                            <br/>
                            Major Changes:<br/>
                            1. Daily contests that reset every day at 8PM EST.<br/>
                            2. Cast Log located on profile page and in game menu (shows up to last 5 casts).<br/>
                            3. New Hybrid bait! A mix of super and legendary with +3% etherfish catch % and +2% etherfish catch rarity.<br/> 
                            4. Bait Upgrading! Upgrade 3 of a lower tier bait for 1 of the next highest or 3 super bait for 1 cast!<br/>
                            <br/>
                        </Typography>
                        {/*<Grid item xs={12}>
                            <img className="home-page-news-image" src={newsImage2} alt=""/>
                        </Grid>*/}
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>   
                <Typography variant="h4" className="home-page-content-title">September 18th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Pirate Derbies!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                            20 fast paced derbies containg 25 fish each!<br/>
                            Build your pirate crew. Each derby has two 5 trait pirates and a legendary pirate, totaling 60 new pirates added during the derbies<br/>
                            <br/>
                            Major Changes:<br/>
                            1. Cast Log located on profile page and in game menu (shows up to last 5 casts).<br/>
                            2. New Hybrid bait! A mix of super and legendary with +3% etherfish catch % and +2% etherfish catch rarity. Only avaiable in the marketplace. <br/> 
                            3. Bait Upgrading! Upgrade 3 of a lower tier bait for 1 of the next highest or 3 super bait for 1 cast!<br/><br/>
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={pirateDerby} alt=""/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">July 21st, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 7: The Open Blue
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">    
                            We have trekked into some dangerous waters, for the chance at catching extremely rare fish!<br/>
                            This season has 5000 fish, featuring: 25 Golden Etherfish, 40 Pirate Legendaries, 14 new species, and the largest variety of Saltwater fish/animals to catch!<br/>
                            <br/>
                            Major Changes:<br/>
                            1. New Profile Layout! Easier GUI for players thinking ahead for future updates.<br/>
                            2. NEW REFERRAL LINKS! Custom Links with your Code!!<br/> 
                            3. Wheel'O'Fish is HERE! Buy Spins or earn them with extra collectibles for great prizes!<br/>
                            4. New leader board! Over the last derbies deployed a new feature to keep track of where you are in contests!<br/>   
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={s7Event} alt=""/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">July 5th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            STATUS UPDATE
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Jumpnet Enjin network has been unreliable for some time and caught fish, along with other NFTs, 
                            will take a while to mint to your account. We are doing everything we can to fix this, but this 
                            is up to Enjin to fully resolve for us all. Fear not and please be patient, we will get everyone 
                            their NFTs in due time. In the meantime, we are hammering out other important updates while working 
                            alongside Enjin to resolve our biggest issue!<br/>   
                        </Typography>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>  
                <Typography variant="h4" className="home-page-content-title">July 4th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            HUNTING DERBIES!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                        20 Derbies with 50 NFTs EACH!<br/>
                        1 Golden & 1 Fish Packin Heat.<br/>
                        Check event times in discord or on the Game Screen, Extra Fishing Bounties in Discord! Happy Hunting!<br/>   
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={hunting1} alt=""/>
                        </Grid>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={hunting2} alt=""/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">June 17th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 6: The Channel    
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            We traveled up the channel until it met the Sea, where we had a a great oceanic view and a huge variety of Freshwater and Saltwater fish to catch! <br/>
                            This season has 3000 fish, featuring 25 Golden Etherfish, 70+ species total, 13 new species. and 5 redrawn species!<br/>
                            <br/>
                            Major Changes:<br/>
                            New Game Mechanic- Choose where you cast! Choose any of the 5 Zones BEFORE you cast!<br/>
                            New Visuals- New pop-ups including a "PERFECT!!" pop-up if you complete the game without missing a tap!<br/>
                            New Cast Stats Screen- See the details of your Cast after the game ends!<br/><br/>   
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={channel1} alt=""/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">May 31th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 5 has ENDED!    
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Season 5 was very exciting and was fished out in just 2 weeks! Now we are onto Wakeboarding Derbies and there are so many contests to participate in! Make sure to join us in discord to reap the benefits!<br/>
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={channel1} alt=""/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">May 13th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 5: Tournament Lake
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            The gorgeous and pristine Tournament Lake is home to the largest variety of Freshwater Game Fish, calling fishers from all over the world to test their skill! There are MANY Tournaments and Contest running throughout the Season!<br/>
                            This season has 2500 fish and featuring 12 new species.<br/>
                            Major Changes:<br/>
                            More Golden Etherfish, from 15 to 25! (25-500 JENJ, 1800 JENJ Total)<br/>
                            New Game Mechanic- If you don't get any "-1's", you get a +1% to fish rarity!<br/>
                            New Game Mechanic- You get a Catch Percentage Bonus the faster you complete the game! Starting from 3% at 15 seconds left, down to 0 at 0 seconds left.<br/>
                            <br/>
                            Join Discord for the Latest News and Contests!
                        </Typography>
                        <Grid item xs={12}>
                            <img className="home-page-news-image" src={tournament} alt=""/>
                    </Grid>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">April 29th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 4 has ended
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Surfing Derbies will begin 4/29 @11 PM EST. These are faced-paced crab shoots for a Golden Etherfish 
                            or a Legendary Surfer Etherfish with Free Entry to Fishcord's "Ride The Wave" Game for Prizes! Most 
                            caught EACH Derby gets 5 Free Casts!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img className="home-page-news-image" src={surf1} alt=""/>
                    </Grid>
                    <Grid item xs={12}>
                        <img className="home-page-news-image" src={surf2} alt=""/>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">April 17th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 4 Special Event!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Shark Attack! The fish are scared into a Frenzy! +3% Catch Rate, -3% Collectibles<br/>
                            Special Events only last around a day, join Discord for more reminders!
                        </Typography>
                        <img className="home-page-news-image" src={surfSpecial} alt=""/>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">April 8th, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 4 and Fishcord AMA
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Season 4 starts April 8th at 7 PM EST with 2500 Etherfish NFTs 
                            including 15 Golden Etherfish worth 1900 JENJ! Over 20 New Species 
                            and all new limited artwork! We will also be hosting an AMA in Discord 
                            on 4/9 at 4PM EST with other fun events planned!
                        </Typography>
                        <img className="home-page-news-image" src={surfStart} alt=""/>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">February 28, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 3: Special Event Over
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            We're about halfway throught the season now with lots of fish caught over this event! A few goldens and a handful of legendaries were some prize fish that were caught. 
                            Along with the event concluding, We did some tweaking to fish scaling and speed to work better with legendary trait animations and 
                            sizing of each fish based on its metadata. Sizing is based on number of traits, type of trait, if it's a premier fish and a base species scale.
                            <br></br>
                            As of now, there are just over 2500 fish with 9 goldens remaining worth a total of 1350 JENJ. Happy fishing! 
                        </Typography>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">February 22, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 3: Special Event
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            This fun, themed event is going on In-Game and in Discord! There are increased NFT Catch Percentages and a boatload of rare NFTs left to catch!                            {<br></br>}  
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img className="home-page-news-image" src={febSpecial} alt=""/>
                    </Grid>
                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">February 4, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 3: DEEP SEA
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            5000 NFTs to be caught including 15 Golden Etherfish worth over $5000! Join our Discord to enter in the fun fishing events all season long!"
                            {<br></br>}  
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img className="home-page-news-image" src={deepSea} alt=""/>
                    </Grid>

                    <Typography variant="h6">
                        {<br></br>}  
                    </Typography>
                </Grid>
                
                <Typography variant="h4" className="home-page-content-title">January 7, 2022</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Season 2 Begins!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Season 2 has started with a variety of updates to gameplay! 2500 Etherfish NFTs with 10 Golden Etherfish totaling 1500 JENJ!  Don't miss the catch of a lifetime!
                            {<br></br>}{<br></br>}  
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">November 10, 2021</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            New Update!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Check out our new <b><a className="home-page-link" href="/species-and-traits/" target="_">species and traits page</a></b> that shows you the data on all Etherfish!
                            Due to a slight delay with Enjin, we had to postpone the Marketplace updates but they will be coming soon along with all Non-Fish NFTs!
                            Make sure to create and attach your Enjin Wallet to receive your NFTs!. 
                            {<br></br>}{<br></br>}  
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h4" className="home-page-content-title">November 09, 2021</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Tokens are here! This is part 1 of 2 for our token update.
                            This update includes the minting of all non-fish NFTs to users wallets. 
                            NFT Items included in this update: Etherfishing Rod, Etherfishing Boat, Halloween Boat, Halloween Rod, Background 6, Background 7, Orange Kayak, Luxury Boat, Red Demon Pole, Green Machine Pole, Royal Purple Pole, Deep Blue Pole.
                            Backgrounds 1-5 are available for owners of premier fish and will not be minted as seperate tokens.
                            Part 2 will contain the minting of fish as you catch them and minting of all prior caugh fish, trophy fish, and your beta fish for those who participated in the beta (does not include premier fish, #000 - #999).
                            To purchase any of the tokens, your Enjin Wallet is required and you can add an Ethereum wallet for some purchases. 
                            Currently Having more than one of the same pole does not mean an extra pole.
                            This will change in future so that 3 of the same pole will have 3 seperate timers. 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Another big change is your Enjin wallet is now required to fish at all now.
                            We are doing this in preperation to start minting everyone their fish that they have caught.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Check out our new <b><a className="home-page-link" href="/species-and-traits/" target="_">species and traits page</a></b>. This is a complete breakdown updated daily for now and soon updated automatically.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Any new users who join with a referral code and make a purchase, now get 1 super bait for free.{<br></br>}{<br></br>}</Typography>
                    </Grid>
                </Grid>
                

                <Typography variant="h4" className="home-page-content-title">September 29, 2021</Typography>
                <Grid container spacing={2} className="home-page-content-news">
                    <Grid item xs={12}>
                        <Typography variant="h6">Season 1 has started! Total of 5000 Etherfish NFTs including 5 Golden Etherfish totaling 2500 Enjin Coin!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Game Tutorial</Typography>
                        <iframe 
                            className="youtube-video"
                            src="https://www.youtube.com/embed/mZ4qZmvYtxA" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">IRL Fishing Contest 2 is on-going in Discord! Win a Trophy for your Case in your profile and a Custom Etherfish NFT!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Weekly Promotion Contests have started! <br/><br/></Typography>
                    </Grid>
                </Grid>
                
                <Grid className="home-page-content-news" item xs={12}>
                        <Typography variant="h5">Join Discord for the Latest News and Contests!</Typography>
                </Grid>
            </div>
        )
    }

    renderWorkWithUs() {
        const { classes } = this.props;
        return (
            <div className="about-page-summary-container">
                <Typography variant="h3">Work With Us</Typography>
                <Divider classes={{root: classes.divider}} className="about-page-content-divider"/>
                <Typography variant="h5">
                    Want to work with us? Want to promote yourself or your business? Want to do some collaboration with Etherfishing?
                    {<div><br/></div>}
                    We have many different options available and are very interested and excited to work with other projects!
                </Typography>
            </div>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
    icon: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconActive: {
        color: "#6690cb",
    },
    iconCompleted: {
        color: "#3465b0",
    },
    imageIcon: {
        height: '100%',
        width: '100%',
    },
    iconRoot: {
        textAlign: 'center'
    }
});

export default (withStyles(styles)(News))