import React, { Component } from 'react';
import p5 from "p5";
/*eslint-disable*/

import wheelImg from '../static/images/wheelImg.png';
import arrowImg from '../static/images/SpinArrow.png';
import { connect } from 'react-redux';
import { spinEnd } from '../store/actions/wofActions';

class WheelRender extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

        this.myRef = React.createRef()
    }

    Sketch = (p) => {
        //image renders
        let wheel
        let arrow

        //spin variables
        let deg = 0
        let degOffset = 0
        let degOffsetMax
        let slowRate
        let lastSlow
        //let endDeg
        //let init2

        //format variables
        const SMALL_SCALER = 1.5
        const LARGE_SCALER = .9
        const ARROW_SACLER = 0.6
        let small = false

        //timer variables
        let init = false
        let timer = 0

        p.preload = () => {
            wheel = p.loadImage(wheelImg)
            arrow = p.loadImage(arrowImg)
        }

        p.setup = () => {
            reset()
        }

        p.windowResized = () => {
            reset()
        }

        const reset = () => {
            //check if small
            if(window.innerWidth > 500) {
                p.createCanvas(500, 600)
                small = false
            }else {
                p.createCanvas(310, 400)
                small = true
            }
        }
    
        //once per tic...
        p.draw = () => {
            //set origin
            p.translate(p.width/2, p.height/2);
            
            if(this.props.isSpinning === 1) {
                if(this.props.spin === 0) { //if waiting or result
                    deg += 4   
                } else {
                    if(!init) {
                        timer = Date.now() + 3 * 1000
                        degOffset = 45 * (this.props.spin - 1) + 5 + Math.floor(Math.random() * 35) //5-40 degrees
                        degOffsetMax = 45 * this.props.spin - 5
                        lastSlow = Date.now()
                        slowRate = 1
                        init = true
                        //init2 = false
                        //endDeg = 0
                    }

                    /*
                    //dynamic stopping WIP
                    if(timer - Date.now() < 0) {
                        if(!init2) {
                            endDeg = deg - (deg%360) + 360 * 1 + degOffset
                            init2 = true
                        }
                        if(deg<endDeg) {
                            
                        } else {
                            init = false
                            this.props.spinEnd()
                        }           
                    } else {
                        deg += 4 
                    }*/

                    //if in range to stop and timer is up
                    if(init && deg % 360 > degOffset && deg % 360 <= degOffsetMax && timer - Date.now() < 0) {
                        init = false
                        this.props.spinEnd()
                    } else { //else slow down, but dont stop
                        deg += 0.12 + 3.8 * slowRate
                        //slow rate of speed every .3 seconds (aquired through testing)
                        if(Date.now() - lastSlow > 280 && timer + 1000 - Date.now() > 0) { 
                            slowRate = slowRate * 0.9
                            lastSlow = Date.now()
                        }
                    }
                }
            }

            //render wheel
            p.push()
            p.rotate(p.PI/180 * deg)
            p.imageMode(p.CENTER)
            if(small)
                p.image(wheel,0,0,wheel.height/SMALL_SCALER,wheel.width/SMALL_SCALER)
            else
                p.image(wheel,0,0,wheel.height/LARGE_SCALER,wheel.width/LARGE_SCALER)
            p.pop()

            //render arrow
            p.imageMode(p.CENTER)
            if(small)
                p.image(arrow,0,-290/2,arrow.height/SMALL_SCALER*ARROW_SACLER,arrow.width/SMALL_SCALER*ARROW_SACLER)
            else
                p.image(arrow,0,-500/2,arrow.height/LARGE_SCALER*ARROW_SACLER,arrow.width/LARGE_SCALER*ARROW_SACLER)
        }
    }


    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current)
    }

    componentWillUnmount() {
        this.myP5.remove()
    }

    render() {
        return (
            <div ref={this.myRef} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.firestore.userInfo,
        isSpinning: state.wof.spinning,
        spin: state.wof.spin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        spinEnd: () => dispatch(spinEnd()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WheelRender)