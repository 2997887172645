import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { clearSnackbar } from "../../store/actions/snackbarActions";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function ErrorSnackbar() {
  const dispatch = useDispatch();

  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    state => state.snackbar
  );

  const messageSplit = errorSnackbarMessage.split('\n')

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={errorSnackbarOpen}
      autoHideDuration={8000}
      onClose={handleClose}
    >
        <Alert onClose={handleClose} severity='error'>
            <AlertTitle>{messageSplit[0]}</AlertTitle>
            {messageSplit[1]}
        </Alert>
    </Snackbar>
  );
}