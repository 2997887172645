import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { sendVerificationEmail, setOpenVerifyEmail } from "../../../store/actions/authActions";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../store/actions/snackbarActions";
import './VerifyEmail.css';

class VerifyEmail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sendingVerificationEmail: false,
            verifyEmailModalOpen: false,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSendEmailVerification = this.handleSendEmailVerification.bind(this)
    }

    handleCloseModal() {
        this.props.setOpenVerifyEmail(false)
    }

    handleSendEmailVerification() {
        this.setState({sendingVerificationEmail: true}, async () => {
            await this.props.sendVerificationEmail()
            this.setState({sendingVerificationEmail: false})
            if (this.props.authError) {
                this.props.showErrorSnackbar(`Verification Email Error\n${this.props.authError}`)
            } else {
                this.props.showSuccessSnackbar(`Verification Email Sent!\n Check inbox for details`)
                this.props.setOpenVerifyEmail(false)
            }
        })
    }

    render() {
        return (
            <Dialog
                open={this.props.verifyEmailOpen}
                onClose={this.handleCloseModal}
            >
                <DialogTitle>Verify Email</DialogTitle>
                <DialogContent className="verify-email-content">
                    <Typography>You must verify your email to use the Free Pole</Typography>
                    <Button
                        className="verify-email-button"
                        color="primary"
                        variant="contained"
                        onClick={this.handleSendEmailVerification}
                    >
                        {this.state.sendingVerificationEmail ? 
                            <CircularProgress size='1.5rem' style={{ color: 'white' }}/>
                        :    
                            <Typography variant="h6">Send email verification</Typography>
                        }
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={this.handleCloseModal}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        verifyEmailOpen: state.auth.verifyEmailOpen,
        authError: state.auth.authError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendVerificationEmail: () => dispatch(sendVerificationEmail()),
        setOpenVerifyEmail: (isOpen) => dispatch(setOpenVerifyEmail(isOpen)),
        showErrorSnackbar: (msg) => dispatch(showErrorSnackbar(msg)),
        showSuccessSnackbar: (msg) => dispatch(showSuccessSnackbar(msg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)