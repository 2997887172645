const initState = {
    order: [],
    curTotal: 0,
    updatedOrder: 0,
}

const marketReducer = (state = initState, action) => {
    switch(action.type) {
        case 'UPDATE_ORDER':
            return {
                ...state,
                order: action.order,
                curTotal: action.curTotal,
                updatedOrder: state.updatedOrder + 1,
            }
        case 'CLEAR_ORDER':
            return {
                ...state,
                order: [],
                curTotal: 0,
                updatedOrder: state.updatedOrder + 1,
            }
        default:
            return state
    }
}

export default marketReducer