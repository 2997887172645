import { Link } from 'react-router-dom';
import React, { Component } from "react";
import { MenuItems } from "./MenuItems";
import './Navbar.css'
import { connect } from 'react-redux';
import { AppBar, Drawer, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import logo from "../../static/images/logo.png";
import { swapSong } from '../../store/actions/audioActions';
import { updateUserInfo } from '../../store/actions/firestoreActions';

class Navbar extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            clicked: false    
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState({clicked: !this.state.clicked})
    }

    render() {
        const playMusic = this.props.userPlayMusic
        
        return(
            <AppBar className="NavbarItems">
                <Toolbar>
                    <div className="navbar-home">
                        <Link className="navbar-home-link" to="/">
                            <img className="navbar-home-logo" src={logo} alt=""/>
                            <h1 className="navbar-home-text">Etherfishing</h1>
                        </Link>
                    </div>
                        {
                            window.innerWidth > 350 ?
                            playMusic ?
                            <IconButton onClick={() => this.props.swapSong('Play')}>
                                <VolumeUpIcon style={{color: 'white'}}/>
                            </IconButton>
                                : 
                            <IconButton onClick={() => this.props.swapSong('Pause')}>
                                <VolumeOffIcon style={{color: 'white'}}/>
                            </IconButton>
                                : null
                        }
                    <div className="navbar-spacer"/>
                    
                    {window.innerWidth > 960 ?
                        MenuItems.map((item, index) => {
                            return (
                                <Link className={item.cName} to={item.url} key={index}>
                                    {item.title === 'Profile' ? 
                                    this.props.user?.uid ? 
                                    item.title :
                                    'Login' :
                                    item.title}
                                </Link>
                            )
                        })
                    :
                        <div>
                            <IconButton 
                                onClick={() => this.setState({clicked: !this.state.clicked})}
                            ><MenuIcon className="navbar-burger-menu-icon" fontSize="large"/></IconButton>
                            <Drawer 
                                open={this.state.clicked}
                                onClose={() => this.setState({clicked: false})}
                                anchor='top'
                                color='primary'
                                className="navbar-home-drawer"
                            >
                                {MenuItems.map((item, index) => {
                                    return (
                                        <Link 
                                            className={item.cName} 
                                            style={{backgroundColor: '#3465b0'}} 
                                            to={item.url} 
                                            onClick={() => this.setState({clicked: false})}
                                            key={index}
                                        >
                                            {item.title === 'Profile' ? 
                                            this.props.user.uid ? 
                                            item.title :
                                            'Login' :
                                            item.title}
                                        </Link>
                                    )
                                })}
                            </Drawer>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.auth,
        userInfo: state.firestore.userInfo,
        songName: state.audio.songName,
        userPlayMusic: state.firestore.userPlayMusic,
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserInfo: (info) => dispatch(updateUserInfo(info)),
        swapSong: (songName) => dispatch(swapSong(songName))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Navbar)