export const getSpecies = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        await firestore.collection('species').get()
            .then((querySnapshot) => {
                let speciesArray = []

                querySnapshot.forEach((doc) => {
                    const docData = doc.data()
                    if (doc.id !== 'undefined') {
                        speciesArray.push({ speciesName: doc.id.trim(), ...docData })
                    }
                })
                dispatch({ type: 'GET_SPECIES_SUCCESS', species: speciesArray })
            })
            .catch((error) => {
                dispatch({ type: 'GET_SPECIES_ERROR', error })
            })
    }
    
}

export const getTraits = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        
        await firestore.collection('traits').get()
        .then((querySnapshot) => {
            let traitsArray = []

            querySnapshot.forEach((doc) => {
                const docData = doc.data()
                if (doc.id !== 'undefined') {
                    traitsArray.push({ traitName: doc.id.trim(), ...docData })
                }
            })

            dispatch({ type: 'GET_TRAITS_SUCCESS', traits: traitsArray })
        })
        .catch((error) => {
            dispatch({ type: 'GET_TRAITS_ERROR', error })
        })
    }
}

