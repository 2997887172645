export default function getSpeciesScale(species) {
    return speciesScale.get(species)
}

const speciesScale = new Map()
speciesScale.set("Adult Emperor Angelfish",3)
speciesScale.set("Albacore Tuna",8)
speciesScale.set("Alligator Gar",11)
speciesScale.set("Amberjack",6)
speciesScale.set("American Alligator",12)
speciesScale.set("American Crocodile",12)
speciesScale.set("Anchovy",1)
speciesScale.set("Angelfish",1)
speciesScale.set("Angler Fish",6)
speciesScale.set("Arctic Char",6)
speciesScale.set("Arowana",7)
speciesScale.set("Australian Flatback Turtle",7)
speciesScale.set("Axolotl",2)
speciesScale.set("Baird's Beaked Whale",13)
speciesScale.set("Banded Butterflyfish",2)
speciesScale.set("Banggai Cardinalfish",1)
speciesScale.set("Barracuda",7)
speciesScale.set("Basking Shark",13)
speciesScale.set("Beluga Whale",11)
speciesScale.set("Bigeye Tuna",8)
speciesScale.set("Black Crappie",1)
speciesScale.set("Black Dragonfish",8)
speciesScale.set("Black Drum",6)
speciesScale.set("Black Grouper",10)
speciesScale.set("Black Marlin",11)
speciesScale.set("Black Sea Bream",1)
speciesScale.set("Blacktip Reef Shark",12)
speciesScale.set("Blobfish",4)
speciesScale.set("Blue Catfish",5)
speciesScale.set("Blue Marlin",11)
speciesScale.set("Blue Ring Angelfish",2)
speciesScale.set("Blue Ringed Octopus",9)
speciesScale.set("Blue Shark",11)
speciesScale.set("Blue Whale",17)
speciesScale.set("Blueface Angelfish",4)
speciesScale.set("Bluegill",3)
speciesScale.set("Bonito Tuna",8)
speciesScale.set("Bottlenose Dolphin",10)
speciesScale.set("Bottomfeeder",3)
speciesScale.set("Bowfin",7)
speciesScale.set("Bowhead Whale",16)
speciesScale.set("Box Jellyfish",3)
speciesScale.set("Brook Trout",4)
speciesScale.set("Brown Trout",4)
speciesScale.set("Bull Trout",4)
speciesScale.set("Bullseye Snakehead",8)
speciesScale.set("Carp",7)
speciesScale.set("Catfish",5)
speciesScale.set("Chain Pikerel",6)
speciesScale.set("Channel Catfish",4)
speciesScale.set("China Rockfish",2)
speciesScale.set("Chum Salmon",8)
speciesScale.set("Clown Triggerfish",4)
speciesScale.set("Clownfish",3)
speciesScale.set("Coho Salmon",7)
speciesScale.set("Common Snook",4)
speciesScale.set("Compass Jellyfish",3)
speciesScale.set("Cookiecutter Shark",6)
speciesScale.set("Crested Penguin",7)
speciesScale.set("Crimson Sea Bream",0)
speciesScale.set("Crystal Jellyfish",3)
speciesScale.set("Cuttlefish",6)
speciesScale.set("Cuvier's Beaked Whale",11)
speciesScale.set("Dana Octopus Squid",8)
speciesScale.set("Dashing Triggerfish",3)
speciesScale.set("Deep Sea Sunfish",8)
speciesScale.set("Demselfish",1)
speciesScale.set("Discus Fish",0)
speciesScale.set("Dolphinfish",7)
speciesScale.set("Dragon Flowerhorn",4)
speciesScale.set("Dumbo Octopus",3)
speciesScale.set("Eagle Ray",4)
speciesScale.set("Dwarf Sperm Whale",8)
speciesScale.set("Emperor Penguin",9)
speciesScale.set("False Killer Whale",9)
speciesScale.set("Fin Whale",17)
speciesScale.set("Firefly Squid",0)
speciesScale.set("Flame Angelfish",2)
speciesScale.set("Flathead Catfish",5)
speciesScale.set("Florida Gar",9)
speciesScale.set("Fraser's Dolphin",10)
speciesScale.set("Freshwater Drum",5)
speciesScale.set("German Blue Ram",2)
speciesScale.set("Giant Sperm Whale",17)
speciesScale.set("Giant Squid",16)
speciesScale.set("Goblin Shark",10)
speciesScale.set("Golden Dorado",7)
speciesScale.set("Goldfish",2)
speciesScale.set("Goliath Grouper",10)
speciesScale.set("Gray Whale",17)
speciesScale.set("Great White Shark",13)
speciesScale.set("Green Sea Turtle",8)
speciesScale.set("Green Spotted Puffer",3)
speciesScale.set("Gulf Flounder",5)
speciesScale.set("Half Banded Spiny Eel",7)
speciesScale.set("Hammerhead Shark",12)
speciesScale.set("Hawksbill Turtle",8)
speciesScale.set("Hector's Dolphin",10)
speciesScale.set("Horse Mackerel",2)
speciesScale.set("Hubb's Beaked Whale",9)
speciesScale.set("Humpback Whale",17)
speciesScale.set("Japanese Sea Bass",6)
speciesScale.set("Jumbo Flying Squid",10)
speciesScale.set("Kemp's Ridley Sea Turtle",7)
speciesScale.set("Killer Whale",14)
speciesScale.set("King Fish",7)
speciesScale.set("King Penguin",8)
speciesScale.set("King Salmon",6)
speciesScale.set("Koi",6)
speciesScale.set("Lake Trout",4)
speciesScale.set("Largemouth Bass",5)
speciesScale.set("Leatherback Turtle",9)
speciesScale.set("Lightning Clownfish",1)
speciesScale.set("Loggerhead Turtle",8)
speciesScale.set("Long Nose Butterflyfish",1)
speciesScale.set("Longnose Hawkfish",1)
speciesScale.set("Long-finned Pilot Whale",10)
speciesScale.set("Longnose Gar",11)
speciesScale.set("Longtail Tuna",8)
speciesScale.set("Macaroni Penguin",6)
speciesScale.set("Mackerel",1)
speciesScale.set("Magellanic Penguin",8)
speciesScale.set("Mahi Mahi",7)
speciesScale.set("Manta Ray",9)
speciesScale.set("Maroon Clownfish",1)
speciesScale.set("Megalodon Shark",16)
speciesScale.set("Megamouth Shark",12)
speciesScale.set("Minke Whale",12)
speciesScale.set("Moorish Idol",3)
speciesScale.set("Moray Eel",9)
speciesScale.set("Mugger Crocodile",12)
speciesScale.set("Muskellunge",8)
speciesScale.set("Narwhal",12)
speciesScale.set("Nassau Grouper",8)
speciesScale.set("Nile Crocodile",12)
speciesScale.set("Nomad Jellyfish",3)
speciesScale.set("Northern Bottlenose Whale",12)
speciesScale.set("Northern Pacific Right Whale",17)
speciesScale.set("Northern Pike",7)
speciesScale.set("Northern Rockhopper Penguin",5)
speciesScale.set("Northern Spotted Bass",5)
speciesScale.set("Octopus",9)
speciesScale.set("Olive Ridley Sea Turtle",7)
speciesScale.set("Oriental Sweetlips",5)
speciesScale.set("Oscar",4)
speciesScale.set("Pacific Cod",5)
speciesScale.set("Pacific Ocean Perch",3)
speciesScale.set("Peacock Bass",6)
speciesScale.set("Pennant Butterflyfish",1)
speciesScale.set("Picasso Clownfish",1)
speciesScale.set("Pink Salmon",6)
speciesScale.set("Princess Parrotfish",2)
speciesScale.set("Purple Striped Jellyfish",3)
speciesScale.set("Pygmy Right Whale",8)
speciesScale.set("Queen Parrotfish",3)
speciesScale.set("Quillback Rockfish",2)
speciesScale.set("Rainbow Parrotfish",3)
speciesScale.set("Rainbow Trout",4)
speciesScale.set("Red Grouper",6)
speciesScale.set("Red Sea Bream",1)
speciesScale.set("Redbreast Sunfish",3)
speciesScale.set("Redeye Bass",3)
speciesScale.set("Redfish",5)
speciesScale.set("Reef Butterflyfish",1)
speciesScale.set("Reef Manta Ray",8)
speciesScale.set("Reef Triggerfish",2)
speciesScale.set("Regal Angelfish",1)
speciesScale.set("Regal Tang",3)
speciesScale.set("Risso's Dolphin",10)
speciesScale.set("Rock Beauty",2)
speciesScale.set("Rock Hopper Penguin",7)
speciesScale.set("Roosterfish",6)
speciesScale.set("Royal Dottyback",1)
speciesScale.set("Sailfin Catfish",5)
speciesScale.set("Sailfish",9)
speciesScale.set("Sardine",9)
speciesScale.set("Saw Shark",11)
speciesScale.set("Scamp Grouper",4)
speciesScale.set("Sea Lion",8)
speciesScale.set("Seal",8)
speciesScale.set("Sei Whale",17)
speciesScale.set("Short-beaked Common Dolphin",9)
speciesScale.set("Short-finned Pilot Whale",10)
speciesScale.set("Silver Salmon",6)
speciesScale.set("Skipjack Tuna",8)
speciesScale.set("Slippery Dick Wrasse",3)
speciesScale.set("Smallmouth Bass",5)
speciesScale.set("Sockeye Salmon",6)
speciesScale.set("Southern Kingfish",6)
speciesScale.set("Sowerby's Beaked Whale",10)
speciesScale.set("Spinner Dolphin",10)
speciesScale.set("Spiny Dogfish",7)
speciesScale.set("Spotfin Butterflyfish",1)
speciesScale.set("Spotted Octopus",7)
speciesScale.set("Spotted Porcupinefish",5)
speciesScale.set("Steelhead",6)
speciesScale.set("Stingray",7)
speciesScale.set("Stone Flounder",6)
speciesScale.set("Striped Bass",5)
speciesScale.set("Striped Dolphin",10)
speciesScale.set("Striped Marlin",10)
speciesScale.set("Sturgeon",12)
speciesScale.set("Sunrise Dottyback",2)
speciesScale.set("Tarpon",7)
speciesScale.set("Thailand Discusfish",3)
speciesScale.set("Threadfin Butterflyfish",3)
speciesScale.set("Threadfin Striker",3)
speciesScale.set("Thresher Shark",12)
speciesScale.set("Tiger Grouper",8)
speciesScale.set("Tiger Muskellunge",8)
speciesScale.set("Tiger Rockfish",2)
speciesScale.set("Tiger Shark",12)
speciesScale.set("Tiger Tilapia",5)
speciesScale.set("Tomato Clownfish",2)
speciesScale.set("True's Beaked Whale",8)
speciesScale.set("Trumpetfish",4)
speciesScale.set("Tuna",7)
speciesScale.set("Viper Shark",11)
speciesScale.set("Wahoo",6)
speciesScale.set("Walleye",5)
speciesScale.set("Walrus",11)
speciesScale.set("Warsaw Grouper",10)
speciesScale.set("Whale Shark",15)
speciesScale.set("White Bass",4)
speciesScale.set("White Catfish",5)
speciesScale.set("White Crappie",1)
speciesScale.set("White Marlin",9)
speciesScale.set("White Spotted Eagle Ray",5)
speciesScale.set("Whitetip Reef Shark",12)
speciesScale.set("Yellow Perch",4)
speciesScale.set("Yellow Tang",3)
speciesScale.set("Yellowfin Bream",1)
speciesScale.set("Yellowfin Grouper",8)
speciesScale.set("Yellowfin Tuna",8)
speciesScale.set("Yellowfin Clownfish",1)
speciesScale.set("Young Emperor Angelfish",2)
speciesScale.set("Zebra Moray Eel",5)

speciesScale.set("Angler Etherfish",8)
speciesScale.set("Coin Shark",13)
speciesScale.set("Etherfish",8)
speciesScale.set("Ethershark",8)
speciesScale.set("Etherwhale",8)
speciesScale.set("Etherkoi",8)
speciesScale.set("Golden Etherfish",8)
speciesScale.set("Seahorse",3)