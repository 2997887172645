import { Paper, Grid, Typography, CircularProgress, Divider } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFish, unsetFish } from '../store/actions/firestoreActions';
import './Fish.css';

class Fish extends Component {

    componentDidMount() {
        this.props.getFish(this.props.match.params.id)
    }

    componentWillUnmount() {
        this.props.unsetFish()
    }

    render() {
        return (
            <div className="fish-page">
                <Paper className="fish-page-paper" elevation={2}>
                    {this.props.currentFishError ? 
                        <Alert severity="error">
                            <AlertTitle>No fish found</AlertTitle>
                        </Alert>
                    : !this.props.currentFish ?
                        <CircularProgress/>
                    :
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                {this.renderFishImage()}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {this.renderFishStats()}
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </div>
        )
    }

    renderFishImage() {
        const fishSeason = this.props.currentFish?.fishCollection?.toUpperCase()

        return (
            <div>
                <Typography variant="h3">{this.props.currentFish?.fishNum}</Typography>
                <img key={this.props.currentFish?.localImageUrl} src={this.props.currentFish?.localImageUrl} alt="" className="fish-page-image"/>
                <Typography variant="h5">{this.props.currentFish?.species}</Typography>
                <Typography variant="body2" style={{color: 'grey'}}>{fishSeason}</Typography>
            </div>
        )
    }

    renderFishStats() {
        const { classes } = this.props;
        const fish = this.props.currentFish

        return (
            <div>
                <Typography variant="h4">Traits</Typography>
                <Divider classes={{ root: classes.divider }} className="fish-page-content-divider"/>
                <Grid item container spacing={5}>
                    {fish?.fishTraitsLegendary?.length > 0 ?
                        <Grid item xs={6}>
                            <Typography variant="h6"><b>Legendary</b></Typography>
                            {fish.fishTraitsLegendary.map((legendaryTrait, index) => (
                                <Typography key={index}>{legendaryTrait.trait}</Typography>
                            ))}
                        </Grid>
                        : null}
                    {fish?.fishTraitsPositive?.length > 0 ?
                        <Grid item xs={6}>
                            <Typography variant="h6"><b>Positive</b></Typography>
                            {fish.fishTraitsPositive.map((positiveTrait, index) => (
                                <Typography key={index}>{positiveTrait.trait}</Typography>
                            ))}
                        </Grid>
                        : null}
                    {fish?.fishTraitsNeutral?.length > 0 ?
                        <Grid item xs={6}>
                            <Typography variant="h6"><b>Neutral</b></Typography>
                            {fish.fishTraitsNeutral.map((neutralTrait, index) => (
                                <Typography key={index}>{neutralTrait.trait}</Typography>
                            ))}
                        </Grid>
                        : null}
                    {fish?.fishTraitsNegative?.length > 0 ?
                        <Grid item xs={6}>
                            <Typography variant="h6"><b>Negative</b></Typography>
                            {fish.fishTraitsNegative.map((negativeTrait, index) => (
                                <Typography key={index}>{negativeTrait.trait}</Typography>
                            ))}
                        </Grid>
                        : null}
                    {fish?.trophy ?
                        <Grid item xs={6}>
                            <Typography variant="h6"><b>Trophy</b></Typography>
                            <Typography>{fish.trophy}</Typography>
                        </Grid>
                        : null}
                </Grid>
            </div>
        )
    }
}

const styles = theme => ({
    divider: {
        background: "#6690cb"
    },
});

const mapStateToProps = (state) => {
    return {
        currentFish: state.firestore.currentFish,
        currentFishError: state.firestore.currentFishError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFish: (id) => dispatch(getFish(id)),
        unsetFish: () => dispatch(unsetFish()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Fish))
